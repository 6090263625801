import { call, put, takeEvery } from 'redux-saga/effects';
import {
  DISCOUNT_PROMOTION_ADD_NEW,
  DISCOUNT_PROMOTION_DELETE,
  DISCOUNT_PROMOTION_GET_BY_ID,
  DISCOUNT_PROMOTION_GET_PAGING,
  DISCOUNT_PROMOTION_UPDATE,
} from './actionType';

import {
  addDiscountPromotionSuccess,
  addDiscountPromotionError,
  getPagingDiscountPromotionSuccess,
  getPagingDiscountPromotionError,
  getDiscountPromotionByIdSuccess,
  getDiscountPromotionByIdError,
  updateDiscountPromotionSuccess,
  updateDiscountPromotionError, deleteDiscountPromotionSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// DiscountPromotion
const getPagingDiscountPromotionRequest = query => get("/api/DiscountPromotion/paging", { params: query });
const addNewDiscountPromotionRequest = data => post("/api/DiscountPromotion", data);
const getDiscountPromotionByIdRequest = id => get(`/api/DiscountPromotion/${id}`);
const updateDiscountPromotionRequest = data => update("/api/DiscountPromotion", data);
const deleteDiscountPromotionRequest = id => del(`/api/DiscountPromotion/${id}`);

function* addNewDiscountPromotion({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(addNewDiscountPromotionRequest, data);
    yield put(addDiscountPromotionSuccess(response));
    history.push('/discount-promotion');
    
    // response = yield call(getPagingDiscountPromotionRequest, queryParams);
    // yield put(getPagingDiscountPromotionSuccess(response));
  } catch (error) {
    yield put(addDiscountPromotionError(error.response.statusText));
  }
}

function* updateDiscountPromotion({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateDiscountPromotionRequest, data);
    yield put(updateDiscountPromotionSuccess(response));
    history.push('/discount-promotion')
    // response = yield call(getPagingDiscountPromotionRequest, queryParams);
    // yield put(getPagingDiscountPromotionSuccess(response));
  } catch (error) {
    yield put(updateDiscountPromotionError(error.response.statusText));
  }
}

function* getPagingDiscountPromotion({ payload }) {
  try {
    let response = yield call(getPagingDiscountPromotionRequest, payload);
    yield put(getPagingDiscountPromotionSuccess(response));
  } catch (error) {
    yield put(getPagingDiscountPromotionError(error.response.statusText));
  }
}

function* getDiscountPromotionById({ payload }) {
  try {
    let response = yield call(getDiscountPromotionByIdRequest, payload);
    yield put(getDiscountPromotionByIdSuccess(response));
  } catch (error) {
    yield put(getDiscountPromotionByIdError(error.response.statusText));
  }
}

function* deleteDiscountPromotion({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteDiscountPromotionRequest, data);

    yield put(deleteDiscountPromotionSuccess(response));
    response = yield call(getPagingDiscountPromotionRequest, queryParams);
    yield put(getPagingDiscountPromotionSuccess(response));
  } catch (error) {
    yield put(getPagingDiscountPromotionError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(DISCOUNT_PROMOTION_ADD_NEW, addNewDiscountPromotion);
  yield takeEvery(DISCOUNT_PROMOTION_UPDATE, updateDiscountPromotion);
  yield takeEvery(DISCOUNT_PROMOTION_GET_PAGING, getPagingDiscountPromotion);
  yield takeEvery(DISCOUNT_PROMOTION_GET_BY_ID, getDiscountPromotionById);
  yield takeEvery(DISCOUNT_PROMOTION_DELETE, deleteDiscountPromotion);
}

export {
  getPagingDiscountPromotionRequest
}

