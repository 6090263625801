import {
  SALE_ORDER_INVOICE_ADD_NEW,
  SALE_ORDER_INVOICE_ADD_NEW_ERROR,
  SALE_ORDER_INVOICE_ADD_NEW_SUCCESS, SALE_ORDER_INVOICE_CLEAR_NOTIFY,
  SALE_ORDER_INVOICE_DELETE,
  SALE_ORDER_INVOICE_DELETE_ERROR,
  SALE_ORDER_INVOICE_DELETE_SUCCESS,
  SALE_ORDER_INVOICE_DETAIL_GET_BY_ID,
  SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_ERROR,
  SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS,
  SALE_ORDER_INVOICE_GET_BY_ID,
  SALE_ORDER_INVOICE_GET_BY_ID_ERROR,
  SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS,
  SALE_ORDER_INVOICE_GET_PAGING,
  SALE_ORDER_INVOICE_GET_PAGING_ERROR,
  SALE_ORDER_INVOICE_GET_PAGING_SUCCESS,
  SALE_ORDER_INVOICE_UPDATE,
  SALE_ORDER_INVOICE_UPDATE_ERROR,
  SALE_ORDER_INVOICE_UPDATE_SUCCESS,
} from './actionType';

// SaleOrderInvoice
export const getPagingSaleOrderInvoice = items => ({
  type: SALE_ORDER_INVOICE_GET_PAGING,
  payload: items,
});

export const getPagingSaleOrderInvoiceSuccess = items => ({
  type: SALE_ORDER_INVOICE_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingSaleOrderInvoiceError = error => ({
  type: SALE_ORDER_INVOICE_GET_PAGING_ERROR,
  payload: error,
});

export const addSaleOrderInvoice = items => ({
  type: SALE_ORDER_INVOICE_ADD_NEW,
  payload: items,
});

export const addSaleOrderInvoiceSuccess = items => ({
  type: SALE_ORDER_INVOICE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addSaleOrderInvoiceError = error => ({
  type: SALE_ORDER_INVOICE_ADD_NEW_ERROR,
  payload: error,
});

export const getSaleOrderInvoiceDetailById = items => ({
  type: SALE_ORDER_INVOICE_DETAIL_GET_BY_ID,
  payload: items,
});

export const getSaleOrderInvoiceDetailByIdSuccess = items => ({
  type: SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getSaleOrderInvoiceDetailByIdError = error => ({
  type: SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_ERROR,
  payload: error,
});

export const getSaleOrderInvoiceById = items => ({
  type: SALE_ORDER_INVOICE_GET_BY_ID,
  payload: items,
});

export const getSaleOrderInvoiceByIdSuccess = items => ({
  type: SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getSaleOrderInvoiceByIdError = error => ({
  type: SALE_ORDER_INVOICE_GET_BY_ID_ERROR,
  payload: error,
});

export const updateSaleOrderInvoice = items => ({
  type: SALE_ORDER_INVOICE_UPDATE,
  payload: items,
});

export const updateSaleOrderInvoiceSuccess = items => ({
  type: SALE_ORDER_INVOICE_UPDATE_SUCCESS,
  payload: items,
});

export const updateSaleOrderInvoiceError = error => ({
  type: SALE_ORDER_INVOICE_UPDATE_ERROR,
  payload: error,
});

export const deleteSaleOrderInvoice = items => ({
  type: SALE_ORDER_INVOICE_DELETE,
  payload: items,
});

export const deleteSaleOrderInvoiceSuccess = items => ({
  type: SALE_ORDER_INVOICE_DELETE_SUCCESS,
  payload: items,
});

export const deleteSaleOrderInvoiceError = error => ({
  type: SALE_ORDER_INVOICE_DELETE_ERROR,
  payload: error,
});

export const clearSaleOrderInvoiceNotify = () => ({
  type: SALE_ORDER_INVOICE_CLEAR_NOTIFY,
});
