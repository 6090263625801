import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STOCK_ADJUSTMENT_ADD_NEW,
  STOCK_ADJUSTMENT_DELETE,
  STOCK_ADJUSTMENT_GET_BY_ID,
  STOCK_ADJUSTMENT_GET_PAGING,
  STOCK_ADJUSTMENT_UPDATE,
} from './actionType';

import {
  addStockAdjustmentSuccess,
  addStockAdjustmentError,
  getPagingStockAdjustmentSuccess,
  getPagingStockAdjustmentError,
  getStockAdjustmentByIdSuccess,
  getStockAdjustmentByIdError,
  updateStockAdjustmentSuccess,
  updateStockAdjustmentError,
  deleteStockAdjustmentSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";
import { updateStockOutError, updateStockOutSuccess } from "../stock-out/actions";

// StockAdjustment
const getPagingStockAdjustmentRequest = query => get("/api/StockAdjustment/paging", { params: query });
const addNewStockAdjustmentRequest = data => post("/api/StockAdjustment", data);
const getStockAdjustmentByIdRequest = id => get(`/api/StockAdjustment/${id}`);
const updateStockAdjustmentRequest = data => update("/api/StockAdjustment", data);
const deleteStockAdjustmentRequest = id => del(`/api/StockAdjustment/${id}`);

const getStockAdjustmentToExcel = async (fileName) => {
  return await getFile(`/api/StockAdjustment/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* addNewStockAdjustment({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(addNewStockAdjustmentRequest, data);
    yield put(addStockAdjustmentSuccess(response));
    history.push('/stock-adjustment')
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(addStockAdjustmentError(err));
  }
}

function* updateStockAdjustment({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateStockAdjustmentRequest, data);
    yield put(updateStockAdjustmentSuccess(response));
    history.push('/stock-adjustment')
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(updateStockAdjustmentError(err));
  }
}

function* getPagingStockAdjustment({ payload }) {
  try {
    let response = yield call(getPagingStockAdjustmentRequest, payload);
    yield put(getPagingStockAdjustmentSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockAdjustmentError(err));
  }
}

function* getStockAdjustmentById({ payload }) {
  try {
    let response = yield call(getStockAdjustmentByIdRequest, payload);
    yield put(getStockAdjustmentByIdSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getStockAdjustmentByIdError(err));
  }
}

function* deleteStockAdjustment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteStockAdjustmentRequest, data);

    yield put(deleteStockAdjustmentSuccess(response));
    response = yield call(getPagingStockAdjustmentRequest, queryParams);
    yield put(getPagingStockAdjustmentSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockAdjustmentError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(STOCK_ADJUSTMENT_ADD_NEW, addNewStockAdjustment);
  yield takeEvery(STOCK_ADJUSTMENT_UPDATE, updateStockAdjustment);
  yield takeEvery(STOCK_ADJUSTMENT_GET_PAGING, getPagingStockAdjustment);
  yield takeEvery(STOCK_ADJUSTMENT_GET_BY_ID, getStockAdjustmentById);
  yield takeEvery(STOCK_ADJUSTMENT_DELETE, deleteStockAdjustment);
}

export {
  getPagingStockAdjustmentRequest,
  getStockAdjustmentToExcel
}

