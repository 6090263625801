import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERIES,
  ADD_NEW_DELIVERY,
  GET_DELIVERY_BY_ID,
  UPDATE_DELIVERY,
  DELETE_DELIVERY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveriesSuccess,
  getDeliveryByIdSuccess,
  getDeliveriesFail,
  addNewDeliverySuccess,
  addNewDeliveryError,
  updateDeliverySuccess,
  deleteDeliverySuccess,
  deleteDeliveryError,
} from "./actions";

// Delivery
const getDeliveriesRequest = query =>
  post("/api/Delivery/paging", query);

const addNewDeliveryRequest = role => post("/api/Delivery", role);
const getDeliveryByIdRequest = id => get(`/api/Delivery/${id}`);
const updateDeliveryRequest = role => update("/api/Delivery", role);
const deleteDeliveryRequest = id => del(`/api/Delivery/${id}`);

function* fetchDeliveries({ payload }) {
  try {
    const response = yield call(getDeliveriesRequest, payload);
    yield put(getDeliveriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveriesFail(error));
  }
}

function* addNewDelivery({ payload }) {
  try {
    const { history, data } = payload;
    let response = yield call(addNewDeliveryRequest, data);
    yield put(addNewDeliverySuccess(response));
    history.push(`/delivery/${response}`);
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(addNewDeliveryError(error));
  }
}

function* updateDelivery({ payload }) {
  try {
    let response = yield call(updateDeliveryRequest, payload);
    response = yield call(getDeliveryByIdRequest, response);
    yield put(getDeliveryByIdSuccess(response));
    yield put(updateDeliverySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveriesFail(error));
    yield put(getDeliveriesFail(error));
  }
}

function* getDeliveryById({ payload }) {
  try {
    var response = yield call(getDeliveryByIdRequest, payload);
    yield put(getDeliveryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveriesFail(error));
  }
}

function* deleteDelivery({ payload }) {
  try {
    yield call(deleteDeliveryRequest, payload);
    const response = yield call(getDeliveriesRequest, {});
    yield put(getDeliveriesSuccess(response));
    yield put(deleteDeliverySuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(deleteDeliveryError(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_DELIVERIES, fetchDeliveries);
  yield takeEvery(ADD_NEW_DELIVERY, addNewDelivery);
  yield takeEvery(GET_DELIVERY_BY_ID, getDeliveryById);
  yield takeEvery(UPDATE_DELIVERY, updateDelivery);
  yield takeEvery(DELETE_DELIVERY, deleteDelivery);
}

export default roleSaga;
