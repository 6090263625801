import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PICKUP_ATTACHMENTS,
  ADD_NEW_PICKUP_ATTACHMENT,
  GET_PICKUP_ATTACHMENT_BY_ID,
  UPDATE_PICKUP_ATTACHMENT,
  UPDATE_PICKUP_ATTACHMENTS,
  DELETE_PICKUP_ATTACHMENT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPickupAttachmentsSuccess,
  getPickupAttachmentByIdSuccess,
  getPickupAttachmentsFail,
} from "./actions";
// PickupAttachment
const getPickupAttachmentsRequest = query =>
  get("/api/PickupAttachment/paging", { params: query });
const addNewPickupAttachmentRequest = role =>
  post("/api/PickupAttachment", role);
const getPickupAttachmentByIdRequest = id => get(`/api/PickupAttachment/${id}`);
const updatePickupAttachmentRequest = role =>
  update("/api/PickupAttachment", role);
const deletePickupAttachmentRequest = id => del(`/api/PickupAttachment/${id}`);

const updatePickupAttachmentsRequest = ({ orderId, attachments }) => {
  return update(`/api/Order/${orderId}/attachments`, attachments);
};

function* fetchPickupAttachments({ payload }) {
  try {
    const response = yield call(getPickupAttachmentsRequest, payload);
    yield put(getPickupAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* addNewPickupAttachment({ payload }) {
  try {
    yield call(addNewPickupAttachmentRequest, payload);
    const { orderId } = payload;
    const response = yield call(getPickupAttachmentsRequest, { orderId });
    yield put(getPickupAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* updatePickupAttachment({ payload }) {
  try {
    yield call(updatePickupAttachmentRequest, payload);
    const { orderId } = payload;
    const response = yield call(getPickupAttachmentsRequest, { orderId });
    yield put(getPickupAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* updatePickupAttachments({ payload }) {
  try {
    console.log(payload);
    let response = yield call(updatePickupAttachmentsRequest, payload);

    response = yield call(getPickupAttachmentsRequest, {
      orderId: response,
    });

    yield put(getPickupAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* getPickupAttachmentById({ payload }) {
  try {
    var response = yield call(getPickupAttachmentByIdRequest, payload);
    yield put(getPickupAttachmentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* deletePickupAttachment({ payload }) {
  try {
    const { id, orderId } = payload;
    let response = yield call(deletePickupAttachmentRequest, id);
    response = yield call(getPickupAttachmentsRequest, { orderId });
    yield put(getPickupAttachmentsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupAttachmentsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_PICKUP_ATTACHMENTS, fetchPickupAttachments);
  yield takeEvery(ADD_NEW_PICKUP_ATTACHMENT, addNewPickupAttachment);
  yield takeEvery(GET_PICKUP_ATTACHMENT_BY_ID, getPickupAttachmentById);
  yield takeEvery(UPDATE_PICKUP_ATTACHMENT, updatePickupAttachment);
  yield takeEvery(UPDATE_PICKUP_ATTACHMENTS, updatePickupAttachments);
  yield takeEvery(DELETE_PICKUP_ATTACHMENT, deletePickupAttachment);
}

export default roleSaga;
