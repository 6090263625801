import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Label, Input } from "reactstrap";
import NumberFormat from "react-number-format";

export class OrderItemRow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = e => {
    const { item, onChange } = this.props;
    const { name, value } = e.target;
    switch (name) {
      case "title":
        item.title = value;
        break;
    }

    item.volume =
      Number(item.quantity) *
      Number(item.length) *
      Number(item.width) *
      Number(item.height);
    onChange(item);
  };
  

  handleOnChange = (name, value) => {
    const { item, onChange } = this.props;
    switch (name) {
      case "quantity":
        item.quantity = value;
        break;
      case "weight":
        item.weight = value ==='' ? null: value;
        break;
      case "length":
        item.length = value;
        break;
      case "width":
        item.width = value;
        break;
      case "height":
        item.height = value;
        break;
    }

    item.volume =
      Number(item.quantity) *
      Number(item.length) *
      Number(item.width) *
      Number(item.height);
    onChange(item);
  };

  render() {
    const { item, onRemove, isFirst } = this.props;
    const { title, cargoCode, commodity, quantityPackage, quantityCatton,  quantity, weight, length, width, height, volume } = item;
    return (
      <Row className="mb-2">
        <Col md={10} className={"pe-1"}>
          <Row>
            <Col md={3} className={"pe-1"}>
              {isFirst && <Label>Title</Label>}
              <Input
                  type="text"
                  name="title"
                  maxLength={255}
                  placeholder="Title"
                  value={title}
                  onChange={this.handleChange}
              />
            </Col>
            <Col className={"px-1"}>
                {isFirst && <Label>Weight (Kg)</Label>}
                <NumberFormat
                  value={weight}
                  className="form-control text-end"
                  placeholder="Weight"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("weight", value);
                  }}
                />
            </Col>
            <Col className={"px-1"}>
              {isFirst && <Label>Quantity</Label>}
              <NumberFormat
                  value={quantity}
                  className="form-control text-end"
                  placeholder="Quantity"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("quantity", value);
                  }}
              />
            </Col>
            <Col className={"px-1"}>
              {isFirst && <Label>Length (m)</Label>}{" "}
              <NumberFormat
                value={length}
                className="form-control text-end"
                placeholder="Length"
                thousandSeparator={true}
                fixedDecimalScale={false}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnChange("length", value);
                }}
              />
            </Col>
            <Col className={"px-1"}>
                {isFirst && <Label>Width (m)</Label>}
                <NumberFormat
                  value={width}
                  className="form-control text-end"
                  placeholder="Width"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("width", value);
                  }}
                />
            </Col>
            <Col className={"px-1"}>
                {isFirst && <Label>Height (m)</Label>}
                <NumberFormat
                  value={height}
                  placeholder="Height"
                  className="form-control text-end"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("height", value);
                  }}
                />
            </Col>
          </Row>
        </Col>
          <Col md={2} className="pe-5">
            {isFirst && <Label>Volume (m3)</Label>}
            <NumberFormat
              name="volume"
              value={Number(volume).toFixed(3)}
              placeholder="Volume"
              className="form-control text-end"
              thousandSeparator={true}
              fixedDecimalScale={false}
              readOnly
            />
              <div
                style={{
                  position: "absolute",
                  top: isFirst ? 30 : 0,
                  right: 0,
                }}
              >
                <Button
                  style={{
                    background: "transparent",
                    color: "#495057",
                    border: "none",
                  }}
                  outline
                  onClick={() => onRemove(item)}
                >
                  <i className="fas fa-trash" />
                </Button>
              </div>
          </Col>
      </Row>
    );
  }
}

OrderItemRow.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default OrderItemRow;
