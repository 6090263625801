import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { NIL } from "uuid";
import NumberFormat from "react-number-format";

const DoorToDoorInvoiceModal = ({isOpen, toggle, title, deliveryToDoor, onSubmit}) => {
  
  const addDays=(date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  }
  
  const [totalAmount, setTotalAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [dueDate, setDueDate]= useState(addDays(new Date(), 7));
  const [note, setNote] = useState('')
  const [submitted, setSubmitted] = useState(false);
  
  const onOpened = ()=>{
    console.log(deliveryToDoor);
  }

  
  
  const handleSubmit=()=>{
    const { orderId, userId } = deliveryToDoor;
    
    const data = {
      id: NIL,
      invoiceNo: 'will generate at backend',
      date,
      dueDate,
      // status,
      totalAmount,
      orderId: deliveryToDoor.orderId,
      invoiceToUserId: userId,
      type: 1,
      note,
    }
    setSubmitted(true);
    
    if(data.date && data.totalAmount > 0){
      onSubmit(data);
    }
  }
  
  return (
      <Modal
          size="md"
          onOpened={onOpened}
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <div className="mb-3">
              <FormGroup
                  className={
                    submitted && (!totalAmount || totalAmount === 0)
                        ? " is-invalid"
                        : ""
                  }
              >
                <Label htmlFor="totalAmount">Fee Amount</Label>
                <NumberFormat
                    id="amount"
                    name="totalAmount"
                    value={totalAmount}
                    className={
                        "form-control text-end" +
                        (submitted && (!totalAmount || totalAmount === 0)
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="0"
                    thousandSeparator={true}
                    fixedDecimalScale={false}
                    onValueChange={values => {
                      setTotalAmount(values.floatValue)
                    }}
                />
              </FormGroup>
                {submitted && (!totalAmount || totalAmount === 0) ? (
                    <div className="invalid-feedback-custom">
                      Amount is required.
                    </div>
                ) : null}
              </div>
              <FormGroup
                  className={
                      "select2-container" +
                      (submitted && !date ? " is-invalid" : "")
                  }
              >
                <Label>Date</Label>
                <DatePicker
                    className="form-control"
                    selectsStart
                    name="date"
                    selected={date}
                    onChange={date => setDate(date)}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                />
              </FormGroup>
              {submitted && !date && (
                  <div className="invalid-feedback-custom">Date is required.</div>
              )}
              <FormGroup>
                <Label>Due Date</Label>
                <DatePicker
                    className="form-control"
                    selectsStart
                    name="dueDate"
                    selected={dueDate}
                    onChange={date => setDueDate(date)}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="invoiceNo">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    rows={3}
                    placeholder="Note..."
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={handleSubmit} type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={toggle}
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

DoorToDoorInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  deliveryToDoor: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default DoorToDoorInvoiceModal;