import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody
} from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import { Link } from "react-router-dom";

export class ShippingFeeEntryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,

      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              { loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                    >
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Value</th>
                        <th>Currency</th>
                        <th style={ { width: "80px" } }>
                          <div className="text-center">Action</div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      { items &&
                          items.map((item, index) => {
                            return (
                                <tr
                                    key={ index }
                                    style={ { cursor: "pointer" } }
                                    onDoubleClick={ () => onEdit(item.id) }
                                >
                                  <td>{ index + 1 }</td>
                                  <td>{ item.from }</td>
                                  <td>{ item.to }</td>
                                  <td>{ item.value }</td>
                                  <td>{ item.currency }</td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <Link className="text-primary" to="#">
                                        <i
                                            className="mdi mdi-pencil font-size-18"
                                            onClick={ () => onEdit(item.id) }
                                        ></i>
                                      </Link>
                                      <Link className="text-danger" to="#">
                                        <i
                                            className="mdi mdi-delete font-size-18"
                                            onClick={ () => onConfirmDelete(item.id) }
                                        ></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                            );
                          }) }
                      </tbody>
                    </Table>
                  </div>
              ) }
            </div>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ currentPage }
                totalPage={ totalPage }
                defaultPageSize={ defaultPageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => onChangePage(i) }
                onPageSizeChange={ size => onPageSizeChange(size) }
            />
          </CardBody>
        </Card>
    );
  }
}

ShippingFeeEntryTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onViewDetail: PropTypes.func,
};

export default ShippingFeeEntryTable;
