import { call, put, takeEvery } from 'redux-saga/effects';
import {
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW,
  DISCOUNT_PROMOTION_PRODUCT_DELETE,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE,
} from './actionType';

import {
  addDiscountPromotionProductSuccess,
  addDiscountPromotionProductError,
  getPagingDiscountPromotionProductSuccess,
  getPagingDiscountPromotionProductError,
  getDiscountPromotionProductByIdSuccess,
  getDiscountPromotionProductByIdError,
  updateDiscountPromotionProductSuccess,
  updateDiscountPromotionProductError, 
  deleteDiscountPromotionProductSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// DiscountPromotionProduct
const getPagingDiscountPromotionProductRequest = query => get("/api/DiscountPromotionProduct/paging", { params: query });
const addNewDiscountPromotionProductRequest = data => post("/api/DiscountPromotionProduct", data);
const getDiscountPromotionProductByIdRequest = id => get(`/api/DiscountPromotionProduct/${id}`);
const updateDiscountPromotionProductRequest = data => update("/api/DiscountPromotionProduct", data);
const deleteDiscountPromotionProductRequest = id => del(`/api/DiscountPromotionProduct/${id}`);

function* addNewDiscountPromotionProduct({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewDiscountPromotionProductRequest, data);
    yield put(addDiscountPromotionProductSuccess(response));
    
    // re-populate data
    response = yield call(getPagingDiscountPromotionProductRequest, queryParams);
    yield put(getPagingDiscountPromotionProductSuccess(response));
  } catch (error) {
    yield put(addDiscountPromotionProductError(error.response.statusText));
  }
}

function* updateDiscountPromotionProduct({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateDiscountPromotionProductRequest, data);
    yield put(updateDiscountPromotionProductSuccess(response));
    history.push('/discount-promotion')
    // response = yield call(getPagingDiscountPromotionProductRequest, queryParams);
    // yield put(getPagingDiscountPromotionProductSuccess(response));
  } catch (error) {
    yield put(updateDiscountPromotionProductError(error.response.statusText));
  }
}

function* getPagingDiscountPromotionProduct({ payload }) {
  try {
    let response = yield call(getPagingDiscountPromotionProductRequest, payload);
    yield put(getPagingDiscountPromotionProductSuccess(response));
  } catch (error) {
    yield put(getPagingDiscountPromotionProductError(error.response.statusText));
  }
}

function* getDiscountPromotionProductById({ payload }) {
  try {
    let response = yield call(getDiscountPromotionProductByIdRequest, payload);
    yield put(getDiscountPromotionProductByIdSuccess(response));
  } catch (error) {
    yield put(getDiscountPromotionProductByIdError(error.response.statusText));
  }
}

function* deleteDiscountPromotionProduct({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteDiscountPromotionProductRequest, data);
    yield put(deleteDiscountPromotionProductSuccess(response));
    
    response = yield call(getPagingDiscountPromotionProductRequest, queryParams);
    yield put(getPagingDiscountPromotionProductSuccess(response));
  } catch (error) {
    yield put(getPagingDiscountPromotionProductError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(DISCOUNT_PROMOTION_PRODUCT_ADD_NEW, addNewDiscountPromotionProduct);
  yield takeEvery(DISCOUNT_PROMOTION_PRODUCT_UPDATE, updateDiscountPromotionProduct);
  yield takeEvery(DISCOUNT_PROMOTION_PRODUCT_GET_PAGING, getPagingDiscountPromotionProduct);
  yield takeEvery(DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID, getDiscountPromotionProductById);
  yield takeEvery(DISCOUNT_PROMOTION_PRODUCT_DELETE, deleteDiscountPromotionProduct);
}

export {
  getPagingDiscountPromotionProductRequest
}

