import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { orderService } from "services/order-service";
import { NIL } from "uuid";

export class SendNotificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onOpened = () => {
    const { orderId } = this.props;

    if (orderId) {
      orderService.getInvoiceDetailForNotificationRequest(orderId).then(res => {
        const { title, content } = res;

        this.setState({
          title,
          description: content,
        });
      });
    }
  };

  handleSubmit = () => {
    const { description } = this.state;
    const { orderId, onSubmit } = this.props;
    const data = {
      id: NIL,
      orderId,
      userId: orderId, // temp it will be change in backend,
      title: "",
      status: 0,
      body: description,
    };

    onSubmit(data);
  };

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Inform Payment for invoice - {this.state.title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup className="mb-3">
            <Label>Description</Label>
            <Input
              type="textarea"
              name="description"
              rows={10}
              placeholder="Description"
              //   className={submitted && !this.state.note ? "is-invalid" : ""}
              value={this.state.description}
              onChange={this.handleChange}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

SendNotificationModal.propTypes = {
  orderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SendNotificationModal;
