import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import CustomPagination from "../../components/Common/CustomPagination";
import OrderRowCard from "./OrderRowCard";

const ReceiveOrderItemTable = props => {
  const {
    loading,
    items,
    itemCount,
    currentPage,
    totalPage,
    defaultPageSize,
    onVerifyOrder,
    onChangeOrderStatus,
    onAddNote,
    onNotificationForPayment,
    onViewNote,
    onChangePage,
    onPageSizeChange,
  } = props;
  return (
      <>
        { loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              { items.map((item, index) => {
                return <OrderRowCard
                    key={ index }
                    item={ item }
                    onVerifyOrder={ onVerifyOrder }
                    onChangeOrderStatus={ onChangeOrderStatus }
                    onAddNote={ onAddNote }
                    onNotificationForPayment={ onNotificationForPayment }
                    onViewNote={ onViewNote }
                />
              }) }
            </>
        ) }
        <Card>
          <CardBody className="p-2">
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ currentPage }
                totalPage={ totalPage }
                defaultPageSize={ defaultPageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => onChangePage(i) }
                onPageSizeChange={ size => onPageSizeChange(size) }
            />
          </CardBody>
        </Card>
      </>

  );
};

ReceiveOrderItemTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,

  onVerifyOrder: PropTypes.func,
  onChangeOrderStatus: PropTypes.func,
  onAddNote: PropTypes.func,
  onNotificationForPayment: PropTypes.func,
  onViewNote: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ReceiveOrderItemTable;