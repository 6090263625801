export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAIL = "GET_CURRENCIES_FAIL";

export const GET_CURRENCY_BY_ID = "GET_CURRENCY_BY_ID";
export const GET_CURRENCY_BY_ID_SUCCESS = "GET_CURRENCY_BY_ID_SUCCESS";

export const ADD_NEW_CURRENCY = "ADD_NEW_CURRENCY";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY";

export const DELETE_CURRENCY = "DELETE_CURRENCY";

export const CURRENCY_CLEAR_ERROR = "CURRENCY_CLEAR_ERROR";
export const GET_DEFAULT_CURRENCY = "GET_DEFAULT_CURRENCY";
export const GET_DEFAULT_CURRENCY_SUCCESS = "GET_DEFAULT_CURRENCY_SUCCESS";
