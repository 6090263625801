import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addStockOut } from "../../store/stock-out/actions";
import { toastMessage } from "helpers/utils";
import StockOutForm from "../../containers/stock-out/StockOutForm";

const AddNewStockOut = props => {
  const { addStockOut, match, history } = props;

  const [ activeTab, setActiveTab ] = useState("1");

  const { addNewProduct, clearProductNotify, stockOut } = props;
  const { item, loading, message } = stockOut;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductNotify();
    }
  }, [ message ]);

  const handleSubmit = data => {
    addStockOut({
      data,
      history,
      queryParams: {}
    })
  };

  const handleOnCancel = () => {
    history.push("/stock-out");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Stock Out | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" backLink={ "/stock-out" } breadcrumbItem={ `Add New Stock Out` }/>

            <Row>

              <Col md={ 12 }>
                <StockOutForm
                    onSubmit={ handleSubmit }
                    onCancel={ handleOnCancel }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

AddNewStockOut.propTypes = {
  stockOut: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addStockOut: PropTypes.func,
};

const mapStateToProps = ({ stockOut }) => {
  return {
    stockOut,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      addStockOut
    })(AddNewStockOut)
);
