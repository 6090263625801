import {
  PRODUCT_REVIEW_ADD_NEW,
  PRODUCT_REVIEW_ADD_NEW_ERROR,
  PRODUCT_REVIEW_ADD_NEW_SUCCESS,
  PRODUCT_REVIEW_DELETE,
  PRODUCT_REVIEW_DELETE_ERROR,
  PRODUCT_REVIEW_DELETE_SUCCESS,
  PRODUCT_REVIEW_GET_BY_ID,
  PRODUCT_REVIEW_GET_BY_ID_ERROR,
  PRODUCT_REVIEW_GET_BY_ID_SUCCESS,
  PRODUCT_REVIEW_GET_PAGING,
  PRODUCT_REVIEW_GET_PAGING_ERROR,
  PRODUCT_REVIEW_GET_PAGING_SUCCESS,
  PRODUCT_REVIEW_UPDATE,
  PRODUCT_REVIEW_UPDATE_ERROR,
  PRODUCT_REVIEW_UPDATE_SUCCESS,
} from './actionType';

// ProductReview
export const getPagingProductReview = items => ({
  type: PRODUCT_REVIEW_GET_PAGING,
  payload: items,
});

export const getPagingProductReviewSuccess = items => ({
  type: PRODUCT_REVIEW_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingProductReviewError = error => ({
  type: PRODUCT_REVIEW_GET_PAGING_ERROR,
  payload: error,
});

export const addProductReview = items => ({
  type: PRODUCT_REVIEW_ADD_NEW,
  payload: items,
});

export const addProductReviewSuccess = items => ({
  type: PRODUCT_REVIEW_ADD_NEW_SUCCESS,
  payload: items,
});

export const addProductReviewError = error => ({
  type: PRODUCT_REVIEW_ADD_NEW_ERROR,
  payload: error,
});

export const getProductReviewById = items => ({
  type: PRODUCT_REVIEW_GET_BY_ID,
  payload: items,
});

export const getProductReviewByIdSuccess = items => ({
  type: PRODUCT_REVIEW_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getProductReviewByIdError = error => ({
  type: PRODUCT_REVIEW_GET_BY_ID_ERROR,
  payload: error,
});

export const updateProductReview = items => ({
  type: PRODUCT_REVIEW_UPDATE,
  payload: items,
});

export const updateProductReviewSuccess = items => ({
  type: PRODUCT_REVIEW_UPDATE_SUCCESS,
  payload: items,
});

export const updateProductReviewError = error => ({
  type: PRODUCT_REVIEW_UPDATE_ERROR,
  payload: error,
});

export const deleteProductReview = items => ({
  type: PRODUCT_REVIEW_DELETE,
  payload: items,
});

export const deleteProductReviewSuccess = items => ({
  type: PRODUCT_REVIEW_DELETE_SUCCESS,
  payload: items,
});

export const deleteProductReviewError = error => ({
  type: PRODUCT_REVIEW_DELETE_ERROR,
  payload: error,
});
