import { SEVERITY } from "helpers/utils.js";
import {
  GET_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_SUCCESS,
  GET_INVOICE_PAYMENT_FAIL,
  GET_INVOICE_PAYMENT_BY_ID_SUCCESS,
  ADD_NEW_INVOICE_PAYMENT,
  UPDATE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENT,
  INVOICE_PAYMENT_CLEAR_NOTIFY,
  ADD_NEW_INVOICE_PAYMENT_SUCCESS,
  UPDATE_INVOICE_PAYMENT_SUCCESS,
  DELETE_INVOICE_PAYMENT_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  draftIds: [],
  pendingIds: [],
  confirmedIds: [],
  rejectedIds: [],
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const InvoicePayment = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_INVOICE_PAYMENT:
    case UPDATE_INVOICE_PAYMENT:
    case DELETE_INVOICE_PAYMENT:
    case GET_INVOICE_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case ADD_NEW_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Payment",
          text: "Invoice Payment has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        item: null,
        message: {
          title: "Invoice Payment",
          text: "Invoice Payment has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Payment",
          text: "Invoice Payment has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_INVOICE_PAYMENT_SUCCESS:
      const {
        draftIds,
        pendingIds,
        confirmedIds,
        rejectedIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        draftIds,
        pendingIds,
        confirmedIds,
        rejectedIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_INVOICE_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case INVOICE_PAYMENT_CLEAR_NOTIFY: {
      return {
        ...state,
        message: null,
      };
    }

    case GET_INVOICE_PAYMENT_FAIL:
      return {
        ...state,
        error: payload,
        message: {
          title: "Invoice Payment",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default InvoicePayment;
