// DELIVERY_ITEM
export const GET_DELIVERY_ITEMS = "GET_DELIVERY_ITEMS";
export const GET_DELIVERY_ITEMS_SUCCESS =
  "GET_DELIVERY_ITEMS_SUCCESS";
export const GET_DELIVERY_ITEMS_FAIL = "GET_DELIVERY_ITEMS_FAIL";

export const GET_DELIVERY_ITEM_BY_ID = "GET_DELIVERY_ITEM_BY_ID";
export const GET_DELIVERY_ITEM_BY_ID_SUCCESS =
  "GET_DELIVERY_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY_ITEM = "ADD_NEW_DELIVERY_ITEM";
export const ADD_NEW_DELIVERY_ITEMS = "ADD_NEW_DELIVERY_ITEMS";

export const UPDATE_DELIVERY_ITEM = "UPDATE_DELIVERY_ITEM";

export const DELETE_DELIVERY_ITEM = "DELETE_DELIVERY_ITEM";
export const DELETE_DELIVERY_ITEMS = "DELETE_DELIVERY_ITEMS";

export const DELIVERY_ITEM_CLEAR_ERROR =
  "DELIVERY_ITEM_CLEAR_ERROR";
