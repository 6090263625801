import { call, put, takeEvery } from "redux-saga/effects";
import { GET_INVOICE_REPORT } from "./actionTypes";
import { get, post } from "../../helpers/api_helper";
import { getInvoiceReportSuccess, getInvoiceReportFail } from "./actions";

const getInvoiceReportRequest = query =>
  post(`/api/Invoice/report`, query);

function* getInvoiceReport({ payload }) {
  try {
    const response = yield call(getInvoiceReportRequest, payload);

    yield put(getInvoiceReportSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceReportFail(error));
  }
}

function* InvoiceReportSaga() {
  yield takeEvery(GET_INVOICE_REPORT, getInvoiceReport);
}

export default InvoiceReportSaga;
