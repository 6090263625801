import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SALE_ORDER_INVOICE_PAYMENT_ADD_NEW,
  SALE_ORDER_INVOICE_PAYMENT_DELETE,
  SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID,
  SALE_ORDER_INVOICE_PAYMENT_GET_PAGING,
  SALE_ORDER_INVOICE_PAYMENT_UPDATE,
} from './actionType';

import {
  addSaleOrderInvoicePaymentSuccess,
  addSaleOrderInvoicePaymentError,
  getPagingSaleOrderInvoicePaymentSuccess,
  getPagingSaleOrderInvoicePaymentError,
  getSaleOrderInvoicePaymentByIdSuccess,
  getSaleOrderInvoicePaymentByIdError,
  updateSaleOrderInvoicePaymentSuccess,
  updateSaleOrderInvoicePaymentError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// SaleOrderInvoicePayment
const getPagingSaleOrderInvoicePaymentRequest = query => get("/api/SaleOrderInvoicePayment/paging", { params: query });
const addNewSaleOrderInvoicePaymentRequest = data => post("/api/SaleOrderInvoicePayment", data);
const getSaleOrderInvoicePaymentByIdRequest = id => get(`/api/SaleOrderInvoicePayment/${id}`);
const updateSaleOrderInvoicePaymentRequest = data => update("/api/SaleOrderInvoicePayment", data);
const deleteSaleOrderInvoicePaymentRequest = id => del(`/api/SaleOrderInvoicePayment/${id}`);

function* addNewSaleOrderInvoicePayment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewSaleOrderInvoicePaymentRequest, data);
    
    response = yield call(getPagingSaleOrderInvoicePaymentRequest, queryParams);
    yield put(getPagingSaleOrderInvoicePaymentSuccess(response));
  } catch (error) {
    yield put(addSaleOrderInvoicePaymentError(error.response.statusText));
  }
}

function* updateSaleOrderInvoicePayment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateSaleOrderInvoicePaymentRequest, data);
    response = yield call(getPagingSaleOrderInvoicePaymentRequest, queryParams);
    yield put(getPagingSaleOrderInvoicePaymentSuccess(response));
  } catch (error) {
    yield put(updateSaleOrderInvoicePaymentError(error.response.statusText));
  }
}

function* getPagingSaleOrderInvoicePayment({ payload }) {
  try {
    let response = yield call(getPagingSaleOrderInvoicePaymentRequest, payload);
    yield put(getPagingSaleOrderInvoicePaymentSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderInvoicePaymentError(error.response.statusText));
  }
}

function* getSaleOrderInvoicePaymentById({ payload }) {
  try {
    let response = yield call(getSaleOrderInvoicePaymentByIdRequest, payload);
    yield put(getSaleOrderInvoicePaymentByIdSuccess(response));
  } catch (error) {
    yield put(getSaleOrderInvoicePaymentByIdError(error.response.statusText));
  }
}

function* deleteSaleOrderInvoicePayment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteSaleOrderInvoicePaymentRequest, data);

    response = yield call(getPagingSaleOrderInvoicePaymentRequest, queryParams);
    yield put(getPagingSaleOrderInvoicePaymentSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderInvoicePaymentError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(SALE_ORDER_INVOICE_PAYMENT_ADD_NEW, addNewSaleOrderInvoicePayment);
  yield takeEvery(SALE_ORDER_INVOICE_PAYMENT_UPDATE, updateSaleOrderInvoicePayment);
  yield takeEvery(SALE_ORDER_INVOICE_PAYMENT_GET_PAGING, getPagingSaleOrderInvoicePayment);
  yield takeEvery(SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID, getSaleOrderInvoicePaymentById);
  yield takeEvery(SALE_ORDER_INVOICE_PAYMENT_DELETE, deleteSaleOrderInvoicePayment);
}

