import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { debounce } from "lodash"
import NumberFormat from "react-number-format";
import moment from "moment";

const discountPromotionTypeOptions = [
  { key: 0, value: 'Assigned to products', label: 'Assigned to products' }
]

const DiscountPromotionForm = props => {
  const { onSubmit, onCancel } = props

  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ productOptions, setProductOptions ] = useState([])
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const [ item, setItem ] = useState(
      {
        id: NIL,
        name: '',
        type: discountPromotionTypeOptions[0],
        usePercentage: false,
        percentage: 0,
        amount: 0,
        requiresCouponCode: false,
        couponCode: '',
        startDate: null,
        endDate: null,
        isActive: false,
        description: '',
      })

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  useEffect(() => {
    if (props.item) {
      const { item } = props;
      setItem({
        ...item,
        type: item.type ? discountPromotionTypeOptions.find(e => e.value === item.type) : null,
        startDate: item.startDate ? moment(item.startDate).toDate() : null,
        endDate: item.endDate ? moment(item.endDate).toDate() : null,
      })
    }
  }, [ props.item ]);

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    setItem({
      ...item,
      [name]: valueType
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setItem({
      ...item,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setItem({
      ...item,
      [name]: value
    })
  }

  const handleOnCheck = e => {
    const { name, checked } = e.target
    setItem({
      ...item,
      [name]: checked
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = item.name

    if (isValid) {
      let data = {
        ...item,
        type: item.type?.value,
        startDate: item.startDate ? moment(item.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"): null,
        endDate: item.endDate ? moment(item.endDate).format("YYYY-MM-DDTHH:mm:ss.SSS"): null,
      }

      onSubmit(data);
    }
  }
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>

              <Col>

                <FormGroup className="mb-3">
                  <Label htmlFor="">Name</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      autoComplete="off"
                      className={ isSubmitted && !item.name ? "is-invalid" : "" }
                      value={ item.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !item.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Coupon Code</Label>
                  <Input
                      type="text"
                      name="couponCode"
                      placeholder="Coupon Code"
                      autoComplete="off"
                      value={ item.couponCode }
                      onChange={ handleOnChange }
                  />
                </FormGroup>
                <div className="form-check form-check-primary mb-3">
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="isActive"
                      name="isActive"
                      checked={ item.isActive }
                      onChange={ handleOnCheck }
                  />
                  <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                  >
                    Is Active ?
                  </label>
                </div>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Type</Label>
                  <Select
                      name="type"
                      value={ item.type }
                      placeholder={ "Select Type" }
                      onChange={ handleSelectChange }
                      options={ discountPromotionTypeOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="totalAmount">Discount percentage</Label>
                  <NumberFormat
                      id="amount"
                      name="amount"
                      value={ item.percentage }
                      className={ "form-control text-end" }
                      placeholder="0"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                      onValueChange={ values => {
                        const { floatValue } = values;
                        setItem({
                          ...item,
                          percentage: floatValue,
                        })
                      } }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="totalAmount">Discount Amount</Label>
                  <NumberFormat
                      id="amount"
                      name="amount"
                      value={ item.amount }
                      className={ "form-control text-end" }
                      placeholder="0"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                      onValueChange={ values => {
                        const { floatValue } = values;
                        setItem({
                          ...item,
                          amount: floatValue,
                        })
                      } }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Start Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="startDate"
                      selected={ item.startDate }
                      startDate={ item.startDate }
                      endDate={ item.endDate }
                      maxDate={ item.endDate }
                      onChange={ obj => handleOnDateChange('startDate', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Start Date"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsEnd
                      selected={ item.endDate }
                      name="endDate"
                      startDate={ item.startDate }
                      endDate={ item.endDate }
                      minDate={ item.startDate }
                      onChange={ obj => handleOnDateChange('endDate', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="End Date"
                      isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Description</Label>
                  <Input
                      type="textarea"
                      rows={ 3 }
                      name="description"
                      placeholder="Description"
                      value={ item.description }
                      onChange={ handleOnChange }
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>


        <Card className={ "mb-2" }>
          <CardBody>

            <Button color="primary" className={ "me-1" }
                    onClick={ handleSubmit }
                    type="submit">
              Submit
            </Button>


            <Button color="secondary"
                // onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>
      </>
  )
}

DiscountPromotionForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default DiscountPromotionForm