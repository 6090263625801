import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Collapse,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

export class OrderNoteRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { item, onEdit, onConfirmDelete, isReadOnly } = this.props;
    return (
      <Card className="mb-2">
        <CardHeader className="bg-transparent border">
          <div>
            <p style={{ marginRight: "5rem" }} className="text-truncate">
              {item.note}
            </p>

            <CardSubtitle>
              <span className="text-muted">
                {item.createdByUser} |{" "}
                {moment
                  .utc(item.createdDate)
                  .local()
                  .format("DD-MMM-yyyy h:mm:ss a")}
              </span>
            </CardSubtitle>
          </div>

          <div
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
            }}
          >
            {!isReadOnly && (
              <>
                <span
                  className="font-size-14 text-primary px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => onEdit(item.id)}
                >
                  <i className={"fas fa-pen"}></i>
                </span>
                <span
                  className="font-size-14 text-danger px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => onConfirmDelete(item.id)}
                >
                  <i className="fas fa-trash"></i>
                </span>
              </>
            )}
            <span
              className="font-size-24 text-primary px-2"
              style={{ cursor: "pointer" }}
              onClick={this.toggleBody}
            >
              <i
                className={
                  this.state.isOpen ? "fas fa-angle-up" : "fas fa-angle-down"
                }
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody className="border">
            <CardTitle>Note</CardTitle>
            <p className="text-muted">{item.note}</p>
            <CardTitle>Created Date</CardTitle>
            <p className="text-muted">
              {moment
                .utc(item.createdDate)
                .local()
                .format("DD-MMM-yyyy h:mm:ss a")}
            </p>
            <CardTitle>Created By</CardTitle>
            <p className="text-muted">{item.createdByUser}</p>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

OrderNoteRow.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default OrderNoteRow;
