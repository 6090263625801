import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { NIL } from "uuid"
import Select from "react-select"
import NumberFormat from "react-number-format"
import { getPagingUomRequest } from "../../store/uom/saga";

const ModalForm = props => {
  const { isOpen, toggle, title, onSubmit } = props
//
  const [ item, setItem ] = useState({
    id: NIL,
    title: '',
    productId: null,
    uomId: null,
    displayOrder: 0,
    isBase: false,
    onHandQuantity: 0,
    outgoingQuantity: 0,
    minQuantity: 0,
    conversionRate: 1,
    note: '',
  })

  const [ submitted, setSubmitted ] = useState(false)
  const [ unitOfMeasurementOptions, setUnitOfMeasurementOptions ] = useState([])

  useEffect(() => {
    getPagingUomRequest({ pageSize: 100 }).then(res => {
      const { data } = res

      setUnitOfMeasurementOptions(
          data.map(a => {
            return {
              id: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })
  }, [])

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setItem({
      ...item,
      [name]: value
    })
  }

  const handleOnCheck = e => {
    const { name, checked } = e.target

    setItem({
      ...item,
      [name]: checked
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    setItem({
      ...item,
      [name]: valueType
    })
  }

  const onOpened = () => {
    const { item } = props

    if (item) {
      setItem({
        ...item,
        uomId: item.uomId ? {
          id: item.uomId,
          value: item.uomId,
          label: item.uomName,
        }: null,
        note: item?.note || ''
      })
    } else {
      setItem({
        id: NIL,
        title: '',
        productId: null,
        uomId: null,
        displayOrder: 0,
        isBase: false,
        onHandQuantity: 0,
        outgoingQuantity: 0,
        minQuantity: 0,
        conversionRate: 1,
        note: '',
      })

      setSubmitted(false)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    let isValid = item.title && item.uomId && item.conversionRate > 0

    if (isValid) {
      const data = {
        ...item,
        uomId: item.uomId?.value,
      }

      onSubmit(data)
    }
  }

  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                    type="text"
                    name="title"
                    placeholder="Title"
                    className={ submitted && !item.title ? "is-invalid" : "" }
                    value={ item.title }
                    onChange={ handleOnChange }
                />
                { submitted && !item.title && (
                    <div className="invalid-feedback-custom">Title is required.</div>
                ) }
              </FormGroup>


            </Col>
          </Row>
          
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">On Hand</Label>
                    <NumberFormat
                        name="onHandQuantity"
                        value={ item.onHandQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setItem({
                            ...item,
                            onHandQuantity: values.floatValue
                          })
                        } }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">Outgoing</Label>
                    <NumberFormat
                        name="outgoingQuantity"
                        value={ item.outgoingQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setItem({
                            ...item,
                            outgoingQuantity: values.floatValue
                          })
                        } }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">Min</Label>
                    <NumberFormat
                        name="minQuantity"
                        value={ item.minQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setItem({
                            ...item,
                            minQuantity: values.floatValue
                          })
                        } }
                    />
                  </FormGroup>
                </Col>
              </Row>
          
          <Row>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && !item.uomId ? " is-invalid" : "")
                    }
                >
                  <Label>Unit of Measurement</Label>
                  <Select
                      name="uomId"
                      value={ item.uomId }
                      onChange={ handleSelectChange }
                      options={ unitOfMeasurementOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </div>
                { submitted && !item.uomId && (
                    <div className="invalid-feedback-custom">
                      Unit of Measurement is required.
                    </div>
                ) }
              </div>
            </Col>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Conversion Rate</Label>
                <NumberFormat
                    name="conversionRate"
                    value={ item.conversionRate }
                    className={
                        "form-control text-end " +
                        (submitted && item.conversionRate === 0 ? "is-invalid" : "")
                    }
                    thousandSeparator={ true }
                    fixedDecimalScale={ false }
                    onValueChange={ values => {
                      values.floatValue = parseFloat(values.value)
                      setItem({
                        ...item,
                        conversionRate: values.floatValue
                      })
                    } }
                />

                { submitted && !item.conversionRate && (
                    <div className="invalid-feedback-custom">ConversionRate is required.</div>
                ) }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Display Order</Label>
                <Input
                    type="number"
                    name="displayOrder"
                    placeholder="Display Order"
                    value={ item.displayOrder }
                    onChange={ handleOnChange }
                />
              </FormGroup>
            </Col>
            <Col>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-check form-check-primary mb-3">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor1"
                    name="isBase"
                    checked={ item.isBase }
                    onChange={ handleOnCheck }
                />
                <label
                    className="form-check-label"
                    htmlFor="customCheckcolor1"
                >
                  Is Based
                </label>
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    placeholder="Note"
                    value={ item.note }
                    onChange={ handleOnChange }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ModalForm
