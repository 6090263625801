import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import moment from "moment";
import { join, sumBy } from "lodash";
import NumberFormat from "react-number-format";
import { invoiceService } from "services/invoice-service";

export class InvoiceDetailInfoTab extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleExportToPdf = () => {
    const { item } = this.props;

    if (item) {
      const { id, invoiceNo } = item;
      invoiceService.exportToPdf(id, `${invoiceNo}_invoice.pdf`);
    }
  };

  render() {
    const { item } = this.props;
    const { order } = item;

    const {
      sender,
      senderUniqueId,
      senderAddress,
      shippingContactObject,
      totalAmount,
      isPickup,
      isDelivery,
      pickupFee,
      deliveryFee,
      orderItems,
      orderCBMEntries,
      weight,
      weightExtra,
      weightComment,
      weightFeeAmount,
      discountAmount,
      discountPercentage,
      unitFee,
      unitFeeAmount,
      createdDate,
      cbmExchangeRate,
      exchangeRate,
      orderAdditionalEntries,
      cbmRate,
      cbmWeight,
      eta,
      etd,
    } = order;

    let totalQuantity = sumBy(orderItems, orderItem => {
      return orderItem.quantity;
    });

    let totalVolumn = sumBy(orderItems, orderItem => {
      const { quantity, length, width, height } = orderItem;
      return quantity * length * width * height;
    });

    let totalWeightOrderItem = _.sumBy(orderItems, a => {
      return Number(a.weight);
    });

    let totalOrderCBMEntryVolume = sumBy(orderCBMEntries, a => {
      return a.volume;
    });

    let orderCBMEntryShippingFee = Number(
      (totalOrderCBMEntryVolume ?? 0) * cbmRate
    );

    let orderCBMEntryShippingFeeKRW =
      orderCBMEntryShippingFee * cbmExchangeRate;

    let totalWeight = Number(weight) + Number(weightExtra);

    let shippingFeeUSD = (totalVolumn ?? 0) * cbmRate;
    let shippingFeeKRW = (shippingFeeUSD ?? 0) * cbmExchangeRate;
    let totalPayment = sumBy(
      item.invoicePayments.filter(e => e.paymentStatus === 2), // 2 is Confirmed
      invoicePayment => {
        return invoicePayment.amount;
      }
    );

    let balanceDue = totalAmount - totalPayment;

    const { contact, phones, address } = shippingContactObject;
    let phoneArr = phones
      ? phones.map(a => {
          return a.phoneNumber;
        })
      : [];

    let totalOrderAdditionalEntries = 0;

    return (
      <>
        <Card className="mb-2">
          <CardHeader className="bg-transparent border-bottom d-flex justify-content-between">
            <CardTitle>Invoice No #{item.invoiceNo}</CardTitle>
            <Button
              outline
              size="sm"
              color="primary"
              onClick={this.handleExportToPdf}
              className="me-1"
            >
              <i className="fas fa-file-pdf"></i> Export to PDF
            </Button>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={3}>
                <address>
                  <strong>Billed To:</strong>
                  <br />
                  <span>
                    {sender} | {senderUniqueId}
                  </span>
                  <br />
                  <span>{senderAddress}</span>
                  <br />
                </address>
              </Col>
              <Col md={3}>
                <address className="mt-2 mt-sm-0">
                  <strong>Shipped To:</strong>
                  <br />
                  <span>{contact}</span>
                  <br />
                  <span>{join(phoneArr, ", ")}</span>
                  <br />
                  <span>{address}</span>
                  <br />
                </address>
              </Col>
              <Col md={3}>
                <address className="mt-2 mt-sm-0">
                  <strong>Whs In Date</strong>
                  <br />
                  <span>{moment(createdDate).format("DD-MMM-YYYY")}</span>
                  <br />
                </address>
                <address className="mt-2 mt-sm-0">
                  <strong>ETD</strong>
                  <br />
                  {etd ? (
                    <span>{moment(etd).format("DD-MMM-YYYY")}</span>
                  ) : (
                    <span>N/A</span>
                  )}
                  <br />
                </address>
                <address className="mt-2 mt-sm-0">
                  <strong>ETA</strong>
                  <br />
                  {eta ? (
                    <span>{moment(eta).format("DD-MMM-YYYY")}</span>
                  ) : (
                    <span>N/A</span>
                  )}
                  <br />
                </address>
              </Col>
              <Col md={3} className="text-sm-end">
                <address className="mt-2 mt-sm-0">
                  <strong>Invoice Date:</strong>
                  <br />
                  <span>{moment(item.date).format("DD-MMM-YYYY")}</span>
                  <br />
                </address>
                {item.dueDate && (
                  <address className="mt-2 mt-sm-0">
                    <strong>Due Date</strong>
                    <br />
                    <span>{moment(item.dueDate).format("DD-MMM-YYYY")}</span>
                    <br />
                  </address>
                )}
                <address className="mt-2 mt-sm-0">
                  <strong>Tracking No</strong>
                  <br />
                  <span>#{order.trackingNumber}</span>
                  <br />
                </address>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {orderItems && orderItems.length > 0 && (
          <Card className="mb-2">
            <CardHeader className="pb-1 bg-transparent border-bottom">
              <CardTitle>CBM item Detail</CardTitle>
            </CardHeader>
            <CardBody>
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>No</th>
                    <th>Title</th>
                    <th>Quantity</th>
                    <th>Weight</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th style={{ width: "10%" }}>
                      Volume (m<sup>3</sup>)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems &&
                    orderItems.map((orderItem, index) => {
                      const { title, quantity, width, height, length, weight } =
                        orderItem;
                      let volume = quantity * length * width * height;
                      return (
                        <tr key={index}>
                          <td className="text-end">{index + 1}</td>
                          <td>{title}</td>
                          <td className="text-end">{quantity}</td>
                          <td className="text-end">
                            {Number(weight).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(length).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(width).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(height).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(volume).toFixed(3)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <th>Total</th>
                    <th className="text-end">{totalQuantity}</th>
                    <th colSpan={4} className="text-end">
                      Total Volume
                    </th>

                    <th className="text-end">
                      {Number(totalVolumn).toFixed(3)}
                    </th>
                  </tr>

                  <tr>
                    <th colSpan={7} className="text-end">
                      Total Weight (Kg)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(totalWeightOrderItem).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>

                  <tr>
                    <th colSpan={7} className="text-end">
                      CBM Rate (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={7} className="text-end">
                      Shipping Fee (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(shippingFeeUSD).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={7} className="text-end">
                      Exchange Rate
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmExchangeRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={7} className="text-end">
                      Shipping Fee (KRW)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(shippingFeeKRW).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </CardBody>
          </Card>
        )}

        {orderCBMEntries && orderCBMEntries.length > 0 && (
          <Card className="mb-2">
            <CardHeader className="pb-1 bg-transparent border-bottom">
              <CardTitle>CBM item Detail From Factory</CardTitle>
            </CardHeader>
            <CardBody>
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>No</th>
                    <th>Cargo Code</th>
                    <th>Commodity</th>
                    <th>PLTS/PKGS</th>
                    <th>PCS/CTNS</th>
                    <th>Weight (KG)</th>
                    <th style={{ width: "10%" }}>
                      Volume (m<sup>3</sup>)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderCBMEntries &&
                    orderCBMEntries.map((orderCBMEntry, index) => {
                      const {
                        cargoCode,
                        commodity,
                        quantityCatton,
                        quantityPackage,
                        weight,
                        volume,
                      } = orderCBMEntry;
                      return (
                        <tr key={index}>
                          <td className="text-end">{index + 1}</td>
                          <td>{cargoCode}</td>
                          <td>{commodity}</td>
                          <td className="text-end">{quantityPackage}</td>
                          <td className="text-end">{quantityCatton}</td>
                          <td className="text-end">{weight}</td>
                          <td className="text-end">{volume}</td>
                        </tr>
                      );
                    })}
                </tbody>

                <tfoot>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Total Volume
                    </th>
                    <th className="text-end">
                      {Number(totalOrderCBMEntryVolume).toFixed(3)}
                    </th>
                  </tr>

                  <tr>
                    <th colSpan={6} className="text-end">
                      Weight (KG)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmWeight).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      CBM Rate (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Shipping Fee (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(orderCBMEntryShippingFee).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Exchange Rate
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmExchangeRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Shipping Fee (KRW)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(orderCBMEntryShippingFeeKRW).toFixed(0)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </CardBody>
          </Card>
        )}
        {totalWeight > 0 && (
          <Card className="mb-2">
            <CardHeader className="pb-1 bg-transparent border-bottom">
              <CardTitle>Weight</CardTitle>
            </CardHeader>
            <CardBody>
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th style={{ width: "10%" }}>Quantity</th>
                    <th style={{ width: "10%" }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{order.description}</p>
                      <p>Weight: {weight}</p>
                      <p>Weight Extra: {weightExtra}</p>
                      <p>
                        Weight Component:{" "}
                        <span className="text-muted">{weightComment}</span>{" "}
                      </p>
                    </td>
                    <td className="text-end">{order.quantity}</td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={weightFeeAmount}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        )}
        {unitFee > 0 && (
          <Card className="mb-2">
            <CardHeader className="pb-1 bg-transparent border-bottom">
              <CardTitle>Unit Fee</CardTitle>
            </CardHeader>
            <CardBody>
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th style={{ width: "10%" }}>Quantity</th>
                    <th style={{ width: "10%" }}>Unit Price</th>
                    <th style={{ width: "10%" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{order.description}</p>
                    </td>
                    <td className="text-end">{order.quantity}</td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(unitFee).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(unitFeeAmount).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        )}
        {orderAdditionalEntries && orderAdditionalEntries.length > 0 && (
          <Card className="mb-2">
            <CardHeader className="pb-1 bg-transparent border-bottom">
              <CardTitle>Additional Services</CardTitle>
            </CardHeader>
            <CardBody>
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th style={{ width: "10%" }}>Amount</th>
                    <th style={{ width: "10%" }}>Currency</th>
                    <th style={{ width: "10%" }}>Rate</th>
                    <th style={{ width: "10%" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderAdditionalEntries.map((item, index) => {
                    const { amount, currencyLabel } = item;
                    let displayExchangeRate =
                      currencyLabel === "USD" ? exchangeRate : 1;
                    let totalOrderAdditionalEntry =
                      amount * displayExchangeRate;
                    totalOrderAdditionalEntries += totalOrderAdditionalEntry;
                    return (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={amount}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                        <td>{item.currencyLabel}</td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={displayExchangeRate}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalOrderAdditionalEntry).toFixed(0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={4} className="text-end">
                      Total
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(totalOrderAdditionalEntries).toFixed(0)}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        readOnly
                      />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </CardBody>
          </Card>
        )}
        <Card className="mb-2">
          <CardHeader className="pb-1 bg-transparent border-bottom">
            <CardTitle>Totals</CardTitle>
          </CardHeader>
          <CardBody>
            <table className="table-nowrap table table-bordered">
              <tbody>
                {isPickup && (
                  <tr>
                    <td className="border-0 text-end">
                      <strong>Pickup</strong>
                    </td>
                    <td className="text-end w-10">
                      <h5 className="m-0">
                        <NumberFormat
                          displayType="text"
                          value={pickupFee}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </h5>
                    </td>
                  </tr>
                )}
                {isDelivery && (
                  <tr>
                    <td className="border-0 text-end">
                      <strong>Delivery</strong>
                    </td>
                    <td className="text-end w-10">
                      <h5 className="m-0">
                        <NumberFormat
                          displayType="text"
                          value={deliveryFee}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </h5>
                    </td>
                  </tr>
                )}
                {discountPercentage > 0 && (
                  <tr>
                    <td className="border-0 text-end">
                      <strong>Discount {discountPercentage} %</strong>
                    </td>
                    <td style={{ width: "10%" }} className="text-end">
                      <h5 className="m-0">
                        <NumberFormat
                          displayType="text"
                          value={Number(discountAmount).toFixed()}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          readOnly
                        />
                      </h5>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="border-0 text-end">
                    <strong>TOTAL (KRW)</strong>
                  </td>
                  <td style={{ width: "10%" }} className="text-end">
                    <h5 className="m-0">
                      <NumberFormat
                        displayType="text"
                        value={Number(totalAmount).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end">
                    <strong>EX.RATE</strong>
                  </td>
                  <td style={{ width: "10%" }} className="text-end">
                    <h5 className="m-0">
                      <NumberFormat
                        displayType="text"
                        value={Number(exchangeRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end">
                    <strong>TOTAL (USD)</strong>
                  </td>
                  <td style={{ width: "10%" }} className="text-end">
                    <h5 className="m-0">
                      <NumberFormat
                        displayType="text"
                        value={Number(totalAmount / exchangeRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end">
                    <strong>PAID (KRW)</strong>
                  </td>
                  <td style={{ width: "10%" }} className="text-end">
                    <h5 className="m-0">
                      <NumberFormat
                        displayType="text"
                        value={`-${Number(totalPayment).toFixed()}`}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end">
                    <strong>BALANCE DUE (KRW)</strong>
                  </td>
                  <td style={{ width: "10%" }} className="text-end">
                    <h5 className="m-0">
                      <NumberFormat
                        displayType="text"
                        value={Number(balanceDue).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="d-flex justify-content-end bg-transparent border-top"></CardFooter>
        </Card>
      </>
    );
  }
}

InvoiceDetailInfoTab.propTypes = {
  item: PropTypes.object,
};

export default InvoiceDetailInfoTab;
