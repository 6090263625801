import { call, put, takeEvery } from 'redux-saga/effects';
import {
  PRODUCT_TRANSACTION_ADD_NEW,
  PRODUCT_TRANSACTION_DELETE,
  PRODUCT_TRANSACTION_GET_BY_ID,
  PRODUCT_TRANSACTION_GET_PAGING,
  PRODUCT_TRANSACTION_UPDATE,
} from './actionType';

import {
  addProductTransactionSuccess,
  addProductTransactionError,
  getPagingProductTransactionSuccess,
  getPagingProductTransactionError,
  getProductTransactionByIdSuccess,
  getProductTransactionByIdError,
  updateProductTransactionSuccess,
  updateProductTransactionError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// ProductTRANSACTION
const getPagingProductTransactionRequest = query => get("/api/ProductStockTransaction/paging", { params: query });
const addNewProductTransactionRequest = data => post("/api/ProductStockTransaction", data);
const getProductTransactionByIdRequest = id => get(`/api/ProductStockTransaction/${id}`);
const updateProductTransactionRequest = data => update("/api/ProductStockTransaction", data);
const deleteProductTransactionRequest = id => del(`/api/ProductStockTransaction/${id}`);

function* addNewProductTransaction({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewProductTransactionRequest, data);
    yield put(addProductTransactionSuccess(response));
    
    response = yield call(getPagingProductTransactionRequest, queryParams);
    yield put(getPagingProductTransactionSuccess(response));
  } catch (error) {
    yield put(addProductTransactionError(error.response.statusText));
  }
}

function* updateProductTransaction({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateProductTransactionRequest, data);
    yield put(updateProductTransactionSuccess(response));
    
    response = yield call(getPagingProductTransactionRequest, queryParams);
    yield put(getPagingProductTransactionSuccess(response));
  } catch (error) {
    yield put(updateProductTransactionError(error.response.statusText));
  }
}

function* getPagingProductTransaction({ payload }) {
  try {
    let response = yield call(getPagingProductTransactionRequest, payload);
    yield put(getPagingProductTransactionSuccess(response));
  } catch (error) {
    yield put(getPagingProductTransactionError(error.response.statusText));
  }
}

function* getProductTransactionById({ payload }) {
  try {
    let response = yield call(getProductTransactionByIdRequest, payload);
    yield put(getProductTransactionByIdSuccess(response));
  } catch (error) {
    yield put(getProductTransactionByIdError(error.response.statusText));
  }
}

function* deleteProductTransaction({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteProductTransactionRequest, data);

    response = yield call(getPagingProductTransactionRequest, queryParams);
    yield put(getPagingProductTransactionSuccess(response));
  } catch (error) {
    yield put(getPagingProductTransactionError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(PRODUCT_TRANSACTION_ADD_NEW, addNewProductTransaction);
  yield takeEvery(PRODUCT_TRANSACTION_UPDATE, updateProductTransaction);
  yield takeEvery(PRODUCT_TRANSACTION_GET_PAGING, getPagingProductTransaction);
  yield takeEvery(PRODUCT_TRANSACTION_GET_BY_ID, getProductTransactionById);
  yield takeEvery(PRODUCT_TRANSACTION_DELETE, deleteProductTransaction);
}

export {
  getPagingProductTransactionRequest
}

