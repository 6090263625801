import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Modal, Label, Row } from "reactstrap";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";

let uploader;

export class ProductUploadModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
    };

    uploader = new FineUploaderTraditional({
      options: {
        multiple: true,
        request: {
          method: "POST",
          endpoint: `${process.env.REACT_APP_API_URL}/api/FileUpload/productImage`,
        },
        validation: {
          sizeLimit: 104857600, // 100MB
        },
      },
    });
  }

  componentDidMount() {
    uploader.on("submitted", id => {
      this.setState({
        uploading: true,
      });
    });

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        const attachment = {
          fileName: responseJSON.fileName,
          url: responseJSON.url,
        };

        this.setState({
          attachments: this.state.attachments.concat(attachment),
        });
      } else {
        this.setState({
          fail: true,
          error: responseJSON.message,
        });
      }
    });

    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name);
      // this.setState({
      //   fail: true,
      //   error: errorReason,
      // });
    });
  }

  onOpened = () => {
    this.setState({
      attachments: [],
    });
  };

  handleSubmit = () => {
    const { attachments } = this.state;
    this.props.onSubmit(attachments);
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    return (
        <Modal
            size="md"
            onOpened={this.onOpened}
            isOpen={isOpen}
            toggle={toggle}
            backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {title}
            </h5>
            <button
                type="button"
                onClick={toggle}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col md={12}>
                <Gallery uploader={uploader} />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="primary" onClick={this.handleSubmit} type="submit">
              Submit
            </Button>
            <button
                type="button"
                onClick={toggle}
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
    );
  }
}

ProductUploadModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ProductUploadModalForm;
