import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container, Card, Input, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { getFeedback } from "../../store/actions";
import FeedbackTable from "containers/feedback/FeedbackTable";
import { getCompanyName } from "../../helpers/utils";

class FeedbackList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      endDate: null,
      paymentStatus: null,

      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.props.getFeedback({ page: 1, pageSize: 10, term: "" });
  }

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getFeedback({ term: value ?? "" });
  };

  handleSearch = () => {
    const { getFeedback } = this.props;
    const { term } = this.state;

    getFeedback({
      term: term ?? "",
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getFeedback({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getFeedback({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/feedback/${id}`);
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { feedback } = this.props;
    const { items, itemCount, pageCount, loading } = feedback;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Feedback List | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Feedback List" />
            <Row>
              <Col md={12}>
                <Card className="mb-1 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={4}></Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <>
                    <FeedbackTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      loading={loading}
                      defaultPageSize={pageSize}
                      onViewDetail={this.handleOnViewDetail}
                      onChangePage={this.handleOnPageChange}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

FeedbackList.propTypes = {
  message: PropTypes.object,
  feedback: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getFeedback: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ feedback }) => {
  return { feedback };
};

export default withRouter(
  connect(mapStateToProps, {
    getFeedback,
  })(FeedbackList)
);
