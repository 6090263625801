import { SEVERITY } from "helpers/utils.js";
import {
  GET_DELIVERIES,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERIES_FAIL,
  GET_DELIVERY_BY_ID_SUCCESS,
  DELIVERY_CLEAR_NOTIFY,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_SUCCESS,
  ADD_NEW_DELIVERY_SUCCESS,
  DELETE_DELIVERY_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  itemCount: 0,
  pageCount: 0,
  draftIds: [],
  closedIds: [],
  completedIds: [],
  inProgressIds: [],
  rejectedIds: [],
  loading: false,
  message: null,
};

const DELIVERY = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DELIVERY:
    case GET_DELIVERIES:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERIES_SUCCESS:
      const {
        draftIds,
        closedIds,
        completedIds,
        inProgressIds,
        rejectedIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        draftIds,
        closedIds,
        completedIds,
        inProgressIds,
        rejectedIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_DELIVERY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case ADD_NEW_DELIVERY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Send Order",
          text: "Send Order has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_DELIVERY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Send Order",
          text: "Send Order has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_DELIVERY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Send Order",
          text: "Send Order has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELIVERY_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    case GET_DELIVERIES_FAIL:
      return {
        ...state,
        message: {
          title: "Send Order",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default DELIVERY;
