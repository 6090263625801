import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Col, Collapse, Row } from "reactstrap";
import moment from "moment/moment";
import Rating from "react-rating";

const ProductReviewCard = props => {
  const { item, onEdit, onConfirmDelete } = props;
  const [ isOpen, setIsOpen ] = useState(false);


  return (
      <Card className="mb-2">
        <CardHeader className="bg-transparent border">
          <Row>
            <Col>
              <div>
                <p style={ { marginRight: "5rem" } } className="text-truncate">
                  { item.createdByUser }
                </p>

                <CardSubtitle>
              <span className="text-muted">
                { moment
                    .utc(item.createdDate)
                    .local()
                    .format("DD-MMM-yyyy h:mm:ss a") }
              </span>
                </CardSubtitle>
              </div>
            </Col>
            <Col>
              <Rating
                  max={ 5 }
                  className={"font-size-24"}
                  initialRating={ item.rating }
                  emptySymbol="mdi mdi-star-outline text-danger fa-1x"
                  fullSymbol="mdi mdi-star text-danger fa-1x"
                  fractions={ 2 }
              />
            </Col>
          </Row>


          <div
              style={ {
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
              } }
          >

            <>
                <span
                    className="font-size-14 text-primary px-2"
                    style={ { cursor: "pointer" } }
                    onClick={ () => onEdit(item.id) }
                >
                  <i className={ "fas fa-pen" }></i>
                </span>
              <span
                  className="font-size-14 text-danger px-2"
                  style={ { cursor: "pointer" } }
                  onClick={ () => onConfirmDelete(item.id) }
              >
                  <i className="fas fa-trash"></i>
                </span>
            </>

            <span
                className="font-size-24 text-primary px-2"
                style={ { cursor: "pointer" } }
                onClick={ () => setIsOpen(!isOpen) }
            >
              <i
                  className={
                    isOpen ? "fas fa-angle-up" : "fas fa-angle-down"
                  }
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={ isOpen }>
          <CardBody className="border">
            <Row>
              <Col md={ 12 }>
                <CardTitle>Comment</CardTitle>
                <div dangerouslySetInnerHTML={ { __html: item.comment } }/>
              </Col>
              <Col md={ 12 }>
                <CardTitle>User</CardTitle>
                <p className="text-muted">{ item.user }</p>
              </Col>
              <Col md={ 12 }>
                <CardTitle>Rating</CardTitle>
                <Rating
                    max={ 5 }
                    initialRating={ item.rating }
                    emptySymbol="mdi mdi-star-outline text-danger fa-1x"
                    fullSymbol="mdi mdi-star text-danger fa-1x"
                    fractions={ 2 }
                />
              </Col>
              <Col md={ 12 }>
                <CardTitle>Date</CardTitle>
                <p className="text-muted">
                  { moment
                      .utc(item.createdDate)
                      .local()
                      .format("DD-MMM-yyyy h:mm:ss a") }
                </p>
              </Col>
              { item.attachments && item.attachments.length > 0 && (
                  <Col md={ 12 }>
                    <CardTitle>Attachments</CardTitle>
                    <Row>
                      { item.attachments.map((attachment, index) => {
                        const { url, fileName } = attachment;
                        return (
                            <Col md={ 4 } key={ index }>
                              <CardImg
                                  top
                                  className="img-fluid img-rounded"
                                  src={ url }
                                  alt={ fileName }
                                  onClick={ () =>
                                      this.setState({
                                        isGallery: true,
                                        photoIndex: index,
                                      })
                                  }
                              />
                            </Col>
                        );
                      }) }
                    </Row>
                  </Col>
              ) }
            </Row>
          </CardBody>
        </Collapse>
      </Card>
  );
};

ProductReviewCard.propTypes = {
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};

export default ProductReviewCard;