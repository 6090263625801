import {
  TAG_ADD_NEW,
  TAG_ADD_NEW_ERROR,
  TAG_ADD_NEW_SUCCESS,
  TAG_DELETE,
  TAG_DELETE_ERROR,
  TAG_DELETE_SUCCESS,
  TAG_GET_BY_ID,
  TAG_GET_BY_ID_ERROR,
  TAG_GET_BY_ID_SUCCESS,
  TAG_GET_PAGING,
  TAG_GET_PAGING_ERROR,
  TAG_GET_PAGING_SUCCESS,
  TAG_UPDATE,
  TAG_UPDATE_ERROR,
  TAG_UPDATE_SUCCESS,
} from './actionType';

// Tag
export const getPagingTag = items => ({
  type: TAG_GET_PAGING,
  payload: items,
});

export const getPagingTagSuccess = items => ({
  type: TAG_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingTagError = error => ({
  type: TAG_GET_PAGING_ERROR,
  payload: error,
});

export const addTag = items => ({
  type: TAG_ADD_NEW,
  payload: items,
});

export const addTagSuccess = items => ({
  type: TAG_ADD_NEW_SUCCESS,
  payload: items,
});

export const addTagError = error => ({
  type: TAG_ADD_NEW_ERROR,
  payload: error,
});

export const getTagById = items => ({
  type: TAG_GET_BY_ID,
  payload: items,
});

export const getTagByIdSuccess = items => ({
  type: TAG_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getTagByIdError = error => ({
  type: TAG_GET_BY_ID_ERROR,
  payload: error,
});

export const updateTag = items => ({
  type: TAG_UPDATE,
  payload: items,
});

export const updateTagSuccess = items => ({
  type: TAG_UPDATE_SUCCESS,
  payload: items,
});

export const updateTagError = error => ({
  type: TAG_UPDATE_ERROR,
  payload: error,
});

export const deleteTag = items => ({
  type: TAG_DELETE,
  payload: items,
});

export const deleteTagSuccess = items => ({
  type: TAG_DELETE_SUCCESS,
  payload: items,
});

export const deleteTagError = error => ({
  type: TAG_DELETE_ERROR,
  payload: error,
});
