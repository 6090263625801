import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_USER_ITEM_SHIPPING_FEES,
  ADD_NEW_USER_ITEM_SHIPPING_FEE,
  GET_USER_ITEM_SHIPPING_FEE_BY_ID,
  UPDATE_USER_ITEM_SHIPPING_FEE,
  DELETE_USER_ITEM_SHIPPING_FEE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getUserItemShippingFeesSuccess,
  getUserItemShippingFeeByIdSuccess,
  getUserItemShippingFeesFail, deleteUserItemShippingFeeSuccess, addNewUserItemShippingFeeSuccess,
} from "./actions";
// UserItemShippingFee
const getUserItemShippingFeesRequest = query =>
  get("/api/UserItemShippingFee/paging", { params: query });
const getUserIdsItemShippingFeesRequest = query =>
  get("/api/UserItemShippingFee/getUserIdsByType", { params: query });
const addNewUserItemShippingFeeRequest = item =>
  post("/api/UserItemShippingFee/bulk", item);
const getUserItemShippingFeeByIdRequest = id =>
  get(`/api/UserItemShippingFee/${id}`);
const updateUserItemShippingFeeRequest = item =>
  update("/api/UserItemShippingFee", item);
const deleteUserItemShippingFeeRequest = query =>
  del(`/api/UserItemShippingFee/`, { params: query });

function* fetchUserItemShippingFees({ payload }) {
  try {
    const response = yield call(getUserItemShippingFeesRequest, payload);
    yield put(getUserItemShippingFeesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUserItemShippingFeesFail(error));
  }
}

function* addNewUserItemShippingFee({ payload }) {
  try {
    const { queryParams, data } = payload;
    yield call(addNewUserItemShippingFeeRequest, data);
    yield put(addNewUserItemShippingFeeSuccess({}));
    const response = yield call(getUserItemShippingFeesRequest, queryParams);
    yield put(getUserItemShippingFeesSuccess(response));
  } catch (err) {
    let error = err?.response?.data?.message ?? "Something has gone wrong.";
    if (error.includes("duplicate key")) {
      error = "Already exist in the list";
    }
    yield put(getUserItemShippingFeesFail(error));
  }
}

function* updateUserItemShippingFee({ payload }) {
  try {
    yield call(updateUserItemShippingFeeRequest, payload);
    const { itemShippingFeeId } = payload;
    const response = yield call(getUserItemShippingFeesRequest, {
      itemShippingFeeId,
    });
    yield put(getUserItemShippingFeesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUserItemShippingFeesFail(error));
  }
}

function* getUserItemShippingFeeById({ payload }) {
  try {
    var response = yield call(getUserItemShippingFeeByIdRequest, payload);
    yield put(getUserItemShippingFeeByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUserItemShippingFeesFail(error));
  }
}

function* deleteUserItemShippingFee({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(deleteUserItemShippingFeeRequest, data);
    
    yield put(deleteUserItemShippingFeeSuccess({}));
    const response = yield call(getUserItemShippingFeesRequest, queryParams);
    
    yield put(getUserItemShippingFeesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUserItemShippingFeesFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_USER_ITEM_SHIPPING_FEES, fetchUserItemShippingFees);
  yield takeEvery(ADD_NEW_USER_ITEM_SHIPPING_FEE, addNewUserItemShippingFee);
  yield takeEvery(GET_USER_ITEM_SHIPPING_FEE_BY_ID, getUserItemShippingFeeById);
  yield takeEvery(UPDATE_USER_ITEM_SHIPPING_FEE, updateUserItemShippingFee);
  yield takeEvery(DELETE_USER_ITEM_SHIPPING_FEE, deleteUserItemShippingFee);
}

export default roleSaga;

export { getUserIdsItemShippingFeesRequest };
