import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import { getVehicleRequest } from "../../store/vehicle/saga";

const ModalForm = props => {
  const { isOpen, toggle, title, userId, onSubmit } = props;

  const [ item, setItem ] = useState({
    id: NIL,
    userId,
    vehicleId: null,
    isActive: true,
    note: '',
  });

  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ vehicleOptions, setVehicleOptions ] = useState(null)

  useEffect(() => {
    getVehicleRequest().then(data =>{
      setVehicleOptions(data.map(a =>{
        return {
          key: a.id,
          value: a.id,
          label: `${a.name} - (${a.numberPlate})`
        }
      }))
    })
  }, []);

  useEffect(() => {
    if (props.item) {
      let { item } = props;
      const { vehicle } = item;
      setItem({
        ...item,
        vehicleId: vehicle ? {
          key: vehicle.id,
          value: vehicle.id,
          label: `${vehicle.name} - (${vehicle.numberPlate})`
        }: null,
      })
    }
  }, [ props.item ]);

  const onOpened = () => {
    setIsSubmitted(false);
    setItem({
      id: NIL,
      userId,
      vehicleId: null,
      isActive: true,
      note: '',
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    let isValid = item.vehicleId;

    if (isValid) {
      let data = {
        ...item,
        vehicleId: item.vehicleId?.value,
        vehicle: null,
      }

      onSubmit(data);
    }
  }

  const handleChange = e => {
    const { value, name } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    
    setItem({
      ...item,
      [name]: valueType
    })
  }

  const handleOnCheck = e => {
    const { name, checked } = e.target

    setItem({
      ...item,
      [name]: checked
    })
  }


  return (
      <Modal
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (isSubmitted && !item.vehicleId ? " is-invalid" : "")
                    }
                >
                  <Label>Vehicle</Label>
                  <Select
                      name="vehicleId"
                      value={ item.vehicleId }
                      onChange={ handleSelectChange }
                      options={ vehicleOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </div>
                { isSubmitted && !item.vehicleId && (
                    <div className="invalid-feedback-custom">
                      vehicle is required.
                    </div>
                ) }
              </div>
              <div className="form-check form-check-primary my-3">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor1"
                    name="isActive"
                    checked={ item.isActive }
                    onChange={ handleOnCheck }
                />
                <label
                    className="form-check-label"
                    htmlFor="customCheckcolor1"
                >
                  Is Active ?
                </label>
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    rows={ 4 }
                    placeholder="Note"
                    value={ item.note }
                    onChange={ handleChange }
                />
              </FormGroup>
            </Col>
          </Row>

        </div>

        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  saleOrderId: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ModalForm;