import {
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW,
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_SUCCESS, 
  DISCOUNT_PROMOTION_PRODUCT_DELETE_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE_SUCCESS,
} from './actionType';
import { SEVERITY, toastMessage } from "../../helpers/utils";
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DISCOUNT_PROMOTION_PRODUCT_ADD_NEW:
    case DISCOUNT_PROMOTION_PRODUCT_GET_PAGING:
    case DISCOUNT_PROMOTION_PRODUCT_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
      };
      
    case DISCOUNT_PROMOTION_PRODUCT_DELETE_SUCCESS:
      toastMessage({
        title: "Discount Promotion",
        text: 'Discount Promotion has been successfully deleted.',
        severity: SEVERITY.INFO
      })
      return {
        ...state,
        loading: false,
        error: null,
      }

    case DISCOUNT_PROMOTION_PRODUCT_UPDATE_SUCCESS:
      toastMessage({
        title: "Discount Promotion",
        text: 'Discount Promotion has been successfully updated.',
        severity: SEVERITY.INFO
      })
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_SUCCESS:
      toastMessage({
        title: "Discount Promotion",
        message: 'Discount Promotion has been successfully added.',
        severity: SEVERITY.INFO
      })
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_ERROR:
    case DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_ERROR:
    case DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_ERROR:
    case DISCOUNT_PROMOTION_PRODUCT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
