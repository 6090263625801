import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  // UserDocument
  // UserDocument
  getPagingUserDocument,
  addUserDocument,
  // getUserDocuments,
  getUserDocumentById,
  updateUserDocument,
  deleteUserDocument,
  // clearUserDocumentError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ModalForm from "containers/user-document/ModalForm"
import { debounce } from "lodash";

const UserDocumentTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const {
    getPagingUserDocument,
    addUserDocument,
    getUserDocumentById,
    updateUserDocument,
    deleteUserDocument,
    clearUserDocumentError,
    userDocument,
    userId,
  } = props;

  const {
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
  } = userDocument;

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term);
        setPage(1);
      }, 1000),
      []
  );

  useEffect(() => {
    getPagingUserDocument({ userId, term, page, pageSize });
  }, [ term, page, pageSize ]);

  const handleSubmit = data => {
    addUserDocument({
      data,
      queryParams: {
        userId, term, page, pageSize
      }
    });

    setModalAddIsOpen(false);
  };

  const handleOnEdit = (id) => {
    getUserDocumentById(id);
    setModalEditIsOpen(true);
  }

  const handleUpdate = data => {
    updateUserDocument({
      data,
      queryParams: {
        userId, term, page, pageSize
      }
    });

    setModalEditIsOpen(false);
  };

  const handleOnConfirmDelete = (id) => {
    setSelectedId(id);
    setModalConfirmDeleteIsOpen(true);
  }

  const handleDelete = () => {
    deleteUserDocument({
      data: selectedId,
      queryParams: {
        userId, term, page, pageSize
      }
    });

    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target;
                          debouncedQuickSearch(value);
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {
          loading ?
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                <Card>
                  <CardBody>
                    <div className="table-rep-plugin">

                      <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                      >
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered table-nowrap"
                        >
                          <thead>
                          <tr>
                            <th style={ { width: "80px" } }>#</th>
                            <th>Title</th>
                            <th>Document Type</th>
                            <th style={ { width: "120px" } }>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          { items.map((item, index) => {
                            let num = (page - 1) * pageSize + (index + 1);
                            return (
                                <tr key={ index }>
                                  <td style={ { textAlign: "right" } }>{ num }</td>
                                  <td>{ item.title }</td>
                                  <td>{ item.documentType }</td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <a href={ item.url } download={ item.fileName } target={ "_blank" }>
                                        <i
                                            className="mdi mdi-download font-size-18"
                                        ></i>
                                      </a>
                                      <Link className="text-primary" to="#">
                                        <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                            onClick={ () => handleOnEdit(item.id) }
                                        ></i>
                                      </Link>

                                      <Link className="text-danger" to="#">
                                        <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                            onClick={ () => handleOnConfirmDelete(item.id) }
                                        ></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                            );
                          }) }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1);
                          setPageSize(size);
                        } }
                    />
                  </CardBody>
                </Card>
              </>

        }
        <ModalForm
            title="Add Document"
            userId={ userId }
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Document"
                item={ item }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  );
};


UserDocumentTab.propTypes = {
  userId: PropTypes.string,
  message: PropTypes.object,
  userDocument: PropTypes.object,
  getPagingUserDocument: PropTypes.func,
  addUserDocument: PropTypes.func,
  getUserDocumentById: PropTypes.func,
  updateUserDocument: PropTypes.func,
  deleteUserDocument: PropTypes.func,
  clearUserDocumentError: PropTypes.func,
};

const mapStateToProps = ({ userDocument }) => {
  return {
    userDocument
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingUserDocument,
      addUserDocument,
      getUserDocumentById,
      updateUserDocument,
      deleteUserDocument,
      // clearUserDocumentError,
    })(UserDocumentTab)
);
