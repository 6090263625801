import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import { getSaleOrderById, updateSaleOrder, getPagingSaleOrderComment } from "store/actions"
import SaleOrderForm from "../../containers/sale-order/SaleOrderForm"
import AppliedToSaleOrderTab from "./ApliedToSaleOrderTab"
import SaleOrderCommentTab from "./SaleOrderCommentTab";

const SaleOrderDetail = props => {
  const { getPagingSaleOrderComment, updateSaleOrder, match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null);

  const { getSaleOrderById, saleOrder } = props
  const { item, loading, result } = saleOrder
  
  useEffect(() => {
    const { id } = match.params
    getSaleOrderById(id)
    setId(id)
  }, [])

  const handleOnSubmit = (data) => {
    updateSaleOrder({
      data,
      queryParams: null,
      history,
      navigateTo: '/sale-order',
    })
  }
  
  const handleOnCancel = () =>{
    history.push(`/sale-order`);
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sale Order Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={"/sale-order"}
                breadcrumbItem={`${item?.orderNo} | Sale Order Detail`}
            />
            <Row>
              <Col md={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setActiveTab("1");
                        }}
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setActiveTab("2");
                          getPagingSaleOrderComment({saleOrderId: id})
                        }}
                    >
                      Comments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          setActiveTab("3");
                        }}
                    >
                      Applied to Sale Order
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {loading ? (
                        <div className="d-flex justify-content-center">
                          <div
                              className="spinner-border text-primary m-1"
                              role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                    ) : (
                        <>
                          {item && (
                              <SaleOrderForm item={ item } onSubmit={ handleOnSubmit } onCancel={handleOnCancel} result={result}/>
                          )}
                        </>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {id ? <SaleOrderCommentTab saleOrderId={id} /> : null}
                    
                  </TabPane>
                </TabContent>
                <TabPane tabId="3">
                    { id ? <AppliedToSaleOrderTab discountPromotionId={ id }/> : null }
                  </TabPane>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
  )
}

SaleOrderDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  saleOrder: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getSaleOrderById: PropTypes.func,
  updateSaleOrder: PropTypes.func,
  getPagingSaleOrderComment: PropTypes.func
}

const mapStateToProps = ({ saleOrder }) => {
  return {
    saleOrder,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getSaleOrderById,
      updateSaleOrder,
      getPagingSaleOrderComment
    })(SaleOrderDetail)
)
