import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from "react-select/creatable"
import { Link } from "react-router-dom"
import NumberFormat from "react-number-format"
// import ModalForm from "../item-unit-of-measurement/ModalForm"
import { NIL } from "uuid"
import Select from "react-select";
// import {
//   addNewItemUnitOfMeasurementRequest, 
//   getItemUnitOfMeasurementByIdRequest,
// } from "../../store/item-unit-of-measurement/saga"


const SaleOrderProductRow = props => {
  const { item, onChange, onDelete, isSubmitted } = props
  const [ isEditable, setIsEditable ] = useState(true)

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name, action } = actionMeta
    onChange({
      ...item,
      [name]: valueType
    })
  }


  return (
      <>
        <tr onDoubleClick={ () => setIsEditable(!isEditable) }>
          <td className={ "input text-center pt-2" }>
            <input
                type="checkbox"
                className="form-check-input"
                checked={ item.isSelected }
                readOnly={ true }
                onChange={ handleCheckboxChange }
            />
          </td>
          <td>
            <div className="d-flex justify-content-center">
              {
                (() => {
                  let productImage = item.productImages.length > 0 ? item.productImages[0] : null;
                  return productImage ?
                    <img style={{ width: "80px", height: "50px", objectFit: "contain" }} src={productImage.url} alt="Product" />
                    :
                    <div className="avatar-order">
                      <i className="fas fa-solid fa-cube text-primary"></i>
                    </div>
                })()
              }
            </div>
          </td>
          <td>
            <>{ item.label }</>
          </td>
          <td className={'text-sm-end' }>
            <NumberFormat
                name="price"
                value={ item.price.toFixed(2) }
                displayType={"text"}
                thousandSeparator={ true }
                fixedDecimalScale={ false }
                // onValueChange={ values => {
                //   values.floatValue = parseFloat(values.value)
                //   onChange({
                //     ...item,
                //     discount: values.floatValue
                //   })
                // } }
            />
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="price"
                        value={ item.quantity }
                        className={
                            "form-control text-end " +
                            (isSubmitted && item.quantity === 0 ? "is-invalid" : "")
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          let total = values.floatValue * item.price;
                          onChange({
                            ...item,
                            quantity: values.floatValue,
                            total
                          })
                        } }
                    />
                  </>
                  :
                  <div className={ isSubmitted && item.quantity === 0 ? ' is-invalid' : '' }>{ item.quantity }</div>
            }
            { isSubmitted && item.quantity === 0 && (
                <div className="invalid-feedback-custom">
                  Quantity is required.
                </div>
            ) }
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <Select
                      name="uomId"
                      value={ item.uomId }
                      onChange={ handleSelectChange }
                      className={ isSubmitted && !item.uomId ? "is-invalid" : '' }
                      options={ item.uomOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  /> : <div className={ isSubmitted && !item.uomId ? "is-invalid" : '' }>
                    { item.uomId?.label }</div>
            }

            { isSubmitted && !item.uomId &&
                <div className="invalid-feedback-custom">
                  Unit of Measurement is required.
                </div>
            }

          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="quantity"
                        value={ item.discount }
                        className={
                          "form-control text-end "
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          onChange({
                            ...item,
                            discount: values.floatValue
                          })
                        } }
                    />
                  </>
                  :
                  <div>{ item.discount }</div>
            }
          </td>
          <td className={ 'text-sm-end' }>
            <NumberFormat
                name="total"
                value={ item.total.toFixed(2) }
                displayType={"text"}
                thousandSeparator={ true }
                fixedDecimalScale={ false }
                // onValueChange={ values => {
                //   values.floatValue = parseFloat(values.value)
                //   onChange({
                //     ...item,
                //     discount: values.floatValue
                //   })
                // } }
            />
            
          </td>
          <td className={ "input text-center" }>
            <div className="d-flex gap-3 justify-content-center">
              <Link className="text-primary" to="#">
                <i
                    className={ "font-size-18 mdi " + (isEditable ? 'mdi-eye' : 'mdi-pencil') }
                    id="edittooltip"
                    onClick={ () => {
                      setIsEditable(!isEditable)
                    } }
                ></i>
              </Link>

              <Link className="text-danger" to="#">
                <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={ () => {
                      onDelete(item)
                    } }
                ></i>
              </Link>
            </div>
          </td>
        </tr>
        {/*<ModalForm*/ }
        {/*    title="Add Unit of Measurement"*/ }
        {/*    item={ itemUom }*/ }
        {/*    isOpen={ modalAddUom }*/ }
        {/*    toggle={ () => setModalAddUom(!modalAddUom) }*/ }
        {/*    onSubmit={ handleSubmit }*/ }
        {/*/>*/ }
      </>
  )
}

SaleOrderProductRow.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitted: PropTypes.bool
}

export default SaleOrderProductRow