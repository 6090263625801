import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import DeliveryToDoorTable from "./DeliveryToDoorTable";

const LoadingContainer = () => <div>Loading...</div>

const MapViewCard = ({ latitude, longitude, ...props }) => {

  return (
      <div
          id="gmaps-overlay"
          className="gmaps"
          style={ { position: "relative" } }
      >
        <Map
            google={ props.google }
            zoom={ 14 }
            style={ { width: "100%", height: "100%" } }
            initialCenter={ {
              lat: latitude,
              lng: longitude,
            } }

        >
          <Marker onClick={ item => {
            const { google, map, name } = item
            console.log(map.mapUrl)

          } }/>

        </Map>
      </div>
  );
};

MapViewCard.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  google: PropTypes.object,
};

export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
      apiKey: "AIzaSyAIgfLKUfhFQect6xW1mL-SaEQEgLP7tX4",
      LoadingContainer: LoadingContainer,
      v: "3",
    })(MapViewCard)
)

