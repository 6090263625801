import { get, post, del, put as update } from "helpers/api_helper";
import axios from "axios";

const getByQuery = async query => {
  return await get(`/api/ItemShippingFee/getByQuery`, { params: query });
};

let currentCancelToken;

const getSingleByQuery = async query => {
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  currentCancelToken = axios.CancelToken.source();
  
  return await get(`/api/ItemShippingFee/getSingleByQuery`, {
    cancelToken: currentCancelToken.token,
    params: query 
  });
};

export const itemShippingFeeService = {
  getByQuery,
  getSingleByQuery
};
