import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getDiscountPromotionById, updateDiscountPromotion } from "store/actions"
import DiscountPromotionForm from "../../containers/discount-promotion/DiscountPromotionForm";
import classNames from "classnames";
import AppliedToProductTab from "./AppliedToProductTab";

const DiscountPromotionDetail = props => {
  const {
    getDiscountPromotionById,
    updateDiscountPromotion,
    match,
    history,
    discountPromotion
  } = props
  const { item, loading } = discountPromotion

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)

  useEffect(() => {
    const { id } = match.params
    getDiscountPromotionById(id)
    setId(id)
  }, [])

  const handleOnSubmit = (data) => {
    updateDiscountPromotion({
      data,
      history,
      queryParams: {}
    })
  }

  const handleOnCancel = () => {
    history.push(`/discount-promotion`);
  }
  
  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock In Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ "/discount-promotion" }
                breadcrumbItem={ `Discount Promotion Detail` }
            />
            <Row>
              <Col md={ 12 }>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "1",
                        }) }
                        onClick={ () => {
                          setActiveTab("1");
                        } }
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "2",
                        }) }
                        onClick={ () => {
                          setActiveTab("2");
                          // getPagingSaleOrderComment({saleOrderId: id})
                        } }
                    >
                      Applied to products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "3",
                        }) }
                        onClick={ () => {
                          setActiveTab("3");
                          // getPagingSaleOrderComment({saleOrderId: id})
                        } }
                    >
                      Usage history
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={ activeTab }>
                  <TabPane tabId="1">
                    { loading ? (
                        <div className="d-flex justify-content-center">
                          <div
                              className="spinner-border text-primary m-1"
                              role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                    ) : (
                        <>
                          { item && (
                              <DiscountPromotionForm
                                  item={ item }
                                  onSubmit={ handleOnSubmit }
                                  onCancel={ handleOnCancel }
                              />
                          ) }
                        </>
                    ) }
                  </TabPane>
                  <TabPane tabId="2">
                    { id ? <AppliedToProductTab discountPromotionId={ id }/> : null }
                  </TabPane>
                  <TabPane tabId="3">
                    <p> Usage History</p>

                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

DiscountPromotionDetail.propTypes = {
  discountPromotion: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getDiscountPromotionById: PropTypes.func,
  updateDiscountPromotion: PropTypes.func,
}

const mapStateToProps = ({ discountPromotion }) => {
  return {
    discountPromotion,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getDiscountPromotionById,
      updateDiscountPromotion,
    })(DiscountPromotionDetail)
)
