// PICKUP_ATTACHMENT
export const GET_PICKUP_ATTACHMENTS = "GET_PICKUP_ATTACHMENTS";
export const GET_PICKUP_ATTACHMENTS_SUCCESS = "GET_PICKUP_ATTACHMENTS_SUCCESS";
export const GET_PICKUP_ATTACHMENTS_FAIL = "GET_PICKUP_ATTACHMENTS_FAIL";

export const GET_PICKUP_ATTACHMENT_BY_ID = "GET_PICKUP_ATTACHMENT_BY_ID";
export const GET_PICKUP_ATTACHMENT_BY_ID_SUCCESS =
  "GET_PICKUP_ATTACHMENT_BY_ID_SUCCESS";

export const ADD_NEW_PICKUP_ATTACHMENT = "ADD_NEW_PICKUP_ATTACHMENT";

export const UPDATE_PICKUP_ATTACHMENT = "UPDATE_PICKUP_ATTACHMENT";
export const UPDATE_PICKUP_ATTACHMENTS = "UPDATE_PICKUP_ATTACHMENTS";

export const DELETE_PICKUP_ATTACHMENT = "DELETE_PICKUP_ATTACHMENT";

export const PICKUP_ATTACHMENT_CLEAR_ERROR = "PICKUP_ATTACHMENT_CLEAR_ERROR";
