import { call, put, takeEvery } from 'redux-saga/effects';
import {
  USER_VEHICLE_ADD_NEW,
  USER_VEHICLE_DELETE,
  USER_VEHICLE_GET_BY_ID,
  USER_VEHICLE_GET_PAGING,
  USER_VEHICLE_UPDATE,
} from './actionType';

import {
  addUserVehicleSuccess,
  addUserVehicleError,
  getPagingUserVehicleSuccess,
  getPagingUserVehicleError,
  getUserVehicleByIdSuccess,
  getUserVehicleByIdError,
  updateUserVehicleSuccess,
  updateUserVehicleError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// UserVehicle
const getPagingUserVehicleRequest = query => get("/api/UserVehicle/paging", { params: query });
const addNewUserVehicleRequest = data => post("/api/UserVehicle", data);
const getUserVehicleByIdRequest = id => get(`/api/UserVehicle/${id}`);
const updateUserVehicleRequest = data => update("/api/UserVehicle", data);
const deleteUserVehicleRequest = id => del(`/api/UserVehicle/${id}`);

function* addNewUserVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewUserVehicleRequest, data);
    yield put(addUserVehicleSuccess(response));
    
    response = yield call(getPagingUserVehicleRequest, queryParams);
    yield put(getPagingUserVehicleSuccess(response));
  } catch (error) {
    yield put(addUserVehicleError(error.response.statusText));
  }
}

function* updateUserVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateUserVehicleRequest, data);
    yield put(updateUserVehicleSuccess(response));
    
    response = yield call(getPagingUserVehicleRequest, queryParams);
    yield put(getPagingUserVehicleSuccess(response));
  } catch (error) {
    yield put(updateUserVehicleError(error.response.statusText));
  }
}

function* getPagingUserVehicle({ payload }) {
  try {
    let response = yield call(getPagingUserVehicleRequest, payload);
    yield put(getPagingUserVehicleSuccess(response));
  } catch (error) {
    yield put(getPagingUserVehicleError(error.response.statusText));
  }
}

function* getUserVehicleById({ payload }) {
  try {
    let response = yield call(getUserVehicleByIdRequest, payload);
    yield put(getUserVehicleByIdSuccess(response));
  } catch (error) {
    yield put(getUserVehicleByIdError(error.response.statusText));
  }
}

function* deleteUserVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(deleteUserVehicleRequest, data);

    let response = yield call(getPagingUserVehicleRequest, queryParams);
    yield put(getPagingUserVehicleSuccess(response));
  } catch (error) {
    yield put(getPagingUserVehicleError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(USER_VEHICLE_ADD_NEW, addNewUserVehicle);
  yield takeEvery(USER_VEHICLE_UPDATE, updateUserVehicle);
  yield takeEvery(USER_VEHICLE_GET_PAGING, getPagingUserVehicle);
  yield takeEvery(USER_VEHICLE_GET_BY_ID, getUserVehicleById);
  yield takeEvery(USER_VEHICLE_DELETE, deleteUserVehicle);
}

export {
  getPagingUserVehicleRequest
}

