import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { NIL } from "uuid";

const ModalForm = ({ isOpen, toggle, title, onSubmit, ...props }) => {
  const [item, setItem] = useState(
      {
        id: NIL,
        name: '',
        description: '',
      })

  const [submitted, setSubmitted] = useState(false);

  const onOpened = () => {
    if (props.item) {
      const { item } = props;
      setItem({
        ...item,
        description: item.description ?? '',
      })
    } else {
      setItem({
        id: NIL,
        name: '',
        description: '',
      });
      setSubmitted(false);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);

    if (item.name) {
      onSubmit(item);
    }
  };

  const handleChange = e =>{
    const { value, name } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }

  return (
      <Modal
          size="md"
          onOpened={onOpened}
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>

              <FormGroup className="mb-3">
                <Label htmlFor="">Name</Label>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={submitted && !item.name ? "is-invalid" : ""}
                    value={item.name}
                    onChange={handleChange}
                />
                {submitted && !item.name && (
                    <div className="invalid-feedback-custom">Name is required.</div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Description</Label>
                <Input
                    type="textarea"
                    rows={3}
                    name="description"
                    placeholder="Description"
                    value={item.description}
                    onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={handleSubmit} type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={toggle}
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  item: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
