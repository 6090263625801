import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input,
  Row, UncontrolledButtonDropdown,
} from "reactstrap";

// Product
import {
  getPagingProduct,
  addProduct,
  updateProduct,
  getProductById,
  deleteProduct,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { debounce } from "lodash";
import ModalForm from "containers/product/ModalForm";
import ProductTable from "containers/product/ProductTable";
import Select from "react-select";
import { shippingPaymentStatusOptions, timestampFileName } from "../../helpers/utils";
import { categoryService } from "../../services/category-service";
import { getTagsRequest } from "../../store/tag/saga";
import { getProductsToExcel } from "../../store/product/saga";

const ProductList = (props) => {
  const {
    getPagingProduct,
    addProduct,
    updateProduct,
    getProductById,
    deleteProduct,
    product,
    history
  } = props;

  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = product;

  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  const [ tagOptions, setTagOptions ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ tags, setTags ] = useState([])


  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingProduct({
      page,
      pageSize,
      term,
      categoryIds: categories.map(cat => {
        return cat.value
      }),
      tagIds: tags.map(tag => {
        return tag.value
      }),
    });
  }, [ page, pageSize, term, categories, tags ]);

  useEffect(() => {
    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(
            data
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(item => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                  };
                })
        );
      }
    });

    getTagsRequest().then(data => {
      setTagOptions(
          data.map(item => {
            return {
              key: item.id,
              value: item.id,
              label: item.name,
            };
          })
      );
    })

  }, []);

  const handleOnEdit = id => {
    history.push(`/product/${ id }`)
    // getProductById(id);
    // setModalEditIsOpen(true);
  };

  const handleUpdate = data => {
    updateProduct({
      data,
      queryParams: {}
    });
    setModalEditIsOpen(false);
  };

  const handleSubmit = data => {
    addProduct({
      data,
      queryParams: {}
    });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteProduct({
      data: id,
      queryParams: {}
    });
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push("/product/add-new")
    // setModalIsOpen(true);
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Product" breadcrumbItem="Products List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-2">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block" style={ { width: "100%" } }>
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 3 }>
                        <FormGroup>
                          <Select
                              name="categories"
                              value={ categories }
                              onChange={ option => setCategories(option) }
                              options={ categoryOptions }
                              classNamePrefix="select2-selection"
                              placeholder="Categories"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 3 }>
                        <FormGroup>
                          <Select
                              name="tags"
                              // value={ this.state.shippingPaymentStatus }
                              onChange={ option => setTags(option) }
                              options={ tagOptions }
                              classNamePrefix="select2-selection"
                              placeholder="Tags"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 4 }>
                        {/*<div className="text-sm-end">*/ }
                        {/*  /!*<Button*!/*/ }
                        {/*  /!*    type="button"*!/*/ }
                        {/*  /!*    color="primary"*!/*/ }
                        {/*  /!*    className="me-1"*!/*/ }
                        {/*  /!*    onClick={ handleOnAddNew }*!/*/ }
                        {/*  /!*>*!/*/ }
                        {/*  /!*  <i className="fas fa-plus"/> Add New*!/*/ }
                        {/*  /!*</Button>*!/*/ }
                        {/*</div>*/ }
                        <div className="text-sm-end">
                          
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>


                          <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={ () => {
                                    getProductsToExcel(
                                        `${ timestampFileName() }_Product.xlsx`
                                    ).then(()=>{
                                      
                                    });
                                  } }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-export me-1"/> Export
                                to Excel
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </> :
                      <>
                        { items && (
                            <ProductTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                defaultPageSize={ pageSize }
                                onEdit={ handleOnEdit }
                                onConfirmDelete={ handleConfirmDelete }
                                onChangePage={ (page) => setPage(page) }
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }

                { item && (
                    <ModalForm
                        title={ "Edit Product" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add Product" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

ProductList.propTypes = {
  getPagingProduct: PropTypes.func,
  addProduct: PropTypes.func,
  updateProduct: PropTypes.func,
  getProductById: PropTypes.func,
  deleteProduct: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ product }) => {
  return {
    product
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingProduct,
      addProduct,
      updateProduct,
      getProductById,
      deleteProduct,
    })(ProductList)
);
