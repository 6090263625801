import React, { useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { settingService } from "../services/setting-service";
import { currencyService } from "../services/currency-service";

const SettingsContext = React.createContext();

const initialState = {
  appInitialized: false,
  company: null,
  defaultCurrency: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'company': {
      return {
        ...state,
        appInitialized: true,
        company: action.payload,
      };
    }
    case 'DefaultCurrency':
      return {
        ...state,
        defaultCurrency: action.payload
      }

    default:
      return initialState;
  }
};

const SettingsProvider = props => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  
  const getCompany = async () => {
    let hostname = window.location.hostname;
    const data = await settingService.getSettingsRequest({ hostname });
    dispatch({
      type: 'company',
      payload: data,
    });
  };
  
  useEffect(async() => {
    await getCompany();
  }, []);

  const value = useMemo(
      () => ({
        ...state,
      }),
      // eslint-disable-next-line comma-dangle
      [ state ]
  );

  return <SettingsContext.Provider value={ value } { ...props } />;
};


const useSettingsService = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error(`useSettingsService must be used within an SettingsProvider`);
  }

  return context;
};

export { SettingsProvider, useSettingsService };