// PRODUCT_REVIEW
export const PRODUCT_REVIEW_ADD_NEW = 'PRODUCT_REVIEW_ADD_NEW';
export const PRODUCT_REVIEW_ADD_NEW_SUCCESS = 'PRODUCT_REVIEW_ADD_NEW_SUCCESS';
export const PRODUCT_REVIEW_ADD_NEW_ERROR = 'PRODUCT_REVIEW_ADD_NEW_ERROR';

export const PRODUCT_REVIEW_GET_PAGING = 'PRODUCT_REVIEW_GET_PAGING';
export const PRODUCT_REVIEW_GET_PAGING_SUCCESS = 'PRODUCT_REVIEW_GET_PAGING_SUCCESS';
export const PRODUCT_REVIEW_GET_PAGING_ERROR = 'PRODUCT_REVIEW_GET_PAGING_ERROR';

export const PRODUCT_REVIEW_GET_BY_ID = 'PRODUCT_REVIEW_GET_BY_ID';
export const PRODUCT_REVIEW_GET_BY_ID_SUCCESS = 'PRODUCT_REVIEW_GET_BY_ID_SUCCESS';
export const PRODUCT_REVIEW_GET_BY_ID_ERROR = 'PRODUCT_REVIEW_GET_BY_ID_ERROR';

export const PRODUCT_REVIEW_UPDATE = 'PRODUCT_REVIEW_UPDATE';
export const PRODUCT_REVIEW_UPDATE_SUCCESS = 'PRODUCT_REVIEW_UPDATE_SUCCESS';
export const PRODUCT_REVIEW_UPDATE_ERROR = 'PRODUCT_REVIEW_UPDATE_ERROR';

export const PRODUCT_REVIEW_DELETE = 'PRODUCT_REVIEW_DELETE';
export const PRODUCT_REVIEW_DELETE_SUCCESS = 'PRODUCT_REVIEW_DELETE_SUCCESS';
export const PRODUCT_REVIEW_DELETE_ERROR = 'PRODUCT_REVIEW_DELETE_ERROR';
