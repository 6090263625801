import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

export class ShippingFeeUserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                      <Th>Phone</Th>
                      <Th>
                        <div className="text-center">Action</div>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items &&
                      items.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{item.uniqueId}</Td>
                            <Td>{item.firstName}</Td>
                            <Td>{item.lastName}</Td>
                            <Td>{item.phoneNumber}</Td>
                            <Td>
                              <div className="text-center">
                                <Button
                                  color="danger"
                                  size="sm"
                                  outline
                                  onClick={() => onConfirmDelete(item.id)}
                                >
                                  <i className="mdi mdi-trash-can"></i>
                                  Remove
                                </Button>
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

ShippingFeeUserTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onViewDetail: PropTypes.func,
};

export default ShippingFeeUserTable;
