import { call, put, takeEvery } from "redux-saga/effects";
import { ADD_NEW_SETTING, GET_SETTING_DEFAULT } from "./actionTypes";
import { get, post, put as update } from "../../helpers/api_helper";
import {
  getSettingFail,
  getDefaultSettingSuccess,
  updateSettingSuccess,
  addNewSettingSuccess,
} from "./actions";
import { NIL } from "uuid";

const addNewSettingRequest = Setting => post(`/api/Setting`, Setting);
const getDefaultSettingRequest = () => get(`/api/Setting/default`);

const getDefaultCustomerServiceInfoRequest = () =>
  get(`/api/Setting/defaultCustomerServiceInfo`);

const saveDefaultCustomerServiceInfoRequest = data =>
  post(`/api/Setting/defaultCustomerServiceInfo`, data);

const updateSettingRequest = Setting => update(`/api/Setting`, Setting);

function* addNewSetting({ payload }) {
  try {
    let response;
    if (payload.id !== NIL) {
      response = yield call(updateSettingRequest, payload);
      yield put(updateSettingSuccess(response));
    } else {
      response = yield call(addNewSettingRequest, payload);
      yield put(addNewSettingSuccess(response));
    }

    response = yield call(getDefaultSettingRequest);

    yield put(getDefaultSettingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSettingFail(error));
  }
}

function* getDefaultSetting({ payload }) {
  try {
    var response = yield call(getDefaultSettingRequest, payload);

    yield put(getDefaultSettingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSettingFail(error));
  }
}

function* SettingSaga() {
  yield takeEvery(ADD_NEW_SETTING, addNewSetting);
  yield takeEvery(GET_SETTING_DEFAULT, getDefaultSetting);
}

export default SettingSaga;

export {
  getDefaultSettingRequest,
  getDefaultCustomerServiceInfoRequest,
  saveDefaultCustomerServiceInfoRequest,
};
