// SALE_ORDER_INVOICE
export const SALE_ORDER_INVOICE_ADD_NEW = 'SALE_ORDER_INVOICE_ADD_NEW';
export const SALE_ORDER_INVOICE_ADD_NEW_SUCCESS = 'SALE_ORDER_INVOICE_ADD_NEW_SUCCESS';
export const SALE_ORDER_INVOICE_ADD_NEW_ERROR = 'SALE_ORDER_INVOICE_ADD_NEW_ERROR';

export const SALE_ORDER_INVOICE_GET_PAGING = 'SALE_ORDER_INVOICE_GET_PAGING';
export const SALE_ORDER_INVOICE_GET_PAGING_SUCCESS = 'SALE_ORDER_INVOICE_GET_PAGING_SUCCESS';
export const SALE_ORDER_INVOICE_GET_PAGING_ERROR = 'SALE_ORDER_INVOICE_GET_PAGING_ERROR';

export const SALE_ORDER_INVOICE_GET_BY_ID = 'SALE_ORDER_INVOICE_GET_BY_ID';
export const SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS = 'SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS';
export const SALE_ORDER_INVOICE_GET_BY_ID_ERROR = 'SALE_ORDER_INVOICE_GET_BY_ID_ERROR';

export const SALE_ORDER_INVOICE_DETAIL_GET_BY_ID = 'SALE_ORDER_INVOICE_DETAIL_GET_BY_ID';
export const SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS = 'SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS';
export const SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_ERROR = 'SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_ERROR';

export const SALE_ORDER_INVOICE_UPDATE = 'SALE_ORDER_INVOICE_UPDATE';
export const SALE_ORDER_INVOICE_UPDATE_SUCCESS = 'SALE_ORDER_INVOICE_UPDATE_SUCCESS';
export const SALE_ORDER_INVOICE_UPDATE_ERROR = 'SALE_ORDER_INVOICE_UPDATE_ERROR';

export const SALE_ORDER_INVOICE_DELETE = 'SALE_ORDER_INVOICE_DELETE';
export const SALE_ORDER_INVOICE_DELETE_SUCCESS = 'SALE_ORDER_INVOICE_DELETE_SUCCESS';
export const SALE_ORDER_INVOICE_DELETE_ERROR = 'SALE_ORDER_INVOICE_DELETE_ERROR';

export const SALE_ORDER_INVOICE_CLEAR_NOTIFY = "SALE_ORDER_INVOICE_CLEAR_NOTIFY";
