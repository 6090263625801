import {
    PRODUCT_TRANSACTION_ADD_NEW,
    PRODUCT_TRANSACTION_ADD_NEW_ERROR,
    PRODUCT_TRANSACTION_ADD_NEW_SUCCESS,
    PRODUCT_TRANSACTION_DELETE,
    PRODUCT_TRANSACTION_DELETE_ERROR,
    PRODUCT_TRANSACTION_DELETE_SUCCESS,
    PRODUCT_TRANSACTION_GET_BY_ID,
    PRODUCT_TRANSACTION_GET_BY_ID_ERROR,
    PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS,
    PRODUCT_TRANSACTION_GET_PAGING,
    PRODUCT_TRANSACTION_GET_PAGING_ERROR,
    PRODUCT_TRANSACTION_GET_PAGING_SUCCESS,
    PRODUCT_TRANSACTION_UPDATE,
    PRODUCT_TRANSACTION_UPDATE_ERROR,
    PRODUCT_TRANSACTION_UPDATE_SUCCESS,
  } from './actionType';
  
  // ProductTransaction
  export const getPagingProductTransaction = items => ({
    type: PRODUCT_TRANSACTION_GET_PAGING,
    payload: items,
  });
  
  export const getPagingProductTransactionSuccess = items => ({
    type: PRODUCT_TRANSACTION_GET_PAGING_SUCCESS,
    payload: items,
  });
  
  export const getPagingProductTransactionError = error => ({
    type: PRODUCT_TRANSACTION_GET_PAGING_ERROR,
    payload: error,
  });
  
  export const addProductTransaction = items => ({
    type: PRODUCT_TRANSACTION_ADD_NEW,
    payload: items,
  });
  
  export const addProductTransactionSuccess = items => ({
    type: PRODUCT_TRANSACTION_ADD_NEW_SUCCESS,
    payload: items,
  });
  
  export const addProductTransactionError = error => ({
    type: PRODUCT_TRANSACTION_ADD_NEW_ERROR,
    payload: error,
  });
  
  export const getProductTransactionById = items => ({
    type: PRODUCT_TRANSACTION_GET_BY_ID,
    payload: items,
  });
  
  export const getProductTransactionByIdSuccess = items => ({
    type: PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS,
    payload: items,
  });
  
  export const getProductTransactionByIdError = error => ({
    type: PRODUCT_TRANSACTION_GET_BY_ID_ERROR,
    payload: error,
  });
  
  export const updateProductTransaction = items => ({
    type: PRODUCT_TRANSACTION_UPDATE,
    payload: items,
  });
  
  export const updateProductTransactionSuccess = items => ({
    type: PRODUCT_TRANSACTION_UPDATE_SUCCESS,
    payload: items,
  });
  
  export const updateProductTransactionError = error => ({
    type: PRODUCT_TRANSACTION_UPDATE_ERROR,
    payload: error,
  });

    export const deleteProductTransaction = items => ({
        type: PRODUCT_TRANSACTION_DELETE,
        payload: items,
    });
  
  
  export const deleteProductTransactionSuccess = items => ({
    type: PRODUCT_TRANSACTION_DELETE_SUCCESS,
    payload: items,
  });
  
  export const deleteProductTransactionError = error => ({
    type: PRODUCT_TRANSACTION_DELETE_ERROR,
    payload: error,
  });
  