import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Collapse,
  CardHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import {
  getTransports,
  addNewTransport,
  updateTransport,
  getTransportById,
  deleteTransport,
  clearTransportNotify,
} from "../../store/actions";
import TransportTable from "../../containers/transport/TransportTable";
import ModalForm from "../../containers/transport/ModalForm";
import {
  getCompanyName,
  handleDateChange,
  sendOrderDeliveryStatusOptions,
  toastMessage,
} from "helpers/utils";
import { transportService } from "services/transport-service";
import { timestampFileName, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

import moment from "moment";
import { debounce } from "lodash";

class TransportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      statuses: null,

      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      moreActionIsOpen: false,
      isOpen: false,
    };
  }

  debouncedFetchData = debounce(term => {
    const { statuses } = this.state;
    this.props.getTransports({
      term,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : null,
    });
  }, 500);

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getTransports({
      page: 1,
      pageSize: 10,
      term: "",
    });
  }

  handleOnEdit = id => {
    const { history } = this.props;
    history.push(`/transport/${id}`);
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    const { history } = this.props;
    history.push("/transport/add-new");
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });

    this.debouncedFetchData(value);
  };

  handleSearch = () => {
    const { term, startDate, endDate, statuses } = this.state;

    this.props.getTransports({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : null,
    });
  };

  handleSubmit = item => {
    this.props.addNewTransport(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    this.props.updateTransport(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteTransport(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { message, clearTransportNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearTransportNotify();
    }
  }

  handleOnPageChange = page => {
    const { pageSize, statuses } = this.state;
    this.props.getTransports({
      page,
      pageSize,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : null,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { statuses } = this.state;

    this.props.getTransports({
      page: 1,
      pageSize,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : null,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/transport/${id}`);
  };

  handleExportToExcel = () => {
    const { term, startDate, endDate, statuses } = this.state;
    transportService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
        statuses:
          statuses && statuses.length > 0
            ? statuses.map(a => {
                return a.value;
              })
            : null,
      },
      `${timestampFileName()}_Send_Order_List.xlsx`
    );
  };

  toggleMoreAction = () => {
    this.setState(prevState => ({
      moreActionIsOpen: !prevState.moreActionIsOpen,
    }));
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { pageSize, currentPage, moreActionIsOpen } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Send Cargo List | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Tranport List" />
            <Row>
              <Col lg={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end me-3">
                          {hasAccess(permission.transport.read) && (
                            <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={this.handleOnAddNew}
                            >
                              <i className="fas fa-plus" /> Add New
                            </Button>
                          )}
                          <ButtonDropdown
                            isOpen={moreActionIsOpen}
                            toggle={this.toggleMoreAction}
                          >
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={this.handleExportToExcel}
                                className="text-primary"
                              >
                                <i className="fas fa-file-excel me-1" /> Export
                                to Excel
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={this.toggleBody}
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                          className={
                            this.state.isOpen
                              ? "fas fa-angle-up"
                              : "fas fa-angle-down"
                          }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={this.state.isOpen}>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Select
                              name="statuses"
                              value={this.state.statuses}
                              onChange={this.handleSelectChange}
                              options={sendOrderDeliveryStatusOptions}
                              classNamePrefix="select2-selection"
                              isMulti
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <TransportTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={this.handleOnEdit}
                    onViewDetail={this.handleOnViewDetail}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Add New Transport"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Transport"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

TransportList.propTypes = {
  items: PropTypes.array,
  message: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  addNewTransport: PropTypes.func,
  updateTransport: PropTypes.func,
  getTransportById: PropTypes.func,
  deleteTransport: PropTypes.func,
  clearTransportNotify: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ transport }) => {
  return transport;
};

export default withRouter(
  connect(mapStateToProps, {
    getTransports,
    addNewTransport,
    updateTransport,
    getTransportById,
    deleteTransport,
    clearTransportNotify,
  })(TransportList)
);
