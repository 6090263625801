import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addProduct,
  deleteProduct,
  getPagingProduct,
  getProductById,
  updateProduct
} from "../../store/product/actions";
import { debounce, sumBy } from "lodash";
import ProductSelectionCard from "./ProductSelectionCard";
import CustomPagination from "../../components/Common/CustomPagination";

const ProductSelectionModal = props => {
  const {
    getPagingProduct,
    isOpen,
    toggle,
    title,
    product,
    onSelected
  } = props;

  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = product;

  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(5);
  const [ selectedProduct, setSelectedProduct ] = useState(null)

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 500), [])

  useEffect(() => {
    getPagingProduct({
      page,
      pageSize,
      term,
    });
  }, [ page, pageSize, term ]);


  const onOpened = () => {
    setPage(1);
    setPageSize(5);
    setSelectedProduct(null);
  }

  const handleSelect = () => {
    onSelected(selectedProduct);
  }

  const handleOnSelect = (product) => {
    setSelectedProduct(product);
  }

  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <ModalHeader className={ "py-2" } toggle={ toggle }>
          <CardTitle>{ title }</CardTitle>
        </ModalHeader>
        <ModalBody className={ "pb-0" }>
          <Row>
            <Col>

              <div className="search-box me-2 mb-2 d-inline-block" style={ { width: "100%" } }>
                <div className="position-relative">
                  <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={ (e) => {
                        debouncedFetchData(e.target.value)
                        setSelectedProduct(null);
                      } }
                  ></Input>
                  <i className="fas fa-search search-icon"/>
                </div>
              </div>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </ModalBody>
        <ModalBody>
          {
            loading ? <>
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </> :
                <>
                  { items && items.map((item, index) => {
                    let selected = selectedProduct && selectedProduct.id === item.id;
                    return <ProductSelectionCard
                        key={ index }
                        index={ index }
                        product={ item }
                        selected={ selected }
                        onSelect={ handleOnSelect }
                    />
                  }) }


                  <CustomPagination
                      itemCount={ itemCount }
                      currentPage={ page }
                      totalPage={ pageCount }
                      defaultPageSize={ pageSize }
                      pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                      onChangePage={ p => setPage(p) }
                      onPageSizeChange={ size => {

                        setPage(1);
                        setPageSize(size);
                      } }
                  />
                </>
          }
        </ModalBody>
        <ModalFooter className={ "py-2" }>
          <Button color="primary" disabled={ !selectedProduct } onClick={ handleSelect } type="submit">
            Select
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
  );
};

ProductSelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  onSelected: PropTypes.func
};

const mapStateToProps = ({ product }) => {
  return {
    product
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingProduct,
      addProduct,
      updateProduct,
      getProductById,
      deleteProduct,
    })(ProductSelectionModal)
);