import FileSaver from "file-saver";
import { getFile } from "helpers/api_helper";
import {
  postFile,
  post,
  del,
  put as update,
} from "helpers/api_helper";

const exportToPdf = async (id, fileName) => {
  return await getFile(`/api/SaleOrderInvoice/${id}/exportToPDF`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/SaleOrderInvoice/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const saleOrderInvoiceService = {
  exportToPdf,
  exportToExcelRequest,
};
