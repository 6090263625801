import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getOrderNote } from "../../store/order-note/actions";

import { Modal } from "reactstrap";
import OrderNoteTable from "./OrderNoteTable";

export class ViewOrderNoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageSize: 5,
    };
  }

  onOpened = () => {
    const { orderId, getOrderNote } = this.props;
    if (orderId) {
      getOrderNote({
        page: 1,
        pageSize: 5,
        term: "",
        orderId,
      });
    }
  };

  handleOnSearch = e => {
    const { orderId, getOrderNote } = this.props;
    const { value } = e.target;
    getOrderNote({ term: value ?? "", orderId });
  };

  handleOnPageChange = page => {
    const { orderId, getOrderNote } = this.props;
    const { pageSize } = this.state;
    getOrderNote({
      page,
      pageSize,
      orderId,
    });

    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { orderId, getOrderNote } = this.props;
    getOrderNote({
      page: 1,
      pageSize,
      orderId,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      title,
      items,

      itemCount,
      pageCount,
      loading,
    } = this.props;

    const { pageSize, currentPage } = this.state;

    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {items && (
            <OrderNoteTable
              items={items}
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={pageCount}
              loading={loading}
              defaultPageSize={pageSize}
              onSearch={this.handleOnSearch}
              onChangePage={this.handleOnPageChange}
              onPageSizeChange={this.handleOnPageSizeChange}
              isReadOnly={true}
            />
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ViewOrderNoteModal.propTypes = {
  orderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  getOrderNote: PropTypes.func,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
};

const mapStateToProps = ({ orderNote }) => {
  return orderNote;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderNote,
  })(ViewOrderNoteModal)
);
