import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from "react-select/creatable"
import { Link } from "react-router-dom"
import NumberFormat from "react-number-format"
// import ModalForm from "../item-unit-of-measurement/ModalForm"
import { NIL } from "uuid"
// import {
//   addNewItemUnitOfMeasurementRequest, 
//   getItemUnitOfMeasurementByIdRequest,
// } from "../../store/item-unit-of-measurement/saga"


const StockOutItemRow = props => {
  const { item, onChange, onDelete, isSubmitted } = props

  const [ isEditable, setIsEditable ] = useState(true)
  const [ modalAddUom, setModalAddUom ] = useState(false)
  const [ itemUom, setItemUom ] = useState({
    id: NIL,
    title: '',
    productId: item.productId?.value,
    uomId: null,
    displayOrder: 0,
    isBase: false,
    onHandQuantity: 0,
    outgoingQuantity: 0,
    minQuantity: 0,
    conversionRate: 1,
    note: '',
  })

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }
  
  const handleSelectChange = (valueType, actionMeta) => {
    const { name, action } = actionMeta
    if (action === "create-option") {
      const { value } = valueType
      setItemUom({
        ...itemUom,
        // itemId: item.itemId,
        title: value,
      })

      setModalAddUom(true)
    } else {
      onChange({
        ...item,
        [name]: valueType,
        productImages: valueType.productImages || []
      })
    }
  }

  // const handleSubmit = (itemUom) => {
  //   addNewItemUnitOfMeasurementRequest(itemUom).then((id) => {
  //     getItemUnitOfMeasurementByIdRequest(id).then(data =>{
  //       console.log(data);
  //       let option = {
  //         id: data.id,
  //         value: data.unitOfMeasurementId,
  //         label: data.title
  //       };
  //      
  //       onChange({
  //         ...item,
  //         unitOfMeasurementId: option,
  //         uomOptions: item.uomOptions.concat(option)
  //       });
  //       setModalAddUom(false);
  //     });
  //    
  //   })
  // }

  return (
      <>
        <tr onDoubleClick={ () => setIsEditable(!isEditable) }>
          <td className={ "input text-center pt-2" }>
            <input
                type="checkbox"
                className="form-check-input"
                checked={ item.isSelected }
                readOnly={ true }
                onChange={ handleCheckboxChange }
            />
          </td>
          <td>
          <div className="d-flex justify-content-center">
            {
              (() => {
                let productImage = item.productImages.length > 0 ? item.productImages[0] : null;
                return productImage ?
                  <img style={{ width: "80px", height: "50px", objectFit: "contain" }} src={productImage.url} alt="Product" />
                  :
                  <div className="avatar-order">
                    <i className="fas fa-solid fa-cube text-primary"></i>
                  </div>
              })()
            }
          </div>
        </td>
          <td>
            <>{ item.label }</>
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="quantity"
                        value={ item.quantity }
                        className={
                            "form-control text-end " +
                            (isSubmitted && item.quantity === 0 ? "is-invalid" : "")
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          onChange({
                            ...item,
                            quantity: values.floatValue
                          })
                        } }
                    />
                  </>
                  :
                  <div className={ isSubmitted && item.quantity === 0 ? ' is-invalid' : '' }>{ item.quantity }</div>
            }
            { isSubmitted && item.quantity === 0 && (
                <div className="invalid-feedback-custom">
                  Quantity is required.
                </div>
            ) }
          </td>
          <td className={ isEditable ? 'input' : '' }>
            {
              isEditable ?
                  <CreatableSelect
                      name="uomId"
                      value={ item.uomId }
                      onChange={ handleSelectChange }
                      className={ isSubmitted && !item.uomId ? "is-invalid" : '' }
                      options={ item.uomOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  /> : <div className={ isSubmitted && !item.uomId ? "is-invalid" : '' }>
                    { item.uomId?.label }</div>
            }

            { isSubmitted && !item.uomId &&
                <div className="invalid-feedback-custom">
                  Unit of Measurement is required.
                </div>
            }

          </td>
          <td className={ "input text-center" }>
            <div className="d-flex gap-3 justify-content-center">
              <Link className="text-primary" to="#">
                <i
                    className={ "font-size-18 mdi " + (isEditable ? 'mdi-eye' : 'mdi-pencil') }
                    id="edittooltip"
                    onClick={ () => {
                      setIsEditable(!isEditable)
                    } }
                ></i>
              </Link>

              <Link className="text-danger" to="#">
                <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={ () => {
                      onDelete(item)
                    } }
                ></i>
              </Link>
            </div>
          </td>
        </tr>
        {/*<ModalForm*/}
        {/*    title="Add Unit of Measurement"*/}
        {/*    item={ itemUom }*/}
        {/*    isOpen={ modalAddUom }*/}
        {/*    toggle={ () => setModalAddUom(!modalAddUom) }*/}
        {/*    onSubmit={ handleSubmit }*/}
        {/*/>*/}
      </>
  )
}

StockOutItemRow.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitted: PropTypes.bool
}

export default StockOutItemRow