import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { userService } from "../../services/user-service";
import Select, { components } from "react-select";
import { Badge } from "reactstrap";
import { debounce } from "lodash";

const customSingleValue = ({
                             children,
                             data,
                             ...props
                           }) => {
  const { label, uniqueId } = data
  return <components.SingleValue { ...props }>{ label } | { uniqueId } </components.SingleValue>
}

const senderFormatOptionLabel = ({ label, uniqueId, roles }) => {
  return (
      <div style={ { display: "flex", flexDirection: "column" } }>
        <p className={ "mb-1" }>
          <strong>{ label }</strong>
        </p>
        <p className={ "mb-0 font-size-13" }>
          <span className={ "me-2" }>ID</span>:<span className={ "ms-2" }>{ uniqueId }</span>
        </p>
        { roles && roles.length > 0 &&
            <p className={ "mb-0 font-size-13" }>
              <span className={ "me-2" }>Role</span>:<span className={ "ms-2" }>
            { roles.map((r, index) => {
              return <Badge color={ "primary" } key={ index } className={ "mx-1 px-2" }>{ r.name }</Badge>
            }) }
          </span>
            </p>
        }
      </div>
  )
}

const UserSelect = props => {
  const { 
    name,
    placeholder,
    value, 
    roleNames, 
    onChange 
  } = props;
  const [ loading, setLoading ] = useState(false)
  const [ userOptions, setUserOptions ] = useState([])
  const [ term, setTerm ] = useState('');
  const [ item, setItem ] = useState(null);

  const debouncedSearchUser = useCallback(
      debounce(term => {
        setTerm(term);
      }, 500),
      []
  )

  useEffect(() => {
    setLoading(true);
    userService.getUsersRequest({ term, roleNames }).then(records => {
      if (records) {
        setUserOptions(records.map(user => {
          return {
            key: user.id,
            value: user.id,
            label: `${ user.firstName } ${ user.lastName }`,
            phoneNumber: user.phoneNumber,
            uniqueId: user.uniqueId,
            roles: user.roles,
          };
        }));

        setLoading(false);
      }
    });
  }, [ term ]);

  return (
      <Select
          name={ name }
          value={ value }
          onChange={ onChange }
          options={ userOptions }
          components={ {
            SingleValue: customSingleValue
          } }
          onInputChange={ value => debouncedSearchUser(value) }
          formatOptionLabel={ senderFormatOptionLabel }
          classNamePrefix="select2-selection"
          placeholder={placeholder}
          isLoading={ loading }
          isClearable
      />
  );
};

UserSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showRole: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  roleNames: PropTypes.array,
  onChange: PropTypes.func
};

export default UserSelect;