import {
  GET_PICKUP_ATTACHMENTS,
  GET_PICKUP_ATTACHMENTS_SUCCESS,
  GET_PICKUP_ATTACHMENTS_FAIL,
  GET_PICKUP_ATTACHMENT_BY_ID,
  GET_PICKUP_ATTACHMENT_BY_ID_SUCCESS,
  ADD_NEW_PICKUP_ATTACHMENT,
  UPDATE_PICKUP_ATTACHMENT,
  DELETE_PICKUP_ATTACHMENT,
  PICKUP_ATTACHMENT_CLEAR_ERROR,
  UPDATE_PICKUP_ATTACHMENTS,
} from "./actionTypes";

// PickupAttachment
export const getPickupAttachments = query => ({
  type: GET_PICKUP_ATTACHMENTS,
  payload: query,
});

export const getPickupAttachmentsSuccess = items => ({
  type: GET_PICKUP_ATTACHMENTS_SUCCESS,
  payload: items,
});

export const getPickupAttachmentsFail = error => ({
  type: GET_PICKUP_ATTACHMENTS_FAIL,
  payload: error,
});

export const getPickupAttachmentById = id => ({
  type: GET_PICKUP_ATTACHMENT_BY_ID,
  payload: id,
});

export const getPickupAttachmentByIdSuccess = item => ({
  type: GET_PICKUP_ATTACHMENT_BY_ID_SUCCESS,
  payload: item,
});

export const getPickupAttachmentByIdFail = error => ({
  type: GET_PICKUP_ATTACHMENT_BY_ID_FAIL,
  payload: error,
});

export const addNewPickupAttachment = item => ({
  type: ADD_NEW_PICKUP_ATTACHMENT,
  payload: item,
});

export const updatePickupAttachment = item => ({
  type: UPDATE_PICKUP_ATTACHMENT,
  payload: item,
});

export const updatePickupAttachments = payload => ({
  type: UPDATE_PICKUP_ATTACHMENTS,
  payload,
});

export const deletePickupAttachment = item => ({
  type: DELETE_PICKUP_ATTACHMENT,
  payload: item,
});

export const clearPickupAttachmentError = () => ({
  type: PICKUP_ATTACHMENT_CLEAR_ERROR,
});
