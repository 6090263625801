import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERY_TO_DOOR,
  ADD_NEW_DELIVERY_TO_DOOR,
  GET_DELIVERY_TO_DOOR_BY_ID,
  UPDATE_DELIVERY_TO_DOOR,
  DELETE_DELIVERY_TO_DOOR,
  APPROVE_DELIVERY_TO_DOOR,
  REJECT_DELIVERY_TO_DOOR,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveryToDoorSuccess,
  getDeliveryToDoorByIdSuccess,
  getDeliveryToDoorFail,
  addNewDeliveryToDoorSuccess,
  updateDeliveryToDoorSuccess,
  deleteDeliveryToDoorSuccess,
  getApproveDeliveryToDoorByIdSuccess,
  getRejectDeliveryToDoorByIdSuccess,
} from "./actions";

const getDeliveryToDoorRequest = query =>
  get(`/api/DeliveryToDoor/paging`, { params: query });
const addNewDeliveryToDoorRequest = data => post(`/api/DeliveryToDoor`, data);
const getDeliveryToDoorByIdRequest = id => get(`/api/DeliveryToDoor/${id}`);
const getApproveDeliveryToDoorByIdRequest = params =>
  post(`/api/DeliveryToDoor/approve`, params);
const getRejectDeliveryToDoorByIdRequest = params =>
  post(`/api/DeliveryToDoor/reject`, params);
const updateDeliveryToDoorRequest = data => update(`/api/DeliveryToDoor`, data);
const deleteDeliveryToDoorRequest = id => del(`/api/DeliveryToDoor/${id}`);

function* fetchDeliveryToDoors({ payload }) {
  try {
    const response = yield call(getDeliveryToDoorRequest, payload);

    yield put(getDeliveryToDoorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* addNewDeliveryToDoor({ payload }) {
  try {
    yield call(addNewDeliveryToDoorRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryToDoorRequest, { orderId });
    yield put(getDeliveryToDoorSuccess(response));
    yield put(addNewDeliveryToDoorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* updateDeliveryToDoor({ payload }) {
  try {
    yield call(updateDeliveryToDoorRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryToDoorRequest, { orderId });
    yield put(getDeliveryToDoorSuccess(response));
    yield put(updateDeliveryToDoorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* getDeliveryToDoorById({ payload }) {
  try {
    var response = yield call(getDeliveryToDoorByIdRequest, payload);
    yield put(getDeliveryToDoorByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* getApproveDeliveryToDoorById({ payload }) {
  try {
    console.log(payload);
    var response = yield call(getApproveDeliveryToDoorByIdRequest, payload);

    yield put(getApproveDeliveryToDoorByIdSuccess(response));
    yield put(getDeliveryToDoorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* getRejectDeliveryToDoorById({ payload }) {
  try {
    console.log(payload);
    var response = yield call(getRejectDeliveryToDoorByIdRequest, payload);
    yield put(getRejectDeliveryToDoorByIdSuccess(response));
    yield put(getDeliveryToDoorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* deleteDeliveryToDoor({ payload }) {
  try {
    yield call(deleteDeliveryToDoorRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryToDoorRequest, { orderId });
    yield put(getDeliveryToDoorSuccess(response));
    yield put(deleteDeliveryToDoorSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryToDoorFail(error));
  }
}

function* DeliveryToDoorSaga() {
  yield takeEvery(GET_DELIVERY_TO_DOOR, fetchDeliveryToDoors);
  yield takeEvery(ADD_NEW_DELIVERY_TO_DOOR, addNewDeliveryToDoor);
  yield takeEvery(GET_DELIVERY_TO_DOOR_BY_ID, getDeliveryToDoorById);
  yield takeEvery(UPDATE_DELIVERY_TO_DOOR, updateDeliveryToDoor);
  yield takeEvery(DELETE_DELIVERY_TO_DOOR, deleteDeliveryToDoor);
  yield takeEvery(APPROVE_DELIVERY_TO_DOOR, getApproveDeliveryToDoorById);
  yield takeEvery(REJECT_DELIVERY_TO_DOOR, getRejectDeliveryToDoorById);
}

export default DeliveryToDoorSaga;
