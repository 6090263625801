import React from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { addNewDelivery } from "store/actions";
import Form from "containers/delivery/Form";
import { getCompanyName } from "../../helpers/utils";

const AddNewDelivery = ({ addNewDelivery }) => {
  const history = useHistory();

  const handleSubmit = (item) => {
    const payload = {
      history,
      data: item,
    };

    addNewDelivery(payload);
  };

  const handleCancel = () => {
    history.push("/transport");
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Add New Delivery | {getCompanyName()}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Home"
          backLink={"/delivery"}
          breadcrumbItem="Add New Delivery"
        />
        <Form onSubmit={handleSubmit} />
      </Container>
    </div>
  );
};

AddNewDelivery.propTypes = {
  addNewDelivery: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {
    addNewDelivery,
  })(AddNewDelivery)
);

