import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import {
  displayInvoicePaymentStatus,
  displayTransportStatus,
  hasAccess,
} from "helpers/utils";
import { permission } from "constants/permission";
import NumberFormat from "react-number-format";

class InvoiceReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Invoice No</th>
                      <th className="text-center">Invoice Date</th>
                      <th className="text-center">Amount</th>
                      <th className="text-center">Payment</th>
                      <th className="text-center">Outstanding</th>
                      <th className="text-center">Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => onEdit(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{num}</td>
                          <td>
                            {/* <Link to={`/invoice/${item.id}`}></Link> */}
                            {item.invoiceNo}
                          </td>
                          <td className="text-end">
                            {moment
                              .utc(item.date)
                              .local()
                              .format("DD-MMM-yyyy")}
                          </td>
                          <td className="text-end">
                            <NumberFormat
                              value={item.amount}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              displayType="text"
                            />
                          </td>
                          <td className="text-end">
                            <NumberFormat
                              value={item.totalPayment}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              displayType="text"
                            />
                          </td>
                          <td className="text-end">
                            <NumberFormat
                              value={item.outstanding}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              displayType="text"
                            />
                          </td>

                          <td className="text-center">
                            {displayInvoicePaymentStatus(item)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

InvoiceReportTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default InvoiceReportTable;
