import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, Card, CardBody } from "reactstrap";
import OrderRowCard from "./OrderRowCard";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

export class UserOrderTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tttop: false,
    };
  }

  handlePrintLabel = item => {
    onViewPrint(item);
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onChangePage,
      onPageSizeChange,
    } = this.props;

    return (
      <>
        <Card className="mt-2">
          <CardBody>
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              
              <>
              {items.map((item, index)=>{
                return <OrderRowCard 
                    key={index} 
                    item={item}
                    typeShow="user"
                    
                />
              })}
            </>
            )}

            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

UserOrderTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onPrintPreview: PropTypes.func,
  onSubmitToWarehouse: PropTypes.func,
  onSendInvoice: PropTypes.func,
  onAddNote: PropTypes.func,
};

export default UserOrderTable;
