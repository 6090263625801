import {
    GET_SUPPLIER,
    GET_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_BY_ID,
    GET_SUPPLIER_BY_ID_SUCCESS,
    ADD_NEW_SUPPLIER,
    ADD_NEW_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER,
    UPDATE_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER,
    DELETE_SUPPLIER_SUCCESS,
    SUPPLIER_CLEAR_NOTIFY,
  } from "./actionTypes";
  
  // SUPPLIER
  export const getSupplier = id => ({
    type: GET_SUPPLIER,
    payload: id,
  });
  
  export const getSupplierSuccess = items => ({
    type: GET_SUPPLIER_SUCCESS,
    payload: items,
  });
  
  export const getSupplierFail = error => ({
    type: GET_SUPPLIER_FAIL,
    payload: error,
  });
  
  export const getSupplierById = data => ({
    type: GET_SUPPLIER_BY_ID,
    payload: data,
  });
  
  export const getSupplierByIdSuccess = item => ({
    type: GET_SUPPLIER_BY_ID_SUCCESS,
    payload: item,
  });
  
  export const getSupplierByIdFail = error => ({
    type: GET_SUPPLIER_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewSupplier = payload => ({
    type: ADD_NEW_SUPPLIER,
    payload,
  });
  
  export const addNewSupplierSuccess = payload => ({
    type: ADD_NEW_SUPPLIER_SUCCESS,
    payload,
  });
  
  export const updateSupplier = payload => ({
    type: UPDATE_SUPPLIER,
    payload,
  });
  
  export const updateSupplierSuccess = payload => ({
    type: UPDATE_SUPPLIER_SUCCESS,
    payload,
  });
  
  export const deleteSupplier = payload => ({
    type: DELETE_SUPPLIER,
    payload,
  });
  
  export const deleteSupplierSuccess = payload => ({
    type: DELETE_SUPPLIER_SUCCESS,
    payload,
  });
  
  export const clearSupplierNotify = () => ({
    type: SUPPLIER_CLEAR_NOTIFY,
  });
