import { SEVERITY } from "helpers/utils.js";
import {
  GET_ALERT_NOTIFICATION_HISTORY,
  GET_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  GET_ALERT_NOTIFICATION_HISTORY_FAIL,
  GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS,
  ADD_NEW_ALERT_NOTIFICATION_HISTORY,
  ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  UPDATE_ALERT_NOTIFICATION_HISTORY,
  UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  DELETE_ALERT_NOTIFICATION_HISTORY,
  DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const alertNotificationHistory = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_ALERT_NOTIFICATION_HISTORY:
    case UPDATE_ALERT_NOTIFICATION_HISTORY:
    case DELETE_ALERT_NOTIFICATION_HISTORY:
    case GET_ALERT_NOTIFICATION_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_ALERT_NOTIFICATION_HISTORY_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Alert Notification History",
          text: "Alert Notification History has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Alert Notification History",
          text: "Alert Notification History has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Alert Notification History",
          text: "Alert Notification History has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_ALERT_NOTIFICATION_HISTORY_FAIL:
      return {
        ...state,
        error: payload,
      };
    case ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default alertNotificationHistory;
