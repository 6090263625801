import FileSaver from "file-saver";
import {
  get,
  getFile,
  postFile,
  post,
  del,
  put as update,
} from "helpers/api_helper";

const exportTransportOrdersToExcel = async (query, fileName) => {
  return await postFile(`/api/TransportOrder/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const exportService = {
  exportTransportOrdersToExcel,
};
