import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import { getPagingSaleOrderInvoicePayment, getSaleOrderInvoiceDetailById, updateSaleOrder, getPagingSaleOrderComment } from "store/actions"
import SaleOrderInvoiceDetailTab from "./SaleOrderInvoiceDetailTab";
import SaleOrderInvoicePaymentTab from "./SaleOrderInvoicePaymentTab";

const SaleOrderInvoiceDetail = props => {
  const { getPagingSaleOrderInvoicePayment, getSaleOrderInvoiceDetailById, saleOrderInvoice, updateSaleOrder, match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)

  const { item, loading } = saleOrderInvoice

  useEffect(() => {
    const { id } = match.params
    // getSaleOrderInvoiceById(id)
    setId(id)
  }, [])

  const handleOnSubmit = (data) => {
    updateSaleOrder({
      data,
      history,
      queryParams: {}
    })
  }

  const handleOnCancel = () => {
    history.push(`/sale-order-invoice`);
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sale Order Invoice Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ "/sale-order-invoice" }
                breadcrumbItem={ `${ item?.invoiceNo } | Sale Order invoice detail` }
            />
            <Row>
              <Col md={ 12 }>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "1",
                        }) }
                        onClick={ () => {
                          setActiveTab("1");
                          getSaleOrderInvoiceDetailById(id)
                        } }
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "2",
                        }) }
                        onClick={ () => {
                          setActiveTab("2");
                          getPagingSaleOrderInvoicePayment({ saleOrderInvoiceId: id })
                        } }
                    >
                      Payment
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={ activeTab }>
                  <TabPane tabId="1">
                   
                    {id ? <SaleOrderInvoiceDetailTab  saleOrderInvoiceId={id}/>: null}
                  </TabPane>
                  <TabPane tabId="2">
                    {id ? <SaleOrderInvoicePaymentTab saleOrderInvoiceId={id}/>: null}

                  </TabPane>
                </TabContent>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
  )
}

SaleOrderInvoiceDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  saleOrderInvoice: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getSaleOrderInvoiceById: PropTypes.func,
  updateSaleOrder: PropTypes.func,
  getPagingSaleOrderComment: PropTypes.func,
  getPagingSaleOrderInvoicePayment: PropTypes.func,
}

const mapStateToProps = ({ saleOrderInvoice }) => {
  return {
    saleOrderInvoice,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPagingSaleOrderInvoicePayment,
      getSaleOrderInvoiceDetailById,
      updateSaleOrder,
      getPagingSaleOrderComment
    })(SaleOrderInvoiceDetail)
)
