import { SEVERITY } from "helpers/utils.js";
import {
  GET_PICKUP,
  GET_PICKUP_SUCCESS,
  GET_PICKUP_FAIL,
  GET_PICKUP_BY_ID_SUCCESS,
  ADD_NEW_PICKUP,
  ADD_NEW_PICKUP_SUCCESS,
  UPDATE_PICKUP,
  UPDATE_PICKUP_SUCCESS,
  DELETE_PICKUP,
  DELETE_PICKUP_SUCCESS,
  PICKUP_CLEAR_NOTIFY,
  APPROVE_PICKUP,
  REJECT_PICKUP,
  APPROVE_PICKUP_SUCCESS,
  REJECT_PICKUP_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const OrderNote = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_PICKUP:
    case UPDATE_PICKUP:
    case DELETE_PICKUP:
    case GET_PICKUP:
    case APPROVE_PICKUP:
    case REJECT_PICKUP:
      return {
        ...state,
        loading: true,
        item: null,
      };
    case APPROVE_PICKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Pickup",
          text: "Pickup has been approved successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case REJECT_PICKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Pickup",
          text: "Pickup has been rejected.",
          severity: SEVERITY.DANGER,
        },
      };
    case GET_PICKUP_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };

    case GET_PICKUP_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_PICKUP_FAIL:
      return {
        ...state,
        error: payload,
      };

    case ADD_NEW_PICKUP_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_PICKUP_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_PICKUP_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case PICKUP_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default OrderNote;
