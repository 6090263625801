import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  getTransportOrders,
  addNewTransportOrders,
  deleteTransportOrders,
  clearTransportOrderError,
  addNoteToTransportOrderItem,
  changeStatusTransportOrderItem,
} from "store/actions";
import Select from "react-select";
import VerifyOrderTrackingForm from "./VerifyOrderTrackingForm";
import { exportService } from "services/export-service";
import { timestampFileName } from "helpers/utils";
import OrderNoteForm from "containers/order/OrderNoteForm";
import ViewOrderNoteModal from "containers/order-note/ViewOrderNoteModal";
import {
  pickupPaymentStatusOptions,
  deliveryPaymentStatusOptions,
} from "helpers/utils";
import SendNotificationModal from "./SendNotificationModal";
import { orderService } from "services/order-service";
import { debounce } from "lodash";
import ReceiveOrderItemTable from "../../containers/receive-order/ReceiveOrderItemTable";

const deliveryStatusOptions = [
  { key: 3, value: 3, label: "In Cambodia Warehouse" },
  { key: 4, value: 4, label: "Delivery To Customer" },
  { key: 5, value: 5, label: "Completed" },
  { key: 6, value: 6, label: "Rejected" },
  { key: 7, value: 7, label: "Closed" },
];

export const shippingPaymentStatusOptions = [
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

export const verifiedStatusOptions = [
  { key: 1, value: 0, label: "None" },
  { key: 2, value: 1, label: "Completed" },
  { key: 3, value: 2, label: "Incomplete" },
];

const ReceiveOrderTab = ({ transportId, ...props }) => {
  const [orderId, setOrderId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [pickupPaymentStatus, setPickupPaymentStatus] = useState(null);
  const [shippingPaymentStatus, setShippingPaymentStatus] = useState(null);
  const [deliveryPaymentStatus, setDeliveryPaymentStatus] = useState(null);
  const [verifiedStatus, setVerifyStatus] = useState(null);
  const [modalVerifyOrderIsOpen, setModalVerifyOrderIsOpen] = useState(false);
  const [modalAddNote, setModalAddNote] = useState(false);
  const [modalViewNote, setModalViewNote] = useState(false);
  const [modalNotification, setModalNotication] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const {
    getTransportOrders,
    addNoteToTransportOrderItem,
    changeStatusTransportOrderItem,
    transportOrder,
  } = props;
  const { items, itemCount, pageCount, loading } = transportOrder;

  useEffect(() => {
    getTransportOrders({
      transportId,
      page,
      pageSize,
      term,
      deliveryStatus:
        deliveryStatus &&
        deliveryStatus.map(a => {
          return a.value;
        }),
      shippingPaymentStatus:
        shippingPaymentStatus &&
        shippingPaymentStatus.map(a => {
          return a.value;
        }),
      pickupPaymentStatus:
        pickupPaymentStatus &&
        pickupPaymentStatus.map(a => {
          return a.value;
        }),
      deliveryPaymentStatus:
        deliveryPaymentStatus &&
        deliveryPaymentStatus.map(a => {
          return a.value;
        }),
      verifiedStatus:
        verifiedStatus &&
        verifiedStatus.map(a => {
          return a.value;
        }),
    });
  }, [
    getTransportOrders,
    isRefresh,
    page,
    pageSize,
    term,
    deliveryStatus,
    shippingPaymentStatus,
    pickupPaymentStatus,
    deliveryPaymentStatus,
    verifiedStatus,
  ]);

  const debouncedFetchData = useCallback(
    debounce(value => {
      setTerm(value);
    }, 500),
    []
  );

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOnPageChange = page => {
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setPageSize(pageSize);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    debouncedFetchData(value);
  };

  const handleOnVerifyOrder = id => {
    setOrderId(id);
    setModalVerifyOrderIsOpen(true);
    setIsRefresh(false);
  };

  const handleSendNotification = data => {
    orderService.informToSenderForInvoicePaymentRequest(data).then(() => {
      setModalNotication(false);
    });
  };

  const handleOnAddNote = orderId => {
    setModalAddNote(true);
    setSelectedId(orderId);
  };

  const handleOnSubmitNote = note => {
    setModalAddNote(false);
    addNoteToTransportOrderItem({ transportId, note });
  };

  const handleOnChangeOrderStatus = order => {
    const { id, deliveryStatus } = order;
    changeStatusTransportOrderItem({
      id,
      deliveryStatus: deliveryStatus === 3 ? 5 : 3, // Toggle between In Cambodia Warehouse and Completed
    });
  };

  const handleOnViewNote = order => {
    const { id, trackingNumber } = order;
    setOrderId(id);
    setTrackingNumber(trackingNumber);
    setModalViewNote(true);
  };

  const handleOnNotificationForPayment = id => {
    setModalNotication(true);
    setSelectedId(id);
  };

  const handleExportToExcel = () => {
    const { transport } = props;
    const { item } = transport;
    exportService.exportTransportOrdersToExcel(
      {
        transportId,
        term,
        deliveryStatus:
          deliveryStatus &&
          deliveryStatus.map(a => {
            return a.value;
          }),
        shippingPaymentStatus:
          shippingPaymentStatus &&
          shippingPaymentStatus.map(a => {
            return a.value;
          }),
        pickupPaymentStatus:
          pickupPaymentStatus &&
          pickupPaymentStatus.map(a => {
            return a.value;
          }),
        deliveryPaymentStatus:
          deliveryPaymentStatus &&
          deliveryPaymentStatus.map(a => {
            return a.value;
          }),
        verifiedStatus:
          verifiedStatus &&
          verifiedStatus.map(a => {
            return a.value;
          }),
      },
      `${timestampFileName()}_${item.title}_Order_List.xlsx`
    );
  };

  return (
    <Row>
      <Col md={12}>
        <Card className="mb-1 rounded-3">
          <CardHeader className="bg-transparent border pb-0 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Select
                    name="shippingPaymentStatus"
                    value={shippingPaymentStatus}
                    onChange={value => {
                      setPage(1);
                      setShippingPaymentStatus(value);
                    }}
                    options={shippingPaymentStatusOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Shipping Payment Status"
                    isClearable
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Select
                    name="verifiedStatus"
                    value={verifiedStatus}
                    onChange={value => {
                      setPage(1);
                      setVerifyStatus(value);
                    }}
                    options={verifiedStatusOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Verify Status"
                    isClearable
                    isMulti
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <div className="text-sm-end me-3">
                  <Button
                    type="button"
                    color="primary"
                    outline
                    onClick={handleExportToExcel}
                  >
                    <i className="fas fa-file-excel me-1" /> Export to Excel
                  </Button>
                </div>
              </Col>
            </Row>
            <div
              style={{
                position: "absolute",
                top: "1rem",
                right: "0.5rem",
                cursor: "pointer",
              }}
              onClick={toggleIsOpen}
            >
              <div className="d-flex">
                <span className="font-size-24 text-primary ps-2 pe-2">
                  <i
                    className={isOpen ? "fas fa-angle-up" : "fas fa-angle-down"}
                  ></i>
                </span>
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody className="pb-1">
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Select
                      name="deliveryStatus"
                      value={deliveryStatus}
                      onChange={value => {
                        setPage(1);
                        setDeliveryStatus(value);
                      }}
                      options={deliveryStatusOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Delivery Status"
                      isClearable
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Select
                      name="pickupPaymentStatus"
                      value={pickupPaymentStatus}
                      onChange={value => {
                        setPage(1);
                        setPickupPaymentStatus(value);
                      }}
                      options={pickupPaymentStatusOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Pickup Payment Status"
                      isClearable
                      isMulti
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Select
                      name="deliveryPaymentStatus"
                      value={deliveryPaymentStatus}
                      onChange={value => {
                        setPage(1);
                        setDeliveryPaymentStatus(value);
                      }}
                      options={deliveryPaymentStatusOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Delivery Payment Status"
                      isClearable
                      isMulti
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>

        <ReceiveOrderItemTable
          items={items}
          itemCount={itemCount}
          currentPage={page}
          defaultPageSize={pageSize}
          totalPage={pageCount}
          loading={loading}
          onChangePage={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          onVerifyOrder={handleOnVerifyOrder}
          onAddNote={handleOnAddNote}
          onChangeOrderStatus={handleOnChangeOrderStatus}
          onViewNote={handleOnViewNote}
          onNotificationForPayment={handleOnNotificationForPayment}
        />
      </Col>

      {orderId && (
        <VerifyOrderTrackingForm
          title="Verify Order Tracking Number"
          orderId={orderId}
          transportId={transportId}
          
          isOpen={modalVerifyOrderIsOpen}
          toggle={() => {
            setModalVerifyOrderIsOpen(!modalVerifyOrderIsOpen);
            setIsRefresh(true);
          }}
        />
      )}

      {selectedId && (
        <SendNotificationModal
          title={"sdfdsf"}
          orderId={selectedId}
          isOpen={modalNotification}
          toggle={() => setModalNotication(!modalNotification)}
          onSubmit={handleSendNotification}
        />
      )}

      {selectedId && (
        <OrderNoteForm
          title="Add New Note"
          orderId={selectedId}
          isOpen={modalAddNote}
          toggle={() => setModalAddNote(!modalAddNote)}
          onSubmit={handleOnSubmitNote}
        />
      )}

      {orderId && (
        <ViewOrderNoteModal
          title={`${trackingNumber} - View Note`}
          orderId={orderId}
          isOpen={modalViewNote}
          toggle={() => setModalViewNote(!modalViewNote)}
        />
      )}

      {selectedId && (
        <SendNotificationModal
          title={"sdfdsf"}
          orderId={selectedId}
          isOpen={modalNotification}
          toggle={() => setModalNotication(!modalNotification)}
          onSubmit={handleSendNotification}
        />
      )}
    </Row>
  );
};

ReceiveOrderTab.propTypes = {
  transport: PropTypes.object,
  transportId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  addNewTransportOrders: PropTypes.func,
  getTransportOrders: PropTypes.func,
  transportOrder: PropTypes.object,
  error: PropTypes.string,
  clearTransportOrderError: PropTypes.func,
  deleteTransportOrders: PropTypes.func,

  addNoteToTransportOrderItem: PropTypes.func,
  changeStatusTransportOrderItem: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ transport, transportOrder }) => {
  return {
    transport,
    transportOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTransportOrders,
    addNewTransportOrders,
    clearTransportOrderError,
    deleteTransportOrders,
    addNoteToTransportOrderItem,
    changeStatusTransportOrderItem,
  })(ReceiveOrderTab)
);
