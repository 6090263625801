import { get } from "helpers/api_helper";

const sendSMSRequest = async query => {
  return await get(`/api/Setting/sendSMS`, { params: query });
};

const getSettingsRequest = async (query)=>{
  return await get(`/api/Setting/default`, { params: query });
}

export const settingService = {
  sendSMSRequest,
  getSettingsRequest
};
