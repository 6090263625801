import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container, Card, Input, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { getDeviceInfo } from "../../store/actions";

import moment from "moment";
import DeviceInfoTable from "containers/device-info/DeviceInfoTable";
import { getCompanyName } from "../../helpers/utils";

class DeviceInfoList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      statuses: null,
    };
  }

  componentDidMount() {
    this.props.getDeviceInfo({ page: 1, pageSize: 10, term: "" });
  }

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getDeviceInfo({ term: value ?? "" });
  };

  handleSearch = () => {
    const { term, startDate, endDate, statuses } = this.state;

    this.props.getDeviceInfo({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses: statuses.map(a => {
        return a.value;
      }),
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getDeviceInfo({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getDeviceInfo({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/device-info/${id}`);
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Device Info List | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Device Info List" />
            <Row>
              <Col lg={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={4}></Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <DeviceInfoTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    showUserInfo={true}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                    onViewDetail={this.handleOnViewDetail}
                  />
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

DeviceInfoList.propTypes = {
  items: PropTypes.array,
  message: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getDeviceInfo: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ deviceInfo }) => {
  return deviceInfo;
};

export default withRouter(
  connect(mapStateToProps, {
    getDeviceInfo,
  })(DeviceInfoList)
);
