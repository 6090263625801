import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Collapse } from "reactstrap";
import moment from "moment/moment";

const SaleOrderCommentCard = props => {
  const { item, onEdit, onConfirmDelete } = props;
  
  const [ isOpen, setIsOpen ] = useState(false);
  
  
  return (
      <Card className="mb-2">
        <CardHeader className="bg-transparent border">
          <div>
            <p style={{ marginRight: "5rem" }} className="text-truncate">
              {item.creatdByUser}
            </p>

            <CardSubtitle>
              <span className="text-muted">
                {moment
                    .utc(item.createdDate)
                    .local()
                    .format("DD-MMM-yyyy h:mm:ss a")}
              </span>
            </CardSubtitle>
          </div>

          <div
              style={{
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
              }}
          >
            
                <>
                <span
                    className="font-size-14 text-primary px-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => onEdit(item.id)}
                >
                  <i className={"fas fa-pen"}></i>
                </span>
                  <span
                      className="font-size-14 text-danger px-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => onConfirmDelete(item.id)}
                  >
                  <i className="fas fa-trash"></i>
                </span>
                </>
            
            <span
                className="font-size-24 text-primary px-2"
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpen(!isOpen)}
            >
              <i
                  className={
                    isOpen ? "fas fa-angle-up" : "fas fa-angle-down"
                  }
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className="border">
            <CardTitle>Note</CardTitle>
            <div id="app" dangerouslySetInnerHTML={{__html: item.comment}}>
           </div>
            <CardTitle>Created Date</CardTitle>
            <p className="text-muted">
              {moment
                  .utc(item.createdDate)
                  .local()
                  .format("DD-MMM-yyyy h:mm:ss a")}
            </p>
            <CardTitle>Created By</CardTitle>
            <p className="text-muted">{item.creatdByUser}</p>
          </CardBody>
        </Collapse>
      </Card>
  );
};

SaleOrderCommentCard.propTypes = {
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};

export default SaleOrderCommentCard;