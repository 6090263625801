import {
  SALE_ORDER_ADD_NEW,
  SALE_ORDER_ADD_NEW_ERROR,
  SALE_ORDER_ADD_NEW_SUCCESS, SALE_ORDER_CHANGE_STATUS, SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS,
  SALE_ORDER_GET_BY_ID,
  SALE_ORDER_GET_BY_ID_ERROR,
  SALE_ORDER_GET_BY_ID_SUCCESS,
  SALE_ORDER_GET_PAGING,
  SALE_ORDER_GET_PAGING_ERROR,
  SALE_ORDER_GET_PAGING_SUCCESS,
  SALE_ORDER_UPDATE,
  SALE_ORDER_UPDATE_ERROR,
  SALE_ORDER_UPDATE_SUCCESS,
} from './actionType';


// item
const initialState = {
  item: null,
  items: [],
  result: null,
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALE_ORDER_ADD_NEW:
    case SALE_ORDER_GET_PAGING:
    case SALE_ORDER_CHANGE_STATUS:
      return {
        ...state,
        result: null,
        loading: true,
        error: null,
        // item: null,
      };
      
    case SALE_ORDER_UPDATE:
      return {
        ...state,
        loading: false,
        error: null,
        result: null
      }
      
    case SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS:
      return {
        ...state,
        result: payload
      }

    case SALE_ORDER_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
      };

    case SALE_ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        result: payload,
        // item: payload
      };

    case SALE_ORDER_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case SALE_ORDER_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        result: payload
      };


    case SALE_ORDER_ADD_NEW_ERROR:
    case SALE_ORDER_GET_PAGING_ERROR:
    case SALE_ORDER_GET_BY_ID_ERROR:
    case SALE_ORDER_UPDATE_ERROR:
      return {
        ...state,
        result: null,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
