import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, FormGroup, Input, Label, Modal } from "reactstrap";

const PickupConfirmModalForm = ({
  isOpen,
  id,
  toggle,
  confirmType,
  onSubmit,
}) => {
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const onOpened = () => {
    switch (confirmType) {
      case "Approve":
        setComment(
          "Your pickup request has been approved. \nWe will contact you soon.\nThank you!"
        );
        break;
      case "Reject":
        setComment(
          "Your pickup request has been rejected.\n For more info please contact:\n(+82)10 6469 7899 \n(+855)96 272 3333 \nThank you!"
        );
        break;
      default:
        break;
    }

    setSubmitted(false);
  };

  const handleSubmit = () => {
    const data = {
      confirmType,
      id,
      comment,
    };
    onSubmit(data);
  };

  return (
    <Modal
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {confirmType == "Approve" ? "Confirm Approval" : "Confirm Rejection"}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {confirmType === "Approve" ? (
          <Alert>
            Are you sure want to approve this request ? Please leave comment
            below.
          </Alert>
        ) : null}
        {confirmType === "Reject" ? (
          <Alert color="danger">
            Are you sure want to reject this request ? Please leave comment
            below.
          </Alert>
        ) : null}
        <FormGroup className="mb-3">
          <Label htmlFor="totalAmount">Comment</Label>
          <Input
            type="textarea"
            rows={5}
            name="comment"
            value={comment}
            placeholder="Comment"
            onChange={e => setComment(e.target.value)}
          />
        </FormGroup>
      </div>
      <div className="modal-footer">
        <Button
          disabled={!comment}
          color={confirmType === "Approve" ? "success" : "danger"}
          onClick={handleSubmit}
          type="submit"
        >
          {confirmType}
        </Button>

        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

PickupConfirmModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  confirmType: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  userId: PropTypes.string,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
};

export default PickupConfirmModalForm;
