import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  // ProductReview
  getPagingProductReview,
  addProductReview,
  getProductReviewById,
  updateProductReview,
  deleteProductReview,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ModalForm from "containers/product-review/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ProductReviewCard from "../../containers/product-review/ProductReviewCard";

const ProductReviewTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const {
    getPagingProductReview,
    addProductReview,
    getProductReviewById,
    updateProductReview,
    deleteProductReview,
    clearProductReviewError,
    productReview,
    productId,
  } = props;

  const {
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
  } = productReview;

  useEffect(() => {
    getPagingProductReview({ productId, page, pageSize })
  }, [ page, pageSize ]);

  const handleSubmit = data => {
    addProductReview({
      data,
      queryParam: {
        productId
      }
    });
    setModalAddIsOpen(false);
  };

  const handleUpdate = data => {
    updateProductReview({
      data,
      queryParam: {
        productId
      }
    });
    setModalEditIsOpen(false);
  };

  const handleDelete = () => {
    deleteProductReview({
      data: selectedId,
      queryParam: {
        productId
      }
    });
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnEdit = (id) => {
    getProductReviewById(id);
    setModalEditIsOpen(true);
  }

  const handleOnConfirmDelete = id => {
    setSelectedId(id);
    setModalConfirmDeleteIsOpen(true);
  }

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target;
                          //   debouncedQuickSearch(value);
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {
          loading ?
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                {
                  items.map((item, index) => {
                    return <ProductReviewCard
                        key={ index }
                        item={ item }
                        onEdit={ handleOnEdit }
                        onConfirmDelete={ handleOnConfirmDelete }/>
                  })
                }
              </>

        }
        <Card>
          <CardBody>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                defaultPageSize={ pageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => setPage(i) }
                onPageSizeChange={ size => {
                  setPage(1);
                  setPageSize(size);
                } }
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Product Review"
            isOpen={ modalAddIsOpen }
            productId={ productId }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Product Review"
                item={ item }
                productId={ productId }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  );
};


ProductReviewTab.propTypes = {
  productId: PropTypes.string,
  productReview: PropTypes.object,
  getPagingProductReview: PropTypes.func,
  addProductReview: PropTypes.func,
  getProductReviewById: PropTypes.func,
  updateProductReview: PropTypes.func,
  deleteProductReview: PropTypes.func,
};

const mapStateToProps = ({ productReview }) => {
  return {
    productReview
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingProductReview,
      addProductReview,
      getProductReviewById,
      updateProductReview,
      deleteProductReview,
    })(ProductReviewTab)
);
