import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import ProductRowCard from "./ProductRowCard";

const ProductTable = ({
  onChangePage, 
  onPageSizeChange, 
  onEdit, 
  onConfirmDelete, 
  onDownloadPrintLabel, 
  onPrintPreview, 
  onAddNote, 
  onSendInvoice, 
  onDeliveryStatusChange, 
  onAddToTransport,
  ...props
}) => {
  const {
    loading,
    items,
    itemCount,
    currentPage,
    totalPage,
    defaultPageSize
  } = props;

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {items.map((item, index) => (
            <ProductRowCard 
              key={index} 
              item={item}
              onEdit={onEdit}
              onConfirmDelete={onConfirmDelete}
              onDownloadPrintLabel={onDownloadPrintLabel}
              onPrintPreview={onPrintPreview}
              onAddNote={onAddNote}
              onSendInvoice={onSendInvoice}
              onDeliveryStatusChange={onDeliveryStatusChange}
              onAddToTransport={onAddToTransport}
            />
          ))}
        </>
      )}
      <Card>
        <CardBody className="p-2">
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    </>
  );
};

ProductTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onDownloadPrintLabel: PropTypes.func,
  onPrintPreview: PropTypes.func,
  onAddNote: PropTypes.func,
  onSendInvoice: PropTypes.func,
  onDeliveryStatusChange: PropTypes.func,
  onAddToTransport: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default ProductTable;
