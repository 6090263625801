import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addStockAdjustment } from "../../store/stock-adjustment/actions";
import { toastMessage } from "helpers/utils";
import StockAdjustmentForm from "../../containers/stock-adjustment/StockAdjustmentForm";

const AddNewStockAdjustment = props => {
  const { addStockAdjustment, match, history } = props;

  const [ activeTab, setActiveTab ] = useState("1");

  const { addNewProduct, clearProductNotify, stockAdjustment } = props;
  const { item, loading, message } = stockAdjustment;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductNotify();
    }
  }, [ message ]);

  const handleSubmit = data => {
    addStockAdjustment({
      data,
      history,
      queryParams: {}
    })
  };

  const handleOnCancel = () => {
    history.push("/stock-adjustment");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Stock Adjustment | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" backLink={ "/stock-adjustment" } breadcrumbItem={ `Add New Stock Adjustment` }/>

            <Row>

              <Col md={ 12 }>
                <StockAdjustmentForm
                    onSubmit={ handleSubmit }
                    onCancel={ handleOnCancel }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

AddNewStockAdjustment.propTypes = {
  stockOut: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addStockAdjustment: PropTypes.func,
};

const mapStateToProps = ({ stockAdjustment }) => {
  return {
    stockAdjustment,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      addStockAdjustment
    })(AddNewStockAdjustment)
);
