import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Button, Input, Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  // DeliveryOrderItem
  getDeliveryOrderItems,
  addNewDeliveryOrderItems,
  deleteDeliveryOrderItems,
  clearDeliveryOrderItemError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SelectionListModal from "containers/order/SelectionListModal";
import VerifyOrderTrackingModalForm from "containers/order/VerifyOrderTrackingModalForm";
import DeliveryOrderItemTable from "containers/delivery-order/DeliveryOrderItemTable";
import DeliveryOrderRowCard from "./DeliveryOrderRowCard";
import CustomPagination from "components/Common/CustomPagination";

import { debounce } from "lodash";

const DeliveryOrderItemTab = ({ deliveryOrderId, ...props }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [orders, setOrders] = useState([]);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalAddOrder, setModalAddOrder] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 1000),
    []
  );

  const {
    deliveryOrder,
    deliveryOrderItem,
    isReceiver,
    deliveryOrderStatus,
    deleteDeliveryOrderItems,
    getDeliveryOrderItems,
    addNewDeliveryOrderItems,
  } = props;
  const { items, itemCount, pageCount, loading } = deliveryOrderItem;

  useEffect(() => {
    getDeliveryOrderItems({ deliveryOrderId, page, pageSize, term });
  }, [deliveryOrderId, term, page, pageSize]);

  useEffect(() => {
    const { item } = deliveryOrder;
    if (item) {
      setIsDisable([1, 2, 3].some(e => e === item.status)); //1: Shipping To Cambodia, //2 In Cambodia Warehouse. 3 Completed)
    }
  }, [deliveryOrder.item]);

  useEffect(() => {
    setOrders(
      items.map(a => {
        return {
          ...a,
          isSelect: false,
        };
      })
    );
  }, [items]);

  const handleHeaderCheckChange = e => {
    const { checked } = e.target;
    setOrders(
      orders.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      })
    );
  };

  const handleRowCheck = (id, e) => {
    const { checked } = e.target;
    setOrders(
      orders.map(a => {
        return a.id === id ? { ...a, isSelect: checked } : a;
      })
    );
  };

  const handleOnRemove = () => {
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    let ids = orders
      .filter(e => e.isSelect)
      .map(a => {
        return a.id;
      });
      console.log(ids);
    deleteDeliveryOrderItems({ deliveryOrderId, orderIds: ids });
    setPage(1);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnSubmit = ids => {
    addNewDeliveryOrderItems({ deliveryOrderId, orderIds: ids });
    setModalAddOrder(false);
    setPage(1);
  };


  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="mb-1 rounded-3">
            <CardHeader className="bg-transparent border pb-0 pt-3">
              <Row className="g-1">
                <Col md={2}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => {
                          const { value } = e.target;
                          debouncedQuickSearch(value);
                        }}
                      ></Input>
                      <i className="fas fa-search search-icon" />
                    </div>
                  </div>
                </Col>
                <Col md={10}>
                  {deliveryOrderStatus === 0 && (
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="me-1"
                        onClick={() => setModalAddOrder(true)}
                      >
                        <i className="fas fa-plus" /> Add Order
                      </Button>
                      <Button
                        type="button"
                        outline
                        disabled={!orders.some(e => e.isSelect)}
                        color="danger"
                        className="me-1"
                        onClick={handleOnRemove}
                      >
                        <i className="fas fa-trash" /> Remove
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ padding: 10 }}>
                      <span>
                        <Input
                          type="checkbox"
                          onClick={e => handleHeaderCheckChange(e)}
                          readOnly
                        />
                        <span style={{ paddingLeft: 10 }}>Select All</span>
                      </span>
                    </div>
                    {orders.length > 0 &&
                      orders.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        const {
                          orderAttachments,
                        } = item;

                        let orderAttachment = null;

                        if (orderAttachments.length > 0) {
                          orderAttachment = orderAttachments[0];
                        }

                        return <DeliveryOrderRowCard
                          key={index}
                          onRowCheck={e => handleRowCheck(item.id, e)}
                          item={item}
                          num={num}

                        />
                      })}
                  </div>
                )}
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                onChangePage={i => setPage(i)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
        <SelectionListModal
          title={"Order Selection"}
          toggle={() => setModalAddOrder(!modalAddOrder)}
          isOpen={modalAddOrder}
          onSubmit={handleOnSubmit}
          orderParams={{ deliveryStatus: [3] }} // In Cambodia Warehouse
          enableUserRequestedFilter={true}
        />

      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </>
  );
};
DeliveryOrderItemTab.propTypes = {
  isReceiver: PropTypes.bool,
  deliveryOrder: PropTypes.object,
  deliveryOrderItem: PropTypes.object,
  deliveryOrderId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  addNewDeliveryOrderItems: PropTypes.func,
  getDeliveryOrderItems: PropTypes.func,
  deliveryOrderStatus: PropTypes.number,

  error: PropTypes.string,
  clearDeliveryOrderItemError: PropTypes.func,
  deleteDeliveryOrderItems: PropTypes.func,

  history: PropTypes.object,
};

const mapStateToProps = ({ deliveryOrder, deliveryOrderItem }) => {
  return {
    deliveryOrder,
    deliveryOrderItem,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryOrderItems,
    addNewDeliveryOrderItems,
    clearDeliveryOrderItemError,
    deleteDeliveryOrderItems,
  })(DeliveryOrderItemTab)
);
