import {
    GET_DELIVERY_ITEMS,
    GET_DELIVERY_ITEMS_SUCCESS,
    GET_DELIVERY_ITEMS_FAIL,
    GET_DELIVERY_ITEM_BY_ID,
    GET_DELIVERY_ITEM_BY_ID_SUCCESS,
    ADD_NEW_DELIVERY_ITEM,
    ADD_NEW_DELIVERY_ITEMS,
    UPDATE_DELIVERY_ITEM,
    DELETE_DELIVERY_ITEM,
    DELETE_DELIVERY_ITEMS,
    DELIVERY_ITEM_CLEAR_ERROR,
  } from "./actionTypes";
  
  // DeliveryItem
  export const getDeliveryItems = query => ({
    type: GET_DELIVERY_ITEMS,
    payload: query,
  });
  
  export const getDeliveryItemsSuccess = items => ({
    type: GET_DELIVERY_ITEMS_SUCCESS,
    payload: items,
  });
  
  export const getDeliveryItemsFail = error => ({
    type: GET_DELIVERY_ITEMS_FAIL,
    payload: error,
  });
  
  export const getDeliveryItemById = id => ({
    type: GET_DELIVERY_ITEM_BY_ID,
    payload: id,
  });
  
  export const getDeliveryItemByIdSuccess = item => ({
    type: GET_DELIVERY_ITEM_BY_ID_SUCCESS,
    payload: item,
  });
  
  export const getDeliveryItemByIdFail = error => ({
    type: GET_DELIVERY_ITEM_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewDeliveryItem = item => ({
    type: ADD_NEW_DELIVERY_ITEM,
    payload: item,
  });
  
  export const addNewDeliveryItems = payload => ({
    type: ADD_NEW_DELIVERY_ITEMS,
    payload,
  });
  
  export const updateDeliveryItem = item => ({
    type: UPDATE_DELIVERY_ITEM,
    payload: item,
  });
  
  export const deleteDeliveryItem = item => ({
    type: DELETE_DELIVERY_ITEM,
    payload: item,
  });
  
  export const deleteDeliveryItems = payload => ({
    type: DELETE_DELIVERY_ITEMS,
    payload,
  });
  
  export const clearDeliveryItemError = () => ({
    type: DELIVERY_ITEM_CLEAR_ERROR,
  });
  