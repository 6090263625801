import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { addProduct} from "../../store/product/actions";
import ProductForm from "containers/product/ProductForm";

import { toastMessage } from "helpers/utils";

const AddNewProduct = props => {
  const { addProduct, match, history } = props;

  const [activeTab, setActiveTab] = useState("1");

  const { addNewProduct, clearProductNotify, product } = props;
  const { item, loading, message } = product;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductNotify();
    }
  }, [message]);

  const handleSubmit = data => {
    addProduct({
      data,
      history,
      queryParams: {}
    })
  };

  const handleOnCancel = () => {
    history.push("/product");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Product | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" backLink={"/product"} breadcrumbItem={`Add New Product`} />

            <Row>
              
              <Col md={12}>
                <ProductForm
                    onSubmit={handleSubmit}
                    onCancel={handleOnCancel}
                />
                
                    
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

AddNewProduct.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addProduct: PropTypes.func,
};

const mapStateToProps = ({ product }) => {
  return {
    product,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      addProduct
    })(AddNewProduct)
);
