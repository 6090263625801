import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getBanners,
  addNewBanner,
  updateBanner,
  getBannerById,
  deleteBanner,
  clearBannerError,
} from "../../../store/banner/actions";
import BannerTable from "./BannerTable";
import ModalForm from "./ModalForm";
import { getCompanyName, getPermissions, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

class BannerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      filterByTitle: "Filter By All",
      type: null,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getBanners({ page: 1, pageSize: 10, term: "" });

    // toastr.options = {
    //   timeOut: 5000,
    //   closeButton: true,
    // };
    // toastr.error("Bad Request", "Error");
  }

  handleOnEdit = id => {
    this.props.getBannerById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.props.getBanners({ term: value ?? "" });
  };

  handleSubmit = item => {
    this.props.addNewBanner(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    this.props.updateBanner(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteBanner(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { error } = this.props;

    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");

      this.props.clearBannerError();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getBanners({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getBanners({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleClickFilterBy = type => {
    let typeLabel = "",
      selectedType = null;
    switch (type) {
      case 0:
        typeLabel = "Slider";
        selectedType = 0;
        break;
      case 1:
        typeLabel = "Landing";
        selectedType = 1;
        break;
      default:
        typeLabel = "All";
        break;
    }

    this.props.getBanners({
      page: 1,
      pageSize: 10,
      term: "",
      type: selectedType ?? null,
    });

    this.setState({
      filterByTitle: `Filter By ${typeLabel}`,
      type: selectedType,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { banner } = this.props;
    const { items, item, itemCount, pageCount, loading } = banner;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Banner | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Inventory" breadcrumbItem="Banner List" />
            <Row>
              <Col md={12}>
                <Card className="mb-2">
                  <CardBody className="pb-1">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                        <div className="mb-2">
                          <Dropdown
                            isOpen={this.state.singlebtn}
                            toggle={() =>
                              this.setState({
                                singlebtn: !this.state.singlebtn,
                              })
                            }
                          >
                            <DropdownToggle
                              color="primary"
                              className="btn btn-primary"
                              caret
                            >
                              {this.state.filterByTitle}{" "}
                              <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => this.handleClickFilterBy(-1)}
                              >
                                All
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => this.handleClickFilterBy(0)}
                              >
                                Slider
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => this.handleClickFilterBy(1)}
                              >
                                Landing
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>

                      {hasAccess(permission.banner.read) && (
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <BannerTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onSearch={this.handleOnSearch}
                    onAddNew={this.handleOnAddNew}
                    onEdit={this.handleOnEdit}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Add New Banner"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Banner"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

BannerList.propTypes = {
  banner: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getBanners: PropTypes.func,
  addNewBanner: PropTypes.func,
  updateBanner: PropTypes.func,
  getBannerById: PropTypes.func,
  deleteBanner: PropTypes.func,
  clearBannerError: PropTypes.func,
  permissions: PropTypes.array,
};

const mapStateToProps = ({ banner, Login }) => {
  const { permissions } = Login;
  return {
    banner,
    permissions,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getBanners,
    addNewBanner,
    updateBanner,
    getBannerById,
    deleteBanner,
    clearBannerError,
  })(BannerList)
);
