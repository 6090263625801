// SALE_ORDER
export const SALE_ORDER_ADD_NEW = 'SALE_ORDER_ADD_NEW';
export const SALE_ORDER_ADD_NEW_SUCCESS = 'SALE_ORDER_ADD_NEW_SUCCESS';
export const SALE_ORDER_ADD_NEW_ERROR = 'SALE_ORDER_ADD_NEW_ERROR';

export const SALE_ORDER_GET_PAGING = 'SALE_ORDER_GET_PAGING';
export const SALE_ORDER_GET_PAGING_SUCCESS = 'SALE_ORDER_GET_PAGING_SUCCESS';
export const SALE_ORDER_GET_PAGING_ERROR = 'SALE_ORDER_GET_PAGING_ERROR';

export const SALE_ORDER_GET_BY_ID = 'SALE_ORDER_GET_BY_ID';
export const SALE_ORDER_GET_BY_ID_SUCCESS = 'SALE_ORDER_GET_BY_ID_SUCCESS';
export const SALE_ORDER_GET_BY_ID_ERROR = 'SALE_ORDER_GET_BY_ID_ERROR';

export const SALE_ORDER_UPDATE = 'SALE_ORDER_UPDATE';
export const SALE_ORDER_UPDATE_SUCCESS = 'SALE_ORDER_UPDATE_SUCCESS';
export const SALE_ORDER_UPDATE_ERROR = 'SALE_ORDER_UPDATE_ERROR';

export const SALE_ORDER_DELETE = 'SALE_ORDER_DELETE';
export const SALE_ORDER_DELETE_SUCCESS = 'SALE_ORDER_DELETE_SUCCESS';
export const SALE_ORDER_DELETE_ERROR = 'SALE_ORDER_DELETE_ERROR';

export const SALE_ORDER_CHANGE_STATUS = 'SALE_ORDER_CHANGE_STATUS';
export const SALE_ORDER_CHANGE_STATUS_SUCCESS = 'SALE_ORDER_CHANGE_STATUS_SUCCESS';
export const SALE_ORDER_CHANGE_STATUS_ERROR = 'SALE_ORDER_CHANGE_STATUS_ERROR';

export const SALE_ORDER_GENERATE_STOCK_OUT = 'SALE_ORDER_GENERATE_STOCK_OUT';
export const SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS = 'SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS';
export const SALE_ORDER_GENERATE_STOCK_OUT_ERROR = 'SALE_ORDER_GENERATE_STOCK_OUT_ERROR';
