import {
  VEHICLE_ADD_NEW,
  VEHICLE_ADD_NEW_ERROR,
  VEHICLE_ADD_NEW_SUCCESS,
  VEHICLE_DELETE,
  VEHICLE_DELETE_ERROR,
  VEHICLE_DELETE_SUCCESS,
  VEHICLE_GET_BY_ID,
  VEHICLE_GET_BY_ID_ERROR,
  VEHICLE_GET_BY_ID_SUCCESS,
  VEHICLE_GET_PAGING,
  VEHICLE_GET_PAGING_ERROR,
  VEHICLE_GET_PAGING_SUCCESS,
  VEHICLE_UPDATE,
  VEHICLE_UPDATE_ERROR,
  VEHICLE_UPDATE_SUCCESS,
} from './actionType';

// Vehicle
export const getPagingVehicle = items => ({
  type: VEHICLE_GET_PAGING,
  payload: items,
});

export const getPagingVehicleSuccess = items => ({
  type: VEHICLE_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingVehicleError = error => ({
  type: VEHICLE_GET_PAGING_ERROR,
  payload: error,
});

export const addVehicle = items => ({
  type: VEHICLE_ADD_NEW,
  payload: items,
});

export const addVehicleSuccess = items => ({
  type: VEHICLE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addVehicleError = error => ({
  type: VEHICLE_ADD_NEW_ERROR,
  payload: error,
});

export const getVehicleById = items => ({
  type: VEHICLE_GET_BY_ID,
  payload: items,
});

export const getVehicleByIdSuccess = items => ({
  type: VEHICLE_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getVehicleByIdError = error => ({
  type: VEHICLE_GET_BY_ID_ERROR,
  payload: error,
});

export const updateVehicle = items => ({
  type: VEHICLE_UPDATE,
  payload: items,
});

export const updateVehicleSuccess = items => ({
  type: VEHICLE_UPDATE_SUCCESS,
  payload: items,
});

export const updateVehicleError = error => ({
  type: VEHICLE_UPDATE_ERROR,
  payload: error,
});

export const deleteVehicle = items => ({
  type: VEHICLE_DELETE,
  payload: items,
});

export const deleteVehicleSuccess = items => ({
  type: VEHICLE_DELETE_SUCCESS,
  payload: items,
});

export const deleteVehicleError = error => ({
  type: VEHICLE_DELETE_ERROR,
  payload: error,
});
