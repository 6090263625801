import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERY_ITEMS,
  ADD_NEW_DELIVERY_ITEM,
  ADD_NEW_DELIVERY_ITEMS,
  GET_DELIVERY_ITEM_BY_ID,
  UPDATE_DELIVERY_ITEM,
  DELETE_DELIVERY_ITEM,
  DELETE_DELIVERY_ITEMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveryItemsSuccess,
  getDeliveryItemByIdSuccess,
  getDeliveryItemsFail,
} from "./actions";
const getDeliveryItemsRequest = query =>
  get("/api/DeliveryItem/paging", { params: query });
const addNewDeliveryItemRequest = role =>
  post("/api/DeliveryItem", role);
const addNewDeliveryItemsRequest = ( itemIds) => {
  post('/api/DeliveryItem/bulkAddItem', itemIds);
};
const getDeliveryItemByIdRequest = id =>
  get(`/api/DeliveryItem/${id}`);
const updateDeliveryItemRequest = role =>
  update("/api/DeliveryItem", role);
const deleteDeliveryItemRequest = id =>
  del(`/api/DeliveryItem/${id}`);
const deleteDeliveryItemsRequest = (itemIds) =>
  post(`/api/DeliveryItem/bulkRemoveItem`, itemIds);

function* fetchDeliveryItems({ payload }) {
  try {
    const response = yield call(getDeliveryItemsRequest, payload);
    yield put(getDeliveryItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* addNewDeliveryItem({ payload }) {
  try {
    yield call(addNewDeliveryItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryItemsRequest, { orderId });
    yield put(getDeliveryItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* addNewDeliveryItems({ payload }) {
  try {
    const { deliveryId, type, itemIds } = payload;
    console.log("Payload:", { deliveryId, type, itemIds });

    yield call(addNewDeliveryItemsRequest, { deliveryId, type, itemIds });
    
    const queryParams = {
      deliveryId,
      page: 1, 
      pageSize: 10 
    };

    const response = yield call(getDeliveryItemsRequest, queryParams);
    
    yield put(getDeliveryItemsSuccess(response));
  } catch (err) {
    let error = err?.response?.data?.message ?? "Something has gone wrong.";
    if (error.includes("duplicate key")) {
      error = "Already exists in the list";
    }

    console.log(error);
    yield put(getDeliveryItemsFail(error));
  }
}


function* updateDeliveryItem({ payload }) {
  try {
    yield call(updateDeliveryItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryItemsRequest, { orderId });
    yield put(getDeliveryItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* getDeliveryItemById({ payload }) {
  try {
    var response = yield call(getDeliveryItemByIdRequest, payload);
    yield put(getDeliveryItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* deleteDeliveryItem({ payload }) {
  try {
    const { data, queryParams } = payload
    yield call(deleteDeliveryItemRequest, data);
    
    const response = yield call(getDeliveryItemsRequest, queryParams);
    yield put(getDeliveryItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* deleteDeliveryItems({ payload }) {
  try {
    const { data, queryParams } = payload
    let response = yield call(deleteDeliveryItemsRequest, data);

    response = yield call(getDeliveryItemsRequest, queryParams);
    yield put(getDeliveryItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryItemsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_DELIVERY_ITEMS, fetchDeliveryItems);
  yield takeEvery(ADD_NEW_DELIVERY_ITEM, addNewDeliveryItem);
  yield takeEvery(ADD_NEW_DELIVERY_ITEMS, addNewDeliveryItems);
  yield takeEvery(GET_DELIVERY_ITEM_BY_ID, getDeliveryItemById);
  yield takeEvery(UPDATE_DELIVERY_ITEM, updateDeliveryItem);
  yield takeEvery(DELETE_DELIVERY_ITEM, deleteDeliveryItem);
  yield takeEvery(DELETE_DELIVERY_ITEMS, deleteDeliveryItems);
}

export default roleSaga;
