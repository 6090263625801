import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Input } from "reactstrap";

const SaleOrderSelectionCard = props => {
    const { saleOrder, selected, onSelect, onRowCheck } = props;
    const { customerName, orderNo, productImages, paymentStatusName } = saleOrder;
    let productImage = productImages && productImages.length > 0 ? productImages[0] : null;
  
    const handleRowCheck = (e) => {
      const { checked } = e.target;
      onRowCheck(saleOrder.id, checked);
    };

    return (
      <Card className={"clickable mb-1 border " + (selected ? 'border-primary' : '')}
            onClick={() => onSelect(saleOrder)}>
        <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
          <div className={"d-flex justify-content-center align-items-center"} style={{ width: "40px" }}>
             <Input
                name={`check_${orderNo}`} 
                onClick={handleRowCheck}
                checked={selected} 
                type="checkbox"
                readOnly
              />
          </div>
          <div className={"d-flex justify-content-center"} style={{ width: "100px" }}>
            {productImage ? (
                <CardImg
                    style={{ width: "90px", height: "68px", objectFit: "contain" }}
                    className={"p-1"}
                    src={productImage.url}
                    alt={productImage.fileName}
                />
            ) : (
                <div
                    style={{
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                >
                  <div className="avatar-order">
                    <i className="fas fa-solid fa-cube text-primary"></i>
                  </div>
                </div>
            )}
          </div>
          <div className={"flex-grow-1"}>
            <CardBody className={"ps-0 py-2"}>
              <CardTitle>{customerName}</CardTitle> 
              <CardSubtitle>Order Id: {orderNo}</CardSubtitle>
            </CardBody>
          </div>
        </CardHeader>
      </Card>
    );
  };
  

  SaleOrderSelectionCard.propTypes = {
    saleOrder: PropTypes.shape({
      customerName: PropTypes.string,
      orderNo: PropTypes.string, // Ensure orderNo is listed here
      productImages: PropTypes.array,
      paymentStatusName: PropTypes.string,
    }).isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onRowCheck: PropTypes.func.isRequired,
  };
  

export default SaleOrderSelectionCard;