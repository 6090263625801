import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

const ModalForm = props => {
  const {
    isOpen,
    toggle,
    title,
  } = props;

  const onOpened = () => {

  }
  
  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <ModalHeader className={ "py-2" } toggle={ toggle }>
          <CardTitle>{ title }</CardTitle>
        </ModalHeader>
        <ModalBody className={ "pb-0" }>
<Row>
  <Col>
    <FormGroup className="mb-3">
      <Label htmlFor="">Name</Label>
      <Input
          type="text"
          name="name"
          placeholder="Name"
          // className={submitted && !item.name ? "is-invalid" : ""}
          // value={item.name}
          // onChange={handleChange}
      />
      {/*{submitted && !item.name && (*/}
      {/*    <div className="invalid-feedback-custom">Name is required.</div>*/}
      {/*)}*/}
    </FormGroup>
  </Col>
</Row>
        </ModalBody>
        <ModalFooter className={ "py-2" }>
          <Button color="primary" type="submit">
            Add Product
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
  );
};

ModalForm.propTypes = {
  
};

export default ModalForm;