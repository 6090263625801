
import {
  ORDER_DOCUMENT_ADD_NEW,
  ORDER_DOCUMENT_ADD_NEW_ERROR,
  ORDER_DOCUMENT_ADD_NEW_SUCCESS,
  ORDER_DOCUMENT_DELETE,
  ORDER_DOCUMENT_DELETE_ERROR,
  ORDER_DOCUMENT_DELETE_SUCCESS,
  ORDER_DOCUMENT_GET_BY_ID,
  ORDER_DOCUMENT_GET_BY_ID_ERROR,
  ORDER_DOCUMENT_GET_BY_ID_SUCCESS,
  ORDER_DOCUMENT_GET_PAGING,
  ORDER_DOCUMENT_GET_PAGING_ERROR,
  ORDER_DOCUMENT_GET_PAGING_SUCCESS,
  ORDER_DOCUMENT_UPDATE,
  ORDER_DOCUMENT_UPDATE_ERROR,
  ORDER_DOCUMENT_UPDATE_SUCCESS,
} from './actionTypes';

// OrderDocument
export const getPagingOrderDocument = items => ({
  type: ORDER_DOCUMENT_GET_PAGING,
  payload: items,
});

export const getPagingOrderDocumentSuccess = items => ({
  type: ORDER_DOCUMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingOrderDocumentError = error => ({
  type: ORDER_DOCUMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addOrderDocument = items => ({
  type: ORDER_DOCUMENT_ADD_NEW,
  payload: items,
});

export const addOrderDocumentSuccess = items => ({
  type: ORDER_DOCUMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addOrderDocumentError = error => ({
  type: ORDER_DOCUMENT_ADD_NEW_ERROR,
  payload: error,
});

export const getOrderDocumentById = items => ({
  type: ORDER_DOCUMENT_GET_BY_ID,
  payload: items,
});

export const getOrderDocumentByIdSuccess = items => ({
  type: ORDER_DOCUMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getOrderDocumentByIdError = error => ({
  type: ORDER_DOCUMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateOrderDocument = items => ({
  type: ORDER_DOCUMENT_UPDATE,
  payload: items,
});

export const updateOrderDocumentSuccess = items => ({
  type: ORDER_DOCUMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateOrderDocumentError = error => ({
  type: ORDER_DOCUMENT_UPDATE_ERROR,
  payload: error,
});

export const deleteOrderDocument = items => ({
  type: ORDER_DOCUMENT_DELETE,
  payload: items,
});

export const deleteOrderDocumentSuccess = items => ({
  type: ORDER_DOCUMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteOrderDocumentError = error => ({
  type: ORDER_DOCUMENT_DELETE_ERROR,
  payload: error,
});
