import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SALE_ORDER_COMMENT_ADD_NEW,
  SALE_ORDER_COMMENT_DELETE,
  SALE_ORDER_COMMENT_GET_BY_ID,
  SALE_ORDER_COMMENT_GET_PAGING,
  SALE_ORDER_COMMENT_UPDATE,
} from './actionType';

import {
  addSaleOrderCommentSuccess,
  addSaleOrderCommentError,
  getPagingSaleOrderCommentSuccess,
  getPagingSaleOrderCommentError,
  getSaleOrderCommentByIdSuccess,
  getSaleOrderCommentByIdError,
  updateSaleOrderCommentSuccess,
  updateSaleOrderCommentError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// SaleOrderComment
const getPagingSaleOrderCommentRequest = query => get("/api/SaleOrderComment/paging", { params: query });
const addNewSaleOrderCommentRequest = data => post("/api/SaleOrderComment", data);
const getSaleOrderCommentByIdRequest = id => get(`/api/SaleOrderComment/${id}`);
const updateSaleOrderCommentRequest = data => update("/api/SaleOrderComment", data);
const deleteSaleOrderCommentRequest = id => del(`/api/SaleOrderComment/${id}`);

function* addNewSaleOrderComment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewSaleOrderCommentRequest, data);
    yield put(addSaleOrderCommentSuccess(response));
    
    response = yield call(getPagingSaleOrderCommentRequest, queryParams);
    yield put(getPagingSaleOrderCommentSuccess(response));
  } catch (error) {
    yield put(addSaleOrderCommentError(error.response.statusText));
  }
}

function* updateSaleOrderComment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateSaleOrderCommentRequest, data);
    yield put(updateSaleOrderCommentSuccess(response));
    
    response = yield call(getPagingSaleOrderCommentRequest, queryParams);
    yield put(getPagingSaleOrderCommentSuccess(response));
  } catch (error) {
    yield put(updateSaleOrderCommentError(error.response.statusText));
  }
}

function* getPagingSaleOrderComment({ payload }) {
  try {
    let response = yield call(getPagingSaleOrderCommentRequest, payload);
    yield put(getPagingSaleOrderCommentSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderCommentError(error.response.statusText));
  }
}

function* getSaleOrderCommentById({ payload }) {
  try {
    let response = yield call(getSaleOrderCommentByIdRequest, payload);
    yield put(getSaleOrderCommentByIdSuccess(response));
  } catch (error) {
    yield put(getSaleOrderCommentByIdError(error.response.statusText));
  }
}

function* deleteSaleOrderComment({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteSaleOrderCommentRequest, data);

    response = yield call(getPagingSaleOrderCommentRequest, queryParams);
    yield put(getPagingSaleOrderCommentSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderCommentError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(SALE_ORDER_COMMENT_ADD_NEW, addNewSaleOrderComment);
  yield takeEvery(SALE_ORDER_COMMENT_UPDATE, updateSaleOrderComment);
  yield takeEvery(SALE_ORDER_COMMENT_GET_PAGING, getPagingSaleOrderComment);
  yield takeEvery(SALE_ORDER_COMMENT_GET_BY_ID, getSaleOrderCommentById);
  yield takeEvery(SALE_ORDER_COMMENT_DELETE, deleteSaleOrderComment);
}

export {
  getPagingSaleOrderCommentRequest
}

