// ORDER_DOCUMENT
export const ORDER_DOCUMENT_ADD_NEW = 'ORDER_DOCUMENT_ADD_NEW';
export const ORDER_DOCUMENT_ADD_NEW_SUCCESS = 'ORDER_DOCUMENT_ADD_NEW_SUCCESS';
export const ORDER_DOCUMENT_ADD_NEW_ERROR = 'ORDER_DOCUMENT_ADD_NEW_ERROR';

export const ORDER_DOCUMENT_GET_PAGING = 'ORDER_DOCUMENT_GET_PAGING';
export const ORDER_DOCUMENT_GET_PAGING_SUCCESS = 'ORDER_DOCUMENT_GET_PAGING_SUCCESS';
export const ORDER_DOCUMENT_GET_PAGING_ERROR = 'ORDER_DOCUMENT_GET_PAGING_ERROR';

export const ORDER_DOCUMENT_GET_BY_ID = 'ORDER_DOCUMENT_GET_BY_ID';
export const ORDER_DOCUMENT_GET_BY_ID_SUCCESS = 'ORDER_DOCUMENT_GET_BY_ID_SUCCESS';
export const ORDER_DOCUMENT_GET_BY_ID_ERROR = 'ORDER_DOCUMENT_GET_BY_ID_ERROR';

export const ORDER_DOCUMENT_UPDATE = 'ORDER_DOCUMENT_UPDATE';
export const ORDER_DOCUMENT_UPDATE_SUCCESS = 'ORDER_DOCUMENT_UPDATE_SUCCESS';
export const ORDER_DOCUMENT_UPDATE_ERROR = 'ORDER_DOCUMENT_UPDATE_ERROR';

export const ORDER_DOCUMENT_DELETE = 'ORDER_DOCUMENT_DELETE';
export const ORDER_DOCUMENT_DELETE_SUCCESS = 'ORDER_DOCUMENT_DELETE_SUCCESS';
export const ORDER_DOCUMENT_DELETE_ERROR = 'ORDER_DOCUMENT_DELETE_ERROR';