import { get, post, del, put as update } from "helpers/api_helper";

const getDiscountPromotionsRequest = async (query) => {
  return await get("/api/SaleOrder/discounts", { params: query });
};

const getDiscountPromotionPagingRequest = async query => {
  return await post("api/SaleOrder/paging", query);
};


export const discountPromotionService = {
   getDiscountPromotionsRequest,
   getDiscountPromotionPagingRequest
};