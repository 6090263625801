import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Modal } from "reactstrap";
import { NIL } from "uuid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ModalForm = props => {
  const { isOpen, toggle, title, productId, onSubmit } = props;

  const [ item, setItem ] = useState({
    id: NIL,
    productId,
    rate: 0,
    comment: '',
  });

  const [ isSubmitted, setIsSubmitted ] = useState(false);

  useEffect(() => {
    if(props.item){
      setItem({
        ...props.item,
      })
    }else{
      setItem({
        id: NIL,
        productId,
        rate: 0,
        comment: null,
      })
    }
  }, [props.item]);
  
  const onOpened = () => {
    setIsSubmitted(false);
    if(props.item){
     
    }else{
      setItem({
        id: NIL,
        productId,
        rate: 0,
        comment: '',
      })
    }
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    if (item.comment) {
      onSubmit(item);
    }
  }

  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup className="mb-3">
            <Label>Comment</Label>
            <CKEditor
                rows={ 7 }
                className={ (isSubmitted && !item.comment ? "is-invalid" : "") }
                editor={ ClassicEditor }
                // data={ product.fullDescription }
                onReady={ editor => {
                  editor.setData(item.comment ?? '')
                } }
                onChange={ (event, editor) => {
                  const data = editor.getData();
                  // console.log({ event, editor, data });
                  setItem({
                    ...item,
                    comment: data,
                  })
                } }
                onBlur={ (event, editor) => {
                  console.log('Blur.', editor);
                } }
                onFocus={ (event, editor) => {
                  console.log('Focus.', editor);
                } }
            />
            <div className={ (isSubmitted && !item.comment ? "is-invalid" : "") }></div>
            { isSubmitted && !item.comment && (
                <div className="invalid-feedback-custom">Comment is Required</div>
            ) }
          </FormGroup>
        </div>

        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  productId: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ModalForm;