// DELIVERY
export const GET_DELIVERIES = "GET_DELIVERIES";
export const GET_DELIVERIES_SUCCESS = "GET_DELIVERIES_SUCCESS";
export const GET_DELIVERIES_FAIL = "GET_DELIVERIES_FAIL";

export const GET_DELIVERY_BY_ID = "GET_DELIVERY_BY_ID";
export const GET_DELIVERY_BY_ID_SUCCESS =
  "GET_DELIVERY_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY = "ADD_NEW_DELIVERY";
export const ADD_NEW_DELIVERY_SUCCESS = "ADD_NEW_DELIVERY_SUCCESS";
export const ADD_NEW_DELIVERY_ERROR = "ADD_NEW_DELIVERY_ERROR";

export const UPDATE_DELIVERY = "UPDATE_DELIVERY";
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS";
export const UPDATE_DELIVERY_ERROR = "UPDATE_DELIVERY_ERROR";

export const DELETE_DELIVERY = "DELETE_DELIVERY";
export const DELETE_DELIVERY_SUCCESS = "DELETE_DELIVERY_SUCCESS";
export const DELETE_DELIVERY_ERROR = "DELETE_DELIVERY_ERROR";

export const DELIVERY_CLEAR_NOTIFY = "DELIVERY_CLEAR_NOTIFY";
