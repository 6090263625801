import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SALE_ORDER_INVOICE_ADD_NEW,
  SALE_ORDER_INVOICE_DELETE, SALE_ORDER_INVOICE_DETAIL_GET_BY_ID,
  SALE_ORDER_INVOICE_GET_BY_ID,
  SALE_ORDER_INVOICE_GET_PAGING,
  SALE_ORDER_INVOICE_UPDATE,
} from './actionType';

import {
  addSaleOrderInvoiceSuccess,
  addSaleOrderInvoiceError,
  getPagingSaleOrderInvoiceSuccess,
  getPagingSaleOrderInvoiceError,
  getSaleOrderInvoiceByIdSuccess,
  getSaleOrderInvoiceByIdError,
  updateSaleOrderInvoiceSuccess,
  updateSaleOrderInvoiceError, getSaleOrderInvoiceDetailByIdSuccess, getSaleOrderInvoiceDetailByIdError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// SaleOrderInvoice
const getPagingSaleOrderInvoiceRequest = query => post("/api/SaleOrderInvoice/paging", query);
const addNewSaleOrderInvoiceRequest = data => post("/api/SaleOrderInvoice", data);
const getSaleOrderInvoiceByIdRequest = id => get(`/api/SaleOrderInvoice/${id}`);
const getSaleOrderInvoiceDetailByIdRequest = id => get(`/api/SaleOrderInvoice/${id}/detail`);
const updateSaleOrderInvoiceRequest = data => update("/api/SaleOrderInvoice", data);
const deleteSaleOrderInvoiceRequest = id => del(`/api/SaleOrderInvoice/${id}`);

function* addNewSaleOrderInvoice({ payload }) {
  try {
    let response = yield call(addNewSaleOrderInvoiceRequest, payload);
    yield put(addSaleOrderInvoiceSuccess(response));
  } catch (error) {
    yield put(addSaleOrderInvoiceError(error.response.statusText));
  }
}

function* updateSaleOrderInvoice({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateSaleOrderInvoiceRequest, data);
    yield put(updateSaleOrderInvoiceSuccess(response));
    history.push('/sale-order')
    // response = yield call(getPagingSaleOrderInvoiceRequest, queryParams);
    // yield put(getPagingSaleOrderInvoiceSuccess(response));
  } catch (error) {
    yield put(updateSaleOrderInvoiceError(error.response.statusText));
  }
}

function* getPagingSaleOrderInvoice({ payload }) {
  try {
    let response = yield call(getPagingSaleOrderInvoiceRequest, payload);
    yield put(getPagingSaleOrderInvoiceSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderInvoiceError(error.response.statusText));
  }
}

function* getSaleOrderInvoiceById({ payload }) {
  try {
    let response = yield call(getSaleOrderInvoiceByIdRequest, payload);
    yield put(getSaleOrderInvoiceByIdSuccess(response));
  } catch (error) {
    yield put(getSaleOrderInvoiceByIdError(error.response.statusText));
  }
}

function* getSaleOrderInvoiceDetailById({ payload }) {
  try {
    let response = yield call(getSaleOrderInvoiceDetailByIdRequest, payload);
    yield put(getSaleOrderInvoiceDetailByIdSuccess(response));
  } catch (error) {
    yield put(getSaleOrderInvoiceDetailByIdError(error.response.statusText));
  }
}

function* deleteSaleOrderInvoice({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteSaleOrderInvoiceRequest, data);

    response = yield call(getPagingSaleOrderInvoiceRequest, queryParams);
    yield put(getPagingSaleOrderInvoiceSuccess(response));
  } catch (error) {
    yield put(getPagingSaleOrderInvoiceError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(SALE_ORDER_INVOICE_ADD_NEW, addNewSaleOrderInvoice);
  yield takeEvery(SALE_ORDER_INVOICE_UPDATE, updateSaleOrderInvoice);
  yield takeEvery(SALE_ORDER_INVOICE_GET_PAGING, getPagingSaleOrderInvoice);
  yield takeEvery(SALE_ORDER_INVOICE_GET_BY_ID, getSaleOrderInvoiceById);
  yield takeEvery(SALE_ORDER_INVOICE_DETAIL_GET_BY_ID, getSaleOrderInvoiceDetailById);
  yield takeEvery(SALE_ORDER_INVOICE_DELETE, deleteSaleOrderInvoice);
}

export {
  getPagingSaleOrderInvoiceRequest,
  addNewSaleOrderInvoiceRequest
}

