import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-flatpickr";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getOrders } from "store/actions";
import RequestedDeliveryTable from "./RequestedDeliveryTable";

const RequestedDeliveryList = props => {
  const { getOrders, order } = props;
  const { items, itemCount, pageCount, loading } = order;

  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");

  useEffect(() => {
    getOrders({ term, page, pageSize, isUserRequestedDelivery: true });
  }, [term, page, pageSize]);

  const handleOnPageChange = page => {
    setCurrentPage(page);
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPageSize(pageSize);
    setPage(1);
    setCurrentPage(1);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    setTerm(value);
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Requested Delivery List | JLS Express</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Requested Delivery List" />
          <Row>
            <Col lg={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <Button
                        type="button"
                        outline
                        color="primary"
                        // onClick={this.handleSearch}
                      >
                        <i className="fas fa-filter" /> Filter
                      </Button>
                    </Col>
                    <Col md={4}>
                      {/* <div className="text-sm-end me-3">
                        {hasAccess(permission.deliveryOrder.write) && (
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        )}
                        <ButtonDropdown
                          isOpen={moreActionIsOpen}
                          toggle={this.toggleMoreAction}
                        >
                          <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            {hasAccess(permission.deliveryOrder.export) && (
                              <DropdownItem
                                onClick={this.handleExportToExcel}
                                className="text-primary"
                              >
                                <i className="fas fa-file-excel me-1" /> Export
                                to Excel
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div> */}
                    </Col>
                  </Row>
                  {/* <div
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={this.toggleBody}
                  >
                    <span className="font-size-24 text-primary ps-2 pe-2">
                      <i
                        className={
                          this.state.isOpen
                            ? "fas fa-angle-up"
                            : "fas fa-angle-down"
                        }
                      ></i>
                    </span>
                  </div> */}
                </CardHeader>
                {/* <Collapse isOpen={this.state.isOpen}>
                  <CardBody className="pb-1">
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Select
                            name="statuses"
                            value={this.state.statuses}
                            onChange={this.handleSelectChange}
                            options={deliveryOrderStatusOptions}
                            classNamePrefix="select2-selection"
                            isMulti
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse> */}
              </Card>
            </Col>
            <Col lg="12">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <RequestedDeliveryTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onChangePage={handleOnPageChange}
                    onPageSizeChange={handleOnPageSizeChange}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
        <h5></h5>
      </div>
    </React.Fragment>
  );
};

RequestedDeliveryList.propTypes = {
  order: PropTypes.object,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrders: PropTypes.func,
  addNewOrder: PropTypes.func,
  updateOrder: PropTypes.func,
  getOrderById: PropTypes.func,
  deleteOrder: PropTypes.func,
  clearOrderError: PropTypes.func,
  history: PropTypes.object,
  submitOrderDeliveryStatus: PropTypes.func,
  addNewInvoice: PropTypes.func,
  clearInvoiceNotify: PropTypes.func,
  addNewOrderNote: PropTypes.func,
};

const mapStateToProps = ({ order, invoice }) => {
  return {
    order,
    invoice,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getOrders,
    // addNewOrder,
    // addNewOrderNote,
    // updateOrder,
    // getOrderById,
    // deleteOrder,
    // clearOrderError,
    // submitOrderDeliveryStatus,
    // addNewInvoice,
    // clearInvoiceNotify,
  })(RequestedDeliveryList)
);
