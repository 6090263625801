import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";

import {
  getTransportOrders,
  addNewTransportOrders,
  deleteTransportOrders,
  clearTransportOrderError,
} from "store/actions";
import CustomPagination from "components/Common/CustomPagination";
import NumberFormat from "react-number-format";
import moment from "moment";
import { debounce } from "lodash";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SelectionListModal from "containers/order/SelectionListModal";
import { exportService } from "services/export-service";
import { timestampFileName } from "helpers/utils";
import TransportOrderRowCard from "../../containers/transport/TransportOrderRowCard";
const TransportOrderTab = ({ transportId, ...props }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [orders, setOrders] = useState([]);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalAddOrder, setModalAddOrder] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false)

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 500),
    []
  );

  const {
    getTransportOrders,
    deleteTransportOrders,
    addNewTransportOrders,
    transport,
    transportOrder,
  } = props;
  const { items, itemCount, pageCount, loading } = transportOrder;

  useEffect(() => {
    getTransportOrders({ transportId, page, pageSize, term });
    setIsSelectedAll(false)
  }, [transportId, term, page, pageSize]);

  useEffect(() => {
    const { item } = transport;
    if (item) {
      setIsDisable([1, 2, 3].some(e => e === item.status)); //1: Shipping To Cambodia, //2 In Cambodia Warehouse. 3 Completed)
    }
  }, [transport.item]);

  useEffect(() => {
    setOrders(
      items.map(a => {
        return {
          ...a,
          isSelect: false,
        };
      })
    );
  }, [items]);

  const handleHeaderCheckChange = e => {
    const { checked } = e.target;
    setIsSelectedAll(checked)
    setOrders(
      orders.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      })
    );
  };

  const handleRowCheck = (id, e) => {
    const { checked } = e.target;
    setOrders(
      orders.map(a => {
        return a.id === id ? { ...a, isSelect: checked } : a;
      })
    );
  };

  const handleOnRemove = () => {
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    let ids = orders
      .filter(e => e.isSelect)
      .map(a => {
        return a.id;
      });

    deleteTransportOrders({ transportId, orderIds: ids });
    setPage(1);
    setIsSelectedAll(false);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnSubmit = ids => {
    addNewTransportOrders({ transportId, orderIds: ids });
    setModalAddOrder(false);
    setIsSelectedAll(false);
    setPage(1);
  };

  const handleExportToExcel = () => {
    const { item } = transport;
    exportService.exportTransportOrdersToExcel(
      { transportId },
      `${timestampFileName()}_${item.title}_Order_List.xlsx`
    );
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="mb-1 rounded-3">
            <CardHeader className="bg-transparent border pb-0 pt-3">
              <Row className="g-1">
                <Col md={2}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => {
                          const { value } = e.target;
                          debouncedQuickSearch(value);
                        }}
                      ></Input>
                      <i className="fas fa-search search-icon" />
                    </div>
                  </div>
                </Col>
                <Col md={10}>
                  <div className="text-sm-end">
                    {!isDisable ? (
                      <>
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={() => setModalAddOrder(true)}
                        >
                          <i className="fas fa-plus" /> Add Cargo
                        </Button>
                        <Button
                          type="button"
                          outline
                          disabled={!orders.some(e => e.isSelect)}
                          color="danger"
                          className="me-1"
                          onClick={handleOnRemove}
                        >
                          <i className="fas fa-trash" /> Remove
                        </Button>
                      </>
                    ) : null}
                    <Button
                      type="button"
                      color="primary"
                      outline
                      onClick={handleExportToExcel}
                    >
                      <i className="fas fa-file-excel me-1" /> Export to Excel
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{padding: 10}}>
                      <span>
                      <Input
                        type="checkbox"
                        checked={isSelectedAll}
                        onClick={e => handleHeaderCheckChange(e)}
                        readOnly
                      />
                      <span style={{paddingLeft: 10}}>Select All</span>
                      </span>
                    </div>
                    {orders.length > 0 &&
                      orders.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        const {
                          shippingContact,
                          orderAttachments,
                          lastNote,
                        } = item;
                        const { phones, address, contact } =
                          shippingContact;
                        let orderAttachment = null;

                        if (orderAttachments.length > 0) {
                          orderAttachment = orderAttachments[0];
                        }

                        return <TransportOrderRowCard
                          key={index}
                          onRowCheck={e => handleRowCheck(item.id, e)}
                          item={item}
                          num={num}

                        />
                      })}
                  </div>
                )}
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                onChangePage={i => setPage(i)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <SelectionListModal
        title={"Cargo Selection"}
        toggle={() => setModalAddOrder(!modalAddOrder)}
        isOpen={modalAddOrder}
        onSubmit={handleOnSubmit}
        orderParams={{ deliveryStatus: [1] }} // In Korea Warehouse
      />

      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </>
  );
};

TransportOrderTab.propTypes = {
  transport: PropTypes.object,
  transportId: PropTypes.string,
  transportOrder: PropTypes.object,

  getTransportOrders: PropTypes.func,
  deleteTransportOrders: PropTypes.func,
  addNewTransportOrders: PropTypes.func,
};

const mapStateToProps = ({ transport, transportOrder }) => {
  return {
    transport,
    transportOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTransportOrders,
    clearTransportOrderError,
    deleteTransportOrders,
    addNewTransportOrders,
  })(TransportOrderTab)
);
