// UOM
export const UOM_ADD_NEW = 'UOM_ADD_NEW';
export const UOM_ADD_NEW_SUCCESS = 'UOM_ADD_NEW_SUCCESS';
export const UOM_ADD_NEW_ERROR = 'UOM_ADD_NEW_ERROR';

export const UOM_GET_PAGING = 'UOM_GET_PAGING';
export const UOM_GET_PAGING_SUCCESS = 'UOM_GET_PAGING_SUCCESS';
export const UOM_GET_PAGING_ERROR = 'UOM_GET_PAGING_ERROR';

export const UOM_GET_BY_ID = 'UOM_GET_BY_ID';
export const UOM_GET_BY_ID_SUCCESS = 'UOM_GET_BY_ID_SUCCESS';
export const UOM_GET_BY_ID_ERROR = 'UOM_GET_BY_ID_ERROR';

export const UOM_UPDATE = 'UOM_UPDATE';
export const UOM_UPDATE_SUCCESS = 'UOM_UPDATE_SUCCESS';
export const UOM_UPDATE_ERROR = 'UOM_UPDATE_ERROR';

export const UOM_DELETE = 'UOM_DELETE';
export const UOM_DELETE_SUCCESS = 'UOM_DELETE_SUCCESS';
export const UOM_DELETE_ERROR = 'UOM_DELETE_ERROR';
