import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export class OrderSummaryChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      values: [],
    };
  }

  componentDidMount = () => {
    const { item } = this.props;
    const { labels, values } = item;

    this.setState({
      labels,
      values,
    });
  };

  render() {
    return (
      <Card className="mt-2">
        <CardHeader className="bg-transparent border">
          <CardTitle>Monthly Order Summary</CardTitle>
        </CardHeader>
        <CardBody>
          <ReactApexChart
            options={{
              chart: {
                type: "bar",
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: this.state.labels,
              },
              yaxis: {
                title: {
                  text: "Total Order",
                },
              },
              fill: {
                opacity: 1,
              },
              // tooltip: {
              //   y: {
              //     formatter: function (val) {
              //       return "$ " + val + " thousands";
              //     },
              //   },
              // },
            }}
            series={[
              {
                name: "Order",
                y: 0,
                data: this.state.values,
              },
            ]}
            type="bar"
            height={250}
          />
        </CardBody>
      </Card>
    );
  }
}

OrderSummaryChart.propTypes = {
  item: PropTypes.object,
};

export default OrderSummaryChart;
