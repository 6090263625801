import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import {
  displayDeliveryPaymentStatus,
  displayDeliveryStatus,
  displayPaymentStatus,
  displayPickupPaymentStatus,
  hasAccess,
} from "helpers/utils";
import { permission } from "constants/permission";
import NumberFormat from "react-number-format";

export class OrderReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tttop: false,
    };
  }

  handlePrintLabel = item => {
    onViewPrint(item);
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onChangePage,
      onPageSizeChange,
      onPrintPreview,
    } = this.props;

    return (
      <>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table className="project-list-table table table-nowrap align-top table-borderless mb-1">
              <thead>
                <tr>
                  <th>#</th>

                  <th>Sender</th>
                  <th>Receiver and Shipping</th>
                  <th>Quantity</th>
                  <th>Payment Status</th>
                  <th>Delivery Status</th>
                  <th style={{ width: "200px" }}>Last Note</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => {
                  let num = (currentPage - 1) * defaultPageSize + (index + 1);
                  const { lastNote } = item;
                  const { shippingContact } = item;
                  const { phones, address, contact } = shippingContact;

                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td className="align-middle">{num}</td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <h5 className="font-size-14 mb-1">{item.sender}</h5>
                          <div className="d-flex">
                            <div className="right d-flex flex-column">
                              <p className="text-muted mb-0">ID:</p>
                              <p className="text-muted mb-0">Tracking No:</p>
                              <p className="text-muted mb-0">Whs In Date:</p>
                              <p className="text-muted mb-0">ETD:</p>
                              <p
                                className={
                                  "mb-0 " +
                                  [0, 1, 2, 8].includes(
                                    //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                    item.deliveryStatus
                                      ? "text-danger"
                                      : "text-muted"
                                  )
                                }
                              >
                                ETA:
                              </p>
                              {item.originalReference && (
                                <p className="text-muted mb-0">Orig Ref:</p>
                              )}
                            </div>
                            <div
                              className="d-flex flex-column"
                              style={{ marginLeft: "1rem" }}
                            >
                              <p className="text-muted mb-0">
                                {item.senderUniqueId}
                              </p>
                              <p className="text-muted mb-0">
                                {item.trackingNumber}
                              </p>
                              <p className="text-muted mb-0">
                                {moment
                                  .utc(item.createdDate)
                                  .local()
                                  .format("DD-MMM-yyyy h:mm:ss a")}
                              </p>
                              <p className="text-muted mb-0">
                                {item.etd ? (
                                  <>{moment(item.etd).format("DD-MMM-yyyy")}</>
                                ) : (
                                  <>N/A</>
                                )}
                              </p>
                              <p
                                className={
                                  "mb-0 " +
                                  [0, 1, 2, 8].includes(
                                    //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                    item.deliveryStatus
                                      ? "text-danger"
                                      : "text-muted"
                                  )
                                }
                              >
                                {item.eta ? (
                                  <>{moment(item.eta).format("DD-MMM-yyyy")}</>
                                ) : (
                                  <>N/A</>
                                )}
                              </p>
                              {item.originalReference && (
                                <p className="text-muted mb-0">
                                  {item.originalReference}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {item.senderUniqueId !== item.receiverUniqueId ? (
                            <h5 className="font-size-14 mb-1">
                              {item.receiver}
                            </h5>
                          ) : (
                            <h5 className="font-size-14 mb-1">{contact}</h5>
                          )}
                          <div className="d-flex">
                            <div className="right d-flex flex-column">
                              {item.senderUniqueId !==
                                item.receiverUniqueId && (
                                <>
                                  <p className="text-muted mb-0">ID:</p>
                                  <p className="text-muted mb-0">Contact:</p>
                                </>
                              )}
                              <p className="text-muted mb-0">Phone:</p>
                              <p className="text-muted mb-0">Address:</p>
                            </div>
                            <div
                              className="d-flex flex-column"
                              style={{ marginLeft: "1rem" }}
                            >
                              {item.senderUniqueId !==
                                item.receiverUniqueId && (
                                <>
                                  <p className="text-muted mb-0">
                                    {item.receiverUniqueId}
                                  </p>
                                  <p className="text-muted mb-0">{contact}</p>
                                </>
                              )}
                              <p className="text-muted mb-0">
                                {phones && phones.length > 0 ? (
                                  phones.map((phone, index) => {
                                    return (
                                      <Badge
                                        color="info"
                                        className="p-1 me-1"
                                        key={index}
                                      >
                                        <NumberFormat
                                          value={phone.phoneNumber}
                                          displayType="text"
                                          format={"### ### ####"}
                                        />
                                      </Badge>
                                    );
                                  })
                                ) : (
                                  <Badge color="info" className="p-1 me-1">
                                    N/A
                                  </Badge>
                                )}
                              </p>
                              <p className="text-muted mb-0">{address}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {item.quantity} {item.unit}
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="right d-flex flex-column">
                            <p className="text-muted mb-0">Shipping :</p>
                            {item.isPickup && (
                              <p className="text-muted mb-0">Pickup :</p>
                            )}
                            {item.isDelivery && (
                              <p className="text-muted mb-0">Delvery :</p>
                            )}
                          </div>
                          <div
                            className="d-flex flex-column"
                            style={{ marginLeft: "1rem" }}
                          >
                            <p className="text-muted mb-0">
                              {displayPaymentStatus(item)}
                            </p>
                            {item.isPickup && (
                              <p className="text-muted mb-0">
                                {displayPickupPaymentStatus(item)}
                              </p>
                            )}
                            {item.isDelivery && (
                              <p className="text-muted mb-0">
                                {displayDeliveryPaymentStatus(item)}
                              </p>
                            )}
                          </div>
                        </div>
                      </td>

                      <td>{displayDeliveryStatus(item)}</td>
                      <td>
                        {lastNote && (
                          <div className="d-flex flex-column">
                            <p className="text-muted mb-0">
                              {moment
                                .utc(lastNote.createdDate)
                                .local()
                                .format("DD-MMM-yyyy h:mm:ss a")}
                            </p>
                            <p
                              style={{ width: "140px" }}
                              className="text-truncate text-muted mb-0"
                            >
                              {lastNote.note}
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <Card>
          <CardBody className="p-2">
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

OrderReportTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onPrintPreview: PropTypes.func,
  onSubmitToWarehouse: PropTypes.func,
  onSendInvoice: PropTypes.func,
  onAddNote: PropTypes.func,
};

export default OrderReportTable;
