import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALERT_NOTIFICATION_HISTORY,
  ADD_NEW_ALERT_NOTIFICATION_HISTORY,
  GET_ALERT_NOTIFICATION_HISTORY_BY_ID,
  UPDATE_ALERT_NOTIFICATION_HISTORY,
  DELETE_ALERT_NOTIFICATION_HISTORY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getAlertNotificationHistorySuccess,
  getAlertNotificationHistoryByIdSuccess,
  getAlertNotificationHistoryFail,
  addNewAlertNotificationHistorySuccess,
  updateAlertNotificationHistorySuccess,
  deleteAlertNotificationHistorySuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/AlertNotificationHistory`);
const getAlertNotificationHistoryRequest = query =>
  get(`/api/AlertNotificationHistory/paging`, { params: query });

const addNewAlertNotificationHistoryRequest = item =>
  post(`/api/AlertNotificationHistory`, item);
const getAlertNotificationHistoryByIdRequest = id =>
  get(`/api/AlertNotificationHistory/${id}`);
const updateAlertNotificationHistoryRequest = item =>
  update(`/api/AlertNotificationHistory`, item);
const deleteAlertNotificationHistoryRequest = id =>
  del(`/api/AlertNotificationHistory/${id}`);

function* fetchAlertNotificationHistorys({ payload }) {
  try {
    const response = yield call(getAlertNotificationHistoryRequest, payload);

    yield put(getAlertNotificationHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationHistoryFail(error));
  }
}

function* addNewAlertNotificationHistory({ payload }) {
  try {
    yield call(addNewAlertNotificationHistoryRequest, payload);
    const response = yield call(getAlertNotificationHistoryRequest);
    yield put(getAlertNotificationHistorySuccess(response));
    yield put(addNewAlertNotificationHistorySuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationHistoryFail(error));
  }
}

function* updateAlertNotificationHistory({ payload }) {
  try {
    yield call(updateAlertNotificationHistoryRequest, payload);
    const response = yield call(getAlertNotificationHistoryRequest);
    yield put(getAlertNotificationHistorySuccess(response));
    yield put(updateAlertNotificationHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationHistoryFail(error));
  }
}

function* getAlertNotificationHistoryById({ payload }) {
  try {
    var response = yield call(getAlertNotificationHistoryByIdRequest, payload);
    yield put(getAlertNotificationHistoryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationHistoryFail(error));
  }
}

function* deleteAlertNotificationHistory({ payload }) {
  try {
    yield call(deleteAlertNotificationHistoryRequest, payload);
    const response = yield call(getAlertNotificationHistoryRequest);
    yield put(getAlertNotificationHistorySuccess(response));
    yield put(deleteAlertNotificationHistorySuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationHistoryFail(error));
  }
}

function* AlertNotificationHistorySaga() {
  yield takeEvery(
    GET_ALERT_NOTIFICATION_HISTORY,
    fetchAlertNotificationHistorys
  );
  yield takeEvery(
    ADD_NEW_ALERT_NOTIFICATION_HISTORY,
    addNewAlertNotificationHistory
  );
  yield takeEvery(
    GET_ALERT_NOTIFICATION_HISTORY_BY_ID,
    getAlertNotificationHistoryById
  );
  yield takeEvery(
    UPDATE_ALERT_NOTIFICATION_HISTORY,
    updateAlertNotificationHistory
  );
  yield takeEvery(
    DELETE_ALERT_NOTIFICATION_HISTORY,
    deleteAlertNotificationHistory
  );
}

export default AlertNotificationHistorySaga;

export { getDeliveryAgenciesRequest };
