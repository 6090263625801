import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button, CardImg } from "reactstrap";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class ProductImageTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isGallery: false,
    };
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    const { photoIndex } = this.state;
    return (
        <>
          <div className="table-rep-plugin">
            {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <Row>
                  {items && items.length > 0 && (
                      <Col md={12}>
                        {this.state.isGallery ? (
                            <Lightbox
                                mainSrc={items[photoIndex].url}
                                nextSrc={items[(photoIndex + 1) % items.length].url}
                                prevSrc={
                                  items[(photoIndex + items.length - 1) % items.length]
                                      .url
                                }
                                enableZoom={true}
                                onCloseRequest={() => this.setState({ isGallery: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex:
                                          (photoIndex + items.length - 1) % items.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + 1) % items.length,
                                    })
                                }
                                imageCaption={`${items[photoIndex].fileName}`}
                                imageTitle={`${photoIndex + 1} / ${items.length}`}
                            />
                        ) : null}
                      </Col>
                  )}
                  {items &&
                      items.length > 0 &&
                      items.map((attachment, index) => {
                        const { url, id } = attachment;
                        let isVideo = url.includes(".mp4");
                        return (
                            <>
                              <Col key={index} md={isVideo ? 6 : 3}>
                                {isVideo ? (
                                    <div className="ratio ratio-21x9">
                                      <iframe
                                          title="test1"
                                          allowFullScreen
                                          src={`${url}`}
                                      ></iframe>
                                    </div>
                                ) : (
                                    <Card className="mb-3">
                                      <CardImg
                                          top
                                          style={{
                                            height: "250px",
                                            objectFit: "cover",
                                          }}
                                          className="img-fluid img-rounded"
                                          src={`${attachment.url}`}
                                          alt="Skote"
                                          onClick={() =>
                                              this.setState({
                                                isGallery: true,
                                                photoIndex: index,
                                              })
                                          }
                                      />
                                      <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                      >
                                        <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => onConfirmDelete(id)}
                                        >
                                          <i className="fas fa-trash font-size-16"></i>{" "}
                                        </Button>
                                      </div>
                                    </Card>
                                )}
                              </Col>
                            </>
                        );
                      })}
                </Row>
            )}
          </div>
          <Card>
            <CardBody>
              <CustomPagination
                  itemCount={itemCount}
                  currentPage={currentPage}
                  totalPage={totalPage}
                  defaultPageSize={defaultPageSize}
                  pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                  onChangePage={i => onChangePage(i)}
                  onPageSizeChange={size => onPageSizeChange(size)}
              />
            </CardBody>
          </Card>
        </>
    );
  }
}

ProductImageTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ProductImageTable;
