import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import MapViewCard from "./MapViewCard";
import moment from "moment";
import NumberFormat from "react-number-format";
import DeliveryToDoorConfirmModalForm from "./DeliveryToDoorConfirmModalForm";

const DeliveryToDoorViewModal = ({ isOpen, toggle, title, item, onReject, onApprove }) => {
  const [ contactName, setContactName ] = useState('');
  const [ phone, setPhone ] = useState('')
  const [ address, setAddress ] = useState('')
  const [ note, setNote] = useState('')
  
  const [ modalConfirmIsOpen, setModalConfirmIsOpen ] = useState(false);
  const [ confirmType, setConfirmType ] = useState('')

  const {
    latitude,
    longitude,
    // shippingContact,
    orderAttachment,
    senderUniqueId,
    trackingNumber,
    orderCreatedDate,
  } = item;

  // const { contact, phones } = shippingContact;

  const onOpened = () => {
    if (item) {
      setContactName(item.contactName || '');
      setPhone(item.phone || '');
      setAddress(item.address || '')
    }
  }

  const handleOnSubmit = data => {
    const { confirmType, id, comment } = data;
    switch (confirmType) {
      case "Approve":
        onApprove({
          id: id,
          contactName,
          phone,
          address,
          note,
          comment,
        });
        break;
      case "Reject":
        onReject({
          id: id,
          contactName,
          phone,
          address,
          note,
          comment,
        });
        break;
      default:
        break;
    }
    setModalConfirmIsOpen(false);
  };

  return (
      <>
        <Modal size="xl" onOpened={ onOpened } isOpen={ isOpen } toggle={ toggle } backdrop="static">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { title }
            </h5>

            <button
                type="button"
                onClick={ toggle }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <Row>
              <Col md={ 4 }>
                <Card color="border border-1 border-primary">
                  <CardHeader className="bg-primary text-white">
                    <CardTitle>Requested Delivery Contact</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="mb-3">
                      <Label htmlFor="contactName">Name</Label>
                      <Input
                          type="text"
                          name="contactName"
                          placeholder="Contact Name"
                          value={ contactName }
                          onChange={ e => {
                            setContactName(e.target.value);
                          } }
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="phone">Phone</Label>
                      <Input
                          type="text"
                          name="shippingContactPhone"
                          placeholder="Phone"
                          value={ phone }
                          onChange={ e => {
                            setPhone(e.target.value);
                          } }
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="contactName">Address</Label>
                      <Input
                          type="textarea"
                          name="address"
                          rows={ "3" }
                          placeholder="Address"
                          value={ address }
                          onChange={ e => {
                            setAddress(e.target.value);
                          } }
                      />
                    </FormGroup>

                  </CardBody>
                </Card>
              </Col>

              <Col md={ 8 }>
                <MapViewCard latitude={ latitude } longitude={ longitude }/>
                <FormGroup className="mb-3">
                  <Label htmlFor="contactName">Note</Label>
                  <Input
                      type="textarea"
                      name="note"
                      rows={ "3" }
                      placeholder="Note"
                      value={note}
                      onChange={e => {
                          setNote(e.target.value);
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <div className="modal-footer">
            {
              // 0 = Pending
                [0].includes(item.status) && 
              <Button
                  color="success"
                  onClick={ () => {
                    setConfirmType("Approve");
                    setModalConfirmIsOpen(true);
                  } }
                  type="submit"
              >
                Approve
              </Button>
            }
            {
              // 0 = Pending
                [0].includes(item.status) && 
                <Button
                    color="danger"
                    onClick={ () => {
                      setConfirmType("Reject");
                      setModalConfirmIsOpen(true);
                    } }
                    type="submit"
                >
                  Reject
                </Button>
            }
            <button
                type="button"
                onClick={ toggle }
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        <DeliveryToDoorConfirmModalForm
            id={ item.id }
            isOpen={ modalConfirmIsOpen }
            confirmType={ confirmType }
            toggle={ () => setModalConfirmIsOpen(!modalConfirmIsOpen) }
            onSubmit={ handleOnSubmit }
        />
      </>
  );
};

DeliveryToDoorViewModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  item: PropTypes.object,
  onReject: PropTypes.func,
  onApprove: PropTypes.func,
};

export default DeliveryToDoorViewModal;
