import {
  GET_USER_ITEM_SHIPPING_FEES,
  GET_USER_ITEM_SHIPPING_FEES_SUCCESS,
  GET_USER_ITEM_SHIPPING_FEES_FAIL,
  GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS,
  UPDATE_USER_ITEM_SHIPPING_FEE,
  USER_ITEM_SHIPPING_FEE_CLEAR_ERROR, DELETE_USER_ITEM_SHIPPING_FEE_SUCCESS, ADD_NEW_USER_ITEM_SHIPPING_FEE_SUCCESS,
} from "./actionTypes.js";
import { SEVERITY, toastMessage } from "../../helpers/utils";
const initState = {
  items: [],
  item: null,
  error: null,
  userIds: [],
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const itemShippingFee = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_ITEM_SHIPPING_FEE:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_ITEM_SHIPPING_FEES:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_ITEM_SHIPPING_FEES_SUCCESS:
      const { data, userIds} = payload;
      const { itemCount, pageCount } = data;
      return {
        ...state,
        itemCount,
        pageCount,
        userIds,
        items: data.data,
        loading: false,
      };
    case GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case DELETE_USER_ITEM_SHIPPING_FEE_SUCCESS:
      toastMessage({
        title: 'Customer',
        text: 'The Customer has been successfully removed.',
        severity: SEVERITY.INFO
      })
      return {
        ...state,
        loading: false,
      }
      
    case ADD_NEW_USER_ITEM_SHIPPING_FEE_SUCCESS:
      toastMessage({
        title: 'Customer',
        text: 'The Customers have been successfully added.',
        severity: SEVERITY.INFO
      })
      return {
        ...state,
        loading: false
      }
    case GET_USER_ITEM_SHIPPING_FEES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case USER_ITEM_SHIPPING_FEE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default itemShippingFee;
