// ALERT_NOTIFICATION_HISTORY_HISTORY
export const GET_ALERT_NOTIFICATION_HISTORY = "GET_ALERT_NOTIFICATION_HISTORY";
export const GET_ALERT_NOTIFICATION_HISTORY_SUCCESS =
  "GET_ALERT_NOTIFICATION_HISTORY_SUCCESS";
export const GET_ALERT_NOTIFICATION_HISTORY_FAIL =
  "GET_ALERT_NOTIFICATION_HISTORY_FAIL";

export const GET_ALERT_NOTIFICATION_HISTORY_BY_ID =
  "GET_ALERT_NOTIFICATION_HISTORY_BY_ID";
export const GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS =
  "GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS";

export const ADD_NEW_ALERT_NOTIFICATION_HISTORY =
  "ADD_NEW_ALERT_NOTIFICATION_HISTORY";
export const ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS =
  "ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS";

export const UPDATE_ALERT_NOTIFICATION_HISTORY =
  "UPDATE_ALERT_NOTIFICATION_HISTORY";
export const UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS =
  "UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS";

export const DELETE_ALERT_NOTIFICATION_HISTORY =
  "DELETE_ALERT_NOTIFICATION_HISTORY";
export const DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS =
  "DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS";

export const ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY =
  "ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY";
