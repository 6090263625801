import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from "reactstrap";

const ModalConfirmation = props => {
  const {isOpen, toggle, title, onSubmit, children} = props
  
  return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <button
              type="button"
              onClick={toggle}
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>

          <Button color="primary" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
  );
};

ModalConfirmation.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ModalConfirmation;