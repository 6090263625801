// TRANSPORT
export const GET_TRANSPORTS = "GET_TRANSPORTS";
export const GET_TRANSPORTS_SUCCESS = "GET_TRANSPORTS_SUCCESS";
export const GET_TRANSPORTS_FAIL = "GET_TRANSPORTS_FAIL";

export const GET_TRANSPORT_BY_ID = "GET_TRANSPORT_BY_ID";
export const GET_TRANSPORT_BY_ID_SUCCESS = "GET_TRANSPORT_BY_ID_SUCCESS";

export const ADD_NEW_TRANSPORT = "ADD_NEW_TRANSPORT";
export const ADD_NEW_TRANSPORT_SUCCESS = "ADD_NEW_TRANSPORT_SUCCESS";
export const ADD_NEW_TRANSPORT_ERROR = "ADD_NEW_TRANSPORT_ERROR";

export const UPDATE_TRANSPORT = "UPDATE_TRANSPORT";
export const UPDATE_TRANSPORT_SUCCESS = "UPDATE_TRANSPORT_SUCCESS";
export const UPDATE_TRANSPORT_ERROR = "UPDATE_TRANSPORT_ERROR";

export const DELETE_TRANSPORT = "DELETE_TRANSPORT";
export const DELETE_TRANSPORT_SUCCESS = "DELETE_TRANSPORT_SUCCESS";
export const DELETE_TRANSPORT_ERROR = "DELETE_TRANSPORT_ERROR";

export const TRANSPORT_CLEAR_NOTIFY = "INVOICE_PAYMENT_CLEAR_NOTIFY";

export const ADD_ORDER_TO_TRANSPORT = "ADD_ORDER_TO_TRANSPORT";
export const ADD_ORDER_TO_TRANSPORT_SUCCESS = "ADD_ORDER_TO_TRANSPORT_SUCCESS";
export const ADD_ORDER_TO_TRANSPORT_ERROR = "ADD_ORDER_TO_TRANSPORT_ERROR";
