import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { NIL } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDeliveryAgenciesRequest } from "store/delivery-agency/saga";
import { userService } from "services/user-service";
import moment from "moment";

const statusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In-Progress" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Rejected" },
  { key: 4, value: 4, label: "Closed" },
];

const Form = ({ item, onSubmit, senderTerm }) => {
  const [formState, setFormState] = useState({
    id: NIL,
    title: "",
    deliveryAgencyId: null,
    userId: null,
    note: "",
    date: null,
    status: statusOptions[0],
    submitted: false,
    deliveryAgencyOptions: [],
    customerOptions: [],
    loadingSender: false,
  });

  useEffect(() => {
    fetchDeliveryAgencies();
    fetchCustomerOptions();
  }, []);

  useEffect(() => {
    if (item) {
      setFormState((prevState) => ({
        ...prevState,
        id: item.id || NIL,
        title: item.title || "",
        deliveryAgencyId: item.deliveryAgencyId
          ? {
              key: item.deliveryAgencyId,
              value: item.deliveryAgencyId,
              label: item.deliveryAgency,
            }
          : null,
        userId: item.userId
          ? {
              key: item.userId,
              value: item.userId,
              label: item.user?.name ?? "Unknown",
            }
          : null,
        date: item.date ? new Date(item.date) : null,
        status: {
          key: item.status,
          value: item.status,
          label: item.statusName,
        },
        note: item.note || "",
      }));
    }
  }, [item]);

  useEffect(() => {
    fetchCustomerOptions();
  }, [senderTerm]);

  const fetchCustomerOptions = () => {
    setFormState((prevState) => ({ ...prevState, loadingSender: true }));
    userService.getUsersRequest({ term: senderTerm }).then((records) => {
      if (records) {
        setFormState((prevState) => ({
          ...prevState,
          customerOptions: records.map((user) => ({
            key: user.id,
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          })),
          loadingSender: false,
        }));
      }
    });
  };

  const fetchDeliveryAgencies = () => {
    getDeliveryAgenciesRequest().then((res) => {
      setFormState((prevState) => ({
        ...prevState,
        deliveryAgencyOptions: res.map((a) => ({
          key: a.index,
          label: a.name,
          value: a.id,
        })),
      }));
    });
  };

  const handleDateChange = (date) => {
    setFormState((prevState) => ({ ...prevState, date }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormState((prevState) => ({ ...prevState, [name]: selectedOption }));
  };

  const handleSubmit = () => {
    const { id, title, date, userId, deliveryAgencyId, status, note } = formState;

    setFormState((prevState) => ({ ...prevState, submitted: true }));

    const isValid = title && deliveryAgencyId && userId;

    if (isValid) {
      const data = {
        id,
        title,
        date: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        deliveryAgencyId: deliveryAgencyId?.value ?? null,
        userId: userId?.value ?? null,
        status: status.value,
        note,
      };
      onSubmit(data);
    }
  };

  const {
    submitted,
    date,
    loadingSender,
    note,
    title,
    userId,
    deliveryAgencyId,
    deliveryAgencyOptions,
    status,
    customerOptions,
  } = formState;

  return (
    <Row>
      <Col md={12}>
        <Card className="mb-3">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup className={submitted && !title ? "is-invalid" : ""}>
                  <Label htmlFor="title">Title</Label>
                  <Input
                    type="text"
                    name="title"
                    className={submitted && !title ? "is-invalid" : ""}
                    placeholder="Title"
                    value={title}
                    onChange={handleChange}
                  />
                  {submitted && !title && (
                    <div className="invalid-feedback-custom">
                      Title is required.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className={submitted && !date ? "is-invalid" : ""}>
                  <Label>Date</Label>
                  <DatePicker
                    id="date"
                    name="date"
                    className={
                      "form-control" + (submitted && !date ? " is-invalid" : "")
                    }
                    selected={date}
                    onChange={handleDateChange}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                  />
                </FormGroup>
                {submitted && !date && (
                  <div className="invalid-feedback-custom">Date is required.</div>
                )}
              </Col>
              <Col md={4}>
                <FormGroup
                  className={submitted && !deliveryAgencyId ? "is-invalid" : ""}
                >
                  <Label>Delivery Agency</Label>
                  <Select
                    name="deliveryAgencyId"
                    value={deliveryAgencyId}
                    onChange={handleSelectChange}
                    options={deliveryAgencyOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                  {submitted && !deliveryAgencyId && (
                    <div className="invalid-feedback-custom">
                      Delivery Agency is required.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className={submitted && !userId ? "is-invalid" : ""}>
                  <Label>User</Label>
                  <Select
                    name="userId"
                    value={userId}
                    onChange={handleSelectChange}
                    options={customerOptions}
                    isLoading={loadingSender}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                  {submitted && !userId && (
                    <div className="invalid-feedback-custom">User is required.</div>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className={submitted && !status ? "is-invalid" : ""}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    value={status}
                    onChange={handleSelectChange}
                    options={statusOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                  {submitted && !status && (
                    <div className="invalid-feedback-custom">
                      Status is required.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="note">Description</Label>
                  <Input
                    type="textarea"
                    name="note"
                    placeholder="Description"
                    value={note}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="float-end">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Form.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  senderTerm: PropTypes.string,
};

export default Form;
