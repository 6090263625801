import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, Input, Row } from "reactstrap";
import CaseAuditRowCard from "./CaseAuditRowCard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getPagingCaseAudit} from "../../store/case-audit/actions";
import CustomPagination from "../../components/Common/CustomPagination";

const CaseAuditTab = props => {
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10)
   
  const { caseAudit, orderId, getPagingCaseAudit } = props;
  const { items, itemCount, pageCount, loading } = caseAudit

  useEffect(() => {
    getPagingCaseAudit({page, pageSize, orderId})
  }, [page, pageSize, orderId]);
  
  return (
      <Fragment>
        <Card className="mb-2">
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        // onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              {items.map((item, index) =>{
                return <CaseAuditRowCard key={index} item={item}/>
              })}
              <Card>
                <CardBody>
                  <CustomPagination
                      itemCount={itemCount}
                      currentPage={page}
                      totalPage={pageCount}
                      defaultPageSize={pageSize}
                      pageSizeOptions={[5, 10, 15, 20, 25, 30]}
                      onChangePage={i => {
                        setPage(i);
                      }}
                      onPageSizeChange={size => {
                        setPage(1);
                        setPageSize(size);
                      }}
                  />
                </CardBody>
              </Card>
            </>
        )}
        
      </Fragment>
  );
};

CaseAuditTab.propTypes = {
  getPagingCaseAudit: PropTypes.func,
};

const mapStateToProps = ({ caseAudit }) => {
  return {
    caseAudit
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingCaseAudit,
    })(CaseAuditTab)
);
