import {
  GET_PRODUCT_UOMS,
  GET_PRODUCT_UOMS_SUCCESS,
  GET_PRODUCT_UOMS_ERROR,
  GET_PRODUCT_UOM_BY_ID,
  GET_PRODUCT_UOM_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_UOM,
  ADD_NEW_PRODUCT_UOM_SUCCESS,
  UPDATE_PRODUCT_UOM,
  UPDATE_PRODUCT_UOM_SUCCESS,
  DELETE_PRODUCT_UOM,
  DELETE_PRODUCT_UOM_SUCCESS,
  PRODUCT_UOM_CLEAR_ERROR,
  ADD_NEW_PRODUCT_UOM_ERROR,
} from "./actionTypes";
// ProductUom
export const getProductUoms = query => ({
  type: GET_PRODUCT_UOMS,
  payload: query,
});

export const getProductUomsSuccess = payload => ({
  type: GET_PRODUCT_UOMS_SUCCESS,
  payload,
});

export const getProductUomsError = error => ({
  type: GET_PRODUCT_UOMS_ERROR,
  payload: error,
});

export const getProductUomById = id => ({
  type: GET_PRODUCT_UOM_BY_ID,
  payload: id,
});

export const getProductUomByIdSuccess = payload => ({
  type: GET_PRODUCT_UOM_BY_ID_SUCCESS,
  payload,
});

export const addNewProductUom = item => ({
  type: ADD_NEW_PRODUCT_UOM,
  payload: item,
});

export const addNewProductUomSuccess = item => ({
  type: ADD_NEW_PRODUCT_UOM_SUCCESS,
  payload: item,
});

export const addNewProductUomError = error => ({
  type: ADD_NEW_PRODUCT_UOM_ERROR,
  payload: error,
});

export const updateProductUom = item => ({
  type: UPDATE_PRODUCT_UOM,
  payload: item,
});

export const updateProductUomSuccess = item => ({
  type: UPDATE_PRODUCT_UOM_SUCCESS,
  payload: item,
});

export const deleteProductUom = item => ({
  type: DELETE_PRODUCT_UOM,
  payload: item,
});

export const deleteProductUomSuccess = item => ({
  type: DELETE_PRODUCT_UOM_SUCCESS,
  payload: item,
});

export const clearProductUomError = () => ({
  type: PRODUCT_UOM_CLEAR_ERROR,
});
