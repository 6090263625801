import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, CardHeader, Col, Row } from "reactstrap";

const ProductTransactionItem = ({ isProductTransaction, item }) => {
  const { type, typeName, productName, quantity, createdDate, createdByUser } = item;

  let color = "primary";
  let icon = "fa-arrow-down";
  let displaySign = "+";

  switch (type) {
    case 0: // Stock In
      color = "primary";
      icon = "fa-arrow-down";
      displaySign = "+";
      break;
    case 1: // Stock Out
      color = "danger";
      icon = "fa-arrow-up";
      displaySign = "-";
      break;
    case 2: // Adjustment
      color = "warning";
      icon = "fa-arrows-alt-v";
      displaySign = quantity > 0 ? "+" : "-";
      break;
    default:
      break;
  }

  return (
    <Card className="mb-2 border border">
      <CardHeader className="bg-transparent border-bottom p-2 d-flex align-items-center">
        <div className="d-flex justify-content-center" style={{ width: "80px" }}>
          <i className={`fas ${icon} text-${color}`} style={{ fontSize: "2rem" }} />
        </div>
        <div className="flex-grow-1">
          <div style={{ padding: "1rem" }}>
            <Row>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3 className="font-size-14 mb-1">{isProductTransaction ? typeName : productName}</h3>
                  <div className="d-flex">
                    <div className="right d-flex flex-column">
                      <p className="mb-0">Date:</p>
                      <p className="mb-0">By:</p>
                    </div>
                    <div className="d-flex flex-column" style={{ marginLeft: "1rem" }}>
                      <p className="mb-0">
                        {moment.utc(createdDate).local().format("DD-MMM-yyyy h:mm:ss a")}
                      </p>
                      <p className="mb-0">{createdByUser}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pe-0 d-flex justify-content-end align-items-center">
                <h5 className={`font-size-16 mb-1 text-${color}`}>
                  {displaySign}
                  {Math.abs(quantity)}
                </h5>
              </Col>
            </Row>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

ProductTransactionItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const ProductTransactionTable = ({
  itemCount,
  isProductTransaction,
  page,
  totalPage,
  defaultPageSize,
  items,
  onChangePage,
  onPageSizeChange,
}) => {
  return (
    <div>
      {items.map((item, index) => (
        <ProductTransactionItem key={index} item={item} isProductTransaction={isProductTransaction}/>
      ))}
      <CustomPagination
        itemCount={itemCount}
        currentPage={page}
        totalPage={totalPage}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
        onChangePage={(i) => onChangePage(i)}
        onPageSizeChange={(size) => onPageSizeChange(size)}
      />
    </div>
  );
};

ProductTransactionTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ProductTransactionTable;
