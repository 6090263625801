import { SEVERITY } from "helpers/utils.js";
import {
  GET_TRANSPORTS,
  GET_TRANSPORTS_SUCCESS,
  GET_TRANSPORTS_FAIL,
  GET_TRANSPORT_BY_ID_SUCCESS,
  TRANSPORT_CLEAR_NOTIFY,
  UPDATE_TRANSPORT,
  UPDATE_TRANSPORT_SUCCESS,
  ADD_NEW_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_SUCCESS,
  GET_TRANSPORT_BY_ID,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
  draftIds: [],
  shippingToCambodiaIds: [],
  inCambodiaWarehouseIds: [],
  completedIds: [],
};

const Transport = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TRANSPORT:
    case GET_TRANSPORTS:
    case GET_TRANSPORT_BY_ID:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case GET_TRANSPORTS_SUCCESS:
      const {
        draftIds,
        shippingToCambodiaIds,
        inCambodiaWarehouseIds,
        completedIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        draftIds,
        shippingToCambodiaIds,
        inCambodiaWarehouseIds,
        completedIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_TRANSPORT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case ADD_NEW_TRANSPORT_SUCCESS:
      return {
        ...state,
        item: null,
        message: {
          title: "Send Order",
          text: "Send Order has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_TRANSPORT_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
        message: {
          title: "Send Order",
          text: "Send Order has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_TRANSPORT_SUCCESS:
      return {
        ...state,
        item: null,
        message: {
          title: "Send Order",
          text: "Send Order has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case TRANSPORT_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    case GET_TRANSPORTS_FAIL:
      return {
        ...state,
        item: null,
        message: {
          title: "Send Order",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default Transport;
