import FileSaver from "file-saver";
import {
  get,
  postFile,
  post,
  del,
  put as update,
} from "helpers/api_helper";

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/SaleOrder/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getSaleOrdersRequest = async (query) => {
  return await get("/api/StockOut/saleOrders", { params: query });
};

const getSaleOrderPagingRequest = async query => {
  return await get("/api/StockOut/paging",  { params: query } );
};

export const saleOrderService = {
  exportToExcelRequest,
  getSaleOrdersRequest,
  getSaleOrderPagingRequest
};
