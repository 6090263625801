// STOCK_OUT
export const STOCK_OUT_ADD_NEW = 'STOCK_OUT_ADD_NEW';
export const STOCK_OUT_ADD_NEW_SUCCESS = 'STOCK_OUT_ADD_NEW_SUCCESS';
export const STOCK_OUT_ADD_NEW_ERROR = 'STOCK_OUT_ADD_NEW_ERROR';

export const STOCK_OUT_GET_PAGING = 'STOCK_OUT_GET_PAGING';
export const STOCK_OUT_GET_PAGING_SUCCESS = 'STOCK_OUT_GET_PAGING_SUCCESS';
export const STOCK_OUT_GET_PAGING_ERROR = 'STOCK_OUT_GET_PAGING_ERROR';

export const STOCK_OUT_GET_BY_ID = 'STOCK_OUT_GET_BY_ID';
export const STOCK_OUT_GET_BY_ID_SUCCESS = 'STOCK_OUT_GET_BY_ID_SUCCESS';
export const STOCK_OUT_GET_BY_ID_ERROR = 'STOCK_OUT_GET_BY_ID_ERROR';

export const STOCK_OUT_UPDATE = 'STOCK_OUT_UPDATE';
export const STOCK_OUT_UPDATE_SUCCESS = 'STOCK_OUT_UPDATE_SUCCESS';
export const STOCK_OUT_UPDATE_ERROR = 'STOCK_OUT_UPDATE_ERROR';

export const STOCK_OUT_DELETE = 'STOCK_OUT_DELETE';
export const STOCK_OUT_DELETE_SUCCESS = 'STOCK_OUT_DELETE_SUCCESS';
export const STOCK_OUT_DELETE_ERROR = 'STOCK_OUT_DELETE_ERROR';
