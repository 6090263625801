import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";

export class OrderCBMEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = e => {
    const { item, onChange } = this.props;
    const { name, value } = e.target;
    switch (name) {
      case "cargoCode":
        item.cargoCode = value;
        break;
      case "commodity":
        item.commodity = value;
        break;
      case "quantityPackage":
        item.quantityPackage = value;
        break;
      case "quantityCatton":
        item.quantityCatton = value;
        break;
    }

    onChange(item);
  };

  handleOnChange = (name, value) => {
    const { item, onChange } = this.props;
    switch (name) {
      case "quantity":
        item.quantity = value;
        break;
      case "weight":
        item.weight = value;
        break;
      case "volume":
        item.volume = Number(value ?? 0);
        break;
    }

    onChange(item);
  };

  render() {
    const { onRemove, isFirst, item } = this.props;
    const {
      cargoCode,
      commodity,
      quantityPackage,
      quantityCatton,
      weight,
      volume,
    } = item;
    return (
      <Row className="mb-2">
        <Col md={3} className="pe-1">
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            {isFirst && <Label>Cargo Code</Label>}
            <Input
              type="text"
              name="cargoCode"
              maxLength={255}
              placeholder="Cargo Code."
              value={cargoCode}
              onChange={this.handleChange}
            />
          </div>
        </Col>
        <Col md={3} className="px-1">
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            {isFirst && <Label>Commodity</Label>}
            <Input
              type="text"
              name="commodity"
              maxLength={255}
              placeholder="Commodity"
              value={commodity}
              onChange={this.handleChange}
            />
          </div>
        </Col>
        <Col md={6} className="pe-5">
          <Row>
            <Col md={3} className="px-1">
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                {isFirst && <Label>PLTS/PKGS</Label>}
                <Input
                  type="text"
                  name="quantityPackage"
                  maxLength={255}
                  placeholder="PLTS/PKGS"
                  value={quantityPackage}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
            <Col md={3} className="px-1">
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                {isFirst && <Label>PCS/CTNS</Label>}
                <Input
                  type="text"
                  name="quantityCatton"
                  maxLength={255}
                  placeholder="PCS/CTNS"
                  value={quantityCatton}
                  onChange={this.handleChange}
                />
              </div>
            </Col>
            <Col md={3} className="px-1">
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                {isFirst && <Label>Weight (KG)</Label>}
                <NumberFormat
                  value={weight}
                  className="form-control text-end"
                  placeholder="Weight"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("weight", value);
                  }}
                />
              </div>
            </Col>
            <Col md={3} className="px-1">
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                {isFirst && <Label>Volume (m3)</Label>}
                <NumberFormat
                  value={volume}
                  className="form-control text-end"
                  placeholder="Volume"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.handleOnChange("volume", value);
                  }}
                />
              </div>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              top: isFirst ? 30 : 0,
              right: 0,
            }}
          >
            <Button
              style={{
                background: "transparent",
                color: "#495057",
                border: "none",
              }}
              outline
              onClick={() => onRemove(item)}
            >
              <i className="fas fa-trash" />
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

OrderCBMEntry.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default OrderCBMEntry;
