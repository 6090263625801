import {
  USER_VEHICLE_ADD_NEW,
  USER_VEHICLE_ADD_NEW_ERROR,
  USER_VEHICLE_ADD_NEW_SUCCESS,
  USER_VEHICLE_DELETE,
  USER_VEHICLE_DELETE_ERROR,
  USER_VEHICLE_DELETE_SUCCESS,
  USER_VEHICLE_GET_BY_ID,
  USER_VEHICLE_GET_BY_ID_ERROR,
  USER_VEHICLE_GET_BY_ID_SUCCESS,
  USER_VEHICLE_GET_PAGING,
  USER_VEHICLE_GET_PAGING_ERROR,
  USER_VEHICLE_GET_PAGING_SUCCESS,
  USER_VEHICLE_UPDATE,
  USER_VEHICLE_UPDATE_ERROR,
  USER_VEHICLE_UPDATE_SUCCESS,
} from './actionType';

// UserVehicle
export const getPagingUserVehicle = items => ({
  type: USER_VEHICLE_GET_PAGING,
  payload: items,
});

export const getPagingUserVehicleSuccess = items => ({
  type: USER_VEHICLE_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingUserVehicleError = error => ({
  type: USER_VEHICLE_GET_PAGING_ERROR,
  payload: error,
});

export const addUserVehicle = items => ({
  type: USER_VEHICLE_ADD_NEW,
  payload: items,
});

export const addUserVehicleSuccess = items => ({
  type: USER_VEHICLE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addUserVehicleError = error => ({
  type: USER_VEHICLE_ADD_NEW_ERROR,
  payload: error,
});

export const getUserVehicleById = items => ({
  type: USER_VEHICLE_GET_BY_ID,
  payload: items,
});

export const getUserVehicleByIdSuccess = items => ({
  type: USER_VEHICLE_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getUserVehicleByIdError = error => ({
  type: USER_VEHICLE_GET_BY_ID_ERROR,
  payload: error,
});

export const updateUserVehicle = items => ({
  type: USER_VEHICLE_UPDATE,
  payload: items,
});

export const updateUserVehicleSuccess = items => ({
  type: USER_VEHICLE_UPDATE_SUCCESS,
  payload: items,
});

export const updateUserVehicleError = error => ({
  type: USER_VEHICLE_UPDATE_ERROR,
  payload: error,
});

export const deleteUserVehicle = items => ({
  type: USER_VEHICLE_DELETE,
  payload: items,
});

export const deleteUserVehicleSuccess = items => ({
  type: USER_VEHICLE_DELETE_SUCCESS,
  payload: items,
});

export const deleteUserVehicleError = error => ({
  type: USER_VEHICLE_DELETE_ERROR,
  payload: error,
});
