// PRODUCT_UOM
export const GET_PRODUCT_UOMS = "GET_PRODUCT_UOMS";
export const GET_PRODUCT_UOMS_SUCCESS =
  "GET_PRODUCT_UOMS_SUCCESS";
export const GET_PRODUCT_UOMS_ERROR =
  "GET_PRODUCT_UOMS_ERROR";

export const GET_PRODUCT_UOM_BY_ID =
  "GET_PRODUCT_UOM_BY_ID";
export const GET_PRODUCT_UOM_BY_ID_SUCCESS =
  "GET_PRODUCT_UOM_BY_ID_SUCCESS";

export const ADD_NEW_PRODUCT_UOM =
  "ADD_NEW_PRODUCT_UOM";
export const ADD_NEW_PRODUCT_UOM_SUCCESS =
  "ADD_NEW_PRODUCT_UOM_SUCCESS";
export const ADD_NEW_PRODUCT_UOM_ERROR =
  "ADD_NEW_PRODUCT_UOM_ERROR";

export const UPDATE_PRODUCT_UOM =
  "UPDATE_PRODUCT_UOM";
export const UPDATE_PRODUCT_UOM_SUCCESS =
  "UPDATE_PRODUCT_UOM_SUCCESS";
export const UPDATE_PRODUCT_UOM_ERROR =
  "UPDATE_PRODUCT_UOM_ERROR";

export const DELETE_PRODUCT_UOM =
  "DELETE_PRODUCT_UOM";
export const DELETE_PRODUCT_UOM_SUCCESS =
  "DELETE_PRODUCT_UOM_SUCCESS";

export const PRODUCT_UOM_CLEAR_ERROR =
  "PRODUCT_UOM_CLEAR_ERROR";
