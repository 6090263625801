import {
  STOCK_OUT_ADD_NEW,
  STOCK_OUT_ADD_NEW_ERROR,
  STOCK_OUT_ADD_NEW_SUCCESS,
  STOCK_OUT_DELETE,
  STOCK_OUT_DELETE_ERROR,
  STOCK_OUT_DELETE_SUCCESS,
  STOCK_OUT_GET_BY_ID,
  STOCK_OUT_GET_BY_ID_ERROR,
  STOCK_OUT_GET_BY_ID_SUCCESS,
  STOCK_OUT_GET_PAGING,
  STOCK_OUT_GET_PAGING_ERROR,
  STOCK_OUT_GET_PAGING_SUCCESS,
  STOCK_OUT_UPDATE,
  STOCK_OUT_UPDATE_ERROR,
  STOCK_OUT_UPDATE_SUCCESS,
} from './actionType';

// StockOut
export const getPagingStockOut = items => ({
  type: STOCK_OUT_GET_PAGING,
  payload: items,
});

export const getPagingStockOutSuccess = items => ({
  type: STOCK_OUT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStockOutError = error => ({
  type: STOCK_OUT_GET_PAGING_ERROR,
  payload: error,
});

export const addStockOut = items => ({
  type: STOCK_OUT_ADD_NEW,
  payload: items,
});

export const addStockOutSuccess = items => ({
  type: STOCK_OUT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStockOutError = error => ({
  type: STOCK_OUT_ADD_NEW_ERROR,
  payload: error,
});

export const getStockOutById = items => ({
  type: STOCK_OUT_GET_BY_ID,
  payload: items,
});

export const getStockOutByIdSuccess = items => ({
  type: STOCK_OUT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStockOutByIdError = error => ({
  type: STOCK_OUT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStockOut = items => ({
  type: STOCK_OUT_UPDATE,
  payload: items,
});

export const updateStockOutSuccess = items => ({
  type: STOCK_OUT_UPDATE_SUCCESS,
  payload: items,
});

export const updateStockOutError = error => ({
  type: STOCK_OUT_UPDATE_ERROR,
  payload: error,
});

export const deleteStockOut = items => ({
  type: STOCK_OUT_DELETE,
  payload: items,
});

export const deleteStockOutSuccess = items => ({
  type: STOCK_OUT_DELETE_SUCCESS,
  payload: items,
});

export const deleteStockOutError = error => ({
  type: STOCK_OUT_DELETE_ERROR,
  payload: error,
});
