// ORDER
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";

export const ADD_NEW_ORDER = "ADD_NEW_ORDER";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const SUBMIT_ORDER_DELIVERY_STATUS = "SUBMIT_ORDER_DELIVERY_STATUS";
export const SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS =
  "SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS";

export const DELETE_ORDER = "DELETE_ORDER";

export const ORDER_CLEAR_ERROR = "ORDER_CLEAR_ERROR";
