import {
  STOCK_ADJUSTMENT_ADD_NEW,
  STOCK_ADJUSTMENT_ADD_NEW_ERROR,
  STOCK_ADJUSTMENT_ADD_NEW_SUCCESS,
  STOCK_ADJUSTMENT_DELETE,
  STOCK_ADJUSTMENT_DELETE_ERROR,
  STOCK_ADJUSTMENT_DELETE_SUCCESS,
  STOCK_ADJUSTMENT_GET_BY_ID,
  STOCK_ADJUSTMENT_GET_BY_ID_ERROR,
  STOCK_ADJUSTMENT_GET_BY_ID_SUCCESS,
  STOCK_ADJUSTMENT_GET_PAGING,
  STOCK_ADJUSTMENT_GET_PAGING_ERROR,
  STOCK_ADJUSTMENT_GET_PAGING_SUCCESS,
  STOCK_ADJUSTMENT_UPDATE,
  STOCK_ADJUSTMENT_UPDATE_ERROR,
  STOCK_ADJUSTMENT_UPDATE_SUCCESS,
} from './actionType';

// StockAdjustment
export const getPagingStockAdjustment = items => ({
  type: STOCK_ADJUSTMENT_GET_PAGING,
  payload: items,
});

export const getPagingStockAdjustmentSuccess = items => ({
  type: STOCK_ADJUSTMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStockAdjustmentError = error => ({
  type: STOCK_ADJUSTMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addStockAdjustment = items => ({
  type: STOCK_ADJUSTMENT_ADD_NEW,
  payload: items,
});

export const addStockAdjustmentSuccess = items => ({
  type: STOCK_ADJUSTMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStockAdjustmentError = error => ({
  type: STOCK_ADJUSTMENT_ADD_NEW_ERROR,
  payload: error,
});

export const getStockAdjustmentById = items => ({
  type: STOCK_ADJUSTMENT_GET_BY_ID,
  payload: items,
});

export const getStockAdjustmentByIdSuccess = items => ({
  type: STOCK_ADJUSTMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStockAdjustmentByIdError = error => ({
  type: STOCK_ADJUSTMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStockAdjustment = items => ({
  type: STOCK_ADJUSTMENT_UPDATE,
  payload: items,
});

export const updateStockAdjustmentSuccess = items => ({
  type: STOCK_ADJUSTMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateStockAdjustmentError = error => ({
  type: STOCK_ADJUSTMENT_UPDATE_ERROR,
  payload: error,
});

export const deleteStockAdjustment = items => ({
  type: STOCK_ADJUSTMENT_DELETE,
  payload: items,
});

export const deleteStockAdjustmentSuccess = items => ({
  type: STOCK_ADJUSTMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteStockAdjustmentError = error => ({
  type: STOCK_ADJUSTMENT_DELETE_ERROR,
  payload: error,
});
