import { call, put, takeEvery } from 'redux-saga/effects';
import {
  PRODUCT_REVIEW_ADD_NEW,
  PRODUCT_REVIEW_DELETE,
  PRODUCT_REVIEW_GET_BY_ID,
  PRODUCT_REVIEW_GET_PAGING,
  PRODUCT_REVIEW_UPDATE,
} from './actionType';

import {
  addProductReviewSuccess,
  addProductReviewError,
  getPagingProductReviewSuccess,
  getPagingProductReviewError,
  getProductReviewByIdSuccess,
  getProductReviewByIdError,
  updateProductReviewSuccess,
  updateProductReviewError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// ProductReview
const getPagingProductReviewRequest = query => get("/api/ProductReview/paging", { params: query });
const addNewProductReviewRequest = data => post("/api/ProductReview", data);
const getProductReviewByIdRequest = id => get(`/api/ProductReview/${id}`);
const updateProductReviewRequest = data => update("/api/ProductReview", data);
const deleteProductReviewRequest = id => del(`/api/ProductReview/${id}`);

function* addNewProductReview({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewProductReviewRequest, data);
    yield put(addProductReviewSuccess(response));
    
    response = yield call(getPagingProductReviewRequest, queryParams);
    yield put(getPagingProductReviewSuccess(response));
  } catch (error) {
    yield put(addProductReviewError(error.response.statusText));
  }
}

function* updateProductReview({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateProductReviewRequest, data);
    yield put(updateProductReviewSuccess(response));
    
    response = yield call(getPagingProductReviewRequest, queryParams);
    yield put(getPagingProductReviewSuccess(response));
  } catch (error) {
    yield put(updateProductReviewError(error.response.statusText));
  }
}

function* getPagingProductReview({ payload }) {
  try {
    let response = yield call(getPagingProductReviewRequest, payload);
    yield put(getPagingProductReviewSuccess(response));
  } catch (error) {
    yield put(getPagingProductReviewError(error.response.statusText));
  }
}

function* getProductReviewById({ payload }) {
  try {
    let response = yield call(getProductReviewByIdRequest, payload);
    yield put(getProductReviewByIdSuccess(response));
  } catch (error) {
    yield put(getProductReviewByIdError(error.response.statusText));
  }
}

function* deleteProductReview({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteProductReviewRequest, data);

    response = yield call(getPagingProductReviewRequest, queryParams);
    yield put(getPagingProductReviewSuccess(response));
  } catch (error) {
    yield put(getPagingProductReviewError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(PRODUCT_REVIEW_ADD_NEW, addNewProductReview);
  yield takeEvery(PRODUCT_REVIEW_UPDATE, updateProductReview);
  yield takeEvery(PRODUCT_REVIEW_GET_PAGING, getPagingProductReview);
  yield takeEvery(PRODUCT_REVIEW_GET_BY_ID, getProductReviewById);
  yield takeEvery(PRODUCT_REVIEW_DELETE, deleteProductReview);
}

export {
  getPagingProductReviewRequest
}

