import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addStockIn } from "../../store/stock-in/actions";
import { toastMessage } from "helpers/utils";
import StockInForm from "../../containers/stock-in/StockInForm";

const AddNewStockIn = props => {
  const { addStockIn, match, history } = props;

  const [ activeTab, setActiveTab ] = useState("1");

  const { addNewProduct, clearProductNotify, product } = props;
  const { item, loading, message } = product;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductNotify();
    }
  }, [ message ]);

  const handleSubmit = data => {
    addStockIn({
      data,
      history,
      queryParams: {}
    })
  };

  const handleOnCancel = () => {
    history.push("/stock-in");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Stock In | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" backLink={ "/stock-in" } breadcrumbItem={ `Add New Stock In` }/>

            <Row>

              <Col md={ 12 }>
                <StockInForm
                    onSubmit={ handleSubmit }
                    onCancel={ handleOnCancel }
                />


              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

AddNewStockIn.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addStockIn: PropTypes.func,
};

const mapStateToProps = ({ product }) => {
  return {
    product,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      addStockIn
    })(AddNewStockIn)
);
