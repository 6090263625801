import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

const documentTypeOptions = [
  {
    key: 0,
    value: 0,
    label: 'None',
  },
  {
    key: 1,
    value: 1,
    label: 'Packing List',
  },
  {
    key: 3,
    value: 2,
    label: 'Export Declaration',
  },
  {
    key: 4,
    value: 3,
    label: 'Bill of Landing',
  },
  {
    key: 5,
    value: 4,
    label: 'Commercial Invoice',
  },
  {
    key: 6,
    value: 5,
    label: 'Others',
  }
]

let uploader = new FineUploaderTraditional({
  options: {
    multiple: false,
    request: {
      method: "POST",
      endpoint: `${ process.env.REACT_APP_API_URL }/api/FileUpload/orderDocument`,
    },
    validation: {
      sizeLimit: 104857600, // 100MB
    },
  },
});

const ModalForm = props => {
  const { isOpen, toggle, title, orderId, onSubmit } = props;

  const [ item, setItem ] = useState({
    id: NIL,
    orderId,
    documentType: 0,
    fileName: '',
    url: '',
    note: '',
  });

  const [ fileName, setFileName ] = useState('');
  const [ url, setUrl ] = useState('');

  const [ isSubmitted, setIsSubmitted ] = useState(false);

  useEffect(() => {

    uploader.on("submitted", id => {
      console.log(id);
    });

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        setFileName(responseJSON.fileName);
        setUrl(responseJSON.url);
      }
    });

    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name);
    });

  }, []);

  const onOpened = () => {
    setIsSubmitted(false);

    if (props.item) {
      let { item } = props;
      setItem({
        ...item,
        documentType: item.documentType ? documentTypeOptions.find(e => e.value === item.documentType) : null,
      });
      
      setFileName(item.fileName);
      setUrl(item.url);
      
    } else {
      setItem({
        id: NIL,
        orderId,
        documentType: 0,
        fileName: '',
        url: '',
        note: '',
      });
      
      setFileName('');
      setUrl('');
    }
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    let isValid = item.documentType && fileName && url;

    if (isValid) {
      let data = {
        ...item,
        documentType: item.documentType?.value,
        fileName,
        url,
      }

      onSubmit(data);
    }
  }

  const handleChange = e => {
    const { value, name } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    setItem({
      ...item,
      [name]: valueType
    })
  }


  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (isSubmitted && !item.documentType ? " is-invalid" : "")
                    }
                >
                  <Label>Document Type</Label>
                  <Select
                      name="documentType"
                      value={ item.documentType }
                      onChange={ handleSelectChange }
                      options={ documentTypeOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </div>
                { isSubmitted && !item.documentType && (
                    <div className="invalid-feedback-custom">
                      Document Type is required.
                    </div>
                ) }
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    rows={ 4 }
                    placeholder="Note"
                    value={ item.note }
                    onChange={ handleChange }
                />
              </FormGroup>
            </Col>
            <Col>
              {
                fileName ?
                    <div className={ "mb-2" }>
                      <a href={ url } download={ fileName } target={ "_blank" }>
                        <i
                            className="mdi mdi-download font-size-18"
                        ></i>
                        <span className={ "ms-2" }>{ fileName }</span>
                      </a>
                    </div>
                    : null
              }
              <Gallery uploader={ uploader }/>
            </Col>
          </Row>

        </div>

        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  orderId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default ModalForm;