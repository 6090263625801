import {
    GET_DELIVERIES,
    GET_DELIVERIES_SUCCESS,
    GET_DELIVERIES_FAIL,
    GET_DELIVERY_BY_ID,
    GET_DELIVERY_BY_ID_SUCCESS,
    ADD_NEW_DELIVERY,
    UPDATE_DELIVERY,
    DELETE_DELIVERY,
    DELIVERY_CLEAR_NOTIFY,
    ADD_NEW_DELIVERY_SUCCESS,
    ADD_NEW_DELIVERY_ERROR,
    UPDATE_DELIVERY_SUCCESS,
    UPDATE_DELIVERY_ERROR,
    DELETE_DELIVERY_SUCCESS,
    DELETE_DELIVERY_ERROR,
  } from "./actionTypes";
  
  // Delivery
  export const getDeliveries = query => ({
    type: GET_DELIVERIES,
    payload: query,
  });
  
  export const getDeliveriesSuccess = items => ({
    type: GET_DELIVERIES_SUCCESS,
    payload: items,
  });
  
  export const getDeliveriesFail = error => ({
    type: GET_DELIVERIES_FAIL,
    payload: error,
  });
  
  export const getDeliveryById = id => ({
    type: GET_DELIVERY_BY_ID,
    payload: id,
  });
  
  export const getDeliveryByIdSuccess = item => ({
    type: GET_DELIVERY_BY_ID_SUCCESS,
    payload: item,
  });
  
  export const getDeliveryOrderByIdFail = error => ({
    type: GET_DELIVERY_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewDelivery = item => ({
    type: ADD_NEW_DELIVERY,
    payload: item,
  });
  
  export const addNewDeliverySuccess = item => ({
    type: ADD_NEW_DELIVERY_SUCCESS,
    payload: item,
  });
  
  export const addNewDeliveryError = item => ({
    type: ADD_NEW_DELIVERY_ERROR,
    payload: item,
  });
  
  export const updateDelivery = item => ({
    type: UPDATE_DELIVERY,
    payload: item,
  });
  
  export const updateDeliverySuccess = item => ({
    type: UPDATE_DELIVERY_SUCCESS,
    payload: item,
  });
  export const updateDeliveryError = item => ({
    type: UPDATE_DELIVERY_ERROR,
    payload: item,
  });
  
  export const deleteDelivery = item => ({
    type: DELETE_DELIVERY,
    payload: item,
  });
  
  export const deleteDeliverySuccess = item => ({
    type: DELETE_DELIVERY_SUCCESS,
    payload: item,
  });
  
  export const deleteDeliveryError = item => ({
    type: DELETE_DELIVERY_ERROR,
    payload: item,
  });
  
  export const clearDeliveryNotify = () => ({
    type: DELIVERY_CLEAR_NOTIFY,
  });
  