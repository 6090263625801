import { SEVERITY } from "helpers/utils.js";
import {
  GET_DELIVERY_TO_DOOR,
  GET_DELIVERY_TO_DOOR_SUCCESS,
  GET_DELIVERY_TO_DOOR_FAIL,
  GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_TO_DOOR,
  ADD_NEW_DELIVERY_TO_DOOR_SUCCESS,
  UPDATE_DELIVERY_TO_DOOR,
  UPDATE_DELIVERY_TO_DOOR_SUCCESS,
  DELETE_DELIVERY_TO_DOOR,
  DELETE_DELIVERY_TO_DOOR_SUCCESS,
  DELIVERY_TO_DOOR_CLEAR_NOTIFY,
  APPROVE_DELIVERY_TO_DOOR,
  REJECT_DELIVERY_TO_DOOR,
  APPROVE_DELIVERY_TO_DOOR_SUCCESS,
  REJECT_DELIVERY_TO_DOOR_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const deliveryToDoor = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_DELIVERY_TO_DOOR:
    case UPDATE_DELIVERY_TO_DOOR:
    case DELETE_DELIVERY_TO_DOOR:
    case GET_DELIVERY_TO_DOOR:
    case APPROVE_DELIVERY_TO_DOOR:
    case REJECT_DELIVERY_TO_DOOR:
      return {
        ...state,
        loading: true,
        item: null,
      };
    case APPROVE_DELIVERY_TO_DOOR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Delivery to Door",
          text: "Delivery to Door has been approved successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case REJECT_DELIVERY_TO_DOOR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Delivery to Door",
          text: "Delivery to Door has been rejected.",
          severity: SEVERITY.DANGER,
        },
      };
    case GET_DELIVERY_TO_DOOR_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };

    case GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_DELIVERY_TO_DOOR_FAIL:
      return {
        ...state,
        error: payload,
      };

    case ADD_NEW_DELIVERY_TO_DOOR_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_DELIVERY_TO_DOOR_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_DELIVERY_TO_DOOR_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELIVERY_TO_DOOR_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default deliveryToDoor;
