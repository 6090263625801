import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_INVOICES,
  ADD_NEW_INVOICE,
  GET_INVOICE_BY_ID,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  GET_INVOICE_DETAIL,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getInvoicesSuccess,
  getInvoiceByIdSuccess,
  getInvoiceDetailSuccess,
  getInvoicesFail,
  addNewInvoiceSuccess,
} from "./actions";
import axios from "axios";
// Invoice
let currentCancelToken;

const getInvoicesRequest = (query)=>{
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  currentCancelToken = axios.CancelToken.source();
  
  return post(`/api/Invoice/paging`, query, {
    cancelToken: currentCancelToken.token
  }); 
} 
const addNewInvoiceRequest = Invoice => post(`/api/Invoice`, Invoice);
const getInvoiceByIdRequest = data => get(`/api/Invoice/${data.id}`);
const getInvoiceDetailRequest = id => get(`/api/Invoice/${id}/detail`);
const updateInvoiceRequest = Invoice => update(`/api/Invoice`, Invoice);
const deleteInvoiceRequest = id => del(`/api/Invoice/${id}`);

function* fetchInvoices({ payload }) {
  try {
    const response = yield call(getInvoicesRequest, payload);

    yield put(getInvoicesSuccess(response));
  } catch (err) {
    // const error = err?.response?.data?.message ?? "Something has gone wrong.";
    // yield put(getInvoicesFail(error));
  }
}

function* addNewInvoice({ payload }) {
  try {
    yield call(addNewInvoiceRequest, payload);
    const { orderId } = payload;
    const response = yield call(getInvoicesRequest, { orderId });
    yield put(getInvoicesSuccess(response));
    yield put(addNewInvoiceSuccess(response));
  } catch (err) {
    console.log(err);
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicesFail(error));
  }
}

function* updateInvoice({ payload }) {
  try {
    yield call(updateInvoiceRequest, payload);
    const { orderId } = payload;
    const response = yield call(getInvoicesRequest, { orderId });
    yield put(getInvoicesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicesFail(error));
  }
}

function* getInvoiceById({ payload }) {
  try {
    var response = yield call(getInvoiceByIdRequest, payload);
    yield put(getInvoiceByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicesFail(error));
  }
}

function* getInvoiceDetail({ payload }) {
  try {
    var response = yield call(getInvoiceDetailRequest, payload);
    yield put(getInvoiceDetailSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicesFail(error));
  }
}

function* deleteInvoice({ payload }) {
  try {
    const { type, id } = payload
    yield call(deleteInvoiceRequest, id);
    const response = yield call(getInvoicesRequest, {type});
    yield put(getInvoicesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicesFail(error));
  }
}

function* InvoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(ADD_NEW_INVOICE, addNewInvoice);
  yield takeEvery(GET_INVOICE_BY_ID, getInvoiceById);
  yield takeEvery(UPDATE_INVOICE, updateInvoice);
  yield takeEvery(DELETE_INVOICE, deleteInvoice);
  yield takeEvery(GET_INVOICE_DETAIL, getInvoiceDetail);
}

export default InvoiceSaga;
