import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
// Core viewer
// import { Viewer } from "@react-pdf-viewer/core";

// // Plugins
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// // Import styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Create new plugin instance

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import DemoPDF from "./DemoPDF";

import Breadcrumbs from "components/Common/Breadcrumb";
import StackedColumnChart from "./StackedColumnChart";
import ApexChart from "./ApexChart";
import OrderSummry from "containers/dashboard/OrderSummry";
import { dashboardService } from "services/dashboard-service";
import OrderSummaryChart from "containers/dashboard/OrderSummaryChart";
import InvoiceChart from "containers/dashboard/InvoiceChart";
import { getCompanyName, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

import MiscellaneousCard from "containers/dashboard/MiscellaneousCard";
import PickupCard from "containers/dashboard/PickupCard";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartSeries: null,
      orderSummary: null,
      invoiceSummary: null,
      monthlyOrder: null,
      miscellaneous: null,
      pickupSummry: null,
      loading: false,
      showDashboard: false,
      myDashboard: false,
      dashboard: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    let myDashboard = hasAccess(permission.myDashboard.enable);
    let dashboard = hasAccess(permission.dashboard.enable);
    let showDashboard = myDashboard || dashboard;
    this.setState({
      loading: true,
      showDashboard,
    });

    dashboardService.getDashboardRequest({ myDashboard }).then(data => {
      if (data) {
        const {
          orderSummary,
          invoiceSummary,
          monthlyOrder,
          miscellaneous,
          pickupSummry,
        } = data;
        this.setState({
          orderSummary,
          invoiceSummary,
          monthlyOrder,
          miscellaneous,
          pickupSummry,
          loading: false,
        });
      }
    });
  }

  render() {
    const {
      loading,
      orderSummary,
      invoiceSummary,
      showDashboard,
      monthlyOrder,
      miscellaneous,
      pickupSummry,
    } = this.state;
    const {company } = this.props;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
            {showDashboard ? (
              <>
                {loading ? (
                  <Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col md={12}>
                        {orderSummary && <OrderSummry item={orderSummary} />}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        {pickupSummry && <PickupCard item={pickupSummry} />}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        {monthlyOrder && (
                          <OrderSummaryChart item={monthlyOrder} />
                        )}
                      </Col>
                      <Col md={5}>
                        {invoiceSummary && (
                          <InvoiceChart item={invoiceSummary} />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <MiscellaneousCard data={miscellaneous} />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <Row>
                <Col md={12}>
                  <Card>
                    {/* <CardHeader className="bg-transparent border">
                      <CardTitle>No Dashboard</CardTitle>
                    </CardHeader> */}
                    <CardBody className="d-flex justify-content-center">
                      <h1>No Dashboard</h1>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.object,
};

export default Dashboard;
