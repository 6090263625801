import {
    PRODUCT_TRANSACTION_ADD_NEW,
    PRODUCT_TRANSACTION_ADD_NEW_ERROR,
    PRODUCT_TRANSACTION_ADD_NEW_SUCCESS,
    PRODUCT_TRANSACTION_GET_BY_ID,
    PRODUCT_TRANSACTION_GET_BY_ID_ERROR,
    PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS,
    PRODUCT_TRANSACTION_GET_PAGING,
    PRODUCT_TRANSACTION_GET_PAGING_ERROR,
    PRODUCT_TRANSACTION_GET_PAGING_SUCCESS,
    PRODUCT_TRANSACTION_UPDATE,
    PRODUCT_TRANSACTION_UPDATE_ERROR,
    PRODUCT_TRANSACTION_UPDATE_SUCCESS,
  } from './actionType';
  // item
  const initialState = {
    item: null,
    items: [],
    loading: false,
    error: null,
    itemCount: 0,
    pageCount: 0,
    total: 0,
  };
  
  export default function initState(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case PRODUCT_TRANSACTION_ADD_NEW:
      case PRODUCT_TRANSACTION_GET_PAGING:
      case PRODUCT_TRANSACTION_UPDATE:
        return {
          ...state,
          loading: true,
          error: null,
          item: null,
        };
  
      case PRODUCT_TRANSACTION_GET_PAGING_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          loading: false,
          error: null,
          itemCount,
          pageCount,
          items: data,
        };
  
      case PRODUCT_TRANSACTION_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          // item: payload
        };
  
      case PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          item: payload,
        };
  
      case PRODUCT_TRANSACTION_ADD_NEW_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          item: payload,
        };
  
      case PRODUCT_TRANSACTION_ADD_NEW_ERROR:
      case PRODUCT_TRANSACTION_GET_PAGING_ERROR:
      case PRODUCT_TRANSACTION_GET_BY_ID_ERROR:
      case PRODUCT_TRANSACTION_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
          error: payload,
        };
  
      default:
        return { ...state };
    }
  }
  