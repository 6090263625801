// PRODUCT_TRANSACTION
export const PRODUCT_TRANSACTION_ADD_NEW = 'PRODUCT_TRANSACTION_ADD_NEW';
export const PRODUCT_TRANSACTION_ADD_NEW_SUCCESS = 'PRODUCT_TRANSACTION_ADD_NEW_SUCCESS';
export const PRODUCT_TRANSACTION_ADD_NEW_ERROR = 'PRODUCT_TRANSACTION_ADD_NEW_ERROR';

export const PRODUCT_TRANSACTION_GET_PAGING = 'PRODUCT_TRANSACTION_GET_PAGING';
export const PRODUCT_TRANSACTION_GET_PAGING_SUCCESS = 'PRODUCT_TRANSACTION_GET_PAGING_SUCCESS';
export const PRODUCT_TRANSACTION_GET_PAGING_ERROR = 'PRODUCT_TRANSACTION_GET_PAGING_ERROR';

export const PRODUCT_TRANSACTION_GET_BY_ID = 'PRODUCT_TRANSACTION_GET_BY_ID';
export const PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS = 'PRODUCT_TRANSACTION_GET_BY_ID_SUCCESS';
export const PRODUCT_TRANSACTION_GET_BY_ID_ERROR = 'PRODUCT_TRANSACTION_GET_BY_ID_ERROR';

export const PRODUCT_TRANSACTION_UPDATE = 'PRODUCT_TRANSACTION_UPDATE';
export const PRODUCT_TRANSACTION_UPDATE_SUCCESS = 'PRODUCT_TRANSACTION_UPDATE_SUCCESS';
export const PRODUCT_TRANSACTION_UPDATE_ERROR = 'PRODUCT_TRANSACTION_UPDATE_ERROR';

export const PRODUCT_TRANSACTION_DELETE = 'PRODUCT_TRANSACTION_DELETE';
export const PRODUCT_TRANSACTION_DELETE_SUCCESS = 'PRODUCT_TRANSACTION_DELETE_SUCCESS';
export const PRODUCT_TRANSACTION_DELETE_ERROR = 'PRODUCT_TRANSACTION_DELETE_ERROR';
