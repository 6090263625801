import {
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW,
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_DELETE,
  DISCOUNT_PROMOTION_PRODUCT_DELETE_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_DELETE_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_SUCCESS,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE_ERROR,
  DISCOUNT_PROMOTION_PRODUCT_UPDATE_SUCCESS,
} from './actionType';

// DiscountPromotionProduct
export const getPagingDiscountPromotionProduct = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_PAGING,
  payload: items,
});

export const getPagingDiscountPromotionProductSuccess = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingDiscountPromotionProductError = error => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_PAGING_ERROR,
  payload: error,
});

export const addDiscountPromotionProduct = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_ADD_NEW,
  payload: items,
});

export const addDiscountPromotionProductSuccess = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addDiscountPromotionProductError = error => ({
  type: DISCOUNT_PROMOTION_PRODUCT_ADD_NEW_ERROR,
  payload: error,
});

export const getDiscountPromotionProductById = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID,
  payload: items,
});

export const getDiscountPromotionProductByIdSuccess = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getDiscountPromotionProductByIdError = error => ({
  type: DISCOUNT_PROMOTION_PRODUCT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateDiscountPromotionProduct = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_UPDATE,
  payload: items,
});

export const updateDiscountPromotionProductSuccess = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_UPDATE_SUCCESS,
  payload: items,
});

export const updateDiscountPromotionProductError = error => ({
  type: DISCOUNT_PROMOTION_PRODUCT_UPDATE_ERROR,
  payload: error,
});

export const deleteDiscountPromotionProduct = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_DELETE,
  payload: items,
});

export const deleteDiscountPromotionProductSuccess = items => ({
  type: DISCOUNT_PROMOTION_PRODUCT_DELETE_SUCCESS,
  payload: items,
});

export const deleteDiscountPromotionProductError = error => ({
  type: DISCOUNT_PROMOTION_PRODUCT_DELETE_ERROR,
  payload: error,
});
