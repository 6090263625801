import React from "react";
import { Alert, Button, Modal } from "reactstrap";

import PropTypes from "prop-types";

const StockOutConfirmModal = ({ isOpen, toggle, title, onSubmit }) => {
  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Alert color="warning">
          Are you sure want to confirm items in to stock?
          <br />
          <span style={{ fontStyle: "italic" }}>
            Note: This process cannot revert
          </span>
        </Alert>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={onSubmit} type="submit">
          Confirm
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

StockOutConfirmModal.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default StockOutConfirmModal;
