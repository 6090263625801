import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Alert, Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup, Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from "reactstrap";
    import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import {connect} from "react-redux";

const LoadingContainer = () => <div>Loading...</div>

const MapViewModel = ({isOpen, toggle, title, latitude, longitude, ...props}) => {
    const {google} = props;
    console.log(google)
    const [isCopyLink, setIsCopyLink] = useState(false);
    
    const onOpened=()=>{
        setIsCopyLink(false)
    }
    
    return (
        <Modal
            size="lg"
            onOpened={onOpened}
            isOpen={isOpen}
            toggle={toggle}
            backdrop="static"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {title}
                </h5>
                <button
                    type="button"
                    onClick={toggle}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body p-0">
                <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: "relative" }}
                >
                <Map
                    google={google}
                    zoom={14}
                    style={{ width: "100%", height: "100%" }}
                    initialCenter={{
                        lat: latitude,
                        lng: longitude,
                    }}
                    onRightclick={e=>{
                        console.log(e)
                    }}
                >
                    <Marker onClick={item=>{
                        const {google, map,name} = item
                        console.log(map.mapUrl)
                        
                    }} />
                    <InfoWindow google={google}>
                        <div>
                            {/*<h1>{this.state.selectedPlace.name}</h1>*/}
                            <h1>ok</h1>
                        </div>
                    </InfoWindow>
                </Map>
                </div>  
            </div>
            <div className="modal-footer">
                <Button color={"primary"} outline onClick={()=>{
                    let link =`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
                    navigator.clipboard.writeText(link)
                    setIsCopyLink(true)
                }}>
                    <i className="fas fa-link pe-2"></i>
                    {isCopyLink ?<>Copied</>:<>Copy Link</>}
                    
                </Button>
             
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </Modal>
    );
};

MapViewModel.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    title: PropTypes.string,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    google: PropTypes.object,
};


export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyAIgfLKUfhFQect6xW1mL-SaEQEgLP7tX4",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(MapViewModel)
)
