import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import moment from "moment/moment";
import { displayTransportStatus } from "../../helpers/utils";

const TransportSelectionCard = ({isSelected, item, onSelect}) => {
  const { title, closeTheContainerDate, transportDate, orderCount } = item;
  return (
      <Card className={"mb-2 border border-1 " + (isSelected ? 'border-primary': '') } onClick={()=> onSelect(item)} style={{cursor: "pointer"}} >
        <CardHeader className="bg-transparent border border-bottom">
          <Row>
            <Col md={6}>
              <CardTitle>{title}</CardTitle>    
            </Col>
            <Col md={6}>
              <div className="text-sm-end">
                {displayTransportStatus(item)}
              </div>
            </Col>
          </Row>
          
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <h5 className={"text-truncate font-size-14 mb-1"}>
                Close the Container Date
              </h5>
              <p className="text-muted">{closeTheContainerDate &&
                  moment(closeTheContainerDate).format(
                      "DD-MMM-yyyy"
                  )}</p>
            </Col>
            <Col md={4}>
              <h5 className={"text-truncate font-size-14 mb-1"}>
                Transport Date
              </h5>
              <p className="text-muted">
                {moment(transportDate).format("DD-MMM-yyyy")}
              </p>
            </Col>
            <Col md={4}>
              <h5 className={"text-truncate font-size-14 mb-1"}>
                Orders
              </h5>
              <p className="text-muted">{
                orderCount
              }</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
};

TransportSelectionCard.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default TransportSelectionCard;