import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_TRANSPORT_ORDERS,
  ADD_NEW_TRANSPORT_ORDER,
  ADD_NEW_TRANSPORT_ORDERS,
  GET_TRANSPORT_ORDER_BY_ID,
  UPDATE_TRANSPORT_ORDER,
  DELETE_TRANSPORT_ORDER,
  DELETE_TRANSPORT_ORDERS,
  ADD_NOTE_TRANSPORT_ORDER_ITEM,
  CHANGE_STATUS_TRANSPORT_ORDER_ITEM,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getTransportOrdersSuccess,
  getTransportOrderByIdSuccess,
  getTransportOrdersFail,
  addNoteToTransportOrderItemSuccess,
  changeStatusTransportOrderItemSuccess,
} from "./actions";
import { orderService } from "services/order-service";
const getTransportOrdersRequest = query =>
  post("/api/TransportOrder/paging", query);
const addNewTransportOrderRequest = role => post("/api/TransportOrder", role);
const addNewTransportOrdersRequest = (transportId, orderIds) =>
  update(`/api/TransportOrder/${transportId}/bulkAddOrder`, orderIds);
const getTransportOrderByIdRequest = id => get(`/api/TransportOrder/${id}`);
const updateTransportOrderRequest = role => update("/api/TransportOrder", role);
const deleteTransportOrderRequest = id => del(`/api/TransportOrder/${id}`);
const deleteTransportOrdersRequest = (transportId, orderIds) =>
  update(`/api/TransportOrder/${transportId}/bulkRemoveOrder`, orderIds);
const addNoteToTransportOrderItemRequest = note => post("/api/OrderNote", note);

function* fetchTransportOrders({ payload }) {
  try {
    const response = yield call(getTransportOrdersRequest, payload);
    yield put(getTransportOrdersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* addNewTransportOrder({ payload }) {
  try {
    yield call(addNewTransportOrderRequest, payload);
    const { orderId } = payload;
    const response = yield call(getTransportOrdersRequest, { orderId });
    yield put(getTransportOrdersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* addNoteToTransportOrderItem({ payload }) {
  try {
    const { note } = payload;
    yield call(addNoteToTransportOrderItemRequest, note);

    // let response = yield call(getTransportOrdersRequest, { transportId });
    // yield put(getTransportOrdersSuccess(response));
    var response = yield call(orderService.getOrderByIdRequest, note.orderId);
    yield put(addNoteToTransportOrderItemSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* changeStatusTransportOrderItem({ payload }) {
  try {
    const { id, deliveryStatus } = payload;
    yield call(orderService.submitOrderDeliveryStatusRequest, {
      id,
      deliveryStatus,
    });

    var response = yield call(orderService.getOrderByIdRequest, id);
    yield put(changeStatusTransportOrderItemSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* addNewTransportOrders({ payload }) {
  try {
    const { transportId, orderIds } = payload;
    let response = yield call(
      addNewTransportOrdersRequest,
      transportId,
      orderIds
    );
    response = yield call(getTransportOrdersRequest, { transportId: response });
    yield put(getTransportOrdersSuccess(response));
    
  } catch (err) {
    let error = err?.response?.data?.message ?? "Something has gone wrong.";
    if (error.includes("duplicate key")) {
      error = "Already exist in the list";
    }

    console.log(error);
    yield put(getTransportOrdersFail(error));

    // const error = err?.response?.data?.message ?? "Something has gone wrong.";
    // yield put(getTransportOrdersFail(error));
  }
}

function* updateTransportOrder({ payload }) {
  try {
    yield call(updateTransportOrderRequest, payload);
    const { orderId } = payload;
    const response = yield call(getTransportOrdersRequest, { orderId });
    yield put(getTransportOrdersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* getTransportOrderById({ payload }) {
  try {
    var response = yield call(getTransportOrderByIdRequest, payload);
    yield put(getTransportOrderByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(error));
  }
}

function* deleteTransportOrder({ payload }) {
  try {
    const { id, orderId } = payload;
    yield call(deleteTransportOrderRequest, id);
    const response = yield call(getTransportOrdersRequest, { orderId });
    yield put(getTransportOrdersSuccess(response));
  } catch (error) {
    const err = error?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(err));
  }
}

function* deleteTransportOrders({ payload }) {
  try {
    const { transportId, orderIds } = payload;
    let response = yield call(
      deleteTransportOrdersRequest,
      transportId,
      orderIds
    );

    response = yield call(getTransportOrdersRequest, { transportId: response });
    yield put(getTransportOrdersSuccess(response));
  } catch (error) {
    const err = error?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportOrdersFail(err));
  }
}

function* roleSaga() {
  yield takeEvery(GET_TRANSPORT_ORDERS, fetchTransportOrders);
  yield takeEvery(ADD_NEW_TRANSPORT_ORDER, addNewTransportOrder);
  yield takeEvery(ADD_NEW_TRANSPORT_ORDERS, addNewTransportOrders);
  yield takeEvery(GET_TRANSPORT_ORDER_BY_ID, getTransportOrderById);
  yield takeEvery(UPDATE_TRANSPORT_ORDER, updateTransportOrder);
  yield takeEvery(DELETE_TRANSPORT_ORDER, deleteTransportOrder);
  yield takeEvery(DELETE_TRANSPORT_ORDERS, deleteTransportOrders);
  yield takeEvery(ADD_NOTE_TRANSPORT_ORDER_ITEM, addNoteToTransportOrderItem);
  yield takeEvery(
    CHANGE_STATUS_TRANSPORT_ORDER_ITEM,
    changeStatusTransportOrderItem
  );
}

export default roleSaga;
export {
  getTransportOrdersRequest
}
