import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardText,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";
import PermissionTab from "./PermissionTab";
import { clearRoleNotify, getRoleById, updateRole } from "store/role/actions";
import { getCompanyName, toastMessage } from "helpers/utils";

export class RoleDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handlePopulateRole = () => {
    const { id } = this.state;

    if (id !== NIL) {
      this.props.getRoleById(id);
    }
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id !== NIL) {
      this.props.getRoleById(id);
      this.setState({
        id,
      });
    }
  }

  componentDidUpdate() {
    const { message, clearRoleNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearRoleNotify();
    }
  }

  handleSubmitPermission = item => {
    const payload = {
      data: item,
      permissionTab: true,
    };
    this.props.updateRole(payload);
  };

  render() {
    const { item, loading } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Role Detail | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            {item && (
              <Breadcrumbs
                title="Home"
                backLink={"/role"}
                breadcrumbItem={`${item.name} - Role Detail`}
              />
            )}
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.handlePopulateRole();
                      }}
                    >
                      Permission
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      item && (
                        <PermissionTab
                          item={item}
                          onSubmit={this.handleSubmitPermission}
                        />
                      )
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RoleDetail.propTypes = {
  message: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getRoleById: PropTypes.func,
  updateRole: PropTypes.func,
  clearRoleNotify: PropTypes.func,
};

const mapStateToProps = ({ role }) => {
  return role;
};

export default withRouter(
  connect(mapStateToProps, { getRoleById, updateRole, clearRoleNotify })(
    RoleDetail
  )
);
