import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    let currentDate = new Date();

    this.state = {
      id: NIL,
      invoiceNo: "",
      date: currentDate,
      dueDate: this.addDays(new Date(), 7),
      status: 0,
      totalAmount: 0,
      orderId: null,
      invoiceToUserId: null,
      note: "",

      submitted: false,
    };
  }

  addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  onOpened = () => {
    const { order } = this.props;

    if (order) {
      const { senderId, id, totalAmount } = order;
      this.setState({
        invoiceToUserId: senderId,
        orderId: id,
        totalAmount,
      });
    } else {
      this.setState({
        id: NIL,
        invoiceNo: "",
        date: null,
        dueDate: null,
        status: 0,
        totalAmount: 0,
        orderId: null,
        invoiceToUserId: null,
        note: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const {
      id,
      invoiceNo,
      date,
      dueDate,
      status,
      totalAmount,
      orderId,
      invoiceToUserId,
      note,
    } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = date;

    
    if (isValid) {
      const invoice = {
        id,
        invoiceNo,
        date,
        dueDate,
        status,
        totalAmount,
        orderId,
        invoiceToUserId,
        type: 0,
        note,
      };

      this.props.onSubmit(invoice);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, date } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="totalAmount">Total Amount</Label>
                <Input
                  type="number"
                  name="totalAmount"
                  placeholder="Invoice No"
                  value={this.state.totalAmount}
                  readOnly
                />
              </FormGroup>
              <FormGroup
                className={
                  "select2-container" +
                  (submitted && !date ? " is-invalid" : "")
                }
              >
                <Label>Date</Label>
                <DatePicker
                  className="form-control"
                  selectsStart
                  name="date"
                  selected={this.state.date}
                  onChange={date => this.handleDateChange(date, "date")}
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="Date"
                  autoComplete="off"
                  isClearable
                />
              </FormGroup>
              {submitted && !date && (
                <div className="invalid-feedback-custom">Date is required.</div>
              )}
              <FormGroup>
                <Label>Due Date</Label>
                <DatePicker
                  className="form-control"
                  selectsStart
                  name="dueDate"
                  selected={this.state.dueDate}
                  onChange={date => this.handleDateChange(date, "dueDate")}
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="Date"
                  autoComplete="off"
                  isClearable
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="invoiceNo">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  rows={3}
                  placeholder="Note..."
                  value={this.state.note}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  order: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
