import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import PatternFormat from "react-number-format";

class ShippingContactTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Contact</Th>
                        <Th>Phone number</Th>
                        <Th>Address</Th>
                        <Th style={{ width: "120px" }}>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {items.map((item, index) => {
                        const { phones } = item;
                        return (
                          <Tr
                            key={index}
                            onDoubleClick={() => onEdit(item.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <Td>{item.contact}</Td>
                            <Td>
                              {phones &&
                                phones.map((phone, index) => {
                                  return (
                                    <p key={index} className="mb-0 text-muted">
                                      <i className="fas fa-phone-alt me-2"></i>{" "}
                                      <PatternFormat
                                        value={phone.phoneNumber}
                                        displayType="text"
                                        format={"### ### ####"}
                                      />
                                    </p>
                                  );
                                })}
                            </Td>
                            <Td>{item.address}</Td>
                            <Td>
                              <div className="d-flex gap-3">
                                <Link className="text-primary" to="#">
                                  <i
                                    className="mdi mdi-pencil font-size-18"
                                    id="edittooltip"
                                    onClick={() => onEdit(item.id)}
                                  ></i>
                                </Link>
                                <Link className="text-danger" to="#">
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => onConfirmDelete(item.id)}
                                  ></i>
                                </Link>
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

ShippingContactTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ShippingContactTable;
