import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Card,
    CardHeader,
    CardImg, CardTitle,
    Col,
    Row,
    Input
} from "reactstrap";
import {

    getDeliveryStatusColor,
} from "../../helpers/utils";
import moment from "moment/moment";
import NumberFormat from "react-number-format";

const DeliveryOrderRowCard = ({ onRowCheck, ...props }) => {
    const { item, num } = props
    const {
        orderAttachments,
        shippingContactObject,


    } = item;
    const { contact, phones, address } = shippingContactObject;

    let orderAttachment = null;

    if (orderAttachments.length > 0) {
        orderAttachment = orderAttachments[0];
    }

    return (
        <Card className={`mb-2 border border-${getDeliveryStatusColor(item)}`}>
            <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
                <div style={{ width: "250px" }}>
                    {orderAttachment ? (
                        <CardImg
                            style={{ width: "250px", height: "200px", objectFit: "cover" }}
                            src={orderAttachment.url}
                            alt={orderAttachment.fileName}
                        />
                    ) : (
                        <div
                            style={{
                                width: "250px",
                                height: "200px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div className="avatar-order">
                                {item.transportationType === "Sea" ? (
                                    <i className="bx bxs-ship text-primary"></i>
                                ) : (
                                    <i className="bx bxs-plane text-primary"></i>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={"flex-grow-1"}>
                    <CardHeader className={`text-white bg-${getDeliveryStatusColor(item)}`}>
                        <Row style={{ cursor: 'pointer' }}>
                            <Col md={1}>
                                <Input
                                    name={`check_${num}`}
                                    onClick={onRowCheck}
                                    checked={item.isSelect}
                                    type="checkbox"
                                    readOnly
                                />
                            </Col>
                            <Col md={4}>
                                <CardTitle>Sender</CardTitle>
                            </Col>
                            <Col md={4}>

                                <CardTitle>Receiver and Shipping</CardTitle>
                            </Col>
                            <Col md={4}></Col>
                        </Row>

                    </CardHeader>
                    <div style={{ padding: "1.25rem" }}>
                        <Row>
                            <Col md={4}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <h5 className="font-size-14 mb-1">{item.sender}</h5>
                                    <div className="d-flex">
                                        <div className="right d-flex flex-column">
                                            <p className="text-muted mb-0">ID:</p>
                                            <p className="text-muted mb-0">Tracking No:</p>
                                            <p className="text-muted mb-0">Whs In Date:</p>
                                            <p className="text-muted mb-0">ETD:</p>
                                            <p
                                                className={
                                                    "mb-0 " +
                                                    [0, 1, 2, 8].includes(
                                                        //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                                        item.deliveryStatus
                                                            ? "text-danger"
                                                            : "text-muted"
                                                    )
                                                }
                                            >
                                                ETA:
                                            </p>
                                            {item.originalReference && (
                                                <p
                                                    className="text-muted text-truncate mb-0"
                                                    style={{ width: "120px" }}
                                                >
                                                    Orig Ref:
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{ marginLeft: "1rem" }}
                                        >
                                            <p className="text-muted mb-0">
                                                {item.senderUniqueId}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {item.trackingNumber}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {moment
                                                    .utc(item.createdDate)
                                                    .local()
                                                    .format("DD-MMM-yyyy h:mm:ss a")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {item.etd ? (
                                                    <>{moment(item.etd).format("DD-MMM-yyyy")}</>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </p>
                                            <p
                                                className={
                                                    "mb-0 " +
                                                    [0, 1, 2, 8].includes(
                                                        //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                                        item.deliveryStatus
                                                            ? "text-danger"
                                                            : "text-muted"
                                                    )
                                                }
                                            >
                                                {item.eta ? (
                                                    <>{moment(item.eta).format("DD-MMM-yyyy")}</>
                                                ) : (
                                                    <>N/A</>
                                                )}
                                            </p>
                                            {item.originalReference && (
                                                <p
                                                    className="text-muted text-truncate mb-0"
                                                    style={{ width: "120px" }}
                                                >
                                                    {item.originalReference}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className={"pe-0"}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {item.senderUniqueId !== item.receiverUniqueId ? (
                                        <h5 className="font-size-14 mb-1">
                                            {item.receiver}
                                        </h5>
                                    ) : (
                                        <h5 className="font-size-14 mb-1">{contact}</h5>
                                    )}
                                    <div className="d-flex">
                                        <div className="right d-flex flex-column">
                                            {item.senderUniqueId !==
                                                item.receiverUniqueId && (
                                                    <>
                                                        <p className="text-muted mb-0">ID:</p>
                                                        <p className="text-muted mb-0">Contact:</p>
                                                    </>
                                                )}
                                            <p className="text-muted mb-0">Phone:</p>
                                            <p className="text-muted mb-0">Address:</p>
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{ marginLeft: "1rem" }}
                                        >
                                            {item.senderUniqueId !==
                                                item.receiverUniqueId && (
                                                    <>
                                                        <p className="text-muted mb-0">
                                                            {item.receiverUniqueId}
                                                        </p>
                                                        <p className="text-muted mb-0">{contact}</p>
                                                    </>
                                                )}
                                            <p className="text-muted mb-0">
                                                {phones && phones.length > 0 ? (
                                                    phones.map((phone, index) => {
                                                        return (
                                                            <Badge
                                                                color="info"
                                                                className="p-1 me-1"
                                                                key={index}
                                                            >
                                                                <NumberFormat
                                                                    value={phone.phoneNumber}
                                                                    displayType="text"
                                                                    format={"### ### ####"}
                                                                />
                                                            </Badge>
                                                        );
                                                    })
                                                ) : (
                                                    <Badge color="info" className="p-1 me-1">
                                                        N/A
                                                    </Badge>
                                                )}
                                            </p>
                                            <p
                                                className="text-muted mb-0 text-truncate"
                                                style={{ width: "120px" }}
                                            >
                                                {address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className={"px-0"}>
                                <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                        <p className="text-muted mb-0">Quantity :</p>
                                    </div>
                                    <div
                                        className="d-flex flex-column"
                                        style={{ marginLeft: "1rem" }}
                                    >
                                        <p className="text-muted mb-0">
                                            {item.quantity} {item.unit}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>


            </CardHeader>

        </Card>
    );
};

DeliveryOrderRowCard.propTypes = {
    item: PropTypes.object,
};

export default DeliveryOrderRowCard;