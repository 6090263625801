import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ORDER_DOCUMENT_ADD_NEW,
  ORDER_DOCUMENT_DELETE,
  ORDER_DOCUMENT_GET_BY_ID,
  ORDER_DOCUMENT_GET_PAGING,
  ORDER_DOCUMENT_UPDATE,
} from './actionTypes';

import {
  addOrderDocumentSuccess,
  addOrderDocumentError,
  getPagingOrderDocumentSuccess,
  getPagingOrderDocumentError,
  getOrderDocumentByIdSuccess,
  getOrderDocumentByIdError,
  updateOrderDocumentSuccess,
  updateOrderDocumentError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// OrderDocument
const getPagingOrderDocumentRequest = query => get("/api/OrderDocument/paging", { params: query });
const addNewOrderDocumentRequest = data => post("/api/OrderDocument", data);
const getOrderDocumentByIdRequest = id => get(`/api/OrderDocument/${id}`);
const updateOrderDocumentRequest = data => update("/api/OrderDocument", data);
const deleteOrderDocumentRequest = id => del(`/api/OrderDocument/${id}`);

function* addNewOrderDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewOrderDocumentRequest, data);
    yield put(addOrderDocumentSuccess(response));

    response = yield call(getPagingOrderDocumentRequest, queryParams);
    yield put(getPagingOrderDocumentSuccess(response));
  } catch (error) {
    yield put(addOrderDocumentError(error.response.statusText));
  }
}

function* updateOrderDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateOrderDocumentRequest, data);
    yield put(updateOrderDocumentSuccess(response));

    response = yield call(getPagingOrderDocumentRequest, queryParams);
    yield put(getPagingOrderDocumentSuccess(response));
  } catch (error) {
    yield put(updateOrderDocumentError(error.response.statusText));
  }
}

function* getPagingOrderDocument({ payload }) {
  try {
    let response = yield call(getPagingOrderDocumentRequest, payload);
    yield put(getPagingOrderDocumentSuccess(response));
  } catch (error) {
    yield put(getPagingOrderDocumentError(error.response.statusText));
  }
}

function* getOrderDocumentById({ payload }) {
  try {
    let response = yield call(getOrderDocumentByIdRequest, payload);
    yield put(getOrderDocumentByIdSuccess(response));
  } catch (error) {
    yield put(getOrderDocumentByIdError(error.response.statusText));
  }
}

function* deleteOrderDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteOrderDocumentRequest, data);

    response = yield call(getPagingOrderDocumentRequest, queryParams);
    yield put(getPagingOrderDocumentSuccess(response));
  } catch (error) {
    yield put(getPagingOrderDocumentError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(ORDER_DOCUMENT_ADD_NEW, addNewOrderDocument);
  yield takeEvery(ORDER_DOCUMENT_UPDATE, updateOrderDocument);
  yield takeEvery(ORDER_DOCUMENT_GET_PAGING, getPagingOrderDocument);
  yield takeEvery(ORDER_DOCUMENT_GET_BY_ID, getOrderDocumentById);
  yield takeEvery(ORDER_DOCUMENT_DELETE, deleteOrderDocument);
}

export {
  getPagingOrderDocumentRequest
}