// PICKUP

export const GET_PICKUP = "GET_PICKUP";
export const GET_PICKUP_SUCCESS = "GET_PICKUP_SUCCESS";
export const GET_PICKUP_FAIL = "GET_PICKUP_FAIL";

export const GET_PICKUP_BY_ID = "GET_PICKUP_BY_ID";
export const GET_PICKUP_BY_ID_SUCCESS = "GET_PICKUP_BY_ID_SUCCESS";

export const ADD_NEW_PICKUP = "ADD_NEW_PICKUP";
export const ADD_NEW_PICKUP_SUCCESS = "ADD_NEW_PICKUP_SUCCESS";

export const UPDATE_PICKUP = "UPDATE_PICKUP";
export const UPDATE_PICKUP_SUCCESS = "UPDATE_PICKUP_SUCCESS";

export const APPROVE_PICKUP = "APPROVE_PICKUP";
export const APPROVE_PICKUP_SUCCESS = "APPROVE_PICKUP_SUCCESS";

export const REJECT_PICKUP = "REJECT_PICKUP";
export const REJECT_PICKUP_SUCCESS = "REJECT_PICKUP_SUCCESS";

export const DELETE_PICKUP = "DELETE_PICKUP";
export const DELETE_PICKUP_SUCCESS = "DELETE_PICKUP_SUCCESS";

export const PICKUP_CLEAR_NOTIFY = "PICKUP_CLEAR_NOTIFY";
