import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import NumberFormat from "react-number-format";

class DeliveryOrderItemTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isSelected: false,
    };
  }

  componentDidMount() {
    const { items } = this.props;
    this.setState({
      items: items.map(a => {
        return {
          ...a,
          isSelect: false,
        };
      }),
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props;

    if (items.length !== state.items.length) {
      return {
        items: items.map(a => {
          return {
            ...a,
            isSelect: false,
          };
        }),
      };
    }

    return null;
  }

  handleHeaderCheck = e => {
    const { checked } = e.target;
    this.setState(prevState => ({
      isHeaderCheck: checked,
      isSelected: checked,
      items: prevState.items.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      }),
    }));
  };

  handleRowCheck = (id, e) => {
    const { checked } = e.target;
    const { items } = this.state;
    let newItems = items.map(a => {
      if (a.id === id && checked) {
        return {
          ...a,
          isSelect: true,
        };
      } else if (a.id === id && !checked) {
        return {
          ...a,
          isSelect: false,
        };
      } else {
        return a;
      }
    });

    this.setState({
      isSelected: newItems.some(a => a.isSelect),
      items: newItems,
    });
  };

  handleOnRemove = () => {
    const { onRemove } = this.props;
    const { items } = this.state;
    let selectedOrderIds = items
      .filter(e => e.isSelect)
      .map(a => {
        return a.id;
      });

    onRemove(selectedOrderIds);
  };

  render() {
    const { items } = this.state;
    const {
      isReceive,
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      loading,
      onAddNew,
      onVerifyOrder,
      onChangePage,
      onPageSizeChange,
      deliveryOrderStatus,
    } = this.props;
    return (
      <>
        <Card className="mb-1 rounded-3">
          <CardHeader className="bg-transparent border pb-0 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={10}>
                {deliveryOrderStatus === 0 && (
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => onAddNew()}
                    >
                      <i className="fas fa-plus" /> Add Order
                    </Button>
                    <Button
                      type="button"
                      outline
                      disabled={!this.state.isSelected}
                      color="danger"
                      style={{ marginLeft: "5px" }}
                      onClick={this.handleOnRemove}
                    >
                      <i className="fas fa-trash" /> Remove
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="table-responsive"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered table-nowrap align-middle"
                  >
                    <thead>
                      <tr>
                        {!isReceive && (
                          <th>
                            <Input
                              type="checkbox"
                              checked={this.state.isHeaderCheck}
                              onClick={this.handleHeaderCheck}
                              readOnly
                            />
                          </th>
                        )}
                        <th>#</th>
                        <th>Type</th>
                        <th>Sender</th>
                        <th>Receiver and Shipping</th>
                        <th>Quantity</th>
                        {isReceive && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {items.length > 0 &&
                        items.map((item, index) => {
                          let num =
                            (currentPage - 1) * defaultPageSize + (index + 1);
                          const { shippingContactObject } = item;
                          const { phones, address, contact } =
                            shippingContactObject;
                          return (
                            <tr key={index}>
                              {!isReceive && (
                                <td>
                                  <Input
                                    name={`check_${num}`}
                                    onClick={e =>
                                      this.handleRowCheck(item.id, e)
                                    }
                                    checked={item.isSelect}
                                    type="checkbox"
                                    readOnly
                                  />
                                </td>
                              )}
                              <td>{num}</td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <div className="avatar-order">
                                    {item.transportationType === "Sea" ? (
                                      <i className="bx bxs-ship"></i>
                                    ) : (
                                      <i className="bx bxs-plane"></i>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p className="mb-1">
                                    <strong>{item.sender}</strong>
                                  </p>
                                  <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                      <p className="text-muted mb-0">ID:</p>
                                      <p className="text-muted mb-0">
                                        Tracking No:
                                      </p>
                                      <p className="text-muted mb-0">
                                        Whs In Date:
                                      </p>
                                      <p className="text-muted mb-0">ETD:</p>
                                      <p
                                        className={
                                          "mb-0 " +
                                          [0, 1, 2, 8].includes(
                                            //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                            item.deliveryStatus
                                              ? "text-danger"
                                              : "text-muted"
                                          )
                                        }
                                      >
                                        ETA:
                                      </p>
                                      {item.originalReference && (
                                        <p className="text-muted mb-0">
                                          Orig Ref:
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      <p className="text-muted mb-0">
                                        {item.senderUniqueId}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {item.trackingNumber}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {moment(item.createdDate).format(
                                          "DD-MMM-yyyy h:mm:ss a"
                                        )}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {item.etd ? (
                                          <>
                                            {moment(item.etd).format(
                                              "DD-MMM-yyyy"
                                            )}
                                          </>
                                        ) : (
                                          <>N/A</>
                                        )}
                                      </p>
                                      <p
                                        className={
                                          "mb-0 " +
                                          [0, 1, 2, 8].includes(
                                            //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                            item.deliveryStatus
                                              ? "text-danger"
                                              : "text-muted"
                                          )
                                        }
                                      >
                                        {item.eta ? (
                                          <>
                                            {moment(item.eta).format(
                                              "DD-MMM-yyyy"
                                            )}
                                          </>
                                        ) : (
                                          <>N/A</>
                                        )}
                                      </p>
                                      {item.originalReference && (
                                        <p className="text-muted mb-0">
                                          {item.originalReference}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item.senderUniqueId !==
                                  item.receiverUniqueId ? (
                                    <p className="mb-1">
                                      <strong>{item.receiver}</strong>
                                    </p>
                                  ) : (
                                    <p className="mb-1">
                                      <strong>{contact}</strong>
                                    </p>
                                  )}
                                  <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                      {item.senderUniqueId !==
                                        item.receiverUniqueId && (
                                        <>
                                          <p className="text-muted mb-0">ID:</p>
                                          <p className="text-muted mb-0">
                                            Contact:
                                          </p>
                                        </>
                                      )}
                                      <p className="text-muted mb-0">Phone:</p>
                                      <p className="text-muted mb-0">
                                        Address:
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      {item.senderUniqueId !==
                                        item.receiverUniqueId && (
                                        <>
                                          <p className="text-muted mb-0">
                                            {item.receiverUniqueId}
                                          </p>
                                          <p className="text-muted mb-0">
                                            {contact}
                                          </p>
                                        </>
                                      )}
                                      <p className="text-muted mb-0">
                                        {phones && phones.length > 0 ? (
                                          phones.map((phone, index) => {
                                            return (
                                              <Badge
                                                color="info"
                                                className="p-1 me-1"
                                                key={index}
                                              >
                                                <NumberFormat
                                                  value={phone.phoneNumber}
                                                  displayType="text"
                                                  format={"### ### ####"}
                                                />
                                              </Badge>
                                            );
                                          })
                                        ) : (
                                          <Badge
                                            color="info"
                                            className="p-1 me-1"
                                          >
                                            N/A
                                          </Badge>
                                        )}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {address}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item.quantity} {item.unit}
                              </td>
                              {isReceive && (
                                <td>
                                  <div className="text-center">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        // className="card-drop"
                                        tag="a"
                                      >
                                        <i className="mdi mdi-dots-horizontal font-size-18" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                          onClick={() => onVerifyOrder(item.id)}
                                        >
                                          <i className="fas fa-check-circle font-size-16 text-success me-1" />{" "}
                                          Verify Order
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

DeliveryOrderItemTable.propTypes = {
  isReceive: PropTypes.bool,
  transportStatus: PropTypes.number,
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onAddNew: PropTypes.func,
  onRemove: PropTypes.func,
  onVerifyOrder: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  deliveryOrderStatus: PropTypes.number,
};

export default DeliveryOrderItemTable;
