import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from "reactstrap";

const TooltipDocument = props => {
  const { document } = props;
  const { documentTypeName, fileName, url } = document;
  const [ isOpen, setIsOpen ] = useState(false)
  
  return (
      <>
        <a href={url}
           className="font-size-24 text-white ps-2 pe-2"
           id={`download-${document.id}`} 
           download={fileName} 
           onClick={(e)=> {
          console.log(e);
        }}>
          <i className="mdi mdi-download"></i>
        </a>
        <Tooltip
            placement="bottom"
            isOpen={isOpen}
            target={`download-${document.id}`}
            toggle={()=> setIsOpen(!isOpen)}
        >
          <div>
            {documentTypeName}
          </div>
        </Tooltip>
      </>
     
  );
};

TooltipDocument.propTypes = {
  
};

export default TooltipDocument;