import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_BY_ID,
  GET_ORDER_BY_ID_SUCCESS,
  ADD_NEW_ORDER,
  UPDATE_ORDER,
  SUBMIT_ORDER_DELIVERY_STATUS,
  // UPDATE_ORDER_ATTACHMENT,
  // DELETE_ORDER_ATTACHMENT,
  DELETE_ORDER,
  ORDER_CLEAR_ERROR,
  SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS,
} from "./actionTypes";

// ORDER
export const getOrders = query => ({
  type: GET_ORDERS,
  payload: query,
});

export const getOrdersSuccess = items => ({
  type: GET_ORDERS_SUCCESS,
  payload: items,
});

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
});

export const getOrderById = id => ({
  type: GET_ORDER_BY_ID,
  payload: id,
});

export const getOrderByIdSuccess = item => ({
  type: GET_ORDER_BY_ID_SUCCESS,
  payload: item,
});

export const getOrderByIdFail = error => ({
  type: GET_ORDER_BY_ID_FAIL,
  payload: error,
});

export const addNewOrder = item => ({
  type: ADD_NEW_ORDER,
  payload: item,
});

export const updateOrder = item => ({
  type: UPDATE_ORDER,
  payload: item,
});

export const submitOrderDeliveryStatus = item => ({
  type: SUBMIT_ORDER_DELIVERY_STATUS,
  payload: item,
});

export const submitOrderDeliveryStatusSuccess = item => ({
  type: SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS,
  payload: item,
});

export const deleteOrder = item => ({
  type: DELETE_ORDER,
  payload: item,
});

export const clearOrderError = () => ({
  type: ORDER_CLEAR_ERROR,
});
