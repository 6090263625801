import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";

import {
  getInvoiceDetail,
  getInvoicePayment,
  getInvoiceNote,
} from "store/actions";
import InvoiceDetailInfoTab from "./InvoiceDetailInfoTab";
import InvoiceNoteTab from "./InvoiceNoteTab";
import InvoicePaymentTab from "./InvoicePaymentTab";

export class DoorToDoorInvoiceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      userId: null,
      activeTab: "1",
      invoicePaymentCount: null,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match, getInvoiceDetail, getInvoicePayment, getInvoiceNote } =
      this.props;
    const { id, userId } = match.params;

    if (id !== NIL) {
      this.setState({
        id,
        userId,
      });
      getInvoiceDetail(id);
      getInvoicePayment({ invoiceId: id });
      getInvoiceNote({ invoiceId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { match, getInvoiceDetail } = props;
    const { id } = match.params;
    if (id !== state.id) {
      getInvoiceDetail(id);
      getInvoicePayment({ invoiceId: id });
      getInvoiceNote({ invoiceId: id });
      return id;
    }
    return null;
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push("/transport");
  };

  handleOnBackToList = () => {
    const { history } = this.props;
    history.push("/transport");
  };

  handlePopulatePayment = () => {
    const { id } = this.state;
    const { getInvoicePayment } = this.props;
    getInvoicePayment({ invoiceId: id });
  };

  handlePopulateInvoieNote = () => {
    const { id } = this.state;
    const { getInvoiceNote } = this.props;
    getInvoiceNote({ invoiceId: id });
  };

  handlePopulateInvoieDetail = () => {
    const { id } = this.state;
    this.props.getInvoiceDetail(id);
  };

  render() {
    const { userId } = this.state;
    const { invoice, invoicePayment, invoiceNote } = this.props;
    const { item, loading } = invoice;
    let invoiceNo = item?.invoiceNo ?? "";

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Invoice Detail | JLS Express</title>
          </MetaTags>
          <Container fluid>
            {userId ? (
              <Breadcrumbs
                title="Home"
                backLink={`/user/${userId}/4`}
                breadcrumbItem={`${invoiceNo} - Invoice Detail`}
              />
            ) : (
              <Breadcrumbs
                title="Home"
                backLink={"/door-to-door-invoice"}
                breadcrumbItem={`${invoiceNo} - Invoice Detail`}
              />
            )}
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.handlePopulateInvoieDetail();
                      }}
                    >
                      Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                        this.handlePopulatePayment();
                      }}
                    >
                      Payment
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {invoicePayment.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggle("3");
                        this.handlePopulateInvoieNote();
                      }}
                    >
                      Notes
                      {item && (
                        <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                          {invoiceNote.itemCount}
                        </span>
                      )}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {item && (
                          <InvoiceDetailInfoTab
                            item={item}
                            onSubmit={this.handleOnSubmit}
                          />
                        )}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.id && this.state.id !== NIL && (
                      <InvoicePaymentTab invoiceId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    {this.state.id && this.state.id !== NIL && (
                      <InvoiceNoteTab invoiceId={this.state.id} />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

DoorToDoorInvoiceDetail.propTypes = {
  invoice: PropTypes.object,
  invoicePayment: PropTypes.object,
  invoiceNote: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getInvoiceDetail: PropTypes.func,
  getInvoicePayment: PropTypes.func,
  getInvoiceNote: PropTypes.func,
};

const mapStateToProps = ({ invoice, invoicePayment, invoiceNote }) => {
  return {
    invoice,
    invoicePayment,
    invoiceNote,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoiceDetail,
    getInvoicePayment,
    getInvoiceNote,
  })(DoorToDoorInvoiceDetail)
);
