import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALERT_NOTIFICATION,
  ADD_NEW_ALERT_NOTIFICATION,
  GET_ALERT_NOTIFICATION_BY_ID,
  UPDATE_ALERT_NOTIFICATION,
  DELETE_ALERT_NOTIFICATION,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getAlertNotificationSuccess,
  getAlertNotificationByIdSuccess,
  getAlertNotificationFail,
  addNewAlertNotificationSuccess,
  updateAlertNotificationSuccess,
  deleteAlertNotificationSuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/AlertNotification`);
const getAlertNotificationRequest = query =>
  get(`/api/AlertNotification/paging`, { params: query });

const addNewAlertNotificationRequest = item =>
  post(`/api/AlertNotification`, item);
const getAlertNotificationByIdRequest = id =>
  get(`/api/AlertNotification/${id}`);
const updateAlertNotificationRequest = item =>
  update(`/api/AlertNotification`, item);
const deleteAlertNotificationRequest = id =>
  del(`/api/AlertNotification/${id}`);

function* fetchAlertNotifications({ payload }) {
  try {
    const response = yield call(getAlertNotificationRequest, payload);

    yield put(getAlertNotificationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationFail(error));
  }
}

function* addNewAlertNotification({ payload }) {
  try {
    yield call(addNewAlertNotificationRequest, payload);
    const response = yield call(getAlertNotificationRequest);
    yield put(getAlertNotificationSuccess(response));
    yield put(addNewAlertNotificationSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationFail(error));
  }
}

function* updateAlertNotification({ payload }) {
  try {
    yield call(updateAlertNotificationRequest, payload);
    const response = yield call(getAlertNotificationRequest);
    yield put(getAlertNotificationSuccess(response));
    yield put(updateAlertNotificationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationFail(error));
  }
}

function* getAlertNotificationById({ payload }) {
  try {
    var response = yield call(getAlertNotificationByIdRequest, payload);
    yield put(getAlertNotificationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationFail(error));
  }
}

function* deleteAlertNotification({ payload }) {
  try {
    yield call(deleteAlertNotificationRequest, payload);
    const response = yield call(getAlertNotificationRequest);
    yield put(getAlertNotificationSuccess(response));
    yield put(deleteAlertNotificationSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAlertNotificationFail(error));
  }
}

function* AlertNotificationSaga() {
  yield takeEvery(GET_ALERT_NOTIFICATION, fetchAlertNotifications);
  yield takeEvery(ADD_NEW_ALERT_NOTIFICATION, addNewAlertNotification);
  yield takeEvery(GET_ALERT_NOTIFICATION_BY_ID, getAlertNotificationById);
  yield takeEvery(UPDATE_ALERT_NOTIFICATION, updateAlertNotification);
  yield takeEvery(DELETE_ALERT_NOTIFICATION, deleteAlertNotification);
}

export default AlertNotificationSaga;

export { getDeliveryAgenciesRequest };
