// USER_VEHICLE
export const USER_VEHICLE_ADD_NEW = 'USER_VEHICLE_ADD_NEW';
export const USER_VEHICLE_ADD_NEW_SUCCESS = 'USER_VEHICLE_ADD_NEW_SUCCESS';
export const USER_VEHICLE_ADD_NEW_ERROR = 'USER_VEHICLE_ADD_NEW_ERROR';

export const USER_VEHICLE_GET_PAGING = 'USER_VEHICLE_GET_PAGING';
export const USER_VEHICLE_GET_PAGING_SUCCESS = 'USER_VEHICLE_GET_PAGING_SUCCESS';
export const USER_VEHICLE_GET_PAGING_ERROR = 'USER_VEHICLE_GET_PAGING_ERROR';

export const USER_VEHICLE_GET_BY_ID = 'USER_VEHICLE_GET_BY_ID';
export const USER_VEHICLE_GET_BY_ID_SUCCESS = 'USER_VEHICLE_GET_BY_ID_SUCCESS';
export const USER_VEHICLE_GET_BY_ID_ERROR = 'USER_VEHICLE_GET_BY_ID_ERROR';

export const USER_VEHICLE_UPDATE = 'USER_VEHICLE_UPDATE';
export const USER_VEHICLE_UPDATE_SUCCESS = 'USER_VEHICLE_UPDATE_SUCCESS';
export const USER_VEHICLE_UPDATE_ERROR = 'USER_VEHICLE_UPDATE_ERROR';

export const USER_VEHICLE_DELETE = 'USER_VEHICLE_DELETE';
export const USER_VEHICLE_DELETE_SUCCESS = 'USER_VEHICLE_DELETE_SUCCESS';
export const USER_VEHICLE_DELETE_ERROR = 'USER_VEHICLE_DELETE_ERROR';
