import {
  GET_DELIVERY_TO_DOOR,
  GET_DELIVERY_TO_DOOR_SUCCESS,
  GET_DELIVERY_TO_DOOR_FAIL,
  GET_DELIVERY_TO_DOOR_BY_ID,
  GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_TO_DOOR,
  ADD_NEW_DELIVERY_TO_DOOR_SUCCESS,
  UPDATE_DELIVERY_TO_DOOR,
  UPDATE_DELIVERY_TO_DOOR_SUCCESS,
  DELETE_DELIVERY_TO_DOOR,
  DELETE_DELIVERY_TO_DOOR_SUCCESS,
  DELIVERY_TO_DOOR_CLEAR_NOTIFY,
  APPROVE_DELIVERY_TO_DOOR,
  APPROVE_DELIVERY_TO_DOOR_SUCCESS,
  REJECT_DELIVERY_TO_DOOR,
  REJECT_DELIVERY_TO_DOOR_SUCCESS,
} from "./actionTypes";

// DeliveryToDoor
export const getDeliveryToDoor = id => ({
  type: GET_DELIVERY_TO_DOOR,
  payload: id,
});

export const getDeliveryToDoorSuccess = payload => ({
  type: GET_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const getDeliveryToDoorFail = error => ({
  type: GET_DELIVERY_TO_DOOR_FAIL,
  payload: error,
});

export const getDeliveryToDoorById = data => ({
  type: GET_DELIVERY_TO_DOOR_BY_ID,
  payload: data,
});

export const getDeliveryToDoorByIdSuccess = payload => ({
  type: GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS,
  payload,
});

export const getDeliveryToDoorByIdFail = error => ({
  type: GET_DELIVERY_TO_DOOR_BY_ID_FAIL,
  payload: error,
});

export const addNewDeliveryToDoor = payload => ({
  type: ADD_NEW_DELIVERY_TO_DOOR,
  payload,
});

export const addNewDeliveryToDoorSuccess = payload => ({
  type: ADD_NEW_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const updateDeliveryToDoor = payload => ({
  type: UPDATE_DELIVERY_TO_DOOR,
  payload,
});

export const updateDeliveryToDoorSuccess = payload => ({
  type: UPDATE_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const deleteDeliveryToDoor = payload => ({
  type: DELETE_DELIVERY_TO_DOOR,
  payload,
});
export const deleteDeliveryToDoorSuccess = payload => ({
  type: DELETE_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const getApproveDeliveryToDoorById = data => ({
  type: APPROVE_DELIVERY_TO_DOOR,
  payload: data,
});

export const getApproveDeliveryToDoorByIdSuccess = payload => ({
  type: APPROVE_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const getRejectDeliveryToDoorById = data => ({
  type: REJECT_DELIVERY_TO_DOOR,
  payload: data,
});

export const getRejectDeliveryToDoorByIdSuccess = payload => ({
  type: REJECT_DELIVERY_TO_DOOR_SUCCESS,
  payload,
});

export const clearDeliveryToDoorNotify = () => ({
  type: DELIVERY_TO_DOOR_CLEAR_NOTIFY,
});
