import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from "prop-types";
import SettingForm from "containers/settings/SettingForm";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import {
  addNewSetting,
  getDefaultSetting,
  clearSettingNotify,
} from "../../store/settings/actions";
import { toastMessage } from "helpers/utils";
import CustomerServiceInfoForm from "./CustomerServiceInfoForm";

export class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      firstName: null,
      lastName: null,
      env: "",
      envData: "",
    };
  }

  componentDidMount() {
    this.props.getDefaultSetting();
  }

  componentDidUpdate() {
    const { message, clearSettingNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearSettingNotify();
    }
  }

  handleSubmit = item => {
    this.props.addNewSetting(item);
  };

  render() {
    const { item, loading } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Settings | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Settings" />
            <CustomerServiceInfoForm />

            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {item ? (
                  <SettingForm item={item} onSubmit={this.handleSubmit} />
                ) : (
                  <SettingForm onSubmit={this.handleSubmit} />
                )}
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Settings.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  addNewSetting: PropTypes.func,
  getDefaultSetting: PropTypes.func,
  clearSettingNotify: PropTypes.func,
};

const mapStateToProps = ({ setting }) => {
  return setting;
};

export default withRouter(
  connect(mapStateToProps, {
    addNewSetting,
    getDefaultSetting,
    clearSettingNotify,
  })(Settings)
);
