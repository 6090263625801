import {
  STOCK_IN_ADD_NEW,
  STOCK_IN_ADD_NEW_ERROR,
  STOCK_IN_ADD_NEW_SUCCESS,
  STOCK_IN_DELETE,
  STOCK_IN_DELETE_ERROR,
  STOCK_IN_DELETE_SUCCESS,
  STOCK_IN_GET_BY_ID,
  STOCK_IN_GET_BY_ID_ERROR,
  STOCK_IN_GET_BY_ID_SUCCESS,
  STOCK_IN_GET_PAGING,
  STOCK_IN_GET_PAGING_ERROR,
  STOCK_IN_GET_PAGING_SUCCESS,
  STOCK_IN_UPDATE,
  STOCK_IN_UPDATE_ERROR,
  STOCK_IN_UPDATE_SUCCESS,
} from './actionType';

// StockIn
export const getPagingStockIn = items => ({
  type: STOCK_IN_GET_PAGING,
  payload: items,
});

export const getPagingStockInSuccess = items => ({
  type: STOCK_IN_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStockInError = error => ({
  type: STOCK_IN_GET_PAGING_ERROR,
  payload: error,
});

export const addStockIn = items => ({
  type: STOCK_IN_ADD_NEW,
  payload: items,
});

export const addStockInSuccess = items => ({
  type: STOCK_IN_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStockInError = error => ({
  type: STOCK_IN_ADD_NEW_ERROR,
  payload: error,
});

export const getStockInById = items => ({
  type: STOCK_IN_GET_BY_ID,
  payload: items,
});

export const getStockInByIdSuccess = items => ({
  type: STOCK_IN_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStockInByIdError = error => ({
  type: STOCK_IN_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStockIn = items => ({
  type: STOCK_IN_UPDATE,
  payload: items,
});

export const updateStockInSuccess = items => ({
  type: STOCK_IN_UPDATE_SUCCESS,
  payload: items,
});

export const updateStockInError = error => ({
  type: STOCK_IN_UPDATE_ERROR,
  payload: error,
});

export const deleteStockIn = items => ({
  type: STOCK_IN_DELETE,
  payload: items,
});

export const deleteStockInSuccess = items => ({
  type: STOCK_IN_DELETE_SUCCESS,
  payload: items,
});

export const deleteStockInError = error => ({
  type: STOCK_IN_DELETE_ERROR,
  payload: error,
});
