import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { getShippingContactRequest } from "store/shipping-contact/saga";
import { debounce } from "lodash";

export class SelectionListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: 0,
      pageCount: 0,
      selectedItem: null,
      items: null,
    };
  }

  debouncedFetchData = debounce(term => {
    const { userId } = this.props;
    if (userId) {
      getShippingContactRequest({ userId, term }).then(res => {
        const { itemCount, pageCount, data } = res;
        this.setState({
          selectedItem: null,
          itemCount,
          pageCount,
          items: data.map(user => {
            return {
              ...user,
              isSelect: false,
            };
          }),
        });
      });
    }
  }, 1000);

  handleSelect = item => {
    this.setState(prevState => ({
      selectedItem: item,
      items: prevState.items.map(a => {
        return a.id === item.id
          ? { ...a, isSelect: true }
          : { ...a, isSelect: false };
      }),
    }));
  };

  onOpened = () => {
    const { userId } = this.props;
    if (userId) {
      getShippingContactRequest({ userId, term: "" }).then(res => {
        if (res) {
          const { itemCount, pageCount, data } = res;
          this.setState({
            selectedItem: null,
            itemCount,
            pageCount,
            items: data.map(user => {
              return {
                ...user,
                isSelect: false,
              };
            }),
          });
        }
      });
    }
  };

  handleFilter = e => {
    const { value } = e.target;
    this.debouncedFetchData(value);
  };

  handleSubmit = () => {
    const { selectedItem } = this.state;

    if (selectedItem) {
      this.props.onSubmit(selectedItem);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { items, selectedItem } = this.state;
    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input
                  type="text"
                  name="search"
                  placeholder="Search..."
                  onKeyUp={e => this.handleFilter(e)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered"
              >
                <Thead>
                  <Tr>
                    <Th>Receiver</Th>
                    <Th>Phone</Th>
                    <Th>Address</Th>
                    <Th style={{ width: "120px" }}>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items &&
                    items.map((item, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{item.contact}</Td>
                          <Td>{item.phone}</Td>
                          <Td>{item.address}</Td>
                          <Td>
                            <Button
                              size="sm"
                              outline={!item.isSelect}
                              color="primary"
                              onClick={() => this.handleSelect(item)}
                            >
                              {item.isSelect ? "Selected" : "Select"}
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            disabled={selectedItem === null}
            onClick={this.handleSubmit}
            type="submit"
          >
            Select
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

SelectionListModal.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  userId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default SelectionListModal;
