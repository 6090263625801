import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDERS,
  ADD_NEW_ORDER,
  GET_ORDER_BY_ID,
  UPDATE_ORDER,
  DELETE_ORDER,
  SUBMIT_ORDER_DELIVERY_STATUS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrdersSuccess,
  getOrderByIdSuccess,
  getOrdersFail,
  submitOrderDeliveryStatusSuccess,
} from "./actions";
import { orderService } from "services/order-service";
import axios from "axios";
// Order

let currentCancelToken;

const getOrdersRequest = data => {
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  currentCancelToken = axios.CancelToken.source();
  
  return post("/api/Order/pagingPortal", data, {
    cancelToken: currentCancelToken.token
  });
};

const addNewOrderRequest = role => post("/api/Order", role);
const updateOrderRequest = role => update("/api/Order", role);
const deleteOrderRequest = id => del(`/api/Order/${id}`);

function* fetchOrders({ payload }) {
  try {
    const response = yield call(getOrdersRequest, payload);
    yield put(getOrdersSuccess(response));
  } catch (err) {
    // const error = err?.response?.data?.message ?? "Something has gone wrong.";
    // yield put(getOrdersFail(error));
  }
}

function* addNewOrder({ payload }) {
  try {
    const { data, history } = payload;
    let response = yield call(addNewOrderRequest, data);
    history.push(`/order/${response}`);
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrdersFail(error));
  }
}

function* updateOrder({ payload }) {
  try {
    const { data } = payload;
    let response = yield call(updateOrderRequest, data);
    response = yield call(orderService.getOrderByIdRequest, response);
    yield put(getOrderByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrdersFail(error));
  }
}

function* submitOrderDeliveryStatus({ payload }) {
  try {
    const { id, deliveryStatus } = payload;
    let response = yield call(orderService.submitOrderDeliveryStatusRequest, {
      id,
      deliveryStatus,
    });

    response = yield call(orderService.getOrderByIdRequest, id);
    yield put(submitOrderDeliveryStatusSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrdersFail(error));
  }
}

function* getOrderById({ payload }) {
  try {
    var response = yield call(orderService.getOrderByIdRequest, payload);
    yield put(getOrderByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrdersFail(error));
  }
}

function* deleteOrder({ payload }) {
  try {
    yield call(deleteOrderRequest, payload);
    const response = yield call(getOrdersRequest, {});
    yield put(getOrdersSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrdersFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders);
  yield takeEvery(ADD_NEW_ORDER, addNewOrder);
  yield takeEvery(GET_ORDER_BY_ID, getOrderById);
  yield takeEvery(UPDATE_ORDER, updateOrder);
  yield takeEvery(SUBMIT_ORDER_DELIVERY_STATUS, submitOrderDeliveryStatus);
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

export default roleSaga;

export { getOrdersRequest };
