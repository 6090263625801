import { get, post, del, put as update } from "helpers/api_helper";

const getUsernotificationRequest = async query => {
  return await get(`/api/UserNotification/paging`, { params: query });
};

const updateUsernotificationRequest = async query => {
  return await post(`/api/UserNotification/updateToSent`, query);
};

const readUsernotificationRequest = async id => {
  return await get(`/api/UserNotification/read/${id}`);
};

export const notificationService = {
  getUsernotificationRequest,
  updateUsernotificationRequest,
  readUsernotificationRequest,
};
