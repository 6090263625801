import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  Input,
  Button,
  FormGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  Collapse,
} from "reactstrap";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ConfirmationModal from "components/Common/ConfirmationModal";
import { addOrderToTransport } from "store/actions";
import {
  getOrders,
  addNewOrder,
  updateOrder,
  getOrderById,
  deleteOrder,
  clearOrderError,
  submitOrderDeliveryStatus,
} from "../../store/order/actions";
import { addNewInvoice, clearInvoiceNotify } from "store/actions";
import PrintPreviewModal from "./PrintPreviewModal";
import InvoiceModalForm from "containers/invoice/ModalForm";
import { orderService } from "services/order-service";
import {
  timestampFileName,
  hasAccess,
  pickupPaymentStatusOptions,
  shippingPaymentStatusOptions,
  deliveryPaymentStatusOptions,
  toastMessage,
  getCompanyName,
} from "helpers/utils";
import { permission } from "constants/permission";
import OrderNoteForm from "containers/order/OrderNoteForm";
import { addNewOrderNote } from "store/actions";
import debounce from "lodash.debounce";
import OrderCardTable from "../../containers/order/OrderTable";
import TransportSelectionModal from "../../containers/transport/TransportSelectionModal";
import { withSettings } from "../../hook/withSettings";

const deliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Korea Warehouse" },
  { key: 2, value: 2, label: "Delivery To Cambodia" },
  { key: 3, value: 3, label: "In Cambodia Warehouse" },
  { key: 4, value: 4, label: "Delivery To Customer" },
  { key: 5, value: 5, label: "Completed" },
  { key: 6, value: 6, label: "Rejected" },
  { key: 7, value: 7, label: "Closed" },
];

export class OrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedId: null,
      modalAdd: false,
      currentPage: 1,
      pageSize: 50,
      modalConfirmDeleteIsOpen: false,
      modalPrintPreview: false,
      modalConfirmOnSubmitToWarehouse: false,
      modalInvoice: false,
      term: "",
      startDate: null,
      endDate: null,
      companyId: null,

      deliveryStatus: null,
      pickupPaymentStatus: null,
      shippingPaymentStatus: null,
      deliveryPaymentStatus: null,
      isUserRequestedDelivery: false,
      isPickup: false,
      order: null,
      moreActionIsOpen: false,
      isOpen: false,
      modalAddNote: false,
      myOrder: false,
      modalTransportSelectionIsOpen: false,
      orderId: null
    };
  }

  debouncedFetchData = debounce(term => {
    this.fetchData(term);
  }, 500);

  toggleModalAddNote = () => {
    this.setState(prevState => ({
      modalAddNote: !prevState.modalAddNote,
    }));
  };

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };


  toggleMoreAction = () => {
    this.setState(prevState => ({
      moreActionIsOpen: !prevState.moreActionIsOpen,
    }));
  };

  toggleConfirmOnSubmitToWarehouse = () => {
    this.setState(prevState => ({
      modalConfirmOnSubmitToWarehouse:
          !prevState.modalConfirmOnSubmitToWarehouse,
    }));
  };

  toggleModalInvoice = () => {
    this.setState(prevState => ({
      modalInvoice: !prevState.modalInvoice,
    }));
  };

  toggleModalPrintPreview = () => {
    this.setState(prevState => ({
      modalPrintPreview: !prevState.modalPrintPreview,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  handleOnAddNew = () => {
    const { history } = this.props;
    history.push("/order/add-new");
  };

  componentDidMount = () => {
    const { pageSize, company } = this.state;
    const { getOrders } = this.props;
    let myOrder = hasAccess(permission.myOrder.enable);
    getOrders({ term: "", pageSize, myOrder });

    this.setState({
      myOrder,
    });
  };

  handleOnEdit = id => {
    const { history } = this.props;
    history.push(`/order/${ id }`);
  };

  componentDidUpdate() {
    const { invoice, order } = this.props;

    toastr.options = {
      timeOut: 5000,
    };

    if (invoice.message) {
      toastMessage(invoice.message);
      this.props.clearInvoiceNotify();
    }

    if (invoice.error) {
      toastr.error(invoice.error, "Error");
      this.props.clearInvoiceNotify();
    }

    if (order.error) {
      toastr.error(order.error, "Error");
      this.props.clearOrderError();
    }
  }

  handleConfirmDelete = id => {
    this.setState({
      selectedId: id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;

    this.setState({
      term: value,
      currentPage: 1,
    });

    this.debouncedFetchData(value);
  };

  fetchData = term => {
    const { getOrders } = this.props;
    const { pageSize, myOrder, isPickup } = this.state;
    getOrders({ term: term ?? "", page: 1, pageSize, myOrder, isPickup });
  };

  static getDerivedStateFromProps(props, state) {
    const { company } = props;
    const { companyId, pageSize } = state;

    if (company) {
      if (companyId !== company.id) {
        console.log("Company Id", company.id)
        // props.getOrders({
        //   companyId: company.id,
        //   pageSize
        // })
      }
    }

    return {
      ...state,
      companyId: company?.id
    };
  }

  handleSearch = () => {
    const { getOrders } = this.props;
    this.setState({
      currentPage: 1
    });

    const {
      isPickup,
      pageSize,
      term,
      startDate,
      endDate,
      deliveryStatus,
      pickupPaymentStatus,
      shippingPaymentStatus,
      deliveryPaymentStatus,
      myOrder,
      isUserRequestedDelivery,
    } = this.state;

    getOrders({
      isPickup,
      term: term ?? "",
      page: 1,
      pageSize,
      myOrder,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      deliveryStatus:
          deliveryStatus &&
          deliveryStatus.map(a => {
            return a.value;
          }),
      pickupPaymentStatus:
          pickupPaymentStatus &&
          pickupPaymentStatus.map(a => {
            return a.value;
          }),
      shippingPaymentStatus:
          shippingPaymentStatus &&
          shippingPaymentStatus.map(a => {
            return a.value;
          }),
      deliveryPaymentStatus:
          deliveryPaymentStatus &&
          deliveryPaymentStatus.map(a => {
            return a.value;
          }),
      isUserRequestedDelivery,
    });
  };

  handleDelete = () => {
    const { selectedId } = this.state;
    this.props.deleteOrder(selectedId);
    this.setState({
      modalConfirmDeleteIsOpen: false,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { myOrder, term } = this.state;
    const { getOrders } = this.props;
    getOrders({ term, page: 1, pageSize, myOrder });

    this.setState({
      pageSize,
      currentPage: 1,
    });
  };

  handleOnPageChange = page => {
    const {
      pageSize,
      term,
      startDate,
      endDate,
      deliveryStatus,
      pickupPaymentStatus,
      shippingPaymentStatus,
      deliveryPaymentStatus,
      myOrder,
      isPickup,
    } = this.state;

    const { getOrders } = this.props;
    getOrders({
      page,
      pageSize,
      term: term ?? "",
      myOrder,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      isPickup,
      deliveryStatus:
          deliveryStatus &&
          deliveryStatus.map(a => {
            return a.value;
          }),
      pickupPaymentStatus:
          pickupPaymentStatus &&
          pickupPaymentStatus.map(a => {
            return a.value;
          }),
      shippingPaymentStatus:
          shippingPaymentStatus &&
          shippingPaymentStatus.map(a => {
            return a.value;
          }),
      deliveryPaymentStatus:
          deliveryPaymentStatus &&
          deliveryPaymentStatus.map(a => {
            return a.value;
          }),
    });

    this.setState({
      currentPage: page,
    });
  };

  handleOnPrintPreview = selectedId => {
    this.setState({
      modalPrintPreview: true,
      selectedId,
    });
  };

  handleSubmitPrintPreview = () => {
    this.setState({ modalPrintPreview: false });
  };

  handleOnSubmitToWarehouse = () => {
    const { selectedId } = this.state;
    this.props.submitOrderDeliveryStatus({
      id: selectedId,
      deliveryStatus: 1,
    });
    this.setState({
      modalConfirmOnSubmitToWarehouse: false,
    });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleExportToExcel = () => {
    const {
      isPickup,
      term,
      startDate,
      endDate,
      deliveryStatus,
      pickupPaymentStatus,
      shippingPaymentStatus,
      deliveryPaymentStatus,
      myOrder,
      isUserRequestedDelivery,
    } = this.state;
    orderService.exportToExcelRequest(
        {
          term: term ?? "",
          myOrder,
          isPickup,
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          deliveryStatus:
              deliveryStatus &&
              deliveryStatus.map(a => {
                return a.value;
              }),
          pickupPaymentStatus:
              pickupPaymentStatus &&
              pickupPaymentStatus.map(a => {
                return a.value;
              }),
          shippingPaymentStatus:
              shippingPaymentStatus &&
              shippingPaymentStatus.map(a => {
                return a.value;
              }),
          deliveryPaymentStatus:
              deliveryPaymentStatus &&
              deliveryPaymentStatus.map(a => {
                return a.value;
              }),
          isUserRequestedDelivery,
        },
        `${ timestampFileName() }_Order_List.xlsx`
    );
  };

  handleOnSendInvoice = item => {
    this.setState({
      order: item,
      modalInvoice: true,
    });
  };

  handleSubmitInvoice = invoice => {
    this.props.addNewInvoice(invoice);
    this.setState({
      modalInvoice: false,
      order: null,
    });
  };

  handleOnAddNote = orderId => {
    this.setState({
      modalAddNote: true,
      selectedId: orderId,
    });
  };

  handleOnSubmitNote = note => {
    this.props.addNewOrderNote(note);
    this.setState({
      modalAddNote: false,
    });
    this.props.getOrders({ term: "" });
  };

  handleOnDownloadLabel = id => {
    let filename = `${ timestampFileName() }_Label_Print.pdf`;
    orderService.downloadLabelRequest(id, filename);
  };

  handleOnDeliveryStatusChange = order => {
    const { id, deliveryStatus } = order;
    this.props.submitOrderDeliveryStatus({
      id,
      deliveryStatus: deliveryStatus === 0 ? 1 : 0,
    });
  };

  handleOnAddToTransport = orderId => {
    this.setState({
      orderId,
      modalTransportSelectionIsOpen: true
    })
  }

  handleSubmitOnAddOrderToTransport = (transportId) => {
    const { addOrderToTransport } = this.props;
    const { orderId } = this.state;
    addOrderToTransport({ transportId, orderId });

    this.setState({
      orderId: null,
      modalTransportSelectionIsOpen: false
    })
  }

  render() {
    const { pageSize, currentPage, moreActionIsOpen, myOrder } = this.state;
    const { order } = this.props;
    const {
      closedOrderIds,
      completedOrderIds,
      deliveryToCambodiaOrderIds,
      deliveryToCustomerOrderIds,
      draftOrderIds,
      inCambodiaWarehouseOrderIds,
      inKoreaWarehouseOrderIds,
      readyForDeliveryToCambodiaOrderIds,
      readyForDeliveryToCustomerOrderIds,
      rejectedOrderIds,
      items,
      itemCount,
      pageCount,
      loading,
    } = order;
    let totalOrder = [];
    totalOrder = totalOrder
        .concat(closedOrderIds)
        .concat(completedOrderIds)
        .concat(deliveryToCambodiaOrderIds)
        .concat(deliveryToCustomerOrderIds)
        .concat(draftOrderIds)
        .concat(inCambodiaWarehouseOrderIds)
        .concat(inKoreaWarehouseOrderIds)
        .concat(readyForDeliveryToCambodiaOrderIds)
        .concat(readyForDeliveryToCustomerOrderIds)
        .concat(rejectedOrderIds);


    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Cargo | { getCompanyName() }</title>
            </MetaTags>
            <div className={"stick-header"}>
              <Breadcrumbs title="Cargo List" breadcrumbItem="Home"/>
              <Row>
                <Col>
                  <Card className="mb-2 rounded-3 border border-primary">
                    <CardHeader className="bg-transparent border-bottom pb-0 pt-3 border-primary">
                      <Row className="g-1">
                        <Col md={ 2 }>
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Input
                                  type="text"
                                  placeholder="Quick Search ..."
                                  onChange={ this.handleOnSearch }
                              ></Input>
                              <i className="fas fa-search search-icon"/>
                            </div>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <FormGroup>
                            <DatePicker
                                className="form-control"
                                selectsStart
                                name="startDate"
                                selected={ this.state.startDate }
                                startDate={ this.state.startDate }
                                endDate={ this.state.endDate }
                                onChange={ date =>
                                    this.handleDateChange(date, "startDate")
                                }
                                dateFormat="dd-MMM-yyyy"
                                placeholderText="Start Date"
                                autoComplete="off"
                                isClearable
                            />
                          </FormGroup>
                        </Col>
                        <Col md={ 2 }>
                          <FormGroup>
                            <DatePicker
                                className="form-control"
                                name="endDate"
                                selectsEnd
                                selected={ this.state.endDate }
                                startDate={ this.state.startDate }
                                endDate={ this.state.endDate }
                                onChange={ date =>
                                    this.handleDateChange(date, "endDate")
                                }
                                dateFormat="dd-MMM-yyyy"
                                placeholderText="End Date"
                                autoComplete="off"
                                isClearable
                            />
                          </FormGroup>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary mt-2 ms-4">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheckcolor1"
                                // checked={isPickup}
                                onChange={ e => {
                                  this.setState({
                                    isPickup: e.target.checked,
                                  });
                                } }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="customCheckcolor1"
                            >
                              Is Pickup ?
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <Button
                              type="button"
                              outline
                              color="primary"
                              onClick={ this.handleSearch }
                          >
                            <i className="fas fa-filter"/> Filter
                          </Button>
                        </Col>
                        <Col md={ 2 }>
                          <div className="text-sm-end me-3">
                            { hasAccess(permission.order.write) && (
                                <Button
                                    type="button"
                                    color="primary"
                                    className="me-1"
                                    onClick={ this.handleOnAddNew }
                                >
                                  <i className="fas fa-plus"/> Add New
                                </Button>
                            ) }

                            <ButtonDropdown
                                isOpen={ moreActionIsOpen }
                                toggle={ this.toggleMoreAction }
                            >
                              <DropdownToggle caret color="primary" outline>
                                <i className="mdi mdi-dots-vertical"></i>
                              </DropdownToggle>
                              { hasAccess(permission.order.export) && (
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                        onClick={ this.handleExportToExcel }
                                        className="text-primary"
                                    >
                                      <i className="fas fa-file-excel me-1"/>{ " " }
                                      Export to Excel
                                    </DropdownItem>
                                  </DropdownMenu>
                              ) }
                            </ButtonDropdown>
                          </div>
                        </Col>
                      </Row>
                      <div
                          style={ {
                            position: "absolute",
                            top: "1rem",
                            right: "0.5rem",
                            cursor: "pointer",
                          } }
                          onClick={ this.toggleBody }
                      >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                            className={
                              this.state.isOpen
                                  ? "fas fa-angle-up"
                                  : "fas fa-angle-down"
                            }
                        ></i>
                      </span>
                      </div>
                    </CardHeader>
                    <Collapse isOpen={ this.state.isOpen }>
                      <CardBody className="pb-1">
                        <Row>
                          <Col md={ 3 }>
                            <FormGroup>
                              <Select
                                  name="deliveryStatus"
                                  value={ this.state.deliveryStatus }
                                  onChange={ this.handleSelectChange }
                                  options={ deliveryStatusOptions }
                                  classNamePrefix="select2-selection"
                                  placeholder="Delivery Status"
                                  isClearable
                                  isMulti
                              />
                            </FormGroup>
                            <div className="form-check form-check-primary mb-3">
                              <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor1"
                                  checked={ this.state.isUserRequestedDelivery }
                                  onChange={ e => {
                                    this.setState({
                                      isUserRequestedDelivery: e.target.checked,
                                    });
                                  } }
                              />
                              <label
                                  className="form-check-label"
                                  htmlFor="customCheckcolor1"
                              >
                                Is User Requested Delivery?
                              </label>
                            </div>
                          </Col>
                          <Col md={ 3 }>
                            <FormGroup>
                              <Select
                                  name="pickupPaymentStatus"
                                  value={ this.state.pickupPaymentStatus }
                                  onChange={ this.handleSelectChange }
                                  options={ pickupPaymentStatusOptions }
                                  classNamePrefix="select2-selection"
                                  placeholder="Pickup Payment Status"
                                  isClearable
                                  isMulti
                              />
                            </FormGroup>
                          </Col>
                          <Col md={ 3 }>
                            <FormGroup>
                              <Select
                                  name="shippingPaymentStatus"
                                  value={ this.state.shippingPaymentStatus }
                                  onChange={ this.handleSelectChange }
                                  options={ shippingPaymentStatusOptions }
                                  classNamePrefix="select2-selection"
                                  placeholder="Shipping Payment Status"
                                  isClearable
                                  isMulti
                              />
                            </FormGroup>
                          </Col>
                          <Col md={ 3 }>
                            <FormGroup>
                              <Select
                                  name="deliveryPaymentStatus"
                                  value={ this.state.deliveryPaymentStatus }
                                  onChange={ this.handleSelectChange }
                                  options={ deliveryPaymentStatusOptions }
                                  classNamePrefix="select2-selection"
                                  placeholder="Delivery Payment Status"
                                  isClearable
                                  isMulti
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
            </div>
            <Container fluid style={{marginTop:"112px"}}>
              <Row>
                { loading ? (
                    <div className="d-flex justify-content-center">
                      <div
                          className="spinner-border text-primary m-1"
                          role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                ) : (
                    <>
                      <Col lg="12">
                        <OrderCardTable
                            items={ items }
                            itemCount={ itemCount }
                            currentPage={ currentPage }
                            totalPage={ pageCount }
                            loading={ loading }
                            defaultPageSize={ pageSize }
                            onChangePage={ this.handleOnPageChange }
                            onPageSizeChange={ this.handleOnPageSizeChange }
                            onEdit={ this.handleOnEdit }
                            onConfirmDelete={ this.handleConfirmDelete }
                            onPrintPreview={ this.handleOnPrintPreview }
                            onDownloadPrintLabel={ this.handleOnDownloadLabel }
                            onAddNote={ this.handleOnAddNote }
                            onSendInvoice={ this.handleOnSendInvoice }
                            onDeliveryStatusChange={ this.handleOnDeliveryStatusChange }
                            onAddToTransport={ this.handleOnAddToTransport }
                        />


                        <ConfirmDeleteModal
                            title="Confirm Delete"
                            isOpen={ this.state.modalConfirmDeleteIsOpen }
                            toggle={ this.toggleModalConfirmDelete }
                            onSubmit={ this.handleDelete }
                        />

                        <ConfirmationModal
                            title="Confirm Order submission"
                            text={ "Are you sure want to submit to warehouse" }
                            isOpen={ this.state.modalConfirmOnSubmitToWarehouse }
                            toggle={ this.toggleConfirmOnSubmitToWarehouse }
                            onSubmit={ this.handleOnSubmitToWarehouse }
                        />
                        <PrintPreviewModal
                            title="Label Print Preview"
                            orderId={ this.state.selectedId }
                            isOpen={ this.state.modalPrintPreview }
                            toggle={ this.toggleModalPrintPreview }
                            onSubmit={ this.handleSubmitPrintPreview }
                        />

                        { this.state.order && (
                            <InvoiceModalForm
                                title="Add Invoice"
                                order={ this.state.order }
                                isOpen={ this.state.modalInvoice }
                                toggle={ this.toggleModalInvoice }
                                onSubmit={ this.handleSubmitInvoice }
                            />
                        ) }

                        <OrderNoteForm
                            title="Add New Note"
                            orderId={ this.state.selectedId }
                            isOpen={ this.state.modalAddNote }
                            toggle={ this.toggleModalAddNote }
                            onSubmit={ this.handleOnSubmitNote }
                        />

                        <TransportSelectionModal
                            title={ "Transport Selection" }
                            isOpen={ this.state.modalTransportSelectionIsOpen }
                            toggle={ () => {
                              this.setState(prevState => ({
                                modalTransportSelectionIsOpen: !prevState.modalTransportSelectionIsOpen,
                              }));
                            } }
                            statuses={ [ 1 ] }
                            onSubmit={ this.handleSubmitOnAddOrderToTransport }
                        />
                      </Col>
                    </>
                ) }
              </Row>
            </Container>
          </div>
        </React.Fragment>
    );
  }
}

OrderList.propTypes = {
  order: PropTypes.object,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrders: PropTypes.func,
  addNewOrder: PropTypes.func,
  updateOrder: PropTypes.func,
  getOrderById: PropTypes.func,
  deleteOrder: PropTypes.func,
  clearOrderError: PropTypes.func,
  history: PropTypes.object,
  submitOrderDeliveryStatus: PropTypes.func,
  addNewInvoice: PropTypes.func,
  clearInvoiceNotify: PropTypes.func,
  addNewOrderNote: PropTypes.func,
  addOrderToTransport: PropTypes.func,
};

const mapStateToProps = ({ order, invoice }) => {
  return {
    order,
    invoice,
  };
};

export default withSettings(
    connect(mapStateToProps, {
      getOrders,
      addNewOrder,
      addNewOrderNote,
      updateOrder,
      getOrderById,
      deleteOrder,
      clearOrderError,
      submitOrderDeliveryStatus,
      addNewInvoice,
      clearInvoiceNotify,
      addOrderToTransport,
    })(OrderList)
);
