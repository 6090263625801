import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import UserCard from "./UserCard";

const MiscellaneousCard = ({ data }) => {
  return (
    <Card className="mb-0">
      <CardHeader className="bg-transparent border">
        <CardTitle>Miscellaneous</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={2}>
            <UserCard number={data.countNewUserThisWeek} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

MiscellaneousCard.propTypes = {
  data: PropTypes.object,
};

export default MiscellaneousCard;
