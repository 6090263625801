import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addDiscountPromotion } from "../../store/discount-promotion/actions";
import DiscountPromotionForm from "../../containers/discount-promotion/DiscountPromotionForm";

const AddNewDiscountPromotion = props => {
  const { addDiscountPromotion, match, history } = props;

  const [ activeTab, setActiveTab ] = useState("1");

  const { addNewProduct, clearProductNotify, product } = props;
  
  
  const handleSubmit = data => {
    addDiscountPromotion({
      data,
      history,
      queryParams: {}
    })
  };

  const handleOnCancel = () => {
    history.push("/discount-promotion");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Discount Promotion | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" backLink={ "/discount-promotion" } breadcrumbItem={ `Add New Discount Promotion` }/>

            <Row>

              <Col md={ 12 }>
                <DiscountPromotionForm
                    onSubmit={ handleSubmit }
                    onCancel={ handleOnCancel }
                />


              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

AddNewDiscountPromotion.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addDiscountPromotion: PropTypes.func,
};

const mapStateToProps = ({ discountPromotion }) => {
  return {
    discountPromotion,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      addDiscountPromotion
    })(AddNewDiscountPromotion)
);
