import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "toastr/build/toastr.min.css";
import { toastMessage } from "helpers/utils";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import {
  getOrderNote,
  addNewOrderNote,
  getOrderNoteById,
  updateOrderNote,
  deleteOrderNote,
  clearOrderNoteNotify,
} from "../../store/order-note/actions";

import OrderNoteForm from "../../containers/order/OrderNoteForm";
import OrderNoteTable from "containers/order-note/OrderNoteTable";

class OrderNoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      orderId: props.orderId,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getOrderNote({
      page: 1,
      pageSize: 10,
      term: "",
      orderId: this.state.orderId,
    });
  }

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    this.props.addNewOrderNote(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  componentDidUpdate() {
    const { message, clearOrderNoteNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearOrderNoteNotify();
    }
  }

  handleOnEdit = id => {
    var data = {};
    data.orderId = this.state.orderId;
    data.id = id;
    this.props.getOrderNoteById(data);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    item.orderId = this.state.orderId;
    this.props.updateOrderNote(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };
  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    var data = {};
    data.orderId = this.state.orderId;
    data.id = this.state.id;
    this.props.deleteOrderNote(data);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };
  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };
  handleOnSearch = e => {
    const { value } = e.target;
    this.props.getOrderNote({ term: value ?? "", orderId: this.state.orderId });
  };

  handleOnPageChange = page => {
    const { pageSize, orderId } = this.state;
    this.props.getOrderNote({
      page,
      pageSize,
      orderId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { orderId } = this.state;
    this.props.getOrderNote({
      page: 1,
      pageSize,
      orderId,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        {items && (
          <OrderNoteTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onAddNew={this.handleOnAddNew}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onSearch={this.handleOnSearch}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <OrderNoteForm
          title={"Add New Note"}
          orderId={this.state.orderId}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <OrderNoteForm
            title={"Edit Note"}
            item={item}
            orderId={this.state.orderId}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

OrderNoteList.propTypes = {
  message: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrderNote: PropTypes.func,
  getOrderNoteById: PropTypes.func,
  addNewOrderNote: PropTypes.func,
  updateOrderNote: PropTypes.func,
  deleteOrderNote: PropTypes.func,
  clearOrderNoteNotify: PropTypes.func,
  orderId: PropTypes.string,
};

const mapStateToProps = ({ orderNote }) => {
  return orderNote;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderNote,
    addNewOrderNote,
    getOrderNoteById,
    updateOrderNote,
    deleteOrderNote,
    clearOrderNoteNotify,
  })(OrderNoteList)
);
