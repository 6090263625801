import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PRODUCT_UOM,
  GET_PRODUCT_UOM_BY_ID,
  UPDATE_PRODUCT_UOM,
  DELETE_PRODUCT_UOM,
  GET_PRODUCT_UOMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getProductUomByIdSuccess,
  getProductUomsError,
  getProductUomsSuccess,
} from "./actions";

const getProductUomsRequest = query =>
  get("/api/ProductUom/paging", { params: query });
const addNewProductUomRequest = item =>
  post("/api/ProductUom", item);
const getProductUomByIdRequest = id =>
  get(`/api/ProductUom/${id}`);
const updateProductUomRequest = item =>
  update("/api/ProductUom", item);
const deleteProductUomRequest = id =>
  del(`/api/ProductUom/${id}`);

function* getProductUoms({ payload }) {
  try {
    const response = yield call(getProductUomsRequest, payload);
    yield put(getProductUomsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUomsError(error));
  }
}

function* addNewProductUom({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(addNewProductUomRequest, data);
    const response = yield call(getProductUomsRequest, queryParam);
    yield put(getProductUomsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUomsError(error));
  }
}

function* updateProductUom({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(updateProductUomRequest, data);
    const response = yield call(getProductUomsRequest, queryParam);
    yield put(getProductUomsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUomsError(error));
  }
}

function* getProductUomById({ payload }) {
  try {
    let response = yield call(getProductUomByIdRequest, payload);
    yield put(getProductUomByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUomsError(error));
  }
}

function* deleteProductUom({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(deleteProductUomRequest, data);
    const response = yield call(getProductUomsRequest, queryParam);
    yield put(getProductUomsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUomsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PRODUCT_UOMS, getProductUoms);
  yield takeEvery(
    ADD_NEW_PRODUCT_UOM,
    addNewProductUom
  );
  yield takeEvery(
    GET_PRODUCT_UOM_BY_ID,
    getProductUomById
  );
  yield takeEvery(UPDATE_PRODUCT_UOM, updateProductUom);
  yield takeEvery(DELETE_PRODUCT_UOM, deleteProductUom);
}

export default itemSaga;

export { 
  addNewProductUomRequest, 
  getProductUomByIdRequest, 
  getProductUomsRequest
};
