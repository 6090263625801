import axios from "axios";
import { resetSection } from "redux-form";

let API_URL = "";
let axiosApi;

if (process.env.REACT_APP_API_URL) {
  API_URL = process.env.REACT_APP_API_URL;
}

const initializeAxios = () => {
  axiosApi = axios.create({
    baseURL: API_URL,
  });

  let accessToken = localStorage.getItem("token");
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    accessToken
  )}`;

  axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
  );
};

export async function get(url, config = {}) {
  initializeAxios();
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function getFile(url, config = {}) {
  initializeAxios();
  return await axiosApi.get(url, { ...config });
}

export async function postFile(url, data, config = {}) {
  initializeAxios();
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function post(url, data, config = {}) {
  initializeAxios();
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  initializeAxios();
  return axiosApi.put(url, data, { ...config }).then(response => response.data);
}

export async function del(url, config = {}) {
  initializeAxios();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
