import React, { Component } from "react";
import { Col, Row, Button, Input, Card, CardBody, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// AlertNotificationHistory
import {
  getAlertNotificationHistory,
  addNewAlertNotificationHistory,
  deleteAlertNotificationHistory,
  clearDeliveryOrderItemError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SelectionListModal from "containers/order/SelectionListModal";
import VerifyOrderTrackingModalForm from "containers/order/VerifyOrderTrackingModalForm";
import AlertNotificationHistoryTable from "containers/alert-notification-history/AlertNotificationHistoryTable";

export class AlertNotificationHistoryTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      alertNotificationId: null,
      selectedIds: [],
      currentPage: 1,
      pageSize: 10,
      deliveryOrderStatus: 0,

      modalAddOrder: false,
      modalConfirmDeleteIsOpen: false,
      modalVerifyOrderIsOpen: false,
    };
  }

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  toggleVerifyOrderModal = () => {
    this.setState(prevState => ({
      modalVerifyOrderIsOpen: !prevState.modalVerifyOrderIsOpen,
    }));
  };

  componentDidMount = () => {
    const { alertNotificationId, getAlertNotificationHistory } = this.props;
    if (alertNotificationId) {
      getAlertNotificationHistory({ alertNotificationId });
      this.setState({
        alertNotificationId,
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { alertNotificationId, deliveryOrderStatus } = props;
    if (alertNotificationId !== state.alertNotificationId) {
      getAlertNotificationHistory({ alertNotificationId });
      return {
        alertNotificationId,
        deliveryOrderStatus,
      };
    }

    return null;
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");
      this.props.clearDeliveryOrderItemError();
    }
  }

  toggleAddOrder = () => {
    this.setState(prevState => ({
      modalAddOrder: !prevState.modalAddOrder,
    }));
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddOrder: true,
    });
  };

  handleOnSubmit = ids => {
    const { addNewAlertNotificationHistory } = this.props;
    const { alertNotificationId } = this.state;

    if (alertNotificationId) {
      addNewAlertNotificationHistory({ alertNotificationId, orderIds: ids });
      this.setState({
        modalAddOrder: false,
      });
    }
  };

  handleOnRemove = ids => {
    this.setState({
      selectedIds: ids,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { alertNotificationId, selectedIds } = this.state;
    const { deleteAlertNotificationHistory } = this.props;
    deleteAlertNotificationHistory({
      alertNotificationId,
      orderIds: selectedIds,
    });
    this.setState({
      modalConfirmDeleteIsOpen: false,
    });
  };

  handleOnPageChange = page => {
    const { alertNotificationId, pageSize } = this.state;
    this.props.getAlertNotificationHistory({
      alertNotificationId,
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { alertNotificationId } = this.state;
    this.props.getAlertNotificationHistory({
      alertNotificationId,
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnVerifyOrder = id => {
    this.setState({
      orderId: id,
      modalVerifyOrderIsOpen: true,
    });
  };

  render() {
    const {
      deliveryOrder,
      alertNotificationHistory,
      isReceiver,
      deliveryOrderStatus,
    } = this.props;
    const { items, itemCount, pageCount, loading } = alertNotificationHistory;
    const { pageSize, currentPage } = this.state;

    return (
      <Row>
        <Col md={12}>
          <AlertNotificationHistoryTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            defaultPageSize={pageSize}
            totalPage={pageCount}
            transportStatus={deliveryOrder?.item?.status}
            loading={loading}
            onAddNew={this.handleOnAddNew}
            onVerifyOrder={this.handleOnVerifyOrder}
            onRemove={this.handleOnRemove}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
            isReceiver={isReceiver}
            deliveryOrderStatus={deliveryOrderStatus}
          />
        </Col>

        <SelectionListModal
          title={"Order Selection"}
          toggle={this.toggleAddOrder}
          isOpen={this.state.modalAddOrder}
          onSubmit={this.handleOnSubmit}
          orderParams={{ deliveryStatus: [3] }} // In Cambodia Warehouse
        />

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />

        <VerifyOrderTrackingModalForm
          title="Verify Order Tracking Number"
          orderId={this.state.orderId}
          isOpen={this.state.modalVerifyOrderIsOpen}
          toggle={this.toggleVerifyOrderModal}
        />
      </Row>
    );
  }
}

AlertNotificationHistoryTab.propTypes = {
  isReceiver: PropTypes.bool,
  deliveryOrder: PropTypes.object,
  alertNotificationHistory: PropTypes.object,
  alertNotificationId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  addNewAlertNotificationHistory: PropTypes.func,
  getAlertNotificationHistory: PropTypes.func,
  deliveryOrderStatus: PropTypes.number,

  error: PropTypes.string,
  clearDeliveryOrderItemError: PropTypes.func,
  deleteAlertNotificationHistory: PropTypes.func,

  history: PropTypes.object,
};

const mapStateToProps = ({ deliveryOrder, alertNotificationHistory }) => {
  return {
    deliveryOrder,
    alertNotificationHistory,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAlertNotificationHistory,
    addNewAlertNotificationHistory,
    clearDeliveryOrderItemError,
    deleteAlertNotificationHistory,
  })(AlertNotificationHistoryTab)
);
