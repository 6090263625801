import {
  SALE_ORDER_COMMENT_ADD_NEW,
  SALE_ORDER_COMMENT_ADD_NEW_ERROR,
  SALE_ORDER_COMMENT_ADD_NEW_SUCCESS,
  SALE_ORDER_COMMENT_DELETE,
  SALE_ORDER_COMMENT_DELETE_ERROR,
  SALE_ORDER_COMMENT_DELETE_SUCCESS,
  SALE_ORDER_COMMENT_GET_BY_ID,
  SALE_ORDER_COMMENT_GET_BY_ID_ERROR,
  SALE_ORDER_COMMENT_GET_BY_ID_SUCCESS,
  SALE_ORDER_COMMENT_GET_PAGING,
  SALE_ORDER_COMMENT_GET_PAGING_ERROR,
  SALE_ORDER_COMMENT_GET_PAGING_SUCCESS,
  SALE_ORDER_COMMENT_UPDATE,
  SALE_ORDER_COMMENT_UPDATE_ERROR,
  SALE_ORDER_COMMENT_UPDATE_SUCCESS,
} from './actionType';

// SaleOrderComment
export const getPagingSaleOrderComment = items => ({
  type: SALE_ORDER_COMMENT_GET_PAGING,
  payload: items,
});

export const getPagingSaleOrderCommentSuccess = items => ({
  type: SALE_ORDER_COMMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingSaleOrderCommentError = error => ({
  type: SALE_ORDER_COMMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addSaleOrderComment = items => ({
  type: SALE_ORDER_COMMENT_ADD_NEW,
  payload: items,
});

export const addSaleOrderCommentSuccess = items => ({
  type: SALE_ORDER_COMMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addSaleOrderCommentError = error => ({
  type: SALE_ORDER_COMMENT_ADD_NEW_ERROR,
  payload: error,
});

export const getSaleOrderCommentById = items => ({
  type: SALE_ORDER_COMMENT_GET_BY_ID,
  payload: items,
});

export const getSaleOrderCommentByIdSuccess = items => ({
  type: SALE_ORDER_COMMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getSaleOrderCommentByIdError = error => ({
  type: SALE_ORDER_COMMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateSaleOrderComment = items => ({
  type: SALE_ORDER_COMMENT_UPDATE,
  payload: items,
});

export const updateSaleOrderCommentSuccess = items => ({
  type: SALE_ORDER_COMMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateSaleOrderCommentError = error => ({
  type: SALE_ORDER_COMMENT_UPDATE_ERROR,
  payload: error,
});

export const deleteSaleOrderComment = items => ({
  type: SALE_ORDER_COMMENT_DELETE,
  payload: items,
});

export const deleteSaleOrderCommentSuccess = items => ({
  type: SALE_ORDER_COMMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteSaleOrderCommentError = error => ({
  type: SALE_ORDER_COMMENT_DELETE_ERROR,
  payload: error,
});
