import FileSaver from "file-saver";
import {
  postFile,
  post,
  del,
  put as update,
} from "helpers/api_helper";

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/StockOut/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const stockOutService = {
  exportToExcelRequest,
};
