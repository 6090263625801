import {
  TAG_ADD_NEW,
  TAG_ADD_NEW_ERROR,
  TAG_ADD_NEW_SUCCESS,
  TAG_GET_BY_ID,
  TAG_GET_BY_ID_ERROR,
  TAG_GET_BY_ID_SUCCESS,
  TAG_GET_PAGING,
  TAG_GET_PAGING_ERROR,
  TAG_GET_PAGING_SUCCESS,
  TAG_UPDATE,
  TAG_UPDATE_ERROR,
  TAG_UPDATE_SUCCESS,
} from './actionType';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TAG_ADD_NEW:
    case TAG_GET_PAGING:
    case TAG_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case TAG_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
      };

    case TAG_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case TAG_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case TAG_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case TAG_ADD_NEW_ERROR:
    case TAG_GET_PAGING_ERROR:
    case TAG_GET_BY_ID_ERROR:
    case TAG_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
