import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
// DiscountPromotion
import {
  getPagingDiscountPromotion,
  addDiscountPromotion,
  updateDiscountPromotion,
  getDiscountPromotionById,
  deleteDiscountPromotion,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { debounce } from "lodash";
import ModalForm from "containers/discount-promotion/ModalForm";
import DiscountPromotionTable from "containers/discount-promotion/DiscountPromotionTable";

const DiscountPromotionList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const {
    getPagingDiscountPromotion,
    addDiscountPromotion,
    history,
    updateDiscountPromotion,
    getDiscountPromotionById,
    deleteDiscountPromotion,
    discountPromotion
  } = props;
  
  const { 
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount } = discountPromotion;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 500), [])

  useEffect(() => {
    getPagingDiscountPromotion({ page, pageSize, term });
  }, [ page, pageSize, term ]);

  // useEffect(() => {
  //   if (message) {
  //     toastMessage(message);
  //     // clearUnitOfMeasurementError();
  //   }
  // }, [ message ]);

  const handleOnEdit = id => {
    console.log(id);
    history.push(`/discount-promotion/${ id }`)
    // getDiscountPromotionById(id);
    // setModalEditIsOpen(true);
  };

  const handleUpdate = data => {
    updateDiscountPromotion({
      data,
      queryParams: {}
    });
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push("/discount-promotion/add-new")
    // setModalAddIsOpen(true);
  }

  const handleSubmit = data => {
    addDiscountPromotion({
      data,
      queryParams: {}
    });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteDiscountPromotion({
      data: id,
      queryParams: {}
    });
    setModalConfirmDeleteIsOpen(false);
  };
  // Discount Promotion
  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Discount Promotion | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Discount Promotion" breadcrumbItem="Discount Promotion List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>:
                      <>
                        { items && (
                            <DiscountPromotionTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                defaultPageSize={ pageSize }
                                onEdit={ handleOnEdit }
                                onConfirmDelete={ handleConfirmDelete }
                                onChangePage={ (page) => setPage(page) }
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }
                
                { item && (
                    <ModalForm
                        title={ "Edit UoM" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add Discount Promotion" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

DiscountPromotionList.propTypes = {
  discountPromotion: PropTypes.object,
  getPagingDiscountPromotion: PropTypes.func,
  addDiscountPromotion: PropTypes.func,
  updateDiscountPromotion: PropTypes.func,
  getDiscountPromotionById: PropTypes.func,
  deleteDiscountPromotion: PropTypes.func,
};

const mapStateToProps = ({ discountPromotion }) => {
  return {
    discountPromotion
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingDiscountPromotion,
      addDiscountPromotion,
      updateDiscountPromotion,
      getDiscountPromotionById,
      deleteDiscountPromotion,
    })(DiscountPromotionList)
);
