import {
  GET_PAGING_CASE_AUDIT, 
  GET_PAGING_CASE_AUDIT_FAIL, 
  GET_PAGING_CASE_AUDIT_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const Banner = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {

    case GET_PAGING_CASE_AUDIT:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGING_CASE_AUDIT_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_PAGING_CASE_AUDIT_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default Banner;
