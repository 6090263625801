import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";

const TagTable = ({
                    itemCount,
                    page,
                    totalPage,
                    defaultPageSize,
                    items,
                    onEdit,
                    onConfirmDelete,
                    onChangePage,
                    onPageSizeChange,
                  }) => {
  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">

            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
              <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                <tr>
                  <th style={ { width: "80px" } }>#</th>
                  <th>Name</th>
                  <th style={ { width: "120px" } }>Action</th>
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => {
                  let num = (page - 1) * defaultPageSize + (index + 1);
                  return (
                      <tr key={ index }>
                        <td style={ { textAlign: "right" } }>{ num }</td>
                        <td>{ item.name }</td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-primary" to="#">
                              <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={ () => onEdit(item.id) }
                              ></i>
                            </Link>

                            <Link className="text-danger" to="#">
                              <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={ () => onConfirmDelete(item.id) }
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                  );
                }) }
                </tbody>
              </Table>
            </div>
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  );
};

TagTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default TagTable;
