import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import { getInvoiceReport } from "store/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import NumberFormat from "react-number-format";
import InvoiceReportTable from "containers/invoice-report/InvoiceReportTable";
import moment from "moment";
import { debounce } from "lodash";
import Select from "react-select";
import { getTransportDropdownRequest } from "../../store/transport/saga";
import { getCompanyName } from "../../helpers/utils";

export class InvoiceReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      currentPage: 1,
      pageSize: 10,
      startDate: null,
      endDate: null,
      transportOption: null,
      transportOptions: []
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getInvoiceReport({ term });
  }, 1000);

  componentDidMount() {
    const { getInvoiceReport } = this.props;

    getTransportDropdownRequest().then(data => {
      if (data) {
        this.setState({
          transportOptions: data.filter(e => e.orderIds.length > 0).map(item => {

            return {
              ...item,
              id: item.id,
              label: item.title,
              value: item.id
            }
          })
        })
      }
    })

    getInvoiceReport({});

  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getInvoiceReport({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getInvoiceReport({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });

    this.debouncedFetchData(value);
  };

  handleSearch = () => {
    const { term, startDate, endDate, transportOption } = this.state;
    console.log(transportOption)
    this.props.getInvoiceReport({
      term: term,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
      orderIds: transportOption ? transportOption.orderIds : [],
    });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  render() {
    const { pageSize, currentPage } = this.state;
    const {
      items,
      itemCount,
      pageCount,
      loading,
      totalAmount,
      totalOutstanding,
      totalPayment,
    } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Invoice Report | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Invoice Report" />
            <Row>
              <Col md={12}>
                <Card className="mb-2 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Select
                              name="transportOption"
                              value={this.state.transportOption}
                              onChange={(option)=>{
                                this.setState({transportOption: option})
                              }}
                              options={this.state.transportOptions}
                              classNamePrefix="select2-selection"
                              placeholder="Transport Shipment"
                              isClearable
                              // isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-lg-none w-100"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>

                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-none d-lg-block"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </CardHeader>
                </Card>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <Card className="mb-2">
                      <CardBody className="pb-0">
                        <Row>
                          <Col md={4}>
                            <Card
                              color="primary"
                              className="border border-primary mini-stats-wid"
                            >
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-white fw-medium">
                                      Total Amount
                                    </p>
                                    <h4 className="mb-0 text-white">
                                      <NumberFormat
                                        value={totalAmount}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        prefix="₩ "
                                      />
                                    </h4>
                                  </div>
                                  <div
                                    className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                                    style={{
                                      borderColor: "white",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <span className="avatar-title">
                                      <i
                                        className={
                                          "fas fa-won-sign font-size-24"
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md={4}>
                            <Card
                              color="success"
                              className="border border-success mini-stats-wid"
                            >
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-white fw-medium">
                                      Total Payment
                                    </p>
                                    <h4 className="mb-0 text-white">
                                      <NumberFormat
                                        value={totalPayment}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        prefix="₩ "
                                      />
                                    </h4>
                                  </div>
                                  <div
                                    className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                                    style={{
                                      borderColor: "white",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <span className="avatar-title bg-success">
                                      <i
                                        className={"fas fa-check font-size-24"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md={4}>
                            <Card
                              color="warning"
                              className="border border-warning mini-stats-wid"
                            >
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-white fw-medium">
                                      Balance Due
                                    </p>
                                    <h4 className="mb-0 text-white">
                                      <NumberFormat
                                        value={totalOutstanding}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                        prefix="₩ "
                                      />
                                    </h4>
                                  </div>
                                  <div
                                    className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                                    style={{
                                      borderColor: "white",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <span className="avatar-title bg-warning">
                                      <i
                                        className={"bx bx-list-ol font-size-24"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <InvoiceReportTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      loading={loading}
                      defaultPageSize={pageSize}
                      onChangePage={this.handleOnPageChange}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

InvoiceReport.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  totalAmount: PropTypes.number,
  totalOutstanding: PropTypes.number,
  totalPayment: PropTypes.number,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoiceReport: PropTypes.func,
};

const mapStateToProps = ({ invoiceReport }) => {
  return invoiceReport;
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoiceReport,
  })(InvoiceReport)
);
