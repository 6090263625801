import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getPagingDiscountPromotionProduct,
  addDiscountPromotionProduct,
  deleteDiscountPromotionProduct
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader, CardSubtitle, CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ProductSelectionModal from "../../containers/product/ProductSelectionModal";
import { debounce } from "lodash";

const AppliedToProductTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10);
  const [ term, setTerm ] = useState('');
  
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 500), [])
  
  const {
    getPagingDiscountPromotionProduct,
    addDiscountPromotionProduct,
    deleteDiscountPromotionProduct,
    discountPromotionProduct,
    discountPromotionId
  } = props;
  
  const { 
    itemCount, 
    pageCount, 
    items,
    loading
  } = discountPromotionProduct
  
  useEffect(() => {
    getPagingDiscountPromotionProduct({ 
      discountPromotionId, 
      page, 
      pageSize, 
      term 
    });
  }, [ page, pageSize, term ]);

  const handleAddProduct = (product) => {
    addDiscountPromotionProduct({
      data: {
        productId: product.id,
        discountPromotionId,
      },
      queryParams: {
        discountPromotionId,
        page: 1,
        pageSize,
        term
      },
    })

    setModalAddIsOpen(false);
  }

  const handleDelete = () => {
    deleteDiscountPromotionProduct({
      data: selectedId,
      queryParams: {
        discountPromotionId,
        page: 1,
        pageSize,
        term
      },
    });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target;
                          setPage(1);
                          debouncedFetchData(value)
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              { loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={ { width: "80px" } }>#</th>
                        <th className={ "text-center" } style={ { width: "120px" } }>Picture</th>
                        <th>Product</th>
                        <th style={ { width: "120px" } }>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      { items.map((item, index) => {
                        const { productImages } = item;
                        let productImage = productImages.length > 0 ? productImages[0] : null;

                        let num = (page - 1) * pageSize + (index + 1);

                        return (
                            <tr key={ index }>
                              <td style={ { textAlign: "right" } }>{ num }</td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  {
                                    productImage ?
                                        <img style={ { width: "80px", height: "50px", objectFit: "contain" } }
                                             src={ productImage.url } alt=""/>
                                        :
                                        <div className="avatar-order">
                                          <i className="fas fa-solid fa-cube text-primary"></i>
                                        </div>
                                  }
                                </div>
                              </td>
                              <td>
                                <CardTitle>{ item.productName }</CardTitle>
                                <CardSubtitle>Code: { item.productCode }</CardSubtitle>
                              </td>
                              <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={ () => {
                                          setSelectedId(item.id);
                                          setModalConfirmDeleteIsOpen(true);
                                        } }
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                        );
                      }) }
                      </tbody>
                    </Table>
                  </div>
              ) }
            </div>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                defaultPageSize={ pageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={i => setPage(i)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
            />
          </CardBody>
        </Card>
        <ProductSelectionModal
            title={ "Select Product" }
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(false) }
            onSelected={ handleAddProduct }
        />

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  );
};


AppliedToProductTab.propTypes = {
  getPagingDiscountPromotionProduct: PropTypes.func,
  addDiscountPromotionProduct: PropTypes.func,
  discountPromotionProduct: PropTypes.object,
};

const mapStateToProps = ({ discountPromotionProduct }) => {
  return {
    discountPromotionProduct
  }
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingDiscountPromotionProduct,
      addDiscountPromotionProduct,
      deleteDiscountPromotionProduct
    })(AppliedToProductTab)
);
