import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NIL } from "uuid";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { categoryService } from "../../services/category-service";
import { addNewTagRequest, getTagByIdRequest, getTagsRequest } from "../../store/tag/saga";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ProductForm = props => {
  const { onSubmit, onCancel } = props;

  const [ product, setProduct ] = useState({
    id: NIL,
    code: '',
    name: '',
    sku: '',
    barcode: '',
    published: false,
    isFeature: false,
    price: 0,
    oldPrice: 0,
    cost: 0,
    expiryDate: null,
    categories: [],
    tags: [],
    shortDescription: '',
    fullDescription: '',
  });

  const [ submitted, setSubmitted ] = useState(false);
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  const [ tagOptions, setTagOptions ] = useState([])

  useEffect(() => {
    if (props.product) {
      const { product } = props;
      setProduct({
        ...product,
        expiryDate: product.expiryDate ? moment(product.expiryDate).toDate() : null,
        categories: product.categories.map(cat => ({ key: cat.id, value: cat.id, label: cat.name })),
        tags: product.tags.map(tag => ({ key: tag.id, value: tag.id, label: tag.name }))
      });
    } else {
      setProduct({
        id: NIL,
        code: '',
        name: '',
        sku: '',
        published: false,
        isFeature: false,
        price: 0,
        oldPrice: 0,
        cost: 0,
        expiryDate: null,
        shortDescription: '',
        fullDescription: '',
      })
    }
    setSubmitted(false);
  }, []);

  useEffect(() => {
    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(
            data
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(item => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                  };
                })
        );
      }
    });

    getTagsRequest().then(data => {
      setTagOptions(
          data.map(item => {
            return {
              key: item.id,
              value: item.id,
              label: item.name,
            };
          })
      );
    })

  }, []);

  const handleCategorySelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true);
      const { value } = newItem;
      const category = {
        name: value,
      };

      categoryService.createRequest(category).then(id => {
        if (id) {
          categoryService.getByIdRequest(id).then(data => {
            if (data) {
              const newOption = {
                key: data.id,
                value: data.id,
                label: data.name,
              };

              setProduct({
                ...product,
                categories: [ ...product.categories, newOption ]
              });

              setCategoryOptions(categoryOptions.concat(newOption));
            }
          });
        }
      });
    } else {
      setProduct({
        ...product,
        categories: newValue
      })
    }
  };

  const handleTagSelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true);
      const { value } = newItem;
      const tag = {
        name: value,
      };
      addNewTagRequest(tag).then(id => {
        if (id) {
          getTagByIdRequest(id).then(data => {
            if (data) {
              const newOption = {
                key: data.id,
                value: data.id,
                label: data.name,
              };

              setProduct({
                ...product,
                tags: [ ...product.tags, newOption ]
              });

              setTagOptions(tagOptions.concat(newOption));
            }
          });
        }
      });
    } else {
      setProduct({
        ...product,
        tags: newValue
      })
    }
  };

  const handleChange = e => {
    const { value, name } = e.target
    setProduct({
      ...product,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    setSubmitted(true);

    let isValid = product.name;

    if (isValid) {
      let data = {
        ...product,
        expiryDate: product.expiryDate ? moment(product.expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        categories: product.categories ? product.categories.map(cat => {
          return {
            id: cat.value,
            name: cat.label
          }
        }) : [],
        tags: product.tags ? product.tags.map(tag => {
          return {
            id: tag.value,
            name: tag.label
          }
        }) : []
      }

      onSubmit(data);
    }
  }

  const handleOnCheck = e => {
    const { name, checked } = e.target

    setProduct({
      ...product,
      [name]: checked
    })
  }

  const handleCancel = () => {
    onCancel();
  }


  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={2}>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col>
                <CardTitle>{product.name}</CardTitle>
              </Col>
              <Col>
                <p className={"mb-0 pb-0"}>
                  Code: <span className={'text-muted'}> {product.code}</span>
                </p>
              </Col>
              <Col>
                <p className={"mb-0 pb-0"}>
                  SKU: <span className={'text-muted'}> {product.sku}</span>
                </p>
              </Col>
            </Row>
            
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Name</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      autoComplete="Off"
                      className={ submitted && !product.name ? "is-invalid" : "" }
                      value={ product.name }
                      onChange={ handleChange }
                  />
                  { submitted && !product.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
                
                <FormGroup className="mb-3">
                  <Label htmlFor="">Barcode</Label>
                  <Input
                      type="text"
                      name="barcode"
                      placeholder="Barcode"
                      autoComplete="Off"
                      className={ "text-end" }
                      value={ product.barcode }
                      onChange={ handleChange }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="expiryDate">Expiry Date</Label>
                  <DatePicker
                      className="form-control"
                      name="expiryDate"
                      selected={ product.expiryDate }
                      // startDate={ this.state.startDate }
                      // endDate={ this.state.endDate }
                      onChange={ date =>
                          setProduct({
                            ...product,
                            expiryDate: date
                          })
                      }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Expiry Date"
                      autoComplete="off"
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="price">Price</Label>
                  <NumberFormat
                      name="price"
                      value={ product.price }
                      className="form-control text-end"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                      placeholder="0"
                      prefix={ `$` }
                      onValueChange={ values => {
                        const { floatValue } = values;
                        setProduct({
                          ...product,
                          price: floatValue
                        })
                      } }
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="price">Old Price</Label>
                  <NumberFormat
                      name="oldPice"
                      value={ product.oldPrice }
                      className="form-control text-end"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                      placeholder="0"
                      prefix={ `$` }
                      onValueChange={ values => {
                        const { floatValue } = values;
                        setProduct({
                          ...product,
                          oldPrice: floatValue
                        })
                      } }
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="price">Cost</Label>
                  <NumberFormat
                      name="cost"
                      value={ product.cost }
                      className="form-control text-end"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                      placeholder="0"
                      prefix={ `$` }
                      onValueChange={ values => {
                        const { floatValue } = values;
                        setProduct({
                          ...product,
                          cost: floatValue
                        })
                      } }
                  />
                </FormGroup>
                
              </Col>
              <Col>
                <FormGroup>
                  <Label>Tags</Label>
                  <CreatableSelect
                      name="tags"
                      value={ product.tags }
                      onChange={ handleTagSelectChange }
                      options={ tagOptions }
                      classNamePrefix="select2-selection"
                      isMulti={ true }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Categories</Label>
                  <CreatableSelect
                      name="categories"
                      value={ product.categories }
                      onChange={ handleCategorySelectChange }
                      options={ categoryOptions }
                      classNamePrefix="select2-selection"
                      isMulti={ true }
                  />
                </FormGroup>
              </Col>
            </Row>


            <Row>
              <Col md={ 3 }>
                <div className="form-check form-check-primary my-3">
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckcolor1"
                      name="published"
                      checked={ product.published }
                      onChange={ handleOnCheck }
                  />
                  <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                  >
                    Published
                  </label>
                </div>
              </Col>
              <Col md={ 3 }>
                <div className="form-check form-check-primary my-3">
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckcolor1"
                      name="isFeature"
                      checked={ product.isFeature }
                      onChange={ handleOnCheck }
                  />
                  <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                  >
                    Is Feature ?
                  </label>
                </div>
              </Col>
              <Col>

              </Col>
              <Col>

              </Col>

            </Row>
            <Row>
              <Col md={ 12 }>
                <FormGroup className="mb-3">
                  <Label>Short Description</Label>
                  <Input
                      type="textarea"
                      id="shortDescription"
                      name="shortDescription"
                      rows={ 3 }
                      value={ product.shortDescription }
                      onChange={ handleChange }
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Full Description</Label>
                  <CKEditor
                      rows={ 7 }
                      editor={ ClassicEditor }
                      // data={ product.fullDescription }
                      onReady={ editor => {
                        editor.setData(product.fullDescription ?? '')
                      } }
                      onChange={ (event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        setProduct({
                          ...product,
                          fullDescription: data,
                        })
                      } }
                      onBlur={ (event, editor) => {
                        console.log('Blur.', editor);
                      } }
                      onFocus={ (event, editor) => {
                        console.log('Focus.', editor);
                      } }
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="float-end">
              <Button color="primary" className={ "me-2" } onClick={ handleSubmit }>
                Submit
              </Button>
              <Button color="primary" onClick={ handleCancel } outline>
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
  );
};

ProductForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ProductForm;