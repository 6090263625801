import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import ProductForm from "containers/product/ProductForm";
import {
  updateProduct,
  getProductById,
  getProductUoms,
  getPagingProductReview,
  getPagingProductTransaction
} from "store/actions";
import { toastMessage } from "helpers/utils";
import ProductImageTab from "./ProductImageTab";
import ProductUomTab from "./ProductUomTab";
import ProductReviewTab from "./ProductReviewTab";
import ProductTransactionTab from "./ProductTransactionTab";
// import ItemAttachmentTab from "../Item/ItemAttachmentTab"
// import ProductImageTab from "./ProductImageTab"

const ProductDetail = props => {
  const { getPagingProductTransaction,getPagingProductReview, getProductUoms, match, history } = props;
  const [ id, setId ] = useState(null);

  const [ activeTab, setActiveTab ] = useState("1");

  const {
    updateProduct,
    getProductById,
    product,
  } = props;

  const { item, loading, message } = product;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      // clearProductNotify();
    }
  }, [ message ]);

  useEffect(() => {
    const { id } = match.params;
    getProductById(id);
    setId(id);
  }, []);

  const handleSubmit = data => {
    updateProduct({
      history,
      data,
    });
  };

  const handleOnCancel = () => {
    history.push("/product");
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ "/product" }
                breadcrumbItem={ `${ item?.code } | Product Detail` }
            />

            <Row>
              <Col md={ 12 }>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "1",
                        }) }
                        onClick={ () => {
                          setActiveTab("1");
                        } }
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "2",
                        }) }
                        onClick={ () => {
                          setActiveTab("2");
                        } }
                    >
                      Images
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "3",
                        }) }
                        onClick={ () => {
                          setActiveTab("3");
                          getProductUoms({ productId: id });
                        } }
                    >
                      Unit of Measurement
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "4",
                        }) }
                        onClick={ () => {
                          setActiveTab("4");
                          getPagingProductReview  ({ productId: id });
                        } }
                    >
                      Reviews
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        style={ { cursor: "pointer" } }
                        className={ classNames({
                          active: activeTab === "5",
                        }) }
                        onClick={ () => {
                          setActiveTab("5");
                          getPagingProductTransaction  ({ productId: id });
                        } }
                    >
                      Transactions
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={ activeTab }>
                  <TabPane tabId="1">
                    { loading ? (
                        <div className="d-flex justify-content-center">
                          <div
                              className="spinner-border text-primary m-1"
                              role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                    ) : (
                        <>
                          { item && (
                              <ProductForm
                                  product={ item }
                                  onSubmit={ handleSubmit }
                                  onCancel={ handleOnCancel }
                              />
                          ) }
                        </>
                    ) }
                  </TabPane>
                  <TabPane tabId="2">
                    { id ? <ProductImageTab productId={ id }/> : null }
                  </TabPane>
                  <TabPane tabId="3">
                    { id ? <ProductUomTab productId={ id }/> : null }
                  </TabPane>

                  <TabPane tabId="4">
                    { id ? <ProductReviewTab productId={ id }/> : null }
                  </TabPane>
                  <TabPane tabId="5">
                    { id ? <ProductTransactionTab productId={ id }/> : null }
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  updateProduct: PropTypes.func,
  getProductById: PropTypes.func,
  getProductUoms: PropTypes.func,
  getPagingProductReview: PropTypes.func,
  getPagingProductTransaction: PropTypes.func
};

const mapStateToProps = ({ product }) => {
  return {
    product,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getProductUoms,
      updateProduct,
      getProductById,
      getPagingProductReview,
      getPagingProductTransaction
    })(ProductDetail)
);
