import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NIL } from "uuid";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { addDays } from "../../helpers/utils";
import NumberFormat from "react-number-format";

const GenerateInvoiceModal = props => {
  const { isOpen, toggle, title, saleOrder, onSubmit } = props;
  
  const [ item, setItem ] = useState({
    id: NIL,
    invoiceNo: "",
    date: new Date(),
    dueDate: addDays(new Date(), 7),
    status: 0,
    totalAmount: 0,
    saleOrderId: null,
    invoiceToUserId: null,
    note: "",
  });
  
  const [ submitted, setSubmitted ] = useState(false);

  const handleDateChange = (date, name) => {
    setItem({
      ...item,
      [name]: date
    })
  }

  const handleOnChange = e => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    setSubmitted(true);

    if (item.date) {
      onSubmit(item);
    }
  };

  const onOpened = () => {
    const { customerId, id, total } = saleOrder;

    setSubmitted(false);

    if (saleOrder) {
      setItem({
        ...item,
        invoiceToUserId: customerId,
        saleOrderId: id,
        totalAmount: total
      })
    } else {
      setItem({
        id: NIL,
        invoiceNo: "",
        date: new Date(),
        dueDate: this.addDays(new Date(), 7),
        status: 0,
        totalAmount: 0,
        saleOrderId: null,
        invoiceToUserId: null,
        note: "",
      })
    }
  }


  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={ 12 }>
              <FormGroup className="mb-3">
                <Label htmlFor="totalAmount">Total Amount</Label>
                <NumberFormat
                    value={ Number(item.totalAmount).toFixed(2) }
                    className={"form-control text-end"}
                    displayType={"input"}
                    thousandSeparator={ true }
                    fixedDecimalScale={ false }
                    prefix={ "$" }
                    readOnly
                />
              
              </FormGroup>
              <FormGroup
                  className={
                      "select2-container" +
                      (submitted && !item.date ? " is-invalid" : "")
                  }
              >
                <Label>Date</Label>
                <DatePicker
                    className="form-control"
                    selectsStart
                    name="date"
                    selected={ item.date }
                    onChange={ date => handleDateChange(date, "date") }
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                />
              </FormGroup>
              { submitted && !item.date && (
                  <div className="invalid-feedback-custom">Date is required.</div>
              ) }
              <FormGroup>
                <Label>Due Date</Label>
                <DatePicker
                    className="form-control"
                    selectsStart
                    name="dueDate"
                    selected={ item.dueDate }
                    onChange={ date => handleDateChange(date, "dueDate") }
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="invoiceNo">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    rows={ 3 }
                    placeholder="Note..."
                    value={ item.note }
                    onChange={ handleOnChange }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

GenerateInvoiceModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  saleOrder: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default GenerateInvoiceModal;