import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  Input,
  Button,
  FormGroup,
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "components/Common/Breadcrumb";

import { getOrderVerifyTracking } from "store/actions";
import OrderVerityTrackingNoCheckBoxTable from "containers/order-verify-tracking/OrderVerityTrackingNoCheckBoxTable";
import moment from "moment";
import { timestampFileName } from "helpers/utils";
import { orderVerifiedHistoryService } from "services/order-verified-history-service";
import NumberFormat from "react-number-format";
import { debounce } from "lodash";

export class OrderVerifyTrackingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: "",
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getOrderVerifyTracking({ term });
  }, 1000);

  componentDidMount = () => {
    const { getOrderVerifyTracking } = this.props;
    getOrderVerifyTracking({});
  };

  handleExportToExcel = () => {
    const { orderId } = this.state;
    orderVerifiedHistoryService.exportToExcelRequest(
      {
        orderId: orderId ?? "",
      },
      `${timestampFileName()}_Order_Verified_History_List.xlsx`
    );
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleOnChangePage = page => {
    const { pageSize } = this.state;
    this.props.getOrderVerifyTracking({ page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = size => {
    const { getOrderVerifyTracking } = this.props;
    getOrderVerifyTracking({ page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  handleSearch = () => {
    const { term, startDate, endDate } = this.state;

    this.props.getOrderVerifyTracking({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.debouncedFetchData(value);
  };

  handleFilterByStatus = ids => {
    this.props.getOrderVerifyTracking({ ids });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const {
      scanVerifiedIds,
      manualVerifiedIds,
      unverifiedIds,
      items,
      itemCount,
      pageCount,
      loading,
    } = this.props;
    let allVerifiedIds = [];
    allVerifiedIds = allVerifiedIds
      .concat(scanVerifiedIds)
      .concat(manualVerifiedIds)
      .concat(unverifiedIds);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Order Verified Tracking | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Sale Order"
              breadcrumbItem="Order Verified Tracking"
            />
            <Row>
              <Col md={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            size="md"
                            onClick={this.handleExportToExcel}
                          >
                            <i className="fas fa-file-excel" /> Export to Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>

              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                items && (
                  <>
                    <Col md={3}>
                      <Card
                        color="info"
                        className="mini-stats-wid mt-1 mb-2 border clickable"
                        onClick={() =>
                          this.handleFilterByStatus(allVerifiedIds)
                        }
                      >
                        <CardBody className="p-2">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-white font-size-12 fw-bold">
                                All
                              </p>
                              <h4 className="mb-0 text-white text-center">
                                <NumberFormat
                                  value={allVerifiedIds.length}
                                  displayType="text"
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                                />
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle salign-self-center"
                              style={{
                                borderColor: "white",
                                borderStyle: "solid",
                              }}
                            >
                              <span className="avatar-title bg-transparent">
                                <i className={"fas fa-cubes font-size-24"} />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        color="success"
                        className="mini-stats-wid mt-1 mb-2 border clickable"
                        onClick={() =>
                          this.handleFilterByStatus(scanVerifiedIds)
                        }
                      >
                        <CardBody className="p-2">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-white font-size-12 fw-bold">
                                Scan Verified
                              </p>
                              <h4 className="mb-0 text-white text-center">
                                <NumberFormat
                                  value={scanVerifiedIds.length}
                                  displayType="text"
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                                />
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                              style={{
                                borderColor: "white",
                                borderStyle: "solid",
                              }}
                            >
                              <span className="avatar-title bg-transparent">
                                <i
                                  className={"mdi mdi-qrcode-scan font-size-24"}
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        color="primary"
                        className="mini-stats-wid mt-1 mb-2 border clickable"
                        onClick={() =>
                          this.handleFilterByStatus(manualVerifiedIds)
                        }
                      >
                        <CardBody className="p-2">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-white font-size-12 fw-bold">
                                Manual Verified
                              </p>
                              <h4 className="mb-0 text-white text-center">
                                <NumberFormat
                                  value={manualVerifiedIds.length}
                                  displayType="text"
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                                />
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                              style={{
                                borderColor: "white",
                                borderStyle: "solid",
                              }}
                            >
                              <span className="avatar-title bg-transparent">
                                <i
                                  className={"mdi mdi-book-search font-size-24"}
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        color="warning"
                        className="mini-stats-wid mt-1 mb-2 border clickable"
                        onClick={() => this.handleFilterByStatus(unverifiedIds)}
                      >
                        <CardBody className="p-2">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-white font-size-12 fw-bold">
                                Unverified
                              </p>
                              <h4 className="mb-0 text-white text-center">
                                <NumberFormat
                                  value={unverifiedIds.length}
                                  displayType="text"
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                                />
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                              style={{
                                borderColor: "white",
                                borderStyle: "solid",
                              }}
                            >
                              <span className="avatar-title bg-transparent">
                                <i
                                  className={"mdi mdi-barcode-off font-size-24"}
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <OrderVerityTrackingNoCheckBoxTable
                            items={items}
                            itemCount={itemCount}
                            currentPage={currentPage}
                            totalPage={pageCount}
                            loading={loading}
                            defaultPageSize={pageSize}
                            onChangePage={this.handleOnChangePage}
                            onPageSizeChange={this.handleOnPageSizeChange}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                )
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OrderVerifyTrackingList.propTypes = {
  scanVerifiedIds: PropTypes.array,
  manualVerifiedIds: PropTypes.array,
  unverifiedIds: PropTypes.array,
  order: PropTypes.object,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrders: PropTypes.func,
  getOrderVerifyTracking: PropTypes.func,
};

const mapStateToProps = ({ orderVerifyTracking }) => {
  return orderVerifyTracking;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderVerifyTracking,
  })(OrderVerifyTrackingList)
);
