import {
  PRODUCT_ADD_NEW,
  PRODUCT_ADD_NEW_ERROR,
  PRODUCT_ADD_NEW_SUCCESS,
  PRODUCT_DELETE,
  PRODUCT_DELETE_ERROR,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_GET_BY_ID,
  PRODUCT_GET_BY_ID_ERROR,
  PRODUCT_GET_BY_ID_SUCCESS,
  PRODUCT_GET_PAGING,
  PRODUCT_GET_PAGING_ERROR,
  PRODUCT_GET_PAGING_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_UPDATE_SUCCESS,
} from './actionType';

// Product
export const getPagingProduct = items => ({
  type: PRODUCT_GET_PAGING,
  payload: items,
});

export const getPagingProductSuccess = items => ({
  type: PRODUCT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingProductError = error => ({
  type: PRODUCT_GET_PAGING_ERROR,
  payload: error,
});

export const addProduct = items => ({
  type: PRODUCT_ADD_NEW,
  payload: items,
});

export const addProductSuccess = items => ({
  type: PRODUCT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addProductError = error => ({
  type: PRODUCT_ADD_NEW_ERROR,
  payload: error,
});

export const getProductById = items => ({
  type: PRODUCT_GET_BY_ID,
  payload: items,
});

export const getProductByIdSuccess = items => ({
  type: PRODUCT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getProductByIdError = error => ({
  type: PRODUCT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateProduct = items => ({
  type: PRODUCT_UPDATE,
  payload: items,
});

export const updateProductSuccess = items => ({
  type: PRODUCT_UPDATE_SUCCESS,
  payload: items,
});

export const updateProductError = error => ({
  type: PRODUCT_UPDATE_ERROR,
  payload: error,
});

export const deleteProduct = items => ({
  type: PRODUCT_DELETE,
  payload: items,
});

export const deleteProductSuccess = items => ({
  type: PRODUCT_DELETE_SUCCESS,
  payload: items,
});

export const deleteProductError = error => ({
  type: PRODUCT_DELETE_ERROR,
  payload: error,
});
