import { call, put, takeEvery } from 'redux-saga/effects';
import {
  USER_DOCUMENT_ADD_NEW,
  USER_DOCUMENT_DELETE,
  USER_DOCUMENT_GET_BY_ID,
  USER_DOCUMENT_GET_PAGING,
  USER_DOCUMENT_UPDATE,
} from './actionType';

import {
  addUserDocumentSuccess,
  addUserDocumentError,
  getPagingUserDocumentSuccess,
  getPagingUserDocumentError,
  getUserDocumentByIdSuccess,
  getUserDocumentByIdError,
  updateUserDocumentSuccess,
  updateUserDocumentError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// UserDocument
const getPagingUserDocumentRequest = query => get("/api/UserDocument/paging", { params: query });
const addNewUserDocumentRequest = data => post("/api/UserDocument", data);
const getUserDocumentByIdRequest = id => get(`/api/UserDocument/${id}`);
const updateUserDocumentRequest = data => update("/api/UserDocument", data);
const deleteUserDocumentRequest = id => del(`/api/UserDocument/${id}`);

function* addNewUserDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewUserDocumentRequest, data);
    yield put(addUserDocumentSuccess(response));
    
    response = yield call(getPagingUserDocumentRequest, queryParams);
    yield put(getPagingUserDocumentSuccess(response));
  } catch (error) {
    yield put(addUserDocumentError(error.response.statusText));
  }
}

function* updateUserDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateUserDocumentRequest, data);
    yield put(updateUserDocumentSuccess(response));
    
    response = yield call(getPagingUserDocumentRequest, queryParams);
    yield put(getPagingUserDocumentSuccess(response));
  } catch (error) {
    yield put(updateUserDocumentError(error.response.statusText));
  }
}

function* getPagingUserDocument({ payload }) {
  try {
    let response = yield call(getPagingUserDocumentRequest, payload);
    yield put(getPagingUserDocumentSuccess(response));
  } catch (error) {
    yield put(getPagingUserDocumentError(error.response.statusText));
  }
}

function* getUserDocumentById({ payload }) {
  try {
    let response = yield call(getUserDocumentByIdRequest, payload);
    yield put(getUserDocumentByIdSuccess(response));
  } catch (error) {
    yield put(getUserDocumentByIdError(error.response.statusText));
  }
}

function* deleteUserDocument({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteUserDocumentRequest, data);

    response = yield call(getPagingUserDocumentRequest, queryParams);
    yield put(getPagingUserDocumentSuccess(response));
  } catch (error) {
    yield put(getPagingUserDocumentError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(USER_DOCUMENT_ADD_NEW, addNewUserDocument);
  yield takeEvery(USER_DOCUMENT_UPDATE, updateUserDocument);
  yield takeEvery(USER_DOCUMENT_GET_PAGING, getPagingUserDocument);
  yield takeEvery(USER_DOCUMENT_GET_BY_ID, getUserDocumentById);
  yield takeEvery(USER_DOCUMENT_DELETE, deleteUserDocument);
}

export {
  getPagingUserDocumentRequest
}

