import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER_NOTE,
  ADD_NEW_ORDER_NOTE,
  GET_ORDER_NOTE_BY_ID,
  UPDATE_ORDER_NOTE,
  DELETE_ORDER_NOTE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderNoteSuccess,
  getOrderNoteByIdSuccess,
  getOrderNoteFail,
  addNewOrderNoteSuccess,
  updateOrderNoteSuccess,
  deleteOrderNoteSuccess,
} from "./actions";

const getOrderNoteRequest = query =>
  get(`/api/OrderNote/paging`, { params: query });
const addNewOrderNoteRequest = ordernote => post(`/api/OrderNote`, ordernote);
const getOrderNoteByIdRequest = data => get(`/api/OrderNote/${data.id}`);
const updateOrderNoteRequest = ordernote => update(`/api/OrderNote`, ordernote);
const deleteOrderNoteRequest = data => del(`/api/OrderNote/${data.id}`);

function* fetchOrdernotes({ payload }) {
  try {
    const response = yield call(getOrderNoteRequest, payload);

    yield put(getOrderNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderNoteFail(error));
  }
}

function* addNewOrderNote({ payload }) {
  try {
    yield call(addNewOrderNoteRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderNoteRequest, { orderId });
    yield put(getOrderNoteSuccess(response));
    yield put(addNewOrderNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderNoteFail(error));
  }
}

function* updateOrderNote({ payload }) {
  try {
    yield call(updateOrderNoteRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderNoteRequest, { orderId });
    yield put(getOrderNoteSuccess(response));
    yield put(updateOrderNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderNoteFail(error));
  }
}

function* getOrderNoteById({ payload }) {
  try {
    var response = yield call(getOrderNoteByIdRequest, payload);
    yield put(getOrderNoteByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderNoteFail(error));
  }
}

function* deleteOrdernote({ payload }) {
  try {
    yield call(deleteOrderNoteRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderNoteRequest, { orderId });
    yield put(getOrderNoteSuccess(response));
    yield put(deleteOrderNoteSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderNoteFail(error));
  }
}

function* orderNoteSaga() {
  yield takeEvery(GET_ORDER_NOTE, fetchOrdernotes);
  yield takeEvery(ADD_NEW_ORDER_NOTE, addNewOrderNote);
  yield takeEvery(GET_ORDER_NOTE_BY_ID, getOrderNoteById);
  yield takeEvery(UPDATE_ORDER_NOTE, updateOrderNote);
  yield takeEvery(DELETE_ORDER_NOTE, deleteOrdernote);
}

export default orderNoteSaga;
