import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { timestampFileName } from "../../helpers/utils";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";

import {
  getPagingStockIn,
  addStockIn,
  updateStockIn,
  getStockInById,
  deleteStockIn,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { stockInService } from "services/stock-in-service";
import { debounce } from "lodash";
import ModalForm from "containers/uom/ModalForm";
import StockInTable from "containers/stock-in/StockInTable";

const StockInList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [moreActionIsOpen, setMoreActionIsOpen] = useState(false);
  const [statuses, setStatuses] = useState([]); 

  const {
    getPagingStockIn,
    addStockIn,
    history,
    updateStockIn,
    getStockInById,
    deleteStockIn,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = props;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingStockIn({ page, pageSize, term });
  }, [ page, pageSize, term ]);

  // useEffect(() => {
  //   if (message) {
  //     toastMessage(message);
  //     // clearUnitOfMeasurementError();
  //   }
  // }, [ message ]);

  const handleOnEdit = id => {
    history.push(`/stock-in/${ id }`)
    // getStockInById(id);
    // setModalEditIsOpen(true);
  };

  const handleUpdate = data => {
    updateStockIn({
      data,
      queryParams: {}
    });
    setModalEditIsOpen(false);
  };

  const toggleMoreAction = () => {
    setMoreActionIsOpen(prevState => !prevState);
  };

  const handleExportToExcel = () => {
    stockInService.exportToExcelRequest(
        {
            code: term ?? "",
            name: "",
            dateFrom: null,
            dateTo: null,
            statuses: statuses.map(status => status.value), 
            isDeleted: false,
        },
        `${timestampFileName()}_StockIn_List.xlsx`
    );
};


  const handleOnAddNew = () => {
    history.push("/stock-in/add-new")
    // setModalIsOpen(true);
  }

  const handleSubmit = data => {
    addStockIn({
      data,
      queryParams: {}
    });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteStockIn({
      data: id,
      queryParams: {}
    });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock In | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Stock In" breadcrumbItem="Stock In List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                          <ButtonDropdown
                                isOpen={ moreActionIsOpen }
                                toggle={ toggleMoreAction }
                            >
                              <DropdownToggle caret color="primary" outline>
                                <i className="mdi mdi-dots-vertical"></i>
                              </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                        onClick={ handleExportToExcel }
                                        className="text-primary"
                                    >
                                      <i className="fas fa-file-excel me-1"/>{ " " }
                                      Export to Excel
                                    </DropdownItem>
                              </DropdownMenu>
                      </ButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>:
                      <>
                        { items && (
                            <StockInTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                defaultPageSize={ pageSize }
                                onEdit={ handleOnEdit }
                                onConfirmDelete={ handleConfirmDelete }
                                onChangePage={ (page) => setPage(page) }
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }
                
                { item && (
                    <ModalForm
                        title={ "Edit UoM" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add UoM" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

StockInList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getPagingStockIn: PropTypes.func,
  addStockIn: PropTypes.func,
  updateStockIn: PropTypes.func,
  getStockInById: PropTypes.func,
  deleteStockIn: PropTypes.func,
};

const mapStateToProps = ({ stockIn }) => {
  return stockIn;
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingStockIn,
      addStockIn,
      updateStockIn,
      getStockInById,
      deleteStockIn,
    })(StockInList)
);
