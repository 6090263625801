import React from "react";
import { Card, CardBody } from "reactstrap";

const PickupInfoTab = () => {
  return (
    <Card>
      <CardBody></CardBody>
    </Card>
  );
};

export default PickupInfoTab;
