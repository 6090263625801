import {
    GET_DELIVERY_ITEMS,
    GET_DELIVERY_ITEMS_SUCCESS,
    GET_DELIVERY_ITEMS_FAIL,
    GET_DELIVERY_ITEM_BY_ID_SUCCESS,
    DELIVERY_ITEM_CLEAR_ERROR,
  } from "./actionTypes.js";
  const initState = {
    items: [],
    item: null,
    error: null,
    itemCount: 0,
    pageCount: 0,
    loading: false,
  };
  
  const deliveryItem = (state = initState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
      case GET_DELIVERY_ITEMS:
        return {
          ...state,
          loading: true,
        };
      case GET_DELIVERY_ITEMS_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          itemCount,
          pageCount,
          items: data,
          loading: false,
        };
      case GET_DELIVERY_ITEM_BY_ID_SUCCESS:
        return {
          ...state,
          item: payload,
        };
      case DELIVERY_ITEM_CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
      case GET_DELIVERY_ITEMS_FAIL:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  };
  
  export default deliveryItem;