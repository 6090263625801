import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Table } from "reactstrap";

const SaleOrderGenerateStockOutResultModal = props => {
  const { isOpen, toggle, title, onSubmit, result } = props
  return (
      <Modal size={ "lg" } isOpen={ isOpen } toggle={ toggle } backdrop="static">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
              result && <>
                {
                  result.isValid ? <>
                        <Alert color="success">Your Sale Order has been generated to Stock Out successfully.</Alert>
                      </> :
                      <>
                        <Alert color="danger">Error, Your Sale Order is invalid to generate stock out</Alert>
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered table-nowrap"
                        >
                          <thead>
                          <tr>
                            <th className={ "text-center" }>Name</th>
                            <th className={ "text-center" }>Barcode</th>
                            <th className={ "text-center" }>Exceed Quantity</th>
                          </tr>
                          </thead>
                          <tbody>
                          { result.items &&
                              result.items.map((product, index) => {
                                const { productCode, exceed, isValid, productName } = product;

                                return <tr key={ index }>
                                  <td className={ isValid ? 'text-success' : 'text-danger' }>{ productName }</td>
                                  <td className={ isValid ? 'text-success' : 'text-danger' }>{ productCode }</td>
                                  <td className={ 'text-end font-size-16 ' + (isValid ? 'text-success' : 'text-danger') }>{ exceed }</td>
                                </tr>
                              })
                          }
                          </tbody>
                        </Table>
                      </>
                }
              </>
          }
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ toggle }>
            Okay
          </Button>
        </div>
      </Modal>
  );
};

SaleOrderGenerateStockOutResultModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SaleOrderGenerateStockOutResultModal;