import React from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Card,
    CardHeader,
    CardSubtitle,
    CardImg,
    CardTitle,
    Col,
    Row,
    Input
} from "reactstrap";
import { getDeliveryStatusColor } from "../../helpers/utils";
import moment from "moment";
import NumberFormat from "react-number-format";

const DeliveryOrderRowCard = ({ item, num, onRowCheck }) => {
    const {productImages, id, type, deliveryId, isSelect } = item;
    let productImage = productImages && productImages.length > 0 ? productImages[0] : null;

    return (
        <Card className={`mb-2 border border-${getDeliveryStatusColor(item)}`}>
            <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
                <div style={{ width: "150px" }}>
                    {productImage ? (
            <CardImg
                style={{ width: "90px", height: "68px", objectFit: "contain" }}
                className={"p-1"}
                src={productImage.url}
                alt={productImage.fileName}
            />
            ) : (
                <div
                    style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    }}
                >
                <div  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    }} className="avatar-order">
                    <i className="fas fa-solid fa-cube text-primary"></i>
                </div>
                </div>
            )}
                </div>
                <div className={"flex-grow-1"}>
                    <CardHeader className={`text-white bg-${getDeliveryStatusColor(item)}`}>
                        <Row style={{ cursor: 'pointer' }}>
                            <Col md={1}>
                                <Input
                                    name={`check_${num}`}
                                    onClick={(e) => onRowCheck(e, item)}
                                    checked={isSelect}
                                    type="checkbox"
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardTitle>{type}</CardTitle>
                    <Card>Id: {id}</Card> 
                    <Card>Type: {type}</Card> 
                </div>
            </CardHeader>
        </Card>
    );
};

DeliveryOrderRowCard.propTypes = {
    item: PropTypes.shape({
        productImages: PropTypes.array,
        id: PropTypes.string,
        type: PropTypes.string,
        deliveryId: PropTypes.string,
        isSelect: PropTypes.bool
    }).isRequired,
    num: PropTypes.number.isRequired,
    onRowCheck: PropTypes.func.isRequired
};

export default DeliveryOrderRowCard;
