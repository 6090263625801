import {
  GET_PAGING_CASE_AUDIT,
  GET_PAGING_CASE_AUDIT_SUCCESS,
  GET_PAGING_CASE_AUDIT_FAIL,
} from "./actionTypes";


export const getPagingCaseAudit = query => ({
  type: GET_PAGING_CASE_AUDIT,
  payload: query,
});

export const getPagingCaseAuditSuccess = banners => ({
  type: GET_PAGING_CASE_AUDIT_SUCCESS,
  payload: banners,
});

export const getPagingCaseAuditFail = error => ({
  type: GET_PAGING_CASE_AUDIT_FAIL,
  payload: error,
});