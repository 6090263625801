import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import AttachmentCarusel from "./AttachmentCarusel";
import PickupConfirmModalForm from "./PickupConfirmModalForm";

const PickupModalForm = ({
  title,
  isOpen,
  item,
  toggle,
  onApprove,
  onReject,
}) => {
  const [id, setId] = useState(null);
  const [contactName, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [shippingContactName, setShippingContactName] = useState("");
  const [shippingContactPhone, setShippingContactPhone] = useState("");
  const [shippingContactAddress, setShippingContactAddress] = useState("");
  const [note, setNote] = useState("");

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
  const [confirmType, setConfirmType] = useState("");

  const onOpened = () => {
    if (item) {
      setId(item.id);
      setContactName(item.contactName);
      setPhone(item.phone);
      setAddress(item.address);

      setShippingContactName(item.shippingContactName);
      setShippingContactPhone(item.shippingContactPhone);
      setShippingContactAddress(item.shippingContactAddress);
      setNote(item.note);
    } else {
      setId(null);
      setContactName("");
      setPhone("");
      setAddress("");

      setShippingContactName("");
      setShippingContactPhone("");
      setShippingContactAddress("");
      setNote("");
    }
  };

  const handleOnSubmit = data => {
    const { confirmType, id, comment } = data;
    switch (confirmType) {
      case "Approve":
        onApprove({
          id: id,
          contactName,
          phone,
          address,
          shippingContactName,
          shippingContactPhone,
          shippingContactAddress,
          note,
          comment,
        });
        break;
      case "Reject":
        onReject({
          id: id,
          contactName,
          phone,
          address,
          shippingContactName,
          shippingContactPhone,
          shippingContactAddress,
          note,
          comment,
        });
        break;
      default:
        break;
    }
    setModalConfirmIsOpen(false);
  };

  return (
    <>
      <Modal
        size="xl"
        onOpened={onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title} From {item.uniqueId} | {item.firstName} {item.lastName}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={4}>
              <Card className="mb-1" color="border border-1 border-primary">
                <CardHeader className="bg-primary text-white">
                  <CardTitle>Korean Pickup Contact</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Label htmlFor="contactName">Contact Name</Label>
                    <Input
                      type="text"
                      name="contactName"
                      placeholder="Contact Name"
                      value={contactName}
                      onChange={e => {
                        setContactName(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      value={phone}
                      onChange={e => {
                        setPhone(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="contactName">Address</Label>
                    <Input
                      type="textarea"
                      name="address"
                      rows={"3"}
                      placeholder="Address"
                      value={address}
                      onChange={e => {
                        setAddress(e.target.value);
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
              <Card color="border border-1 border-primary">
                <CardHeader className="bg-primary text-white">
                  <CardTitle>Cambodian Shipping Contact</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Label htmlFor="contactName">Name</Label>
                    <Input
                      type="text"
                      name="shippingContactName"
                      placeholder="Contact Name"
                      value={shippingContactName}
                      onChange={e => {
                        setShippingContactName(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      type="text"
                      name="shippingContactPhone"
                      placeholder="Phone"
                      value={shippingContactPhone}
                      onChange={e => {
                        setShippingContactPhone(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="contactName">Address</Label>
                    <Input
                      type="textarea"
                      name="shippingContactAddress"
                      rows={"3"}
                      placeholder="Address"
                      value={shippingContactAddress}
                      onChange={e => {
                        setShippingContactAddress(e.target.value);
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              {item.attachments.length > 0 ? (
                <AttachmentCarusel attachments={item.attachments} />
              ) : (
                <div className="text-center">
                  <Alert color="warning">There is no attachment found.</Alert>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="contactName">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  rows={"3"}
                  placeholder="Note"
                  value={note}
                  onChange={e => {
                    setNote(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="success"
            onClick={() => {
              setConfirmType("Approve");
              setModalConfirmIsOpen(true);
            }}
            type="submit"
          >
            Approve
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setConfirmType("Reject");
              setModalConfirmIsOpen(true);
            }}
            type="submit"
          >
            Reject
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <PickupConfirmModalForm
        id={item.id}
        isOpen={modalConfirmIsOpen}
        confirmType={confirmType}
        toggle={() => setModalConfirmIsOpen(!modalConfirmIsOpen)}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

PickupModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  userId: PropTypes.string,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
};

export default PickupModalForm;
