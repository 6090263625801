import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";
import moment from "moment";
import avatar from "../../assets/images/avatar.png";

const CaseAuditRowCard = props => {
  const { item } = props;
  let profile = item.avatar ??  avatar;
  return (
      <Card className="mb-2">
        <CardHeader className={"bg-transparent border-bottom"}>
          <Row>
            <Col md={2} className="text-center">
                <img className={"rounded-circle avatar-lg"}
                     src={profile} alt={item.user}/>
            </Col>
            <Col>
              <CardTitle>
                {item.title}
              </CardTitle>
              <p className="mb-1">
                <i className="fas fa-user me-1"></i> 
                <span className="text-black me-2">By :</span>
                <span className="text-muted">{item.user}</span>
              </p>
              <p className="mb-1">
                <i className="fas fa-calendar me-1"></i> 
                <span className="text-black me-2">Date time:</span>
                <span className="text-muted"> {moment
                    .utc(item.createdDate)
                    .local()
                    .format("DD-MMM-yyyy h:mm:ss a")}</span>
              </p>
            </Col>
          </Row>
        </CardHeader>
      </Card>
  );
};

CaseAuditRowCard.propTypes = {
  item: PropTypes.object,
};

export default CaseAuditRowCard;