import { call, put, takeEvery } from 'redux-saga/effects';
import {
  VEHICLE_ADD_NEW,
  VEHICLE_DELETE,
  VEHICLE_GET_BY_ID,
  VEHICLE_GET_PAGING,
  VEHICLE_UPDATE,
} from './actionType';

import {
  addVehicleSuccess,
  addVehicleError,
  getPagingVehicleSuccess,
  getPagingVehicleError,
  getVehicleByIdSuccess,
  getVehicleByIdError,
  updateVehicleSuccess,
  updateVehicleError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// Vehicle
const getPagingVehicleRequest = query => get("/api/Vehicle/paging", { params: query });
const getVehicleRequest = () => get("/api/Vehicle");
const addNewVehicleRequest = data => post("/api/Vehicle", data);
const getVehicleByIdRequest = id => get(`/api/Vehicle/${id}`);
const updateVehicleRequest = data => update("/api/Vehicle", data);
const deleteVehicleRequest = id => del(`/api/Vehicle/${id}`);

function* addNewVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewVehicleRequest, data);
    yield put(addVehicleSuccess(response));
    
    response = yield call(getPagingVehicleRequest, queryParams);
    yield put(getPagingVehicleSuccess(response));
  } catch (error) {
    yield put(addVehicleError(error.response.statusText));
  }
}

function* updateVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateVehicleRequest, data);
    yield put(updateVehicleSuccess(response));

    response = yield call(getPagingVehicleRequest, queryParams);
    yield put(getPagingVehicleSuccess(response));
  } catch (error) {
    yield put(updateVehicleError(error.response.statusText));
  }
}

function* getPagingVehicle({ payload }) {
  try {
    let response = yield call(getPagingVehicleRequest, payload);
    yield put(getPagingVehicleSuccess(response));
  } catch (error) {
    yield put(getPagingVehicleError(error.response.statusText));
  }
}

function* getVehicleById({ payload }) {
  try {
    let response = yield call(getVehicleByIdRequest, payload);
    yield put(getVehicleByIdSuccess(response));
  } catch (error) {
    yield put(getVehicleByIdError(error.response.statusText));
  }
}

function* deleteVehicle({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteVehicleRequest, data);

    response = yield call(getPagingVehicleRequest, queryParams);
    yield put(getPagingVehicleSuccess(response));
  } catch (error) {
    yield put(getPagingVehicleError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(VEHICLE_ADD_NEW, addNewVehicle);
  yield takeEvery(VEHICLE_UPDATE, updateVehicle);
  yield takeEvery(VEHICLE_GET_PAGING, getPagingVehicle);
  yield takeEvery(VEHICLE_GET_BY_ID, getVehicleById);
  yield takeEvery(VEHICLE_DELETE, deleteVehicle);
}

export {
  getPagingVehicleRequest,
  getVehicleRequest
}

