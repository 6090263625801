import {
  USER_DOCUMENT_ADD_NEW,
  USER_DOCUMENT_ADD_NEW_ERROR,
  USER_DOCUMENT_ADD_NEW_SUCCESS,
  USER_DOCUMENT_DELETE,
  USER_DOCUMENT_DELETE_ERROR,
  USER_DOCUMENT_DELETE_SUCCESS,
  USER_DOCUMENT_GET_BY_ID,
  USER_DOCUMENT_GET_BY_ID_ERROR,
  USER_DOCUMENT_GET_BY_ID_SUCCESS,
  USER_DOCUMENT_GET_PAGING,
  USER_DOCUMENT_GET_PAGING_ERROR,
  USER_DOCUMENT_GET_PAGING_SUCCESS,
  USER_DOCUMENT_UPDATE,
  USER_DOCUMENT_UPDATE_ERROR,
  USER_DOCUMENT_UPDATE_SUCCESS,
} from './actionType';

// UserDocument
export const getPagingUserDocument = items => ({
  type: USER_DOCUMENT_GET_PAGING,
  payload: items,
});

export const getPagingUserDocumentSuccess = items => ({
  type: USER_DOCUMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingUserDocumentError = error => ({
  type: USER_DOCUMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addUserDocument = items => ({
  type: USER_DOCUMENT_ADD_NEW,
  payload: items,
});

export const addUserDocumentSuccess = items => ({
  type: USER_DOCUMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addUserDocumentError = error => ({
  type: USER_DOCUMENT_ADD_NEW_ERROR,
  payload: error,
});

export const getUserDocumentById = items => ({
  type: USER_DOCUMENT_GET_BY_ID,
  payload: items,
});

export const getUserDocumentByIdSuccess = items => ({
  type: USER_DOCUMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getUserDocumentByIdError = error => ({
  type: USER_DOCUMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateUserDocument = items => ({
  type: USER_DOCUMENT_UPDATE,
  payload: items,
});

export const updateUserDocumentSuccess = items => ({
  type: USER_DOCUMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateUserDocumentError = error => ({
  type: USER_DOCUMENT_UPDATE_ERROR,
  payload: error,
});

export const deleteUserDocument = items => ({
  type: USER_DOCUMENT_DELETE,
  payload: items,
});

export const deleteUserDocumentSuccess = items => ({
  type: USER_DOCUMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteUserDocumentError = error => ({
  type: USER_DOCUMENT_DELETE_ERROR,
  payload: error,
});
