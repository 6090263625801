import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_CURRENCIES,
  ADD_NEW_CURRENCY,
  GET_CURRENCY_BY_ID,
  UPDATE_CURRENCY,
  DELETE_CURRENCY, GET_DEFAULT_CURRENCY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCurrenciesSuccess,
  getCurrencyByIdSuccess,
  getCurrenciesFail, getDefaultCurrencySuccess,
} from "./actions";

const getCurrenciesRequest = query =>
  get("/api/currency/paging", { params: query });
const addNewCurrencyRequest = currency => post("/api/currency", currency);
const getCurrencyByIdRequest = id => get(`/api/currency/${id}`);
const updateCurrencyRequest = currency => update("/api/currency", currency);
const deleteCurrencyRequest = id => del(`/api/currency/${id}`);
const getDefaultRequest=() => get('/api/currency/default');

function* fetchCurrencies({ payload }) {
  try {
    const response = yield call(getCurrenciesRequest, payload);
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* addNewCurrency({ payload }) {
  try {
    yield call(addNewCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* updateCurrency({ payload }) {
  try {
    yield call(updateCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* getCurrencyById({ payload }) {
  try {
    var response = yield call(getCurrencyByIdRequest, payload);
    yield put(getCurrencyByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* getDefaultCurrency({ }) {
  try {
    let response = yield call(getDefaultRequest);
    yield put(getDefaultCurrencySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* deleteCurrency({ payload }) {
  try {
    yield call(deleteCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesFail(error));
  }
}

function* currencySaga() {
  yield takeEvery(GET_CURRENCIES, fetchCurrencies);
  yield takeEvery(ADD_NEW_CURRENCY, addNewCurrency);
  yield takeEvery(GET_CURRENCY_BY_ID, getCurrencyById);
  yield takeEvery(UPDATE_CURRENCY, updateCurrency);
  yield takeEvery(DELETE_CURRENCY, deleteCurrency);
  yield takeEvery(GET_DEFAULT_CURRENCY, getDefaultCurrency);
}

export default currencySaga;
