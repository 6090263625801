import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  Modal,
  Label,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

const countries = [
  { key: 0, value: "+855", label: "Cambodia (+855)", flag: "🇰🇭" },
  { key: 1, value: "+82", label: "South Korea (+82)", flag: "🇰🇷" },
];

const phoneNumberKoreanMask = [
  "(",
  "+",
  "8",
  "2",
  ")",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const phoneNumberMask = [
  "(",
  "+",
  "8",
  "5",
  "5",
  ")",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      isShow: false,
      isShowPwdConfirm: false,

      selectedCountry: {
        key: 1,
        value: "+82",
        label: "South Korea (+82)",
        flag: "🇰🇷",
      },
    };
  }

  handleSelectCountryChange = country => {
    this.setState({
      selectedCountry: country,
    });
  };

  toggleShowhide = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }));
  };

  toggleShowhidePwdConfirm = () => {
    this.setState(prevState => ({
      isShowPwdConfirm: !prevState.isShowPwdConfirm,
    }));
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { selectedCountry, isShow, isShowPwdConfirm } = this.state;
    return (
      <Modal
        // onOpened={this.onOpened}s
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: (this.state && this.state.firstName) || "",
            lastName: (this.state && this.state.lastName) || "",
            phoneNumber: (this.state && this.state.phoneNumber) || "",
            email: (this.state && this.state.email) || "",
            address: (this.state && this.state.address) || "",
            password: (this.state && this.state.password) || "",
            passwordConfirm: (this.state && this.state.passwordConfirm) || "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("Please Enter First Name"),
            lastName: Yup.string().required("Please Enter Last Name"),
            phoneNumber: Yup.string().required("Please Enter Phone Number"),
            email: Yup.string().email("Invalid Email"),
            password: Yup.string()
              .required("Please Enter Valid Password")
              .min(8, "Must be at least 8 characters long."),
            passwordConfirm: Yup.string()
              .required("Please Enter Valid Password Confirmation")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
          onSubmit={values => {
            this.setState({
              loading: true,
            });
            this.props.onSubmit(values);
          }}
        >
          {({ errors, status, touched, handleChange, handleBlur }) => (
            <>
              <Form className="form-horizontal">
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    {title}
                  </h5>
                  <button
                    type="button"
                    onClick={toggle}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="firstName" className="form-label">
                          First Name
                        </Label>
                        <Field
                          name="firstName"
                          type="text"
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="lastName" className="form-label">
                          Last Name
                        </Label>
                        <Field
                          name="lastName"
                          type="text"
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          Password
                        </Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Field
                            name="password"
                            autoComplete="new-password"
                            type={isShow ? "text" : "password"}
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                            onClick={this.toggleShowhide}
                          >
                            <i
                              className={
                                "mdi " +
                                (isShow
                                  ? "mdi-eye-off-outline"
                                  : "mdi-eye-outline")
                              }
                            ></i>
                          </button>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label for="passwordConfirm" className="form-label">
                          Password Confirmation
                        </Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Field
                            name="passwordConfirm"
                            type={isShowPwdConfirm ? "text" : "password"}
                            className={
                              "form-control" +
                              (errors.passwordConfirm && touched.passwordConfirm
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                            onClick={this.toggleShowhidePwdConfirm}
                          >
                            <i
                              className={
                                "mdi " +
                                (isShowPwdConfirm
                                  ? "mdi-eye-off-outline"
                                  : "mdi-eye-outline")
                              }
                            ></i>
                          </button>
                          <ErrorMessage
                            name="passwordConfirm"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label for="phoneNumber" className="form-label">
                          Phone Nubmer
                        </Label>
                        <br />
                        <div
                          className={
                            "btn-group w-100" +
                            (errors.phoneNumber && touched.phoneNumber
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <Dropdown
                            isOpen={this.state.singlebtn}
                            toggle={() =>
                              this.setState({
                                singlebtn: !this.state.singlebtn,
                              })
                            }
                          >
                            <DropdownToggle outline color="primary" caret>
                              <span className="me-2 font-size-13">
                                {selectedCountry.flag}
                              </span>
                              {selectedCountry.label}{" "}
                              <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              {countries.map((country, index) => {
                                return (
                                  <DropdownItem
                                    onClick={() =>
                                      this.handleSelectCountryChange(country)
                                    }
                                    key={index}
                                  >
                                    <span className="me-2">{country.flag}</span>
                                    {country.label}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                          <Field
                            name="phoneNumber"
                            type="phone"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={
                                  selectedCountry.value === "+82"
                                    ? phoneNumberKoreanMask
                                    : phoneNumberMask
                                }
                                id="phoneNumber"
                                guide={false}
                                placeholder="Enter your phone number"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.phoneNumber && touched.phoneNumber
                                    ? "form-control error"
                                    : "form-control"
                                }
                              />
                            )}
                            className={
                              "form-control" +
                              (errors.phoneNumber && touched.phoneNumber
                                ? " is-invalid"
                                : "")
                            }
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="mb-3">
                        <Label for="email" className="form-label">
                          Email
                        </Label>
                        <Field
                          name="email"
                          type="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="address" className="form-label">
                          Address
                        </Label>
                        <Field
                          name="address"
                          component="textarea"
                          rows="4"
                          className={
                            "form-control" +
                            (errors.address && touched.address
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    onClick={this.handleSubmit}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
