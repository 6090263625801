import {
  SALE_ORDER_ADD_NEW,
  SALE_ORDER_ADD_NEW_ERROR,
  SALE_ORDER_ADD_NEW_SUCCESS,
  SALE_ORDER_CHANGE_STATUS,
  SALE_ORDER_CHANGE_STATUS_ERROR,
  SALE_ORDER_CHANGE_STATUS_SUCCESS,
  SALE_ORDER_DELETE,
  SALE_ORDER_DELETE_ERROR,
  SALE_ORDER_DELETE_SUCCESS,
  SALE_ORDER_GET_BY_ID,
  SALE_ORDER_GET_BY_ID_ERROR,
  SALE_ORDER_GET_BY_ID_SUCCESS,
  SALE_ORDER_GET_PAGING,
  SALE_ORDER_GET_PAGING_ERROR,
  SALE_ORDER_GET_PAGING_SUCCESS,
  SALE_ORDER_UPDATE,
  SALE_ORDER_UPDATE_ERROR,
  SALE_ORDER_UPDATE_SUCCESS,
  SALE_ORDER_GENERATE_STOCK_OUT,
  SALE_ORDER_GENERATE_STOCK_OUT_ERROR,
  SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS
} from './actionType';

// SaleOrder
export const getPagingSaleOrder = items => ({
  type: SALE_ORDER_GET_PAGING,
  payload: items,
});

export const getPagingSaleOrderSuccess = items => ({
  type: SALE_ORDER_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingSaleOrderError = error => ({
  type: SALE_ORDER_GET_PAGING_ERROR,
  payload: error,
});

export const addSaleOrder = items => ({
  type: SALE_ORDER_ADD_NEW,
  payload: items,
});

export const addSaleOrderSuccess = items => ({
  type: SALE_ORDER_ADD_NEW_SUCCESS,
  payload: items,
});

export const addSaleOrderError = error => ({
  type: SALE_ORDER_ADD_NEW_ERROR,
  payload: error,
});

export const getSaleOrderById = items => ({
  type: SALE_ORDER_GET_BY_ID,
  payload: items,
});

export const getSaleOrderByIdSuccess = items => ({
  type: SALE_ORDER_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getSaleOrderByIdError = error => ({
  type: SALE_ORDER_GET_BY_ID_ERROR,
  payload: error,
});

export const updateSaleOrder = items => ({
  type: SALE_ORDER_UPDATE,
  payload: items,
});

export const updateSaleOrderSuccess = items => ({
  type: SALE_ORDER_UPDATE_SUCCESS,
  payload: items,
});

export const updateSaleOrderError = error => ({
  type: SALE_ORDER_UPDATE_ERROR,
  payload: error,
});

export const deleteSaleOrder = items => ({
  type: SALE_ORDER_DELETE,
  payload: items,
});

export const deleteSaleOrderSuccess = items => ({
  type: SALE_ORDER_DELETE_SUCCESS,
  payload: items,
});

export const deleteSaleOrderError = error => ({
  type: SALE_ORDER_DELETE_ERROR,
  payload: error,
});

export const changeSaleOrderStatus = items => ({
  type: SALE_ORDER_CHANGE_STATUS,
  payload: items,
});

export const changeSaleOrderStatusSuccess = items => ({
  type: SALE_ORDER_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeSaleOrderStatusError = error => ({
  type: SALE_ORDER_CHANGE_STATUS_ERROR,
  payload: error,
});

export const generateStockOutFromSaleOrder = payload => ({
  type: SALE_ORDER_GENERATE_STOCK_OUT,
  payload
});

export const generateStockOutFromSaleOrderSuccess = items => ({
  type: SALE_ORDER_GENERATE_STOCK_OUT_SUCCESS,
  payload: items,
});

export const generateStockOutFromSaleOrderError = error => ({
  type: SALE_ORDER_GENERATE_STOCK_OUT_ERROR,
  payload: error,
});


