import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Table } from "reactstrap";
import moment from "moment";
import NumberFormat from "react-number-format";

const DiscountPromotionTable = ({
                    itemCount,
                    page,
                    totalPage,
                    defaultPageSize,
                    items,
                    onEdit,
                    onConfirmDelete,
                    onChangePage,
                    onPageSizeChange,
                  }) => {

  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "success"
        break
      case 2:
        color = "dark"
        break
    }
    return (
        <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
          <span>{ statusName }</span>
        </Badge>
    )
  }
  
  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">

            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
              <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                <tr>
                  <th className={"text-center"} style={ { width: "80px" } }>#</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Discount</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Times used</th>
                  <th>Is Active</th>
                  <th style={ { width: "120px" } }>Action</th>
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => {
                  let num = (page - 1) * defaultPageSize + (index + 1);
                  return (
                      <tr key={ index } style={{cursor:"pointer"}} onDoubleClick={()=>onEdit(item.id)}>
                        <td style={ { textAlign: "right" } }>{ num }</td>
                        <td>{ item.name }</td>
                        <td>{ item.type }</td>
                        <td className={"text-end"}>
                          <NumberFormat
                              displayType="text"
                              value={Number(item.amount).toFixed(2)}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                          />
                        </td>
                        <td>{ item.startDate && moment(item.startDate).format('DD-MMM-YYYY') }</td>
                        <td>{  item.endDate && moment(item.endDate).format('DD-MMM-YYYY') }</td>
                        <td></td>
                        <td className={"text-center"}>
                          <span className={item.isActive? 'text-success': 'text-danger'}>
                            <i className={`font-size-18 fa `+(item.isActive ? 'fa-check': 'fa-times')}/>
                          </span>
                        </td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-primary" to="#">
                              <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={ () => onEdit(item.id) }
                              ></i>
                            </Link>

                            <Link className="text-danger" to="#">
                              <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={ () => onConfirmDelete(item.id) }
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                  );
                }) }
                </tbody>
              </Table>
            </div>
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  );
};

DiscountPromotionTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default DiscountPromotionTable;
