import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardImg, CardTitle,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row, 
  Tooltip,
  UncontrolledDropdown
} from "reactstrap";
import {
  displayDeliveryPaymentStatus, displayDeliveryStatus,
  displayPaymentStatus,
  displayPickupPaymentStatus, displayVerifyStatus, getDeliveryStatusColor, getDeliveryStatusColorCode,
  hasAccess
} from "../../helpers/utils";
import { permission } from "../../constants/permission";
import moment from "moment/moment";
import NumberFormat from "react-number-format";
import TooltipDocument from "./TooltipDocument";

const OrderRowCard = ({onEdit, onConfirmDelete, onDownloadPrintLabel, onPrintPreview, onAddNote, onSendInvoice, onDeliveryStatusChange, onAddToTransport,...props}) => {
  const { item, typeShow } = props
  const [isExpand, setIsExpand] = useState(false)
  const [ isTooltipOpen, setIsTooltipOpen ] = useState(false)
  
  const { 
    lastNote, 
    orderAttachments, 
    shippingContact, 
    deliveryStatus, 
    weight, 
    weightExtra, 
    totalAmount,
    totalCbmWeight,
    totalCbmWeightFromShipper,
    totalVolume,
    totalVolumeFromShipper,
    orderDocuments
  } = item;
  const { contact, phones, address } = shippingContact;
  
  let orderAttachment = null;
  let totalWeight = weight+ weightExtra;

  if (orderAttachments.length > 0) {
    orderAttachment = orderAttachments[0];
  }
  
  return (
      <Card className={`mb-2 border border-${getDeliveryStatusColor(item)}`}>
        <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
          <div style={{width: "250px"}}>
              {orderAttachment ? (
                  <CardImg
                      style={{ width: "250px", height: "200px", objectFit: "cover" }}
                      src={orderAttachment.url}
                      alt={orderAttachment.fileName}
                  />
              ) : (
                  <div
                      style={{
                        width: "250px",
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                  >
                    <div className="avatar-order">
                      {item.transportationType === "Sea" ? (
                          <i className="bx bxs-ship text-primary"></i>
                      ) : (
                          <i className="bx bxs-plane text-primary"></i>
                      )}
                    </div>
                  </div>
              )}
          </div>
          <div className={"flex-grow-1"}>
            <CardHeader className={`text-white` } style={{backgroundColor: `${getDeliveryStatusColorCode(item)}`}}>
              <Row onDoubleClick={() => typeShow !== "user" && onEdit(item.id)} style={{cursor:'pointer'}}>
                <Col md={4}>
                  <CardTitle>Sender</CardTitle>
                </Col>
                <Col md={4}>
                  <CardTitle>Receiver and Shipping</CardTitle>
                </Col>
                <Col md={ 4 }>
                  {orderDocuments.map(document =>{
                    return <TooltipDocument document={document}/>
                  })}
                  
                  
                 
                </Col>
              </Row>
              {typeShow !== "user" && <div
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    cursor: "pointer",
                    display: "flex"
                  }}

              >
                <span className="font-size-24 text-white ps-2 pe-2" onClick={ () => onDownloadPrintLabel(item.id) }>
                <i className="mdi mdi-download"></i>
              </span>
                <span className="font-size-24 text-white ps-2 pe-2" onClick={ () => onPrintPreview(item.id) }>
                <i className="mdi mdi-printer"></i>
              </span>
                <UncontrolledDropdown className={ "px-2" }>
                  <DropdownToggle className="card-drop text-white" tag="a">
                    <i className="mdi mdi-dots-horizontal font-size-24"/>
                  </DropdownToggle>
                  <DropdownMenu
                      end={true}
                      className="text-primary"
                  >
                    {hasAccess(permission.order.read) && (
                        <DropdownItem onClick={() => onEdit(item.id)}>
                          <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                          Edit
                        </DropdownItem>
                    )}
                    {hasAccess(permission.order.delete) && (
                        <DropdownItem
                            onClick={() => onConfirmDelete(item.id)}
                        >
                          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                          Delete
                        </DropdownItem>
                    )}
                    <DropdownItem divider />
                    {hasAccess(permission.orderNote.write) && (
                        <DropdownItem
                            onClick={() => onAddNote(item.id)}
                        >
                          <i className="fas fa-sticky-note font-size-16 text-primary me-1" />{" "}
                          Add Note
                        </DropdownItem>
                    )}
                    <DropdownItem
                        onClick={() => onDownloadPrintLabel(item.id)}
                    >
                      <i className="fas fa-download text-primary font-size-16 me-1" />{" "}
                      Download Label
                    </DropdownItem>
                    {hasAccess(
                        permission.order.changeDeliveryStatus
                    ) && (
                        <>
                          {[0, 1].includes(deliveryStatus) && ( // 0 is Draft, 1 is In Korea Warehouse
                              <DropdownItem
                                  onClick={() =>
                                      onDeliveryStatusChange(item)
                                  }
                              >
                                <i className="fas fa-sync text-primary font-size-16 me-1" />{" "}
                                Change Status to
                                {deliveryStatus === 0
                                    ? " [In Korea Warehouse]"
                                    : " [Draft]"}
                              </DropdownItem>
                          )}
                        </>
                    )}
                    {
                      deliveryStatus === 1 ?
                          <DropdownItem
                              onClick={ () => onAddToTransport(item.id) }
                          >
                            <i className="fas fa-truck-loading text-primary font-size-16 me-1"/>{ " " }
                            Add To Transport
                          </DropdownItem> : null
                    }
                    <DropdownItem divider />
                    {hasAccess(
                        permission.order.generateInvoice
                    ) && (
                        <DropdownItem
                            onClick={() => onSendInvoice(item)}
                        >
                          <i className="bx bx-receipt font-size-16 text-info me-1" />{" "}
                          {item.hasInvoice
                              ? "Regenerate Invoice"
                              : "Generate Invoice"}
                        </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <span className="font-size-24 text-white ps-2 pe-2" onClick={()=> setIsExpand(!isExpand)}>
                <i className={
                      isExpand
                          ? "fas fa-angle-up"
                          : "fas fa-angle-down"
                    }
                ></i>
              </span>
              </div>
}
            </CardHeader>
            <div style={{padding:"1.25rem"}}>
              <Row>
                <Col md={4}>
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                  >
                    <h5 className="font-size-14 mb-1">{item.sender} {item.senderRoles?.map((role, index)=>{
                      return <Badge key={index} color={"primary"} className={"mx-1 pt-1 px-2"}>{role.name}</Badge>
                    })}</h5>
                    <div className="d-flex">
                      <div className="right d-flex flex-column">
                        <p className="text-muted mb-0">ID:</p>
                        <p className="text-muted mb-0">Tracking No:</p>
                        <p className="text-muted mb-0">Whs In Date:</p>
                        <p className="text-muted mb-0">ETD:</p>
                        <p
                            className={
                                "mb-0 " +
                                [0, 1, 2, 8].includes(
                                    //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                    item.deliveryStatus
                                        ? "text-danger"
                                        : "text-muted"
                                )
                            }
                        >
                          ETA:
                        </p>
                        {item.originalReference && (
                            <p
                                className="text-muted text-truncate mb-0"
                                style={{ width: "120px" }}
                            >
                              Orig Ref:
                            </p>
                        )}
                      </div>
                      <div
                          className="d-flex flex-column"
                          style={{ marginLeft: "1rem" }}
                      >
                        <p className="text-muted mb-0">
                          {item.senderUniqueId}
                        </p>
                        <p className="text-muted mb-0">
                          {item.trackingNumber}
                        </p>
                        <p className="text-muted mb-0">
                          {moment
                              .utc(item.createdDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                        </p>
                        <p className="text-muted mb-0">
                          {item.etd ? (
                              <>{moment(item.etd).format("DD-MMM-yyyy")}</>
                          ) : (
                              <>N/A</>
                          )}
                        </p>
                        <p
                            className={
                                "mb-0 " +
                                [0, 1, 2, 8].includes(
                                    //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                    item.deliveryStatus
                                        ? "text-danger"
                                        : "text-muted"
                                )
                            }
                        >
                          {item.eta ? (
                              <>{moment(item.eta).format("DD-MMM-yyyy")}</>
                          ) : (
                              <>N/A</>
                          )}
                        </p>
                        {item.originalReference && (
                            <p
                                className="text-muted text-truncate mb-0"
                                style={{ width: "120px" }}
                            >
                              {item.originalReference}
                            </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className={"pe-0"}>
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                  >
                    {item.senderUniqueId !== item.receiverUniqueId ? (
                        <h5 className="font-size-14 mb-1">
                          {item.receiver}
                        </h5>
                    ) : (
                        <h5 className="font-size-14 mb-1">{contact}</h5>
                    )}
                    <div className="d-flex">
                      <div className="right d-flex flex-column">
                        {item.senderUniqueId !==
                            item.receiverUniqueId && (
                                <>
                                  <p className="text-muted mb-0">ID:</p>
                                  <p className="text-muted mb-0">Contact:</p>
                                </>
                            )}
                        <p className="text-muted mb-0">Phone:</p>
                        <p className="text-muted mb-0">Address:</p>
                      </div>
                      <div
                          className="d-flex flex-column"
                          style={{ marginLeft: "1rem" }}
                      >
                        {item.senderUniqueId !==
                            item.receiverUniqueId && (
                                <>
                                  <p className="text-muted mb-0">
                                    {item.receiverUniqueId}
                                  </p>
                                  <p className="text-muted mb-0">{contact}</p>
                                </>
                            )}
                        <p className="text-muted mb-0">
                          {phones && phones.length > 0 ? (
                              phones.map((phone, index) => {
                                return (
                                    <Badge
                                        color="info"
                                        className="p-1 me-1"
                                        key={index}
                                    >
                                      <NumberFormat
                                          value={phone.phoneNumber}
                                          displayType="text"
                                          format={"### ### ####"}
                                      />
                                    </Badge>
                                );
                              })
                          ) : (
                              <Badge color="info" className="p-1 me-1">
                                N/A
                              </Badge>
                          )}
                        </p>
                        <p
                            className="text-muted mb-0 text-truncate"
                            style={{ width: "120px" }}
                        >
                          {address}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className={"px-0"}>
                  <div className="d-flex">
                    <div className="right d-flex flex-column">
                      <p className="text-muted mb-0">Quantity :</p>
                      <p className="text-muted mb-0">Shipping :</p>
                      {item.isPickup && (
                          <p className="text-muted mb-0">Pickup :</p>
                      )}
                      {item.isDelivery && (
                          <p className="text-muted mb-0">Delvery :</p>
                      )}
                      <p className="text-muted mb-0">Delivery Status :</p>
                      <p className="text-muted mb-0">Verify Status :</p>
                      {
                        totalWeight && totalWeight > 0 ?
                        <p className="text-muted mb-0">Weight :</p>:null
                      }
                      { 
                        totalCbmWeight > 0 ? <p className="text-muted mb-0">CBM Weight :</p>:null
                      }
                      {
                        totalVolume > 0 ? <p className="text-muted mb-0">CBM Volume :</p>:null
                      }
                      {
                        totalCbmWeightFromShipper > 0 ? <p className="text-muted mb-0">CBM Weight *:</p>:null
                      }
                      {
                        totalVolumeFromShipper > 0 ? <p className="text-muted mb-0">CBM Volume *:</p>:null
                      }
                      
                      <p className="text-muted mb-0">Cost :</p>
                    </div>
                    <div
                        className="d-flex flex-column"
                        style={{ marginLeft: "1rem" }}
                    >
                      <p className="text-muted mb-0">
                        {item.quantity} {item.unit}
                      </p>
                      <p className="text-muted mb-0">
                        {displayPaymentStatus(item)}
                      </p>
                      {item.isPickup && (
                          <p className="text-muted mb-0">
                            {displayPickupPaymentStatus(item)}
                          </p>
                      )}
                      {item.isDelivery && (
                          <p className="text-muted mb-0">
                            {displayDeliveryPaymentStatus(item)}
                          </p>
                      )}
                      <p className="text-muted mb-0">
                        {displayDeliveryStatus(item)}
                      </p>
                      <p className="text-muted mb-0">
                        {displayVerifyStatus(item)}
                      </p>
                      {
                        totalWeight && totalWeight > 0 ?
                        <p className="text-muted mb-0">
                          <NumberFormat type={"text"} displayType="text" value={totalWeight}/>
                          {" "}<span>kg</span>
                        </p>: null
                      }
                      {
                        totalCbmWeight > 0 ? <p className="text-muted mb-0">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalCbmWeight).toFixed(2)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                        /> {" "}<span>kg</span></p>:null
                      }
                      {
                        totalVolume > 0 ? <p className="text-muted mb-0">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalVolume).toFixed(3)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                        />{" "}<span>m<sup>3</sup></span></p>:null
                      }
                      {
                        totalCbmWeightFromShipper > 0 ? <p className="text-muted mb-0">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalCbmWeightFromShipper).toFixed(2)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                        />{" "}<span>kg</span></p>:null
                      }
                      {
                        totalVolumeFromShipper > 0 ? <p className="text-muted mb-0">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalVolumeFromShipper).toFixed(3)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                        />{" "}<span>m<sup>3</sup></span></p>:null
                      }
                      <p className="text-muted mb-0">
                        <NumberFormat
                            displayType="text"
                            value={Number(totalAmount).toFixed()}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                        />
                        <span className="ps-1 font-size-12">
                          <i className={"fas fa-won-sign"}></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>  
            </div>
          </div>
          
          
        </CardHeader>
        <Collapse isOpen={isExpand}>
          <CardBody>
            {lastNote &&
                <>
                  <CardTitle>Note</CardTitle>
                  <p className="text-muted">{lastNote?.note}</p>
                  <CardTitle>Created Date</CardTitle>
                  <p className="text-muted">
                    {moment
                        .utc(lastNote?.createdDate)
                        .local()
                        .format("DD-MMM-yyyy h:mm:ss a")}
                  </p>
                  <CardTitle>Created By</CardTitle>
                  <p className="text-muted">{lastNote?.createdByUser}</p>  
                </>
            }
          </CardBody>
        </Collapse>
      </Card>
  );
};

OrderRowCard.propTypes = {
  item: PropTypes.object,
  typeShow: PropTypes.string,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onPrintPreview: PropTypes.func,
  onDownloadPrintLabel: PropTypes.func,
  onAddNote: PropTypes.func,
  onSendInvoice: PropTypes.func,
  onDeliveryStatusChange: PropTypes.func,
  onAddToTransport: PropTypes.func,
};

export default OrderRowCard;