import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import { displayRoleBadge } from "helpers/utils";

// users
import avatar from "../../../assets/images/avatar.png";

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const profileImage = avatar;

    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onViewDetail,
      onResetPassword,
      onConfirmDelete,
      onSendNotification,
      onChangePage,
      onPageSizeChange,
    } = this.props;

    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
                >
                  <Thead>
                    <Tr>
                      <Th>Profile</Th>
                      <Th>User Id</Th>
                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                      <Th>Phone Number</Th>
                      <Th>Roles</Th>

                      <Th style={{ width: "120px" }}>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.map((item, index) => {
                      let roles = item.roles.map(a => {
                        return a.name;
                      });
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onDoubleClick={() => onViewDetail(item.id)}
                        >
                          <Td style={{ width: "50px" }}>
                            {item.avatar != null ? (
                              <img
                                src={item.avatar}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            ) : (
                              <img
                                src={profileImage}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            )}
                          </Td>
                          <Td>{item.uniqueId}</Td>
                          <Td>{item.firstName}</Td>
                          <Td>{item.lastName}</Td>
                          <Td>{item.phoneNumber}</Td>
                          <Td>
                            {roles.map((a, index) => {
                              return (
                                <span className="me-1" key={index}>
                                  {displayRoleBadge(a)}
                                </span>
                              );
                            })}
                          </Td>
                          <Td>
                            <div className="text-center">
                              <UncontrolledDropdown>
                                <DropdownToggle className="card-drop" tag="a">
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end text-primary">
                                  <DropdownItem
                                    onClick={() => onViewDetail(item.id)}
                                  >
                                    <i className="mdi mdi-account-details font-size-16 text-primary me-1" />{" "}
                                    Details
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => onConfirmDelete(item.id)}
                                  >
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                    Delete
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => onSendNotification(item.id)}
                                  >
                                    <i className="mdi mdi-bell font-size-16 text-primary me-1" />{" "}
                                    Send Notification
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() => onResetPassword(item.id)}
                                  >
                                    <i className="mdi mdi-account-key font-size-16 text-primary me-1" />{" "}
                                    Reset Password
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </Td>
                        </tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

UserTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onResetPassword: PropTypes.func,
  onSendNotification: PropTypes.func,
};

export default UserTable;
