// ALERT_NOTIFICATION
export const GET_ALERT_NOTIFICATION = "GET_ALERT_NOTIFICATION";
export const GET_ALERT_NOTIFICATION_SUCCESS = "GET_ALERT_NOTIFICATION_SUCCESS";
export const GET_ALERT_NOTIFICATION_FAIL = "GET_ALERT_NOTIFICATION_FAIL";

export const GET_ALERT_NOTIFICATION_BY_ID = "GET_ALERT_NOTIFICATION_BY_ID";
export const GET_ALERT_NOTIFICATION_BY_ID_SUCCESS =
  "GET_ALERT_NOTIFICATION_BY_ID_SUCCESS";

export const ADD_NEW_ALERT_NOTIFICATION = "ADD_NEW_ALERT_NOTIFICATION";
export const ADD_NEW_ALERT_NOTIFICATION_SUCCESS =
  "ADD_NEW_ALERT_NOTIFICATION_SUCCESS";

export const UPDATE_ALERT_NOTIFICATION = "UPDATE_ALERT_NOTIFICATION";
export const UPDATE_ALERT_NOTIFICATION_SUCCESS =
  "UPDATE_ALERT_NOTIFICATION_SUCCESS";

export const DELETE_ALERT_NOTIFICATION = "DELETE_ALERT_NOTIFICATION";
export const DELETE_ALERT_NOTIFICATION_SUCCESS =
  "DELETE_ALERT_NOTIFICATION_SUCCESS";

export const ALERT_NOTIFICATION_CLEAR_NOTIFY =
  "ALERT_NOTIFICATION_CLEAR_NOTIFY";
