import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Table } from "react-super-responsive-table";
import moment from "moment";
import CustomPagination from "components/Common/CustomPagination";
import PickupRowCard from "./PickupRowCard";

const PickupTable = ({
  onViewDetail,
  onApprove,
  onReject,
  onChangePage,
  onPageSizeChange,
  ...props
}) => {
  const { items, itemCount, totalPage, page, pageSize } = props;

  return (
    <>
      {items.map((item, index) => {
        return (
          <PickupRowCard
            key={index}
            item={item}
            onViewDetail={onViewDetail}
            onApprove={onApprove}
            onReject={onReject}
          />
        );
      })}

      <CustomPagination
        itemCount={itemCount}
        currentPage={page}
        totalPage={totalPage}
        defaultPageSize={pageSize}
        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
        onChangePage={i => onChangePage(i)}
        onPageSizeChange={size => onPageSizeChange(size)}
      />
    </>
  );
};

PickupTable.propTypes = {
  myInvoice: PropTypes.bool,
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  pageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onViewDetail: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onNotificationForPayment: PropTypes.func,
};

export default PickupTable;
