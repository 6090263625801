import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STOCK_IN_ADD_NEW,
  STOCK_IN_DELETE,
  STOCK_IN_GET_BY_ID,
  STOCK_IN_GET_PAGING,
  STOCK_IN_UPDATE,
} from './actionType';

import {
  addStockInSuccess,
  addStockInError,
  getPagingStockInSuccess,
  getPagingStockInError,
  getStockInByIdSuccess,
  getStockInByIdError,
  updateStockInSuccess,
  updateStockInError,
  deleteStockInSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// StockIn
const getPagingStockInRequest = query => get("/api/StockIn/paging", { params: query });
const addNewStockInRequest = data => post("/api/StockIn", data);
const getStockInByIdRequest = id => get(`/api/StockIn/${id}`);
const updateStockInRequest = data => update("/api/StockIn", data);
const deleteStockInRequest = id => del(`/api/StockIn/${id}`);

const getStockInToExcel = async (fileName) => {
  return await getFile(`/api/StockIn/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* addNewStockIn({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(addNewStockInRequest, data);
    yield put(addStockInSuccess(response));
    history.push('/stock-in')
    // response = yield call(getPagingStockInRequest, queryParams);
    // yield put(getPagingStockInSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(addStockInError(err));
  }
}

function* updateStockIn({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateStockInRequest, data);
    yield put(updateStockInSuccess(response));
    history.push('/stock-in')
    // response = yield call(getPagingStockInRequest, queryParams);
    // yield put(getPagingStockInSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(updateStockInError(err));
  }
}

function* getPagingStockIn({ payload }) {
  try {
    let response = yield call(getPagingStockInRequest, payload);
    yield put(getPagingStockInSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockInError(err));
  }
}

function* getStockInById({ payload }) {
  try {
    let response = yield call(getStockInByIdRequest, payload);
    yield put(getStockInByIdSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getStockInByIdError(err));
  }
}

function* deleteStockIn({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteStockInRequest, data);

    yield put(deleteStockInSuccess(response));
    response = yield call(getPagingStockInRequest, queryParams);
    yield put(getPagingStockInSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockInError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(STOCK_IN_ADD_NEW, addNewStockIn);
  yield takeEvery(STOCK_IN_UPDATE, updateStockIn);
  yield takeEvery(STOCK_IN_GET_PAGING, getPagingStockIn);
  yield takeEvery(STOCK_IN_GET_BY_ID, getStockInById);
  yield takeEvery(STOCK_IN_DELETE, deleteStockIn);
}

export {
  getPagingStockInRequest,
  getStockInToExcel
}

