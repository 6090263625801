import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getProductUoms,
  addNewProductUom,
  getProductUomById,
  updateProductUom,
  deleteProductUom,
  clearProductUomError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ModalForm from "containers/product-uom/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import NumberFormat from "react-number-format"

const ProductUomTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
      useState(false);
  let totalQuantityBase=0;

  const {
    getProductUoms,
    addNewProductUom,
    getProductUomById,
    updateProductUom,
    deleteProductUom,
    clearProductUomError,
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
    productId,
  } = props;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductUomError();
    }
  }, [message]);

  const handleSubmit = data => {
    
    addNewProductUom({
      data: {
        ...data,
        productId
      },
      queryParam: {
        productId
      }
    });
    
    setModalAddIsOpen(false);
  };

  const handleUpdate = data => {
    updateProductUom({
      data,
      queryParam: {
        productId
      }
    });
    
    setModalEditIsOpen(false);
  };

  const handleDelete = () => {
    deleteProductUom({
      data: selectedId,
      queryParam: {
        productId
      }
    });
    
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => {
                          const { value } = e.target;
                          //   debouncedQuickSearch(value);
                        }}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={10}>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={() => setModalAddIsOpen(true)}
                  >
                    <i className="fas fa-plus" /> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={{ width: "80px" }}>#</th>
                        <th>Title</th>
                        <th>On Hand</th>
                        <th>Out going </th>
                        <th>Min </th>
                        <th>UoM</th>
                        <th>Conversion Rate</th>
                        <th>On Hand Quantity (Based)</th>
                        <th>Display Order</th>
                        <th>Is Base</th>
                        <th>Note</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      {items.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        totalQuantityBase += item.onHand * item.conversionRate;
                        
                        return (
                            <tr key={index}>
                              <td style={{ textAlign: "right" }}>{num}</td>
                              <td>{item.title}</td>


                              <td className="text-end">
                                <NumberFormat
                                    value={ item.onHand }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.outgoing }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.min}
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td>{item.uomName}</td>
                              <td className="text-end">
                                {item.conversionRate}
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ Number(item.onHand * item.conversionRate).toFixed(0) }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">{item.displayOrder}</td>
                              <td className="text-end">{item.isBase ? "Yes": "No"}</td>
                              <td>{item.note}</td>
                              <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-primary" to="#">
                                    <i
                                        className="mdi mdi-pencil font-size-18"
                                        id="edittooltip"
                                        onClick={() => {
                                          getProductUomById(item.id);
                                          setModalEditIsOpen(true);
                                        }}
                                    ></i>
                                  </Link>

                                  <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                          setSelectedId(item.id);
                                          setModalConfirmDeleteIsOpen(true);
                                        }}
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                      <tfoot>
                      <tr>
                        <th colSpan={2}>Total</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className="text-end">
                          <NumberFormat
                              value={ totalQuantityBase}
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      </tfoot>
                    </Table>
                  </div>
              )}
            </div>
            <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                // onChangePage={i => onChangePage(i)}
                // onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Unit of Measurement"
            isOpen={modalAddIsOpen}
            toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
            onSubmit={handleSubmit}
        />
        {item ? (
            <ModalForm
                title="Edit Unit of Measurement"
                item={item}
                isOpen={modalEditIsOpen}
                toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                onSubmit={handleUpdate}
            />
        ) : null}

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={modalConfirmDeleteIsOpen}
            toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
            onSubmit={handleDelete}
        />
      </div>
  );
};


ProductUomTab.propTypes = {
  productId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  totalAmount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getProductUoms: PropTypes.func,
  addNewProductUom: PropTypes.func,
  getProductUomById: PropTypes.func,
  updateProductUom: PropTypes.func,
  deleteProductUom: PropTypes.func,
  clearProductUomError: PropTypes.func,
};

const mapStateToProps = ({ productUom }) => {
  return productUom;
};

export default withRouter(
    connect(mapStateToProps, {
      getProductUoms,
      addNewProductUom,
      getProductUomById,
      updateProductUom,
      deleteProductUom,
      clearProductUomError,
    })(ProductUomTab)
);
