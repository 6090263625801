import React from 'react';
import { useSettingsService } from '../providers/settings.provider';

export const withSettings = Component => {
  return props => {
    const { appInitialized, company } = useSettingsService();

    return <Component appInitialized={appInitialized} company={company} {...props} />;
  };
};
