import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button, Input, CardTitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import OrderNoteRow from "./OrderNoteRow";

class OrderNoteTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,
      onAddNew,
      onSearch,
      onChangePage,
      onPageSizeChange,
      isReadOnly,
    } = this.props;
    return (
      <>
        <Card className="mb-2">
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={e => onSearch(e)}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </div>
              {!isReadOnly && (
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => onAddNew()}
                  >
                    <i className="fas fa-plus" /> Add
                  </Button>
                </div>
              )}
            </div>
          </CardBody>
        </Card>

        <div className="table-rep-plugin">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive" data-pattern="priority-columns">
              {items.map((item, index) => {
                return (
                  <OrderNoteRow
                    item={item}
                    key={index}
                    onEdit={onEdit}
                    onConfirmDelete={onConfirmDelete}
                    isReadOnly={isReadOnly}
                  />
                );
              })}
            </div>
          )}
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

OrderNoteTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default OrderNoteTable;
