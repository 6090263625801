import {
  GET_ALERT_NOTIFICATION,
  GET_ALERT_NOTIFICATION_SUCCESS,
  GET_ALERT_NOTIFICATION_FAIL,
  GET_ALERT_NOTIFICATION_BY_ID,
  GET_ALERT_NOTIFICATION_BY_ID_SUCCESS,
  ADD_NEW_ALERT_NOTIFICATION,
  ADD_NEW_ALERT_NOTIFICATION_SUCCESS,
  UPDATE_ALERT_NOTIFICATION,
  UPDATE_ALERT_NOTIFICATION_SUCCESS,
  DELETE_ALERT_NOTIFICATION,
  DELETE_ALERT_NOTIFICATION_SUCCESS,
  ALERT_NOTIFICATION_CLEAR_NOTIFY,
} from "./actionTypes";

// AlertNotification
export const getAlertNotification = id => ({
  type: GET_ALERT_NOTIFICATION,
  payload: id,
});

export const getAlertNotificationSuccess = items => ({
  type: GET_ALERT_NOTIFICATION_SUCCESS,
  payload: items,
});

export const getAlertNotificationFail = error => ({
  type: GET_ALERT_NOTIFICATION_FAIL,
  payload: error,
});

export const getAlertNotificationById = data => ({
  type: GET_ALERT_NOTIFICATION_BY_ID,
  payload: data,
});

export const getAlertNotificationByIdSuccess = item => ({
  type: GET_ALERT_NOTIFICATION_BY_ID_SUCCESS,
  payload: item,
});

export const getAlertNotificationByIdFail = error => ({
  type: GET_ALERT_NOTIFICATION_BY_ID_FAIL,
  payload: error,
});

export const addNewAlertNotification = payload => ({
  type: ADD_NEW_ALERT_NOTIFICATION,
  payload,
});

export const addNewAlertNotificationSuccess = payload => ({
  type: ADD_NEW_ALERT_NOTIFICATION_SUCCESS,
  payload,
});

export const updateAlertNotification = payload => ({
  type: UPDATE_ALERT_NOTIFICATION,
  payload,
});

export const updateAlertNotificationSuccess = payload => ({
  type: UPDATE_ALERT_NOTIFICATION_SUCCESS,
  payload,
});

export const deleteAlertNotification = payload => ({
  type: DELETE_ALERT_NOTIFICATION,
  payload,
});

export const deleteAlertNotificationSuccess = payload => ({
  type: DELETE_ALERT_NOTIFICATION_SUCCESS,
  payload,
});

export const clearAlertNotificationNotify = () => ({
  type: ALERT_NOTIFICATION_CLEAR_NOTIFY,
});
