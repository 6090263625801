import React, { Component } from "react";
import PropTypes from "prop-types";

import { Card, CardBody } from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displayDeliveryOrderStatus } from "helpers/utils";

import NumberFormat from "react-number-format";

class DeliveryOrderReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,

      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Delivery Agency</th>
                      <th>Deliverer</th>
                      <th>Order Count</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      const { deliverer } = item;
                      const { phones } = deliverer;

                      return (
                        <tr key={index}>
                          <td>{num}</td>
                          <td>{item.title}</td>
                          <td>
                            <p className="text-truncate mb-1">
                              {item.deliveryAgency}
                            </p>
                          </td>
                          <td>
                            <p className="text-truncate mb-1">
                              {deliverer.name}
                            </p>
                            {phones.map((phone, index) => {
                              return (
                                <p key={index} className="mb-0 text-muted">
                                  <small>
                                    <i className="fas fa-phone-alt me-2"></i>{" "}
                                    <NumberFormat
                                      value={phone.phoneNumber}
                                      displayType="text"
                                      format={"### ### ####"}
                                    />
                                  </small>
                                </p>
                              );
                            })}
                          </td>
                          <td className="text-end">
                            {item?.orders?.length ?? 0}
                          </td>
                          <td>{displayDeliveryOrderStatus(item)}</td>
                          <td>{moment(item.date).format("DD-MMM-yyyy")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

DeliveryOrderReportTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default DeliveryOrderReportTable;
