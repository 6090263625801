import {
  GET_ALERT_NOTIFICATION_HISTORY,
  GET_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  GET_ALERT_NOTIFICATION_HISTORY_FAIL,
  GET_ALERT_NOTIFICATION_HISTORY_BY_ID,
  GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS,
  ADD_NEW_ALERT_NOTIFICATION_HISTORY,
  ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  UPDATE_ALERT_NOTIFICATION_HISTORY,
  UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  DELETE_ALERT_NOTIFICATION_HISTORY,
  DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY,
} from "./actionTypes";

// AlertNotificationHistory
export const getAlertNotificationHistory = id => ({
  type: GET_ALERT_NOTIFICATION_HISTORY,
  payload: id,
});

export const getAlertNotificationHistorySuccess = items => ({
  type: GET_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  payload: items,
});

export const getAlertNotificationHistoryFail = error => ({
  type: GET_ALERT_NOTIFICATION_HISTORY_FAIL,
  payload: error,
});

export const getAlertNotificationHistoryById = data => ({
  type: GET_ALERT_NOTIFICATION_HISTORY_BY_ID,
  payload: data,
});

export const getAlertNotificationHistoryByIdSuccess = item => ({
  type: GET_ALERT_NOTIFICATION_HISTORY_BY_ID_SUCCESS,
  payload: item,
});

export const getAlertNotificationHistoryByIdFail = error => ({
  type: GET_ALERT_NOTIFICATION_HISTORY_BY_ID_FAIL,
  payload: error,
});

export const addNewAlertNotificationHistory = payload => ({
  type: ADD_NEW_ALERT_NOTIFICATION_HISTORY,
  payload,
});

export const addNewAlertNotificationHistorySuccess = payload => ({
  type: ADD_NEW_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const updateAlertNotificationHistory = payload => ({
  type: UPDATE_ALERT_NOTIFICATION_HISTORY,
  payload,
});

export const updateAlertNotificationHistorySuccess = payload => ({
  type: UPDATE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const deleteAlertNotificationHistory = payload => ({
  type: DELETE_ALERT_NOTIFICATION_HISTORY,
  payload,
});

export const deleteAlertNotificationHistorySuccess = payload => ({
  type: DELETE_ALERT_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const clearAlertNotificationHistoryNotify = () => ({
  type: ALERT_NOTIFICATION_HISTORY_CLEAR_NOTIFY,
});
