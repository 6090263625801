import {
  GET_TRANSPORT_ORDERS,
  GET_TRANSPORT_ORDERS_SUCCESS,
  GET_TRANSPORT_ORDERS_FAIL,
  GET_TRANSPORT_ORDER_BY_ID,
  GET_TRANSPORT_ORDER_BY_ID_SUCCESS,
  ADD_NEW_TRANSPORT_ORDER,
  ADD_NEW_TRANSPORT_ORDERS,
  UPDATE_TRANSPORT_ORDER,
  DELETE_TRANSPORT_ORDER,
  DELETE_TRANSPORT_ORDERS,
  TRANSPORT_ORDER_CLEAR_ERROR,
  ADD_NOTE_TRANSPORT_ORDER_ITEM,
  ADD_NOTE_TRANSPORT_ORDER_ITEM_SUCCESS,
  CHANGE_STATUS_TRANSPORT_ORDER_ITEM,
  CHANGE_STATUS_TRANSPORT_ORDER_ITEM_SUCCESS,
} from "./actionTypes";

// TransportOrder
export const getTransportOrders = query => ({
  type: GET_TRANSPORT_ORDERS,
  payload: query,
});

export const getTransportOrdersSuccess = items => ({
  type: GET_TRANSPORT_ORDERS_SUCCESS,
  payload: items,
});

export const getTransportOrdersFail = error => ({
  type: GET_TRANSPORT_ORDERS_FAIL,
  payload: error,
});

export const getTransportOrderById = id => ({
  type: GET_TRANSPORT_ORDER_BY_ID,
  payload: id,
});

export const getTransportOrderByIdSuccess = item => ({
  type: GET_TRANSPORT_ORDER_BY_ID_SUCCESS,
  payload: item,
});

export const getTransportOrderByIdFail = error => ({
  type: GET_TRANSPORT_ORDER_BY_ID_FAIL,
  payload: error,
});

export const addNewTransportOrder = item => ({
  type: ADD_NEW_TRANSPORT_ORDER,
  payload: item,
});

export const addNoteToTransportOrderItem = payload => ({
  type: ADD_NOTE_TRANSPORT_ORDER_ITEM,
  payload,
});

export const addNoteToTransportOrderItemSuccess = payload => ({
  type: ADD_NOTE_TRANSPORT_ORDER_ITEM_SUCCESS,
  payload,
});

export const changeStatusTransportOrderItem = payload => ({
  type: CHANGE_STATUS_TRANSPORT_ORDER_ITEM,
  payload,
});

export const changeStatusTransportOrderItemSuccess = payload => ({
  type: CHANGE_STATUS_TRANSPORT_ORDER_ITEM_SUCCESS,
  payload,
});

export const addNewTransportOrders = payload => ({
  type: ADD_NEW_TRANSPORT_ORDERS,
  payload,
});

export const updateTransportOrder = item => ({
  type: UPDATE_TRANSPORT_ORDER,
  payload: item,
});

export const deleteTransportOrder = item => ({
  type: DELETE_TRANSPORT_ORDER,
  payload: item,
});

export const deleteTransportOrders = payload => ({
  type: DELETE_TRANSPORT_ORDERS,
  payload,
});

export const clearTransportOrderError = () => ({
  type: TRANSPORT_ORDER_CLEAR_ERROR,
});
