// STOCK_ADJUSTMENT
export const STOCK_ADJUSTMENT_ADD_NEW = 'STOCK_ADJUSTMENT_ADD_NEW';
export const STOCK_ADJUSTMENT_ADD_NEW_SUCCESS = 'STOCK_ADJUSTMENT_ADD_NEW_SUCCESS';
export const STOCK_ADJUSTMENT_ADD_NEW_ERROR = 'STOCK_ADJUSTMENT_ADD_NEW_ERROR';

export const STOCK_ADJUSTMENT_GET_PAGING = 'STOCK_ADJUSTMENT_GET_PAGING';
export const STOCK_ADJUSTMENT_GET_PAGING_SUCCESS = 'STOCK_ADJUSTMENT_GET_PAGING_SUCCESS';
export const STOCK_ADJUSTMENT_GET_PAGING_ERROR = 'STOCK_ADJUSTMENT_GET_PAGING_ERROR';

export const STOCK_ADJUSTMENT_GET_BY_ID = 'STOCK_ADJUSTMENT_GET_BY_ID';
export const STOCK_ADJUSTMENT_GET_BY_ID_SUCCESS = 'STOCK_ADJUSTMENT_GET_BY_ID_SUCCESS';
export const STOCK_ADJUSTMENT_GET_BY_ID_ERROR = 'STOCK_ADJUSTMENT_GET_BY_ID_ERROR';

export const STOCK_ADJUSTMENT_UPDATE = 'STOCK_ADJUSTMENT_UPDATE';
export const STOCK_ADJUSTMENT_UPDATE_SUCCESS = 'STOCK_ADJUSTMENT_UPDATE_SUCCESS';
export const STOCK_ADJUSTMENT_UPDATE_ERROR = 'STOCK_ADJUSTMENT_UPDATE_ERROR';

export const STOCK_ADJUSTMENT_DELETE = 'STOCK_ADJUSTMENT_DELETE';
export const STOCK_ADJUSTMENT_DELETE_SUCCESS = 'STOCK_ADJUSTMENT_DELETE_SUCCESS';
export const STOCK_ADJUSTMENT_DELETE_ERROR = 'STOCK_ADJUSTMENT_DELETE_ERROR';
