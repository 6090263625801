import {
  SALE_ORDER_INVOICE_PAYMENT_ADD_NEW,
  SALE_ORDER_INVOICE_PAYMENT_ADD_NEW_ERROR,
  SALE_ORDER_INVOICE_PAYMENT_ADD_NEW_SUCCESS,
  SALE_ORDER_INVOICE_PAYMENT_DELETE,
  SALE_ORDER_INVOICE_PAYMENT_DELETE_ERROR,
  SALE_ORDER_INVOICE_PAYMENT_DELETE_SUCCESS,
  SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID,
  SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID_ERROR,
  SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID_SUCCESS,
  SALE_ORDER_INVOICE_PAYMENT_GET_PAGING,
  SALE_ORDER_INVOICE_PAYMENT_GET_PAGING_ERROR,
  SALE_ORDER_INVOICE_PAYMENT_GET_PAGING_SUCCESS,
  SALE_ORDER_INVOICE_PAYMENT_UPDATE,
  SALE_ORDER_INVOICE_PAYMENT_UPDATE_ERROR,
  SALE_ORDER_INVOICE_PAYMENT_UPDATE_SUCCESS,
} from './actionType';

// SaleOrderInvoicePaymentPayment
export const getPagingSaleOrderInvoicePayment = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_PAGING,
  payload: items,
});

export const getPagingSaleOrderInvoicePaymentSuccess = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingSaleOrderInvoicePaymentError = error => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addSaleOrderInvoicePayment = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_ADD_NEW,
  payload: items,
});

export const addSaleOrderInvoicePaymentSuccess = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addSaleOrderInvoicePaymentError = error => ({
  type: SALE_ORDER_INVOICE_PAYMENT_ADD_NEW_ERROR,
  payload: error,
});

export const getSaleOrderInvoicePaymentById = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID,
  payload: items,
});

export const getSaleOrderInvoicePaymentByIdSuccess = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getSaleOrderInvoicePaymentByIdError = error => ({
  type: SALE_ORDER_INVOICE_PAYMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateSaleOrderInvoicePayment = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_UPDATE,
  payload: items,
});

export const updateSaleOrderInvoicePaymentSuccess = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateSaleOrderInvoicePaymentError = error => ({
  type: SALE_ORDER_INVOICE_PAYMENT_UPDATE_ERROR,
  payload: error,
});

export const deleteSaleOrderInvoicePayment = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_DELETE,
  payload: items,
});

export const deleteSaleOrderInvoicePaymentSuccess = items => ({
  type: SALE_ORDER_INVOICE_PAYMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteSaleOrderInvoicePaymentError = error => ({
  type: SALE_ORDER_INVOICE_PAYMENT_DELETE_ERROR,
  payload: error,
});
