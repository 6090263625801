// PRODUCT
export const PRODUCT_ADD_NEW = 'PRODUCT_ADD_NEW';
export const PRODUCT_ADD_NEW_SUCCESS = 'PRODUCT_ADD_NEW_SUCCESS';
export const PRODUCT_ADD_NEW_ERROR = 'PRODUCT_ADD_NEW_ERROR';

export const PRODUCT_GET_PAGING = 'PRODUCT_GET_PAGING';
export const PRODUCT_GET_PAGING_SUCCESS = 'PRODUCT_GET_PAGING_SUCCESS';
export const PRODUCT_GET_PAGING_ERROR = 'PRODUCT_GET_PAGING_ERROR';

export const PRODUCT_GET_BY_ID = 'PRODUCT_GET_BY_ID';
export const PRODUCT_GET_BY_ID_SUCCESS = 'PRODUCT_GET_BY_ID_SUCCESS';
export const PRODUCT_GET_BY_ID_ERROR = 'PRODUCT_GET_BY_ID_ERROR';

export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';

export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';
