import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse,
  Container, FormGroup,
  Input,
  Row,
} from "reactstrap";
import { timestampFileName, hasAccess} from "../../helpers/utils";
import {
  // SaleOrderInvoice
  getPagingSaleOrderInvoice,
  addSaleOrderInvoice,
  updateSaleOrderInvoice,
  getSaleOrderInvoiceById,
  deleteSaleOrderInvoice,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { saleOrderInvoiceService } from "services/sale-order-invoice-service";
import { permission } from "constants/permission";
import { debounce } from "lodash";
import SaleOrderInvoiceTable from "containers/sale-order-invoice/SaleOrderInvoiceTable";
import Select from "react-select";
import {
  saleOrderInvoiceOptions,
  saleOrderPaymentStatusOptions, 
  saleOrderShippingStatusOptions, 
  saleOrderStatusOptions,
} from "../../helpers/utils";
import { Value } from "sass";

const SaleOrderInvoiceList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [ isCollapse, setIsCollapse ] = useState(false);
  const [ statuses, setStatuses ] = useState([]);
  const [ paymentStatuses, setPaymentStatuses ] = useState([])
  const [ shippingStatuses, setShippingStatuses ] = useState([])
  const [moreActionIsOpen, setMoreActionIsOpen] = useState(false);
  const [mySaleOrderInvoice, setMySaleOrderInvoice] = useState(true); 

  const {
    getPagingSaleOrderInvoice,
    history,
    deleteSaleOrderInvoice,
    saleOrderInvoice
  } = props;

  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = saleOrderInvoice;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingSaleOrderInvoice({
      page,
      pageSize,
      term,
      mySaleOrderInvoice,
      statuses: statuses ? statuses.map(status => {
        return status.value
      }) : null,
      paymentStatuses: paymentStatuses ? paymentStatuses.map(status => {
        return status.value
      }) : null,
      shippingStatuses: shippingStatuses ? shippingStatuses.map(status => {
        return status.value
      }) : null,
    });
  }, [ page, pageSize, term,mySaleOrderInvoice, statuses, paymentStatuses, shippingStatuses ]);
  
  const handleOnEdit = id => {
    history.push(`/sale-order-invoice/${ id }`)
  };

  const handleStatusChange = data => {
    ({
      data,
      queryParams: {
        page,
        pageSize,
        term,
        mySaleOrderInvoice,
        statuses: statuses ? statuses.map(status => {
          return status.value
        }) : null,
        paymentStatuses: paymentStatuses ? paymentStatuses.map(status => {
          return status.value
        }) : null,
        shippingStatuses: shippingStatuses ? shippingStatuses.map(status => {
          return status.value
        }) : null,
      },
    });
  };

  const toggleMoreAction = () => {
    setMoreActionIsOpen(prevState => !prevState);
  };

  const handleExportToExcel = () => {
    saleOrderInvoiceService.exportToExcelRequest(
        {
          invoiceNo: term ?? "", 
          startDate: null, 
          endDate: null, 
          status: statuses.length > 0 ? statuses.map(status => status.value) : null, 
          saleOrderId: null, 
          invoiceToUserId: null, 
          note: null, 
          dueDateStart: null, 
          dueDateEnd: null, 
          minTotalAmount: null,
          maxTotalAmount: null,
        },
        `${timestampFileName()}_SaleOrderInvoice_List.xlsx`
    );
};

  const handleOnAddNew = () => {
    history.push("/sale-order/add-new")
  }

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteSaleOrderInvoice({
      data: id,
      queryParams: {}
    });
    setModalConfirmDeleteIsOpen(false);
  };

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    if (name === "status") {
      setStatuses(valueType);
    } else if (name === "mySaleOrderInvoice"){
      setMySaleOrderInvoice(valueType ? valueType.value : false);
    }
    else if (name === "paymentStatus") {
      setPaymentStatuses(valueType);
    } else if (name === "shippingStatus") {
      setShippingStatuses(valueType);
    }
  }
 

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sale Order Invoice | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Sale Order Invoice" breadcrumbItem="Sale Order Invoice List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      { hasAccess(permission.mySaleOrderInvoice.enable) && ( 
                     <Col>
                      <FormGroup>
                        <Select
                          name="mySaleOrderInvoice"
                          value={saleOrderInvoiceOptions.find(option => option.value === mySaleOrderInvoice)}
                          onChange={handleSelectChange}
                          options={saleOrderInvoiceOptions}
                          classNamePrefix="select2-selection"
                          placeholder="Order Type"
                          isClearable
                        />
                      </FormGroup>
                      </Col>
                      )
                    } 
                      <Col>
                        <FormGroup>
                          <Select
                              name="status"
                              value={ statuses }
                              onChange={ handleSelectChange }
                              options={ saleOrderStatusOptions }
                              classNamePrefix="select2-selection"
                              placeholder="Status"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Select
                              name="paymentStatus"
                              value={ paymentStatuses }
                              onChange={ handleSelectChange }
                              options={ saleOrderPaymentStatusOptions }
                              classNamePrefix="select2-selection"
                              placeholder="Payment Status"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                      <ButtonDropdown
                          isOpen={moreActionIsOpen}
                          toggle={toggleMoreAction}
                      >
                          <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={handleExportToExcel}
                                  className="text-primary"
                              >
                                  <i className="fas fa-file-excel me-1"></i> Export to Excel
                              </DropdownItem>
                          </DropdownMenu>
                      </ButtonDropdown>
                      </Col>
                    </Row>
                    <div
                        style={ {
                          position: "absolute",
                          top: "1rem",
                          right: "0.5rem",
                          cursor: "pointer",
                        } }
                        onClick={ () => setIsCollapse(!isCollapse) }
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                            className={
                              isCollapse
                                  ? "fas fa-angle-up"
                                  : "fas fa-angle-down"
                            }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={ isCollapse }>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={ 4 }>
                          <FormGroup>
                            <Select
                                name="shippingStatus"
                                value={ shippingStatuses }
                                onChange={ handleSelectChange }
                                options={ saleOrderShippingStatusOptions }
                                classNamePrefix="select2-selection"
                                placeholder="Shipping Status"
                                isClearable
                                isMulti
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                        
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </> :
                      <>
                        { items && (
                            <SaleOrderInvoiceTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                defaultPageSize={ pageSize }
                                onEdit={ handleOnEdit }
                                onConfirmDelete={ handleConfirmDelete }
                                onChangePage={ (page) => setPage(page) }
                                onStatusChange={handleStatusChange}
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }
                
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

SaleOrderInvoiceList.propTypes = {
  saleOrderInvoice: PropTypes.object,

  getPagingSaleOrderInvoice: PropTypes.func,
  addSaleOrderInvoice: PropTypes.func,
  updateSaleOrderInvoice: PropTypes.func,
  getSaleOrderInvoiceById: PropTypes.func,
  deleteSaleOrderInvoice: PropTypes.func,
};

const mapStateToProps = ({ saleOrderInvoice }) => {
  return {
    saleOrderInvoice
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingSaleOrderInvoice,
      addSaleOrderInvoice,
      updateSaleOrderInvoice,
      getSaleOrderInvoiceById,
      deleteSaleOrderInvoice,
    })(SaleOrderInvoiceList)
);
