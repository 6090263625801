import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { addNewOrder } from "store/order/actions";
import { getCompanyName } from "../../helpers/utils";
import OrderForm2 from "../../containers/order/OrderForm2";

export class AddNewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = () => {
    const { history } = this.props;
    history.push(`/order/edit/${12}`);
  };

  handleSubmit = item => {
    const { history } = this.props;
    const payload = {
      data: item,
      history,
    };
    this.props.addNewOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/order");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>New Cargo | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Add New"
              breadcrumbItem="Add New Cargo"
              backLink={"/order"}
            />
            <OrderForm2
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isAddNew={true}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AddNewOrder.propTypes = {
  history: PropTypes.object,
  addNewOrder: PropTypes.func,
};

const mapStateToProps = ({ order }) => {
  return order;
};

export default withRouter(
  connect(mapStateToProps, {
    // getOrders,
    addNewOrder,
    // updateOrder,
    // getOrderById,
    // deleteOrder,
    // clearOrderError,
  })(AddNewOrder)
);
