import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";
import OrderAttachmentTab from "./OrderAttachmentTab";
import OrderNoteTab from "./OrderNoteTab";
import OrderTrackingTab from "./OrderTrackingTab";
import OrderNotificationHistoryTab from "./OrderNotificationHistoryTab";
import OrderMessageHistoryTab from "./OrderMessageHistoryTab";
import OrderVerifyTrackingTab from "./OrderVerifyTrackingTab";
import {
  updateOrder,
  getOrderById,
  updateOrderAttachment,
  deleteOrderAttachment,
  getOrderAttachments,
  getOrderNote,
  getOrderTracking,
  getOrderVerifyTracking,
  getOrderNotificationHistory,
  getOrderMessageHistory,
  getPagingCaseAudit,
  getPagingOrderDocument,
} from "store/actions";
import { getCompanyName, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";
import OrderForm2 from "containers/order/OrderForm2";
import CaseAuditTab from "./CaseAuditTab";
import OrderDocumentTab from "./OrderDocumentTab";

export class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const {
      match,
      getOrderById,
    } = this.props;
    const { id } = match.params;

    if (id !== NIL) {
      this.setState({
        id,
      });
      getOrderById(id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match,
      getOrderById,
    } = props;
    const { id } = match.params;
    if (id !== state.id) {
      getOrderById(id);
      return id;
    }
    return null;
  }
  
  handleSubmit = order => {
    const { updateOrder, history } = this.props;
    const payload = {
      history,
      data: order,
    };
    updateOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/order");
  };
  
  render() {
    const {
      order,
      orderNote,
      orderAttachment,
      orderTracking,
      orderVerifyTracking,
      orderNotificationHistory,
      orderMessageHistory,
      caseAudit,
      orderDocument,
        
      getOrderNote,
      getOrderTracking,
      getOrderAttachments,
      getPagingOrderDocument,
      getOrderVerifyTracking,
      getOrderNotificationHistory,
      getOrderMessageHistory
    } = this.props;
    const { item, loading } = order;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Cargo Detail | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/order"}
              breadcrumbItem="Cargo Detail"
            />
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      General
                    </NavLink>
                  </NavItem>
                  {hasAccess(permission.orderAttachment.enable) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                          const {id} = this.state;
                          getOrderAttachments({ orderId: id, pageSize: 12 })
                        }}
                      >
                        Attachments
                        <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                          {orderAttachment.itemCount}
                        </span>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "9",
                        })}
                        onClick={() => {
                          this.toggle("9");
                          const { id } = this.state;
                          getPagingOrderDocument({orderId: id})
                        }}
                    >
                      Documents
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {orderDocument.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                  {hasAccess(permission.orderNote.enable) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3");
                          const { id } = this.state;
                          getOrderNote({ orderId: id });
                        }}
                      >
                        Notes
                        <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                          {orderNote.itemCount}
                        </span>
                      </NavLink>
                    </NavItem>
                  )}

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4");
                        
                        const { id } = this.state;
                        getOrderTracking({ orderId: id });
                      }}
                    >
                      Tracking
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {orderTracking.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={() => {
                        this.toggle("5");
                        const { id } = this.state;
                        getOrderVerifyTracking({ orderId: id });
                      }}
                    >
                      Verify Tracking
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {orderVerifyTracking.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={() => {
                        this.toggle("6");
                        const { id } = this.state;
                        getOrderNotificationHistory({ orderId: id })
                      }}
                    >
                      Notification
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {orderNotificationHistory.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "7",
                      })}
                      onClick={() => {
                        this.toggle("7");
                        
                        const { id } = this.state;
                        getOrderMessageHistory({ orderId: id })
                      }}
                    >
                      Message
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {orderMessageHistory.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "8",
                        })}
                        onClick={() => {
                          this.toggle("8");
                          const { id } = this.state;
                          const { getPagingCaseAudit } = this.props;
                          getPagingCaseAudit({orderId: id})
                        }}
                    >
                      Case Audit
                      <span className="badge rounded-pill bg-success float-right ms-1 font-size-11">
                        {caseAudit.itemCount}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      item && (
                          <OrderForm2
                                id={this.state.id}
                                order={item}
                                onCancel={this.handleCancel}
                                onSubmit={this.handleSubmit}
                          />
                      )
                    )}
                  </TabPane>
                  {hasAccess(permission.orderAttachment.enable) && (
                    <TabPane tabId="2">
                      {this.state.id !== NIL && (
                        <OrderAttachmentTab orderId={this.state.id} />
                      )}
                    </TabPane>
                  )}
                  {hasAccess(permission.orderNote.enable) && (
                    <TabPane tabId="3">
                      {this.state.id !== NIL && (
                        <OrderNoteTab orderId={this.state.id} />
                      )}
                    </TabPane>
                  )}
                  <TabPane tabId="4">
                    {this.state.id !== NIL && (
                      <OrderTrackingTab orderId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="5">
                    {this.state.id !== NIL && (
                      <OrderVerifyTrackingTab orderId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="6">
                    {this.state.id !== NIL && (
                      <OrderNotificationHistoryTab orderId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="7">
                    {this.state.id !== NIL && (
                      <OrderMessageHistoryTab orderId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="8">
                    {this.state.id !== NIL && (
                        <CaseAuditTab orderId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="9">
                    {this.state.id !== NIL && (
                        <OrderDocumentTab orderId={this.state.id} />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OrderDetail.propTypes = {
  item: PropTypes.object,
  order: PropTypes.object,
  orderNote: PropTypes.object,
  orderAttachment: PropTypes.object,
  orderTracking: PropTypes.object,
  orderVerifyTracking: PropTypes.object,
  orderNotificationHistory: PropTypes.object,
  orderMessageHistory: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getOrderById: PropTypes.func,
  updateOrder: PropTypes.func,
  updateOrderAttachment: PropTypes.func,
  deleteOrderAttachment: PropTypes.func,
  getOrderAttachments: PropTypes.func,
  getOrderNote: PropTypes.func,
  getOrderTracking: PropTypes.func,
  getOrderVerifyTracking: PropTypes.func,
  getOrderNotificationHistory: PropTypes.func,
  getOrderMessageHistory: PropTypes.func,
  getPagingCaseAudit: PropTypes.func,
  getPagingOrderDocument: PropTypes.func,
};

const mapStateToProps = ({
  order,
  orderNote,
  orderAttachment,
  orderTracking,
  orderVerifyTracking,
  orderNotificationHistory,
  orderMessageHistory,
  caseAudit,
  orderDocument,
}) => {
  return {
    order,
    orderNote,
    orderTracking,
    orderAttachment,
    orderVerifyTracking,
    orderNotificationHistory,
    orderMessageHistory,
    caseAudit,
    orderDocument,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateOrder,
    getOrderById,
    updateOrderAttachment,
    deleteOrderAttachment,
    getOrderAttachments,
    getOrderNote,
    getOrderTracking,
    getOrderVerifyTracking,
    getOrderNotificationHistory,
    getOrderMessageHistory,
    getPagingCaseAudit,
    getPagingOrderDocument
  })(OrderDetail)
);
