import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  CardText,
  Container,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";

import { NIL } from "uuid";
import classnames from "classnames";
import {
  getItemShippingFeeById,
  getItemShippingFeeEntrys,
  getUserItemShippingFees,
} from "store/actions";
import ShippingEntryTab from "./ShippingEntryTab";
import ShippingCustomerTab from "./ShippingCustomerTab";
import { getUserIdsItemShippingFeesRequest } from "store/user-item-shipping-fee/saga";
import { getCompanyName } from "../../helpers/utils";

export class ShippingDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,

      modalAdd: false,
      modalEdit: false,
      modalDelete: false,

      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id !== NIL) {
      this.setState({
        id,
      });

      this.props.getItemShippingFeeById(id);
    }

    // getUserIdsItemShippingFeesRequest({ type: 0 }).then(res => {
    //   console.log(res);
    // });
  }

  populateUserShippingFee = () => {
    const { item } = this.props;
    const { id } = this.state;
    this.props.getUserItemShippingFees({
      itemShippingFeeId: id,
      term: "",
      type: item.type,
    });
  };

  populateItemShippingEntry = () => {
    const { id } = this.state;
    this.props.getItemShippingFeeEntrys({
      itemShippingFeeId: id,
      term: "",
    });
  };

  render() {
    const { item } = this.props;
    console.log(item);
    const title = item?.title ?? "";

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Shipping Fee | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Shipping Fee"
              backLink={"/shipping-fee"}
              breadcrumbItem={`Shipping Fee Detail - ${title}`}
            />
            <Row>
              <Col md={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.populateItemShippingEntry();
                      }}
                    >
                      Entry
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                        this.populateUserShippingFee();
                      }}
                    >
                      Customer
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.state.id && (
                      <ShippingEntryTab itemShippingFeeId={this.state.id} />
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.id && item && (
                      <ShippingCustomerTab
                        type={item.type}
                        itemShippingFeeId={this.state.id}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ShippingDetail.propTypes = {
  item: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemShippingFeeById: PropTypes.func,
  getUserItemShippingFees: PropTypes.func,
  getItemShippingFeeEntrys: PropTypes.func,
};

const mapStateToProps = ({ itemShippingFee }) => {
  return itemShippingFee;
};

export default withRouter(
  connect(mapStateToProps, {
    getItemShippingFeeById,
    getUserItemShippingFees,
    getItemShippingFeeEntrys,
  })(ShippingDetail)
);
