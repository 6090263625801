import { get, post, del, put as update } from "helpers/api_helper";
const getUsersRequest = async (query) => {
  return await post("/api/Account/users", query);
};

const quickCreateUserRequest = async (user) => {
  return await post("/api/Account/quickCreate", user);
};

const getUserPagingRequest = async query => {
  return await post("api/Account/paging", query);
};

const sendNotificationRequest = async query => {
  return await post("api/PushNotification/pushMessage", query);
};

export const userService = {
  getUsersRequest,
  quickCreateUserRequest,
  getUserPagingRequest,
  sendNotificationRequest,
};
