import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STOCK_OUT_ADD_NEW,
  STOCK_OUT_DELETE,
  STOCK_OUT_GET_BY_ID,
  STOCK_OUT_GET_PAGING,
  STOCK_OUT_UPDATE,
} from './actionType';

import {
  addStockOutSuccess,
  addStockOutError,
  getPagingStockOutSuccess,
  getPagingStockOutError,
  getStockOutByIdSuccess,
  getStockOutByIdError,
  updateStockOutSuccess,
  updateStockOutError,
  deleteStockOutSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// StockOut
const getPagingStockOutRequest = query => get("/api/StockOut/paging", { params: query });
const addNewStockOutRequest = data => post("/api/StockOut", data);
const getStockOutByIdRequest = id => get(`/api/StockOut/${id}`);
const updateStockOutRequest = data => update("/api/StockOut", data);
const deleteStockOutRequest = id => del(`/api/StockOut/${id}`);

const getStockOutToExcel = async (fileName) => {
  return await getFile(`/api/StockOut/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* addNewStockOut({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(addNewStockOutRequest, data);
    yield put(addStockOutSuccess(response));
    history.push('/stock-out')
    // response = yield call(getPagingStockOutRequest, queryParams);
    // yield put(getPagingStockOutSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    
    yield put(addStockOutError(err));
  }
}

function* updateStockOut({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateStockOutRequest, data);
    yield put(updateStockOutSuccess(response));
    history.push('/stock-out')
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(updateStockOutError(err));
  }
}

function* getPagingStockOut({ payload }) {
  try {
    let response = yield call(getPagingStockOutRequest, payload);
    yield put(getPagingStockOutSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockOutError(err));
  }
}

function* getStockOutById({ payload }) {
  try {
    let response = yield call(getStockOutByIdRequest, payload);
    yield put(getStockOutByIdSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getStockOutByIdError(err));
  }
}

function* deleteStockOut({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteStockOutRequest, data);

    yield put(deleteStockOutSuccess(response));
    response = yield call(getPagingStockOutRequest, queryParams);
    yield put(getPagingStockOutSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingStockOutError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(STOCK_OUT_ADD_NEW, addNewStockOut);
  yield takeEvery(STOCK_OUT_UPDATE, updateStockOut);
  yield takeEvery(STOCK_OUT_GET_PAGING, getPagingStockOut);
  yield takeEvery(STOCK_OUT_GET_BY_ID, getStockOutById);
  yield takeEvery(STOCK_OUT_DELETE, deleteStockOut);
}

export {
  getPagingStockOutRequest,
  getStockOutToExcel
}

