import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const UserCard = ({ number }) => {
  return (
    <Card color="success" className="mini-stats-wid mt-1 mb-2 border">
      <CardBody className="p-2">
        <div className="flex-grow-1">
          <p className="text-white font-size-12 fw-bold">New User this week</p>
          <h4 className="mb-0 text-white text-center">
            <NumberFormat
              value={number}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={false}
            />
          </h4>
        </div>
      </CardBody>
    </Card>
  );
};

UserCard.propTypes = {
  number: PropTypes.number,
};

export default UserCard;
