import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import Select from "react-select";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { getMaxValue, receiverDeliveryStatusOptions } from "helpers/utils";
import PhoneRow from "./PhoneRow";
const phoneTypeOptions = [
  { key: 0, value: "Primary", label: "Primary" },
  { key: 0, value: "Alternative", label: "Alternative" },
];

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      name: "",
      phones: [
        { id: NIL, type: "Primary", index: 0, phoneNumber: "099780960" },
      ],

      submitted: false,
    };
  }

  onOpened = () => {
    const { item, supplierName } = this.props;
    if (item) {
      this.setState({
        ...item,
        phones:
          item.phones && item.phones.length > 0
            ? item.phones.map((phone, index) => {
                return {
                  ...phone,
                  index,
                  type: phoneTypeOptions.find(e => e.value === phone.type),
                };
              })
            : [
                {
                  type: null,
                  phoneNumber: "",
                  index: 0,
                },
              ],
      });
    } else {
      this.setState({
        id: NIL,
        name: "",
        phones: [
          {
            type: null,
            phoneNumber: "",
            index: 0,
          },
        ],
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  isValidPhone = phone => phone && phone.phoneNumber && phone.type;

  isValidPhones = () => {
    const { phones } = this.state;
    return phones && phones.filter(e => this.isValidPhone(e));
  };

  handleSubmit = () => {
    const { id, name, phones } = this.state;
    this.setState({
      submitted: true,
    });

    console.log(this.isValidPhones());

    let isValid = name && this.isValidPhones().length > 0;

    if (isValid) {
      const item = {
        id,
        name,
        phones:
          phones &&
          phones
            .filter(p => this.isValidPhone(p))
            .map(phone => {
              return {
                id: phone.id,
                type: phone.type?.value ?? null,
                phoneNumber: phone.phoneNumber,
              };
            }),
      };

      this.props.onSubmit(item);
    }
  };

  handleOnAdd = () => {
    const { phones } = this.state;

    let max = 0;

    if (phones.length > 0) {
      max = getMaxValue(
        phones.map(a => {
          return a.index;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newPhone = {
      index: max,
      type: null,
      phoneNumber: "",
    };

    this.setState(prevState => ({
      phones: prevState.phones.concat(newPhone),
    }));
  };

  handleOnRemove = phone => {
    const { phones } = this.state;

    this.setState({
      phones: phones.filter(e => e.index !== phone.index),
    });
  };

  handleOnChange = phone => {
    this.setState(prevState => ({
      phones: prevState.phones.map(a => {
        return a.index === phone.index ? phone : a;
      }),
    }));
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, phones } = this.state;

    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Phones</Label>
                <div
                  className={
                    this.isValidPhones().length > 0 ? "" : "is-invalid"
                  }
                >
                  {phones.map((phone, index) => {
                    return (
                      <PhoneRow
                        phone={phone}
                        key={index}
                        isPrimary={index === 0}
                        onAdd={this.handleOnAdd}
                        onRemove={this.handleOnRemove}
                        onChange={this.handleOnChange}
                      />
                    );
                  })}
                </div>
                {submitted && !this.isValidPhones().length > 0 && (
                  <div className="invalid-feedback-custom">
                    Phone is invalid
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  supplierName: PropTypes.string,
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
