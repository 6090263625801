// TAG
export const TAG_ADD_NEW = 'TAG_ADD_NEW';
export const TAG_ADD_NEW_SUCCESS = 'TAG_ADD_NEW_SUCCESS';
export const TAG_ADD_NEW_ERROR = 'TAG_ADD_NEW_ERROR';

export const TAG_GET_PAGING = 'TAG_GET_PAGING';
export const TAG_GET_PAGING_SUCCESS = 'TAG_GET_PAGING_SUCCESS';
export const TAG_GET_PAGING_ERROR = 'TAG_GET_PAGING_ERROR';

export const TAG_GET_BY_ID = 'TAG_GET_BY_ID';
export const TAG_GET_BY_ID_SUCCESS = 'TAG_GET_BY_ID_SUCCESS';
export const TAG_GET_BY_ID_ERROR = 'TAG_GET_BY_ID_ERROR';

export const TAG_UPDATE = 'TAG_UPDATE';
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS';
export const TAG_UPDATE_ERROR = 'TAG_UPDATE_ERROR';

export const TAG_DELETE = 'TAG_DELETE';
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS';
export const TAG_DELETE_ERROR = 'TAG_DELETE_ERROR';
