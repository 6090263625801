import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

const documentTypeOptions = [
  {
    key: 0,
    value: 'National ID',
    label: 'National ID',
  },
  {
    key: 1,
    value: 'Family Book',
    label: 'Family Book',
  },
  {
    key: 3,
    value: 'Work Book',
    label: 'Work Book',
  }
]

const ModalForm = props => {
  const { isOpen, toggle, title, userId, onSubmit } = props;

  const [ item, setItem ] = useState({
    id: NIL,
    userId,
    title: '',
    documentType: '',
    fileName: '',
    url: '',
    note: '',
  });

  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const uploader = new FineUploaderTraditional({
    options: {
      multiple: false,
      request: {
        method: "POST",
        endpoint: `${ process.env.REACT_APP_API_URL }/api/FileUpload/userDocument`,
      },
      validation: {
        sizeLimit: 104857600, // 100MB
        // allowedExtensions: ["xlsx"],
      },

      // autoUpload: false,
      callbacks: {
        onSubmitted: function (id, name) {
          console.log(id);
        },

        onComplete: (id, name, responseJSON, xhr) => {
          if (responseJSON.success) {

            setItem({
              ...item,
              fileName: responseJSON.fileName,
              url: responseJSON.url
            })
          }
        },
        onError: (id, name, errorReason, xhr) => {
          // setError(errorReason);
        },
      },
    },
  });

  useEffect(() => {
    if (props.item) {
      let { item } = props;
      setItem({
        ...item,
        documentType: item.documentType ? documentTypeOptions.find(e => e.value === item.documentType) : null,
      })
    }
  }, [ props.item ]);

  const onOpened = () => {
    setIsSubmitted(false);
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    let isValid = item.title && item.documentType && item.fileName && item.url;

    if (isValid) {
      let data = {
        ...item,
        documentType: item.documentType?.value,
      }

      onSubmit(data);
    }
  }

  const handleChange = e => {
    const { value, name } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    setItem({
      ...item,
      [name]: valueType
    })
  }


  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                    type="text"
                    name="title"
                    placeholder="Title"
                    className={ isSubmitted && !item.title ? "is-invalid" : "" }
                    value={ item.title }
                    onChange={ handleChange }
                />
                { isSubmitted && !item.title && (
                    <div className="invalid-feedback-custom">Title is required.</div>
                ) }
              </FormGroup>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (isSubmitted && !item.documentType ? " is-invalid" : "")
                    }
                >
                  <Label>Document Type</Label>
                  <Select
                      name="documentType"
                      value={ item.documentType }
                      onChange={ handleSelectChange }
                      options={ documentTypeOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </div>
                { isSubmitted && !item.documentType && (
                    <div className="invalid-feedback-custom">
                      Document Type is required.
                    </div>
                ) }
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    rows={ 4 }
                    placeholder="Note"
                    value={ item.note }
                    onChange={ handleChange }
                />
              </FormGroup>
            </Col>
            <Col>
              {
                item.fileName ?
                    <div className={"mb-2"}>
                      <a href={ item.url } download={ item.fileName } target={ "_blank" }>
                        <i
                            className="mdi mdi-download font-size-18"
                        ></i>
                        <span className={ "ms-2" }>{ item.fileName }</span>
                      </a>
                    </div>
                    : null
              }
              <Gallery uploader={ uploader }/>
            </Col>
          </Row>

        </div>

        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  saleOrderId: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ModalForm;