import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
    getPagingProductTransaction,
    getProductTransactionById,
} from "store/actions";
import { debounce } from "lodash";
import ProductTransactionTable from "containers/product-transaction/ProductTransactionTable";

const ProductTransactionList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  const {
    getPagingProductTransaction,
    items,
    loading,
    itemCount,
    pageCount
  } = props;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingProductTransaction({ page, pageSize, term });
  }, [ page, pageSize, term ]);

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Transaction | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Transaction" breadcrumbItem="Transaction List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                     
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>:
                      <>
                        { items && (
                            <ProductTransactionTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                isProductTransaction={false}
                                defaultPageSize={ pageSize }                            
                                onChangePage={ (page) => setPage(page) }
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

ProductTransactionList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getPagingProductTransaction: PropTypes.func,
  getProductTransactionById: PropTypes.func,

};

const mapStateToProps = ({ productTransaction }) => {
  return productTransaction;
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingProductTransaction,
      getProductTransactionById,
    })(ProductTransactionList)
);
