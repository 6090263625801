import { call, put, takeEvery } from 'redux-saga/effects';
import {
  TAG_ADD_NEW,
  TAG_DELETE,
  TAG_GET_BY_ID,
  TAG_GET_PAGING,
  TAG_UPDATE,
} from './actionType';

import {
  addTagSuccess,
  addTagError,
  getPagingTagSuccess,
  getPagingTagError,
  getTagByIdSuccess,
  getTagByIdError,
  updateTagSuccess,
  updateTagError,
} from './actions';

import { del, get, post, put as update } from "../../helpers/api_helper";

const getTagsRequest = () => get("/api/Tag");
const getPagingTagRequest = query => get("/api/Tag/paging", { params: query });
const addNewTagRequest = data => post("/api/Tag", data);
const getTagByIdRequest = id => get(`/api/Tag/${ id }`);
const updateTagRequest = data => update("/api/Tag", data);
const deleteTagRequest = id => del(`/api/Tag/${ id }`);

function* addNewTag({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewTagRequest, data);
    yield put(addTagSuccess(response));

    response = yield call(getPagingTagRequest, queryParams);
    yield put(getPagingTagSuccess(response));
  } catch (error) {
    yield put(addTagError(error.response.statusText));
  }
}

function* updateTag({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateTagRequest, data);
    yield put(updateTagSuccess(response));

    response = yield call(getPagingTagRequest, queryParams);
    yield put(getPagingTagSuccess(response));
  } catch (error) {
    yield put(updateTagError(error.response.statusText));
  }
}

function* getPagingTag({ payload }) {
  try {
    let response = yield call(getPagingTagRequest, payload);
    yield put(getPagingTagSuccess(response));
  } catch (error) {
    yield put(getPagingTagError(error.response.statusText));
  }
}

function* getTagById({ payload }) {
  try {
    let response = yield call(getTagByIdRequest, payload);
    yield put(getTagByIdSuccess(response));
  } catch (error) {
    yield put(getTagByIdError(error.response.statusText));
  }
}

function* deleteTag({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteTagRequest, data);

    response = yield call(getPagingTagRequest, queryParams);
    yield put(getPagingTagSuccess(response));
  } catch (error) {
    yield put(getPagingTagError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(TAG_ADD_NEW, addNewTag);
  yield takeEvery(TAG_UPDATE, updateTag);
  yield takeEvery(TAG_GET_PAGING, getPagingTag);
  yield takeEvery(TAG_GET_BY_ID, getTagById);
  yield takeEvery(TAG_DELETE, deleteTag);
}

export {
  getTagsRequest,
  getTagByIdRequest,
  addNewTagRequest,
}