import React, { Component } from "react";
import PropTypes from "prop-types";
import InvoicePaymentTable from "containers/invoice-payment/InvoicePaymentTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getInvoicePayment,
  getInvoicePaymentById,
  addNewInvoicePayment,
  updateInvoicePayment,
  deleteInvoicePayment,
  clearInvoicePaymentNotify,
} from "store/actions";
import { invoicePaymentService } from "services/invoice-payment-service";
import { timestampFileName } from "helpers/utils";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import InvoicePaymentForm from "containers/invoice-payment/ModalForm";
import { toastMessage } from "helpers/utils";
import NumberFormat from "react-number-format";
import { sumBy } from "lodash";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

export class InvoicePaymentTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      invoiceId: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      invoicePayment: null,
    };
  }

  componentDidMount() {
    const { invoiceId } = this.props;
    this.setState({
      invoiceId,
    });
  }

  componentDidUpdate() {
    const { message, clearInvoicePaymentNotify } = this.props;

    if (message) {
      toastMessage(message);
      clearInvoicePaymentNotify();
    }
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  handleExportToExcel = () => {
    const { invoiceId } = this.state;
    invoicePaymentService.exportToExcelRequest(
      {
        invoiceId: invoiceId ?? "",
      },
      `${timestampFileName()}_Invoice_Payment_Id_List.xlsx`
    );
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    this.props.addNewInvoicePayment(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleOnEdit = id => {
    this.props.getInvoicePaymentById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    this.props.updateInvoicePayment(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };
  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { id, invoiceId } = this.state;
    this.props.deleteInvoicePayment({ id, invoiceId });
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleOnSearch = e => {
    const { invoiceId } = this.state;
    const { value } = e.target;
    this.props.getInvoicePayment({
      term: value ?? "",
      invoiceId,
    });
  };

  handleOnPageChange = page => {
    const { pageSize, invoiceId } = this.state;
    this.props.getInvoicePayment({
      page,
      pageSize,
      invoiceId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { invoiceId } = this.state;
    this.props.getInvoicePayment({
      page: 1,
      pageSize,
      invoiceId,
    });

    this.setState({
      pageSize,
    });
  };
  render() {
    const { invoice, invoicePayment } = this.props;
    const { pageSize, currentPage, invoiceId } = this.state;
    const { items, item, itemCount, pageCount, loading } = invoicePayment;
    let order = invoice.item;
    let totalAmount = 0;
    let totalPayment = 0;
    let outstanding = 0;
    if (order) {
      totalAmount = order.totalAmount;
      totalPayment = sumBy(
        items.filter(e => e.paymentStatus === 2), // 2 is Confirmed
        item => {
          return item.amount;
        }
      );
      outstanding = totalAmount - totalPayment;
    }
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={6}></Col>
              <Col md={4}>
                <div className="text-sm-end">
                  {hasAccess(permission.invoicePayment.read) && (
                    <Button
                      style={{
                        marginRight: "10px",
                      }}
                      type="button"
                      color="primary"
                      onClick={this.handleOnAddNew}
                    >
                      <i className="fas fa-plus" /> Add New
                    </Button>
                  )}
                  {hasAccess(permission.invoicePayment.export) && (
                    <Button
                      type="button"
                      color="primary"
                      outline
                      size="md"
                      onClick={this.handleExportToExcel}
                    >
                      <i className="fas fa-file-excel" /> Export to Excel
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card className="mb-2">
          <CardBody className="pb-0">
            <Row>
              <Col md={4}>
                <Card
                  color="primary"
                  className="border border-primary mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Total Amount</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                            value={totalAmount ?? 0}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            displayType="text"
                            prefix="₩ "
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title">
                          <i className={"fas fa-won-sign font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  color="success"
                  className="border border-success mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Total Payment</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                            value={totalPayment}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            displayType="text"
                            prefix="₩ "
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-success">
                          <i className={"bx bx-check-circle font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  color="warning"
                  className="border border-warning mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Balance Due</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                            value={outstanding}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            displayType="text"
                            prefix="₩ "
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-warning">
                          <i className={"bx bx-list-ol font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {items && (
          <InvoicePaymentTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onAddNew={this.handleOnAddNew}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}

        <InvoicePaymentForm
          title={"Add New Invoice Payment"}
          invoiceId={invoiceId}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <InvoicePaymentForm
            title={"Edit Invoice Payment"}
            invoicePayment={item}
            totalAmount={totalAmount}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

InvoicePaymentTab.propTypes = {
  invoiceId: PropTypes.string,
  invoice: PropTypes.object,
  invoicePayment: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoicePayment: PropTypes.func,
  getInvoicePaymentById: PropTypes.func,
  addNewInvoicePayment: PropTypes.func,
  updateInvoicePayment: PropTypes.func,
  deleteInvoicePayment: PropTypes.func,
  clearInvoicePaymentNotify: PropTypes.func,
};

const mapStateToProps = ({ invoice, invoicePayment }) => {
  return {
    invoice,
    invoicePayment,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoicePayment,
    getInvoicePaymentById,
    addNewInvoicePayment,
    updateInvoicePayment,
    deleteInvoicePayment,
    clearInvoicePaymentNotify,
  })(InvoicePaymentTab)
);
