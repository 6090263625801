import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Col,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { NIL } from "uuid";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PickupAttachmentTab from "./PickupAttachmentTab";
import PickupInfoTab from "./PickupInfoTab";
import { getCompanyName } from "../../helpers/utils";

const PickupDetail = props => {
  const [activeTab, setActiveTab] = useState(1);
  const { match } = props;
  const { id } = match.params;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pickup Detail | {getCompanyName()}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            backLink={"/pickup"}
            breadcrumbItem={`Pickup Detail`}
          />

          <Row>
            <Col lg={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 1,
                    })}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 2,
                    })}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                  >
                    Attachment
                    <span className="badge rounded-pill bg-success float-right ms-1 font-size-11"></span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 3,
                    })}
                    onClick={() => {
                      setActiveTab(3);
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <PickupInfoTab />
                </TabPane>
                <TabPane tabId={2}>
                  <PickupAttachmentTab />
                </TabPane>
                <TabPane tabId={3}></TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PickupDetail.propTypes = {
  match: PropTypes.object,
  // invoice: PropTypes.object,
  // invoicePayment: PropTypes.object,
  // invoiceNote: PropTypes.object,
  // item: PropTypes.object,
  // loading: PropTypes.bool,
  // history: PropTypes.object,
  // getInvoiceDetail: PropTypes.func,
  // getInvoicePayment: PropTypes.func,
  // getInvoiceNote: PropTypes.func,
};

const mapStateToProps = ({ pickup }) => {
  return {
    pickup,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    //   getInvoiceDetail,
    //   getInvoicePayment,
    //   getInvoiceNote,
  })(PickupDetail)
);
