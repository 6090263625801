import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardHeader, Col, Row } from "reactstrap";

const PickupAttachmentTab = ({ pickupId, ...props }) => {
  return (
    <>
      <Card className="mb-2 rounded-2">
        <CardHeader className="bg-transparent border pb-1 pt-3">
          <Row className="g-1">
            <Col md={2}></Col>
            <Col md={10}></Col>
          </Row>
        </CardHeader>
      </Card>
    </>
  );
};

PickupAttachmentTab.propTypes = {
  pickupId: PropTypes.string,
  //   items: PropTypes.array,
  //   itemCount: PropTypes.number,
  //   pageCount: PropTypes.number,
  //   loading: PropTypes.bool,
  //   getOrderAttachments: PropTypes.func,
  //   updateOrderAttachments: PropTypes.func,
  //   deleteOrderAttachment: PropTypes.func,
};

const mapStateToProps = ({ pickupAttachment }) => {
  return pickupAttachment;
};

export default withRouter(
  connect(mapStateToProps, {
    //   getOrderAttachments,
    //   updateOrderAttachments,
    //   deleteOrderAttachment,
    // updateOrder,
    // getOrderById,
    // updateOrderAttachment,
    // deleteOrderAttachment,
  })(PickupAttachmentTab)
);
