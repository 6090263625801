import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Modal, Row, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { userService } from "services/user-service";
import { debounce } from "lodash";

export class UserSelectionModalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: 0,
      pageCount: 0,

      isHeaderCheck: false,

      selectedUser: null,
      users: [],
    };
  }

  debouncedFetchData = debounce(term => {
    userService.getUserPagingRequest({ term }).then(res => {
      const { itemCount, pageCount, data } = res;
      this.setState({
        users: data.map(user => {
          return {
            ...user,
            isSelect: false,
          };
        }),
        itemCount,
        pageCount,
      });
    });
  }, 1000);

  handleRowCheck = (id, e) => {
    const { checked } = e.target;
    this.setState(prevState => ({
      users: prevState.users.map(user => {
        return ((user.id === id) ? { ...user, isSelect: checked } : user)
      })
    }))
  };

  handleFilter = e => {
    const { value } = e.target;
    this.debouncedFetchData(value);
  };

  onOpened = () => {
    const { notUserIds } = this.props;
    userService
        .getUserPagingRequest({ term: "", notUserIds: notUserIds })
        .then(res => {
          const { itemCount, pageCount, data } = res;
          this.setState({
            selectedUser: null,
            users: data.map(user => {
              return {
                ...user,
                isSelect: false,
              };
            }),
            itemCount,
            pageCount,
          });
        });
  };

  handleSubmit = () => {
    const { users } = this.state;
    if (users.length >0) {
      let selectedUsers = users.filter(e => e.isSelect);
      
      this.props.onSubmit(selectedUsers);
    }
  };

  handleHeaderCheck = e => {
    const { checked } = e.target;
    this.setState(prevState => ({
      isHeaderCheck: checked,
      users: prevState.users.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      }),
    }));
  };


  render() {
    const { isOpen, toggle, title } = this.props;
    const { users } = this.state;
    let selectedUsers = users.filter(e => e.isSelect);

    return (
        <Modal
            size="lg"
            onOpened={ this.onOpened }
            isOpen={ isOpen }
            toggle={ toggle }
            backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { title }
            </h5>
            <button
                type="button"
                onClick={ toggle }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Input
                      type="text"
                      name="search"
                      placeholder="Search: Id, Name, PhoneNumber ..."
                      onKeyUp={ e => this.handleFilter(e) }
                  />
                </FormGroup>
              </Col>
              <Col md={ 12 }>
                <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th className={ "text-center" }>
                        <Input
                            type="checkbox"
                            checked={ this.state.isHeaderCheck }
                            onClick={ this.handleHeaderCheck }
                            readOnly
                        />
                      </Th>
                      <Th>Id</Th>
                      <Th>Name</Th>
                      <Th>Phone</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    { users &&
                        users.map((user, index) => {
                          const name = `${ user.firstName } ${ user.lastName }`;
                          return (
                              <Tr key={ index }>
                                <Td className={ "text-center" }>
                                  <Input
                                      name={ `check_${ user.id }` }
                                      onClick={ e =>
                                          this.handleRowCheck(user.id, e)
                                      }
                                      checked={ user.isSelect }
                                      type="checkbox"
                                      readOnly
                                  />
                                </Td>
                                <Td>{ user.uniqueId }</Td>
                                <Td>{ name }</Td>
                                <Td>{ user.phoneNumber }</Td>
                              </Tr>
                          );
                        }) }
                  </Tbody>
                </Table>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
                color="primary"
                disabled={ !selectedUsers.length > 0 }
                onClick={ this.handleSubmit }
                type="submit"
            >
              Select
            </Button>
            <button
                type="button"
                onClick={ toggle }
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
    );
  }
}

UserSelectionModalList.propTypes = {
  item: PropTypes.object,
  notUserIds: PropTypes.array,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UserSelectionModalList;
