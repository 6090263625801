import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Modal } from "reactstrap";
import TransportSelectionCard from "./TransportSelectionCard";
import { getTransportsRequest } from "../../store/transport/saga";
import CustomPagination from "../../components/Common/CustomPagination";

const TransportSelectionModal = ({isOpen, toggle, title, statuses, onSubmit}) => {
  const [itemCount, setItemCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null);
  
  useEffect(() => {
    if(isOpen){
      getTransportsRequest({
        statuses,
        page,
        pageSize
      }).then(res =>{
        const {itemCount, pageCount, data} = res;
        setItemCount(itemCount);
        setPageCount(pageCount);
        setItems(data);
      });

    }
    
  }, [statuses, page, pageSize]);
  
  const handleOnSelect=(selectedItem)=>{
    setSelectedItem(selectedItem);
  }
  
  const onOpened=()=>{
    setSelectedItem(null);
  }
  
  const handleSubmit=()=>{
    onSubmit(selectedItem.id);
  }
  
  
  return (
      <Modal
          size="lg"
          onOpened={onOpened}
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {title}
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {items &&
              items.length >0 &&
            items.map((item, index)=>{
              let isSelected = selectedItem ? selectedItem.id === item.id : false ;
              return <TransportSelectionCard key={index}
                                             item={item}
                                             isSelected={isSelected}
                                             onSelect={handleOnSelect}
              /> 
            })
          }
          <CustomPagination
              itemCount={itemCount}
              currentPage={page}
              totalPage={pageCount}
              defaultPageSize={pageSize}
              pageSizeOptions={[5, 10, 15, 20, 25, 30]}
              onChangePage={i => this.handleOnChangePage(i)}
              // onPageSizeChange={size => this.handleOnPageSizeChange(size)}
          />
        </div>

        <div className="modal-footer">
          <Button color="primary" disabled={selectedItem === null} onClick={handleSubmit} type="submit">
            Select
          </Button>
          <button
              type="button"
              onClick={toggle}
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

TransportSelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  statuses: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default TransportSelectionModal;