import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

const PickupCard = ({ item }) => {
  return (
    <Card className="mt-2">
      <CardHeader className="bg-transparent border">
        <CardTitle>Pickup Summary</CardTitle>
        <Row>
          <Col md={3}>
            <Card color="warning" className="mini-stats-wid mt-1 mb-2 border">
              <CardBody className="p-2">
                <div className="flex-grow-1">
                  <p className="text-white font-size-12 fw-bold">Pending</p>
                  <h4 className="mb-0 text-white text-center">
                    <NumberFormat
                      value={item.pending}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card color="success" className="mini-stats-wid mt-1 mb-2 border">
              <CardBody className="p-2">
                <div className="flex-grow-1">
                  <p className="text-white font-size-12 fw-bold">Accept</p>
                  <h4 className="mb-0 text-white text-center">
                    <NumberFormat
                      value={item.accepted}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card color="danger" className="mini-stats-wid mt-1 mb-2 border">
              <CardBody className="p-2">
                <div className="flex-grow-1">
                  <p className="text-white font-size-12 fw-bold">Rejected</p>
                  <h4 className="mb-0 text-white text-center">
                    <NumberFormat
                      value={item.rejected}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card color="dark" className="mini-stats-wid mt-1 mb-2 border">
              <CardBody className="p-2">
                <div className="flex-grow-1">
                  <p className="text-white font-size-12 fw-bold">Closed</p>
                  <h4 className="mb-0 text-white text-center">
                    <NumberFormat
                      value={item.closed}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardHeader>
      {/* <CardBody></CardBody> */}
    </Card>
  );
};

PickupCard.propTypes = {
  item: PropTypes.object,
};

export default PickupCard;
