import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAIL,
  GET_CURRENCY_BY_ID_SUCCESS, GET_DEFAULT_CURRENCY_SUCCESS,
} from "./actionTypes.js";

const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const Currency = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURRENCIES:
      return {
        ...state,
        loading: true,
      };
    case GET_CURRENCIES_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_CURRENCY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_DEFAULT_CURRENCY_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_CURRENCIES_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default Currency;
