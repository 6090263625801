import {
  UOM_ADD_NEW,
  UOM_ADD_NEW_ERROR,
  UOM_ADD_NEW_SUCCESS,
  UOM_DELETE,
  UOM_DELETE_ERROR,
  UOM_DELETE_SUCCESS,
  UOM_GET_BY_ID,
  UOM_GET_BY_ID_ERROR,
  UOM_GET_BY_ID_SUCCESS,
  UOM_GET_PAGING,
  UOM_GET_PAGING_ERROR,
  UOM_GET_PAGING_SUCCESS,
  UOM_UPDATE,
  UOM_UPDATE_ERROR,
  UOM_UPDATE_SUCCESS,
} from './actionType';

// Uom
export const getPagingUom = items => ({
  type: UOM_GET_PAGING,
  payload: items,
});

export const getPagingUomSuccess = items => ({
  type: UOM_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingUomError = error => ({
  type: UOM_GET_PAGING_ERROR,
  payload: error,
});

export const addUom = items => ({
  type: UOM_ADD_NEW,
  payload: items,
});

export const addUomSuccess = items => ({
  type: UOM_ADD_NEW_SUCCESS,
  payload: items,
});

export const addUomError = error => ({
  type: UOM_ADD_NEW_ERROR,
  payload: error,
});

export const getUomById = items => ({
  type: UOM_GET_BY_ID,
  payload: items,
});

export const getUomByIdSuccess = items => ({
  type: UOM_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getUomByIdError = error => ({
  type: UOM_GET_BY_ID_ERROR,
  payload: error,
});

export const updateUom = items => ({
  type: UOM_UPDATE,
  payload: items,
});

export const updateUomSuccess = items => ({
  type: UOM_UPDATE_SUCCESS,
  payload: items,
});

export const updateUomError = error => ({
  type: UOM_UPDATE_ERROR,
  payload: error,
});

export const deleteUom = items => ({
  type: UOM_DELETE,
  payload: items,
});

export const deleteUomSuccess = items => ({
  type: UOM_DELETE_SUCCESS,
  payload: items,
});

export const deleteUomError = error => ({
  type: UOM_DELETE_ERROR,
  payload: error,
});
