import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",

      submitted: false,
    };
  }

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const { id, name } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = name;

    if (isValid) {
      const item = {
        id,
        title,
      };

      this.props.onSubmit(item);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.title && (
                  <div className="invalid-feedback-custom">
                    Title is required.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>

          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
