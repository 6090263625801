import { SEVERITY, toastMessage } from "helpers/utils";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  getDefaultCustomerServiceInfoRequest,
  saveDefaultCustomerServiceInfoRequest,
} from "store/settings/saga";
import { NIL } from "uuid";

const CustomerServiceInfoForm = () => {
  const [id, setId] = useState(null);
  const [krPhone1, setKRPhone1] = useState("");
  const [krPhone2, setKRPhone2] = useState("");
  const [krPhone3, setKRPhone3] = useState("");
  const [krPhone4, setKRPhone4] = useState("");

  const [kmPhone1, setKMPhone1] = useState("");
  const [kmPhone2, setKMPhone2] = useState("");
  const [kmPhone3, setKMPhone3] = useState("");
  const [kmPhone4, setKMPhone4] = useState("");

  const [monFrom, setMonFrom] = useState("");
  const [monTo, setMonTo] = useState("");
  const [tueFrom, setTueFrom] = useState("");
  const [tueTo, setTueTo] = useState("");
  const [wedFrom, setWedFrom] = useState("");
  const [wedTo, setWedTo] = useState("");
  const [thuFrom, setThuFrom] = useState("");
  const [thuTo, setThuTo] = useState("");
  const [friFrom, setFriFrom] = useState("");
  const [friTo, setFriTo] = useState("");
  const [satFrom, setSatFrom] = useState("");
  const [satTo, setSatTo] = useState("");
  const [sunFrom, setSunFrom] = useState("");
  const [sunTo, setSunTo] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const populateCustomerServiceInfo = () => {
    setIsLoading(true);
    getDefaultCustomerServiceInfoRequest().then(data => {
      if (data) {
        setId(data.id != NIL ? data.id : NIL);
        setKRPhone1(data?.krPhone1 ?? "");
        setKRPhone2(data?.krPhone2 ?? "");
        setKRPhone3(data?.krPhone3 ?? "");
        setKRPhone4(data?.krPhone4 ?? "");
        setKMPhone1(data?.kmPhone1 ?? "");
        setKMPhone2(data?.kmPhone2 ?? "");
        setKMPhone3(data?.kmPhone3 ?? "");
        setKMPhone4(data?.kmPhone4 ?? "");

        setMonFrom(data?.monFrom ?? "");
        setMonTo(data?.monTo ?? "");

        setTueFrom(data?.tueFrom ?? "");
        setTueTo(data?.tueTo ?? "");

        setWedFrom(data?.wedFrom ?? "");
        setWedTo(data?.wedTo ?? "");

        setThuFrom(data?.thuFrom ?? "");
        setThuTo(data?.thuTo ?? "");

        setFriFrom(data?.friFrom ?? "");
        setFriTo(data?.friTo ?? "");

        setSatFrom(data?.satFrom ?? "");
        setSatTo(data?.satTo ?? "");

        setSunFrom(data?.sunFrom ?? "");
        setSunTo(data?.sunTo ?? "");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    populateCustomerServiceInfo();
  }, []);

  const handleSubmit = () => {
    const data = {
      id,
      krPhone1,
      krPhone2,
      krPhone3,
      krPhone4,
      kmPhone1,
      kmPhone2,
      kmPhone3,
      kmPhone4,
      monFrom,
      monTo,
      tueFrom,
      tueTo,
      wedFrom,
      wedTo,
      thuFrom,
      thuTo,
      friFrom,
      friTo,
      satFrom,
      satTo,
      sunFrom,
      sunTo,
    };

    saveDefaultCustomerServiceInfoRequest(data).then(() => {
      toastMessage({
        title: "Customer Service Info",
        text: "Customer Service Info has been updated successfully.",
        severity: SEVERITY.SUCCESS,
      });
      populateCustomerServiceInfo();
    });
  };

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">
        <CardTitle>Contact and Working hour</CardTitle>
      </CardHeader>
      {isLoading ? (
        <CardBody>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </CardBody>
      ) : (
        <>
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label>Korean Phone</Label>
                  <Input
                    type="text"
                    name="krPhone1"
                    className="mb-3"
                    placeholder="Korean Phone"
                    value={krPhone1}
                    onChange={e => setKRPhone1(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="krPhone2"
                    className="mb-3"
                    placeholder="Korean Phone"
                    value={krPhone2}
                    onChange={e => setKRPhone2(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="krPhone3"
                    className="mb-3"
                    placeholder="Korean Phone"
                    value={krPhone3}
                    onChange={e => setKRPhone3(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="krPhone4"
                    className="mb-3"
                    placeholder="Korean Phone"
                    value={krPhone4}
                    onChange={e => setKRPhone4(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Cambodian Phone</Label>
                  <Input
                    type="text"
                    name="kmPhone1"
                    className="mb-3"
                    placeholder="Cambodian Phone"
                    value={kmPhone1}
                    onChange={e => setKMPhone1(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="kmPhone2"
                    className="mb-3"
                    placeholder="Cambodian Phone"
                    value={kmPhone2}
                    onChange={e => setKMPhone2(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="kmPhone3"
                    className="mb-3"
                    placeholder="Cambodian Phone"
                    value={kmPhone3}
                    onChange={e => setKMPhone3(e.target.value)}
                  />
                  <Input
                    type="text"
                    name="kmPhone4"
                    className="mb-3"
                    placeholder="Cambodian Phone"
                    value={kmPhone4}
                    onChange={e => setKMPhone4(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-2">
                  <Label>Monday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="monFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={monFrom}
                        onChange={e => setMonFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="monTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={monTo}
                        onChange={e => setMonTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-2">
                  <Label>Tuesday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="tueFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={tueFrom}
                        onChange={e => setTueFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="tueTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={tueTo}
                        onChange={e => setTueTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-2">
                  <Label>Wednesday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="wedFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={wedFrom}
                        onChange={e => setWedFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="wedTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={wedTo}
                        onChange={e => setWedTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Thurday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="thuFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={thuFrom}
                        onChange={e => setThuFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="thuTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={thuTo}
                        onChange={e => setThuTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Friday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="friFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={friFrom}
                        onChange={e => setFriFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="friTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={friTo}
                        onChange={e => setFriTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Saturday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="satFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={satFrom}
                        onChange={e => setSatFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="satTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={satTo}
                        onChange={e => setSatTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Sunday</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="sunFrom"
                        className="mb-1"
                        placeholder="From hour"
                        value={sunFrom}
                        onChange={e => setSunFrom(e.target.value)}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="sunTo"
                        className="mb-1"
                        placeholder="To hour"
                        value={sunTo}
                        onChange={e => setSunTo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </>
      )}

      <CardFooter className="bg-transparent border-top text-end">
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CustomerServiceInfoForm;
