import { call, put, takeEvery } from 'redux-saga/effects';
import {
  UOM_ADD_NEW,
  UOM_DELETE,
  UOM_GET_BY_ID,
  UOM_GET_PAGING,
  UOM_UPDATE,
} from './actionType';

import {
  addUomSuccess,
  addUomError,
  getPagingUomSuccess,
  getPagingUomError,
  getUomByIdSuccess,
  getUomByIdError,
  updateUomSuccess,
  updateUomError,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";

// Uom
const getPagingUomRequest = query => get("/api/Uom/paging", { params: query });
const addNewUomRequest = data => post("/api/Uom", data);
const getUomByIdRequest = id => get(`/api/Uom/${id}`);
const updateUomRequest = data => update("/api/Uom", data);
const deleteUomRequest = id => del(`/api/Uom/${id}`);

function* addNewUom({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewUomRequest, data);
    yield put(addUomSuccess(response));
    
    response = yield call(getPagingUomRequest, queryParams);
    yield put(getPagingUomSuccess(response));
  } catch (error) {
    yield put(addUomError(error.response.statusText));
  }
}

function* updateUom({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateUomRequest, data);
    yield put(updateUomSuccess(response));

    response = yield call(getPagingUomRequest, queryParams);
    yield put(getPagingUomSuccess(response));
  } catch (error) {
    yield put(updateUomError(error.response.statusText));
  }
}

function* getPagingUom({ payload }) {
  try {
    let response = yield call(getPagingUomRequest, payload);
    yield put(getPagingUomSuccess(response));
  } catch (error) {
    yield put(getPagingUomError(error.response.statusText));
  }
}

function* getUomById({ payload }) {
  try {
    let response = yield call(getUomByIdRequest, payload);
    yield put(getUomByIdSuccess(response));
  } catch (error) {
    yield put(getUomByIdError(error.response.statusText));
  }
}

function* deleteUom({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteUomRequest, data);

    response = yield call(getPagingUomRequest, queryParams);
    yield put(getPagingUomSuccess(response));
  } catch (error) {
    yield put(getPagingUomError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(UOM_ADD_NEW, addNewUom);
  yield takeEvery(UOM_UPDATE, updateUom);
  yield takeEvery(UOM_GET_PAGING, getPagingUom);
  yield takeEvery(UOM_GET_BY_ID, getUomById);
  yield takeEvery(UOM_DELETE, deleteUom);
}

export {
  getPagingUomRequest
}

