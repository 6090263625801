import {
  GET_ORDERS,
  GET_ORDER_BY_ID,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_BY_ID_SUCCESS,
  ORDER_CLEAR_ERROR,
  UPDATE_ORDER,
  SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,

  closedOrderIds: [],
  completedOrderIds: [],
  deliveryToCambodiaOrderIds: [],
  deliveryToCustomerOrderIds: [],
  draftOrderIds: [],
  inCambodiaWarehouseOrderIds: [],
  inKoreaWarehouseOrderIds: [],
  readyForDeliveryToCambodiaOrderIds: [],
  readyForDeliveryToCustomerOrderIds: [],
  rejectedOrderIds: [],
  loading: false,
};

const Order = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ORDER:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_BY_ID:
      return {
        ...state,
        loading: true,
        item: null,
      };
    case GET_ORDERS_SUCCESS:
      const {
        closedOrderIds,
        completedOrderIds,
        deliveryToCambodiaOrderIds,
        deliveryToCustomerOrderIds,
        draftOrderIds,
        inCambodiaWarehouseOrderIds,
        inKoreaWarehouseOrderIds,
        readyForDeliveryToCambodiaOrderIds,
        readyForDeliveryToCustomerOrderIds,
        rejectedOrderIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        closedOrderIds,
        completedOrderIds,
        deliveryToCambodiaOrderIds,
        deliveryToCustomerOrderIds,
        draftOrderIds,
        inCambodiaWarehouseOrderIds,
        inKoreaWarehouseOrderIds,
        readyForDeliveryToCambodiaOrderIds,
        readyForDeliveryToCustomerOrderIds,
        rejectedOrderIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case ORDER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case SUBMIT_ORDER_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        items: state.items.map(a => {
          return a.id === payload.id ? payload : a;
        }),
      };
    default:
      return state;
  }
};

export default Order;
