import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getInvoiceNote,
  addNewInvoiceNote,
  getInvoiceNoteById,
  updateInvoiceNote,
  deleteInvoiceNote,
  clearInvoiceNoteNotify,
} from "../../store/invoice-note/actions";

// import InvoiceNoteTable from "containers/invoice-note/InvoiceNoteTable";
import InvoiceNoteTable from "containers/invoice-note/InvoiceNoteTable";
import InvoiceNoteForm from "containers/invoice-note/InvoiceNoteForm";
import { Button, Card, CardHeader, Col, Input, Row } from "reactstrap";
import { toastMessage } from "helpers/utils";
import { getPermissions, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

class InvoiceNoteTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      invoiceId: props.invoiceId,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    const { invoiceId } = this.props;

    if (invoiceId) {
      this.setState({
        invoiceId,
      });
    }
  }

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    item.invoiceId = this.state.invoiceId;
    this.props.addNewInvoiceNote(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  componentDidUpdate() {
    const { message, clearInvoiceNoteNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearInvoiceNoteNotify();
    }
  }

  handleOnEdit = id => {
    this.props.getInvoiceNoteById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    this.props.updateInvoiceNote(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { id, invoiceId } = this.state;
    this.props.deleteInvoiceNote({ id, invoiceId });
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleOnSearch = e => {
    const { invoiceId } = this.state;

    const { value } = e.target;
    this.props.getInvoiceNote({
      term: value ?? "",
      invoiceId,
    });
  };

  handleOnPageChange = page => {
    const { pageSize, invoiceId } = this.state;
    this.props.getInvoiceNote({
      page,
      pageSize,
      invoiceId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { invoiceId } = this.state;
    this.props.getInvoiceNote({
      page: 1,
      pageSize,
      invoiceId,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage, invoiceId } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={6}></Col>
              <Col md={4}>
                {hasAccess(permission.invoiceNote.read) && (
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      onClick={this.handleOnAddNew}
                    >
                      <i className="fas fa-plus" /> Add New
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </CardHeader>
        </Card>

        {items && (
          <InvoiceNoteTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <InvoiceNoteForm
          title={"Add New Note"}
          invoiceId={invoiceId}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <InvoiceNoteForm
            title={"Edit Note"}
            item={item}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

InvoiceNoteTab.propTypes = {
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoiceNote: PropTypes.func,
  getInvoiceNoteById: PropTypes.func,
  addNewInvoiceNote: PropTypes.func,
  updateInvoiceNote: PropTypes.func,
  deleteInvoiceNote: PropTypes.func,
  clearInvoiceNoteNotify: PropTypes.func,
};

const mapStateToProps = ({ invoiceNote }) => {
  return invoiceNote;
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoiceNote,
    addNewInvoiceNote,
    getInvoiceNoteById,
    updateInvoiceNote,
    deleteInvoiceNote,
    clearInvoiceNoteNotify,
  })(InvoiceNoteTab)
);
