import { call, put, takeEvery } from 'redux-saga/effects';
import FileSaver from "file-saver";
import {
  SALE_ORDER_ADD_NEW,
  SALE_ORDER_CHANGE_STATUS,
  SALE_ORDER_DELETE,
  SALE_ORDER_GENERATE_STOCK_OUT,
  SALE_ORDER_GET_BY_ID,
  SALE_ORDER_GET_PAGING,
  SALE_ORDER_UPDATE,
} from './actionType';

import {
  addSaleOrderSuccess,
  addSaleOrderError,
  getPagingSaleOrderSuccess,
  getPagingSaleOrderError,
  getSaleOrderByIdSuccess,
  getSaleOrderByIdError,
  updateSaleOrderSuccess,
  updateSaleOrderError, generateStockOutFromSaleOrderSuccess, generateStockOutFromSaleOrderError,
} from './actions';
import { del, get, getFile, post, put as update } from "../../helpers/api_helper";

// SaleOrder
const getPagingSaleOrderRequest = query => post("/api/SaleOrder/paging", query);
const addNewSaleOrderRequest = data => post("/api/SaleOrder", data);
const getSaleOrderByIdRequest = id => get(`/api/SaleOrder/${ id }`);
const updateSaleOrderRequest = data => update("/api/SaleOrder", data);
const deleteSaleOrderRequest = id => del(`/api/SaleOrder/${ id }`);
const changeSaleOrderStatusRequest = query => get("/api/SaleOrder/statusChange", { params: query });
const generateStockOutFromSaleOrderRequest = (id) => get(`/api/SaleOrder/${ id }/generateStockOut`)

const getSaleOrderToExcel = async (fileName) => {
  return await getFile(`/api/SaleOrder/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([ data ]), fileName);
    }
  });
};


function* addNewSaleOrder({ payload }) {
  try {
    const {
      data,
      queryParams,
      history,
      navigateTo
    } = payload;

    let response = yield call(addNewSaleOrderRequest, data);
    
    if (response) {
      const { isValid, items } = response;
      if(isValid){
        if (queryParams) {
          response = yield call(getPagingSaleOrderRequest, queryParams);
          yield put(getPagingSaleOrderSuccess(response));
        }
        if (history) {
          history.push(navigateTo)
        }
      }else{
        if(items){
          yield put(addSaleOrderSuccess(response));
        }
      }
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(addSaleOrderError(err));
  }
}

function* updateSaleOrder({ payload }) {

  try {
    const {
      data,
      queryParams,
      history,
      navigateTo
    } = payload;
    
    let response = yield call(updateSaleOrderRequest, data);

    if (response) {
      const { isValid, items } = response;
      if(isValid){
        if (queryParams) {
          response = yield call(getPagingSaleOrderRequest, queryParams);
          yield put(getPagingSaleOrderSuccess(response));
        }
        if (history) {
          history.push(navigateTo)
        }
      }else{
        if(items){
          yield put(updateSaleOrderSuccess(response));
        }
      }
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(updateSaleOrderError(err));
  }
}

function* getPagingSaleOrder({ payload }) {
  try {
    let response = yield call(getPagingSaleOrderRequest, payload);
    yield put(getPagingSaleOrderSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingSaleOrderError(err));
  }
}

function* getSaleOrderById({ payload }) {
  try {
    let response = yield call(getSaleOrderByIdRequest, payload);
    yield put(getSaleOrderByIdSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getSaleOrderByIdError(err));
  }
}

function* deleteSaleOrder({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteSaleOrderRequest, data);

    response = yield call(getPagingSaleOrderRequest, queryParams);
    yield put(getPagingSaleOrderSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingSaleOrderError(err));
  }
}

function* changeSaleOrderStatus({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(changeSaleOrderStatusRequest, data);

    let response = yield call(getPagingSaleOrderRequest, queryParams);
    yield put(getPagingSaleOrderSuccess(response));

  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';

    yield put(getPagingSaleOrderError(err));
  }
}

function* generateStockOutFromSaleOrder({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(generateStockOutFromSaleOrderRequest, data);

    yield put(generateStockOutFromSaleOrderSuccess(response));

  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';

    yield put(generateStockOutFromSaleOrderError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(SALE_ORDER_ADD_NEW, addNewSaleOrder);
  yield takeEvery(SALE_ORDER_UPDATE, updateSaleOrder);
  yield takeEvery(SALE_ORDER_GET_PAGING, getPagingSaleOrder);
  yield takeEvery(SALE_ORDER_GET_BY_ID, getSaleOrderById);
  yield takeEvery(SALE_ORDER_DELETE, deleteSaleOrder);
  yield takeEvery(SALE_ORDER_CHANGE_STATUS, changeSaleOrderStatus);
  yield takeEvery(SALE_ORDER_GENERATE_STOCK_OUT, generateStockOutFromSaleOrder);
}

export {
  generateStockOutFromSaleOrderRequest,
  getPagingSaleOrderRequest,
  getSaleOrderToExcel
}

