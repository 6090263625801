export const GET_DELIVERY_TO_DOOR = "GET_DELIVERY_TO_DOOR";
export const GET_DELIVERY_TO_DOOR_SUCCESS = "GET_DELIVERY_TO_DOOR_SUCCESS";
export const GET_DELIVERY_TO_DOOR_FAIL = "GET_DELIVERY_TO_DOOR_FAIL";

export const GET_DELIVERY_TO_DOOR_BY_ID = "GET_DELIVERY_TO_DOOR_BY_ID";
export const GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS =
  "GET_DELIVERY_TO_DOOR_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY_TO_DOOR = "ADD_NEW_DELIVERY_TO_DOOR";
export const ADD_NEW_DELIVERY_TO_DOOR_SUCCESS =
  "ADD_NEW_DELIVERY_TO_DOOR_SUCCESS";

export const UPDATE_DELIVERY_TO_DOOR = "UPDATE_DELIVERY_TO_DOOR";
export const UPDATE_DELIVERY_TO_DOOR_SUCCESS =
  "UPDATE_DELIVERY_TO_DOOR_SUCCESS";

export const APPROVE_DELIVERY_TO_DOOR = "APPROVE_DELIVERY_TO_DOOR";
export const APPROVE_DELIVERY_TO_DOOR_SUCCESS =
  "APPROVE_DELIVERY_TO_DOOR_SUCCESS";

export const REJECT_DELIVERY_TO_DOOR = "REJECT_DELIVERY_TO_DOOR";
export const REJECT_DELIVERY_TO_DOOR_SUCCESS =
  "REJECT_DELIVERY_TO_DOOR_SUCCESS";

export const DELETE_DELIVERY_TO_DOOR = "DELETE_DELIVERY_TO_DOOR";
export const DELETE_DELIVERY_TO_DOOR_SUCCESS =
  "DELETE_DELIVERY_TO_DOOR_SUCCESS";

export const DELIVERY_TO_DOOR_CLEAR_NOTIFY = "DELIVERY_TO_DOOR_CLEAR_NOTIFY";
