import {
  GET_PICKUP,
  GET_PICKUP_SUCCESS,
  GET_PICKUP_FAIL,
  GET_PICKUP_BY_ID,
  GET_PICKUP_BY_ID_SUCCESS,
  ADD_NEW_PICKUP,
  ADD_NEW_PICKUP_SUCCESS,
  UPDATE_PICKUP,
  UPDATE_PICKUP_SUCCESS,
  DELETE_PICKUP,
  DELETE_PICKUP_SUCCESS,
  PICKUP_CLEAR_NOTIFY,
  APPROVE_PICKUP,
  APPROVE_PICKUP_SUCCESS,
  REJECT_PICKUP,
  REJECT_PICKUP_SUCCESS,
} from "./actionTypes";

// Pickup
export const getPickup = id => ({
  type: GET_PICKUP,
  payload: id,
});

export const getPickupSuccess = Pickups => ({
  type: GET_PICKUP_SUCCESS,
  payload: Pickups,
});

export const getPickupFail = error => ({
  type: GET_PICKUP_FAIL,
  payload: error,
});

export const getPickupById = data => ({
  type: GET_PICKUP_BY_ID,
  payload: data,
});

export const getPickupByIdSuccess = Pickup => ({
  type: GET_PICKUP_BY_ID_SUCCESS,
  payload: Pickup,
});

export const getPickupByIdFail = error => ({
  type: GET_PICKUP_BY_ID_FAIL,
  payload: error,
});

export const addNewPickup = payload => ({
  type: ADD_NEW_PICKUP,
  payload,
});

export const addNewPickupSuccess = payload => ({
  type: ADD_NEW_PICKUP_SUCCESS,
  payload,
});

export const updatePickup = payload => ({
  type: UPDATE_PICKUP,
  payload,
});

export const updatePickupSuccess = payload => ({
  type: UPDATE_PICKUP_SUCCESS,
  payload,
});

export const deletePickup = payload => ({
  type: DELETE_PICKUP,
  payload,
});
export const deletePickupSuccess = payload => ({
  type: DELETE_PICKUP_SUCCESS,
  payload,
});

export const getApprovePickupById = data => ({
  type: APPROVE_PICKUP,
  payload: data,
});

export const getApprovePickupByIdSuccess = Pickup => ({
  type: APPROVE_PICKUP_SUCCESS,
  payload: Pickup,
});

export const getRejectPickupById = data => ({
  type: REJECT_PICKUP,
  payload: data,
});

export const getRejectPickupByIdSuccess = Pickup => ({
  type: REJECT_PICKUP_SUCCESS,
  payload: Pickup,
});

export const clearPickupNotify = () => ({
  type: PICKUP_CLEAR_NOTIFY,
});
