import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button, Input, CardTitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";

class OrderTrackingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,
      onAddNew,
      onSearch,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <>
        <Card className="mb-2">
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={e => onSearch(e)}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </div>
              {/* <div className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => onAddNew()}
                >
                  <i className="fas fa-plus" /> Add
                </Button>
              </div> */}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="table-responsive"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered table-nowrap align-middle"
                  >
                    <thead>
                      <tr>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Created Date</Th>
                        <Th style={{ width: "120px" }}>Action</Th>
                      </tr>
                    </thead>
                    <Tbody>
                      {items.map((item, index) => {
                        return (
                          <Tr
                            key={index}
                            onDoubleClick={() => onEdit(item.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <Td>{item.title}</Td>
                            <Td>{item.description}</Td>
                            <Td>
                              {moment
                                .utc(item.createdDate)
                                .local()
                                .format("DD-MMM-yyyy h:mm:ss a")}
                            </Td>

                            <Td>
                              {/* <div className="d-flex gap-3">
                                <Link className="text-primary" to="#">
                                  <i
                                    className="mdi mdi-pencil font-size-18"
                                    id="edittooltip"
                                    onClick={() => onEdit(item.id)}
                                  ></i>
                                </Link>
                                <Link className="text-danger" to="#">
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => onConfirmDelete(item.id)}
                                  ></i>
                                </Link>
                              </div> */}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

OrderTrackingTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default OrderTrackingTable;
