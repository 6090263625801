import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";

const QuickCreateUserModal = props => {
  const { isOpen, toggle, title, onSubmit } = props;
  const [ isSubmitted, setIsSubmitted ] = useState(false)
  const [ item, setItem ] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: '',
  });

  const handleOnOpened = () => {
    if (props.item) {
      setItem({
        ...props.item,
      })
    }else{
      setItem({
        firstName: null,
        lastName: null,
        phoneNumber: '',
      });
    }
  }

  const handleOnSubmit = () => {
    onSubmit(item);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value
    })
  }

  return (
      <Modal
          size="md"
          onOpened={ handleOnOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup className="mb-3">
            <Label htmlFor="">Phone Number</Label>
            <Input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                className={ isSubmitted && !item.phoneNumber ? "is-invalid" : "" }
                value={ item.phoneNumber }
                onChange={ handleOnChange }
            />
            { isSubmitted && !item.phoneNumber && (
                <div className="invalid-feedback-custom">Phone Number is required.</div>
            ) }
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleOnSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

QuickCreateUserModal.propTypes = {};

export default QuickCreateUserModal;