import { call, put, takeEvery } from "redux-saga/effects";
import {
GET_PAGING_CASE_AUDIT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPagingCaseAuditSuccess, 
  getPagingCaseAuditFail,
} from "./actions";

const getPagingCaseAuditRequest = query => get("/api/CaseAudit/paging", { params: query });


function* getPagingCaseAudit({ payload }) {
  try {
    const response = yield call(getPagingCaseAuditRequest, payload);
    yield put(getPagingCaseAuditSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCaseAuditFail(error));
  }
}


function* caseAuditSaga() {
  yield takeEvery(GET_PAGING_CASE_AUDIT, getPagingCaseAudit);
}

export default caseAuditSaga;
